import React, { useCallback } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFieldArray } from 'react-hook-form';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ShippingEventNewFormInputs } from '../ShippingEventNewDialog';
import ShippingEventFileCategory from '../../../../common/enums/shipping-event-file-category.enum';
import ShippingEventFileListItem from './Item';

const useStyles = makeStyles((theme: Theme) => ({
  addFileButton: {
    color: theme.palette.info.main,
    padding: 25,
    width: '100%',
  },
  addFileButtonContainer: {
    width: '100%',
  },
  container: {
    border: '1px solid #C2C3C9',
    borderRadius: 4,
  },
  containerSpacing: {
    margin: 0,
    width: '100%',
  },
  descriptionIcon: {
    marginRight: 16,
  },
  fileInput: {
    display: 'none',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    padding: 16,
  },
}));

const ShippingEventFileList = () => {
  const classes = useStyles();

  const { append, fields, remove } = useFieldArray<ShippingEventNewFormInputs>({
    name: 'files',
  });

  const handleOnChange = useCallback(
    (event: React.BaseSyntheticEvent) => {
      const { files } = event.target;

      if (files) {
        append({
          category: 'no-option' as ShippingEventFileCategory & 'no-option',
          file: files[0],
        });
      }
    },
    [append]
  );

  const handleOnRemove = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  return (
    <Grid
      classes={{
        root: classes.container,
        'spacing-xs-4': classes.containerSpacing,
      }}
      container
      spacing={4}
    >
      {fields.length > 0 && (
        <>
          <Grid className={classes.title} item xs={12}>
            <DescriptionOutlinedIcon className={classes.descriptionIcon} />
            <Typography>Comprovantes</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>Tipo</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Nome</Typography>
          </Grid>
          {fields.map((field, index) => (
            <Grid item key={field.id} xs={12}>
              <ShippingEventFileListItem
                index={index}
                onRemove={() => handleOnRemove(index)}
              />
            </Grid>
          ))}
        </>
      )}
      <Box className={classes.addFileButtonContainer}>
        <label htmlFor="file">
          <input
            accept="image/*"
            className={classes.fileInput}
            id="file"
            type="file"
            onChange={handleOnChange}
          />
          <Button
            className={classes.addFileButton}
            component="span"
            size="large"
          >
            <AddIcon />
            Adicionar Comprovante
          </Button>
        </label>
      </Box>
    </Grid>
  );
};

export default ShippingEventFileList;
