import React from 'react';

import { Theme } from '@material-ui/core';
import dayjs from 'dayjs';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { decimalNumber } from '../../helpers/mask.helper';
import FiscalDocument from '../../data-types/fiscal-document-data';
import SkeletonField from '../SkeletonField';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 12,
    fontWeight: 300,
  },
  value: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export interface ShipmentPreviousDocumentsItemProps {
  isLoading: boolean;
  previousDocument?: FiscalDocument;
}

const ShipmentPreviousDocumentsItem = ({
  isLoading,
  previousDocument,
}: ShipmentPreviousDocumentsItemProps) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={3}>
        <SkeletonField isLoading={isLoading}>
          <Typography className={classes.label}>Número</Typography>
        </SkeletonField>
        <SkeletonField isLoading={isLoading}>
          <Typography
            className={classes.value}
            color="textPrimary"
            component="strong"
          >
            {previousDocument?.number || '---'}
          </Typography>
        </SkeletonField>
      </Grid>
      <Grid item xs={3}>
        <SkeletonField isLoading={isLoading}>
          <Typography className={classes.label}>Série</Typography>
        </SkeletonField>
        <SkeletonField isLoading={isLoading}>
          <Typography
            className={classes.value}
            color="textPrimary"
            component="strong"
          >
            {previousDocument?.serie != null ? previousDocument.serie : '---'}
          </Typography>
        </SkeletonField>
      </Grid>
      <Grid item xs={3}>
        <SkeletonField isLoading={isLoading}>
          <Typography className={classes.label}>Data Emissão</Typography>
        </SkeletonField>
        <SkeletonField isLoading={isLoading}>
          <Typography
            className={classes.value}
            color="textPrimary"
            component="strong"
          >
            {previousDocument?.issueDate
              ? dayjs(previousDocument?.issueDate).format('DD/MM/YYYY HH:mm')
              : '---'}
          </Typography>
        </SkeletonField>
      </Grid>
      <Grid item xs={3}>
        <SkeletonField isLoading={isLoading}>
          <Typography className={classes.label}>Valor</Typography>
        </SkeletonField>
        <SkeletonField isLoading={isLoading}>
          <Typography
            className={classes.value}
            color="textPrimary"
            component="strong"
          >
            {previousDocument?.value
              ? `R$ ${decimalNumber(previousDocument?.value)}`
              : '--- '}
          </Typography>
        </SkeletonField>
      </Grid>
    </Grid>
  );
};

export default ShipmentPreviousDocumentsItem;
