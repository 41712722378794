import React, { useCallback } from 'react';

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import NFe from '../../data-types/nfe-data';
import NfeListItem from './NfeListItem';

const useStyles = makeStyles({
  labels: {
    borderColor: '#C2C3C9',
    color: '#606771',
    fontWeight: 800,
  },
});

export interface NfeListProps {
  nfes: NFe[];
  onRemove: (index: number) => void;
  totalValue: number | null;
}

const NfeList = ({ nfes, onRemove, totalValue }: NfeListProps) => {
  const classes = useStyles();

  const handleRemoveNfe = useCallback(
    (index: number) => {
      onRemove(index);
    },
    [onRemove]
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.labels} padding="none" />
          <TableCell align="right" className={classes.labels}>
            Serie
          </TableCell>
          <TableCell className={classes.labels}>Número</TableCell>
          <TableCell className={classes.labels}>Data</TableCell>
          <TableCell className={classes.labels}>Pedido</TableCell>
          <TableCell align="right" className={classes.labels}>
            Qtd. vol
          </TableCell>
          <TableCell align="right" className={classes.labels}>
            Peso (Kg)
          </TableCell>
          <TableCell align="right" className={classes.labels}>
            Valor NF (R$)
          </TableCell>
          <TableCell className={classes.labels} align="right" />
        </TableRow>
      </TableHead>
      <TableBody>
        {nfes.map((nfe: NFe, index) => (
          <NfeListItem
            index={index}
            key={index}
            nfe={nfe}
            onRemove={handleRemoveNfe}
          />
        ))}
        {nfes.length > 1 && (
          <NfeListItem index={nfes.length + 1} totalValue={totalValue} />
        )}
      </TableBody>
    </Table>
  );
};

export default NfeList;
