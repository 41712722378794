export enum ShipmentStatus {
  Cancelled = 'cancelled',
  Draft = 'draft',
  Finished = 'finished',
  InTransit = 'inTransit',
  Ready = 'ready',
  ToWithdrawal = 'toWithdrawal',
  Unknown = 'unknown',
}

export const SHIPMENT_STATUS_LABEL_MAP: Map<ShipmentStatus, string> = new Map([
  [ShipmentStatus.Finished, 'Entregue'],
  [ShipmentStatus.InTransit, 'Em Trânsito'],
  [ShipmentStatus.Ready, 'Não Iniciado'],
  [ShipmentStatus.ToWithdrawal, 'Aguardando Retirada'],
]);
