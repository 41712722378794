import React from 'react';

import {
  TabPanel as MuiTabPanel,
  TabPanelProps as MuiTabPanelProps,
} from '@material-ui/lab';

type TabPanelProps = MuiTabPanelProps;

export const TabPanel = ({ children, ...rest }: TabPanelProps) => {
  return <MuiTabPanel {...rest}>{children}</MuiTabPanel>;
};
