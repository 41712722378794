import React from 'react';

import { Box, Tooltip, makeStyles, Typography } from '@material-ui/core';

import { getColorByStatus } from '../../helpers/status.helper';
import {
  ShipmentStatus,
  SHIPMENT_STATUS_LABEL_MAP,
} from '../../enums/shipment-status.enum';
import ShipmentModality from '../../enums/shipment-modality.enum';
import Skeleton from '../Skeleton';

interface StyleProps {
  color?: string;
  fontSize?: string | number;
}

const useStyles = makeStyles({
  status: {
    fontWeight: 'bold',
    fontSize: ({ fontSize }: StyleProps) => fontSize || '13px',
    color: ({ color }: StyleProps) => color || '#606771',
  },
});

export interface StatusProps {
  classes?: StyleProps;
  isLoading?: boolean;
  quantity?: number;
  receiptModality?: ShipmentModality;
  status: ShipmentStatus;
}

const Status = ({
  classes,
  isLoading = false,
  quantity,
  receiptModality,
  status,
}: StatusProps) => {
  const styles = useStyles(classes || { color: getColorByStatus(status) });

  const getTooltipText = () => {
    if (status === ShipmentStatus.Ready) {
      return receiptModality === ShipmentModality.Counter
        ? 'Entregue esses volumes na base da Enviabybus para que o envio possa ser iniciado'
        : 'Aguardando coleta';
    }
    if (status === ShipmentStatus.ToWithdrawal) {
      return 'Volumes estão disponíveis para retirada na base da Enviabybus';
    }
    return '';
  };

  return isLoading ? (
    <Skeleton width={100} />
  ) : (
    <Box alignItems="center" display="flex">
      <Tooltip arrow placement="top" title={`${quantity} Volumes`}>
        <Box
          alignItems="center"
          bgcolor={classes?.color || getColorByStatus(status)}
          borderRadius="0.25rem"
          color="#ffffff"
          display="flex"
          fontSize="0.75rem"
          fontWeight="bold"
          height="1.125rem"
          justifyContent="center"
          marginRight="0.375rem"
          minWidth="1.5625rem"
        >
          <p>{quantity}</p>
        </Box>
      </Tooltip>
      <Tooltip arrow placement="top" title={getTooltipText()}>
        <Typography className={styles.status}>
          {SHIPMENT_STATUS_LABEL_MAP.get(status)}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default Status;
