enum ShippingEventType {
  Delivery = 'delivery',
  GiveCustody = 'giveCustody',
  Loading = 'loading',
  ReceiveCustody = 'receiveCustody',
  Unloading = 'unloading',
}

export const SHIPPING_EVENT_TYPES = [
  ShippingEventType.Delivery,
  ShippingEventType.GiveCustody,
  ShippingEventType.Loading,
  ShippingEventType.ReceiveCustody,
  ShippingEventType.Unloading,
];

export const SHIPPING_EVENT_TYPE_LABEL_MAP = new Map([
  [ShippingEventType.Delivery, 'Entrega'],
  [ShippingEventType.GiveCustody, 'Entrega na base'],
  [ShippingEventType.Loading, 'Embarque'],
  [ShippingEventType.ReceiveCustody, 'Recebimento da carga'],
  [ShippingEventType.Unloading, 'Desembarque'],
]);

export default ShippingEventType;
