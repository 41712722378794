import React from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import { Pagination as MuiPagination } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  paginationRoot: {
    margin: '12px auto',
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: theme.palette.info.light,
      color: 'white',
    },
  },
}));

interface PaginationProps {
  count: number;
  currentPage: number;
  rowsPerPage?: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const Pagination = ({
  count,
  currentPage,
  rowsPerPage = 20,
  onChange,
}: PaginationProps) => {
  const classes = useStyles();

  return (
    <MuiPagination
      classes={{ root: classes.paginationRoot }}
      count={Math.ceil(count / rowsPerPage)}
      page={currentPage}
      onChange={onChange}
    />
  );
};

export default Pagination;
