import React from 'react';

import { Grid, makeStyles, Typography } from '@material-ui/core';

import { maskStringByPattern } from '../helpers/mask.helper';
import { PersonType } from '../enums/person-type.enum';
import EntityData from '../data-types/entity-data';
import Skeleton, { SkeletonWidthSize } from './Skeleton';

const useStyles = makeStyles({
  label: {
    fontSize: '0.75rem',
  },
  value: {
    fontSize: '0.75rem',
    fontWeight: 'bolder',
    marginTop: '0.1875rem',
  },
});

export interface EntityDetailsProps {
  entity: EntityData;
  isLoading: boolean;
  title: string;
}

const EntityDetails = ({ entity, isLoading, title }: EntityDetailsProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography color="secondary">{title}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.label} color="textPrimary">
          Nome
        </Typography>
        {isLoading ? (
          <Skeleton width={SkeletonWidthSize.Medium} />
        ) : (
          <Typography className={classes.value}>
            {entity?.displayName || entity?.name}
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        <Typography className={classes.label} color="textPrimary">
          {entity?.type === PersonType.Individual ? 'CPF' : 'CNPJ'}
        </Typography>
        {isLoading ? (
          <Skeleton width={SkeletonWidthSize.Medium} />
        ) : (
          <Typography className={classes.value}>
            {entity?.documentNumber
              ? maskStringByPattern(
                  entity?.documentNumber,
                  entity?.type === PersonType.Individual
                    ? '###.###.###-##'
                    : '##.###.###/####-##'
                )
              : '---'}
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        {entity?.type === PersonType.Corporation && (
          <>
            <Typography className={classes.label} color="textPrimary">
              Inscrição Estadual
            </Typography>
            <Typography className={classes.value}>
              {entity?.stateRegistration || '---'}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label} color="textPrimary">
          Endereço
        </Typography>
        {isLoading ? (
          <Skeleton width={SkeletonWidthSize.Medium} />
        ) : (
          <Typography className={classes.value}>
            {entity?.address
              ? `${entity?.address?.street} ${entity?.address?.number}${
                  entity?.address?.complement
                    ? ` ${entity?.address?.complement}`
                    : ''
                }, ${entity?.address?.neighborhood}, ${
                  entity?.address?.location
                } - ${entity?.address?.state} - ${maskStringByPattern(
                  entity?.address?.cep || '',
                  '#####-###'
                )} `
              : '---'}
          </Typography>
        )}
      </Grid>
      {entity?.code && (
        <Grid item xs={6}>
          <Typography className={classes.label} color="textPrimary">
            Código do cliente
          </Typography>
          {isLoading ? (
            <Skeleton width={SkeletonWidthSize.Medium} />
          ) : (
            <Typography className={classes.value}>{entity?.code} </Typography>
          )}
        </Grid>
      )}
      {entity?.phone && (
        <Grid item xs={entity?.code ? 3 : 6}>
          <Typography className={classes.label} color="textPrimary">
            Telefone
          </Typography>
          {isLoading ? (
            <Skeleton width={SkeletonWidthSize.Medium} />
          ) : (
            <Typography className={classes.value}>{entity?.phone}</Typography>
          )}
        </Grid>
      )}
      {entity?.email && (
        <Grid item xs={3}>
          <Typography className={classes.label} color="textPrimary">
            Email
          </Typography>
          <Typography className={classes.value}>{entity?.email} </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default EntityDetails;
