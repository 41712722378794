import { JSONSchema4, JSONSchema4Object, JSONSchema4Type } from 'json-schema';

// #region Typings
export interface BaseErrorDetails extends Partial<JSONSchema4Object> {
  body?: JSONSchema4Type;
  method?: string;
  status?: number;
  url?: string;
}

export interface BaseErrorAttributes {
  code?: string;
  details?: BaseErrorDetails;
  message?: string;
}
// #endregion Typings

export class BaseError extends Error implements BaseError {
  code: string;

  details?: BaseErrorDetails;

  constructor({
    code = 'unknown_error',
    details,
    message,
  }: BaseErrorAttributes) {
    super(message);

    Error.call(this, message);

    Error.captureStackTrace(this, this.constructor);

    this.code = code;
    this.details = details;
  }

  toJSON(): JSONSchema4 {
    const json: JSONSchema4 = {
      code: this.code,
      message: this.message,
    };

    if (this.details) {
      json.details = this.details;
    }

    return json;
  }
}

export default BaseError;
