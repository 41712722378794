export interface FileHelperParams {
  file?: File | Blob | string;
  showPrintOptions?: boolean;
}

export const fileHandler = ({
  file,
  showPrintOptions = false,
}: FileHelperParams) => {
  let fileURL = file;

  if (fileURL) {
    if (fileURL instanceof File || fileURL instanceof Blob) {
      fileURL = window.URL.createObjectURL(fileURL);
    }

    const tab = window.open(fileURL);

    if (tab && showPrintOptions) {
      tab.print();
    }
  }
};
