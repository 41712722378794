import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { RemoveCircle } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';

import {
  listPackagesThunk,
  selectPackages,
} from '../../../features/Packages/packagesSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Drawer from '../Drawer';
import PackageData from '../../data-types/package-data';
import PackageDrawer from '../PackageDrawer';
import Select, { SelectOption } from '../Select';
import TextField from '../TextField';

const useStyles = makeStyles((theme: Theme) => ({
  dimensions: {
    width: 80,
  },
  label: {
    whiteSpace: 'nowrap',
  },
  numberField: {
    '& .MuiInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  removeArrows: {
    '&::-webkit-clear-button, &::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  removeButton: {
    '&:hover': {
      color: theme.palette.error.main,
    },
    marginBottom: '-2.9rem',
  },
  savePackageGridItem: {
    alignItems: 'center',
    display: 'flex',
  },
  togglePackageLink: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    fontSize: '0.75rem',
    marginTop: 6,
  },
}));

interface ShipmentVolumeItemState {
  showPackageDrawer: boolean;
  showPackageList: boolean;
}

export interface ShipmentVolumeItemData {
  height: number;
  length: number;
  packageId: number | 'no-option';
  quantity: number;
  width: number;
}

interface ShipmentVolumeItemProps {
  index: number;
  onRemove: () => void;
}

export const ShipmentVolumeItem = ({
  index,
  onRemove,
}: ShipmentVolumeItemProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { control, setValue } = useFormContext();

  const packageId = useWatch({
    control,
    name: `volumes.${index}.packageId` as 'volumes.0.packageId',
  });
  const volume = useWatch({ control, name: `volumes.${index}` as 'volumes.0' });

  const packages = useAppSelector(selectPackages);

  const [state, setState] = useState<ShipmentVolumeItemState>({
    showPackageDrawer: false,
    showPackageList: false,
  });

  const hasAllDimensions = useMemo<boolean>(() => {
    const { height, length, width } = volume;

    return !!height && !!length && !!width;
  }, [volume]);

  const packagesOptions = useMemo<SelectOption[]>(
    () =>
      packages.map(({ height, id, length, name, width }) => ({
        label: `${name} (${length} x ${width} x ${height})`,
        value: id,
      })),
    [packages]
  );

  const showSavePackageButton = useMemo<boolean>(() => {
    return hasAllDimensions && !state.showPackageList;
  }, [hasAllDimensions, state.showPackageList]);

  const handleCreatePackage = useCallback(
    (id: PackageData['id']) => {
      setValue(`volumes.${index}.packageId` as 'volumes.0.packageId', id);

      setState((prevState) => ({
        ...prevState,
        showPackageDrawer: false,
        showPackageList: true,
      }));
    },
    [index, setValue]
  );

  const handleOpenPackageDrawer = () => {
    setState((prevState) => ({ ...prevState, showPackageDrawer: true }));
  };

  const handleOpenPackageList = () => {
    setState((prevState) => ({ ...prevState, showPackageList: true }));
  };

  const handleClosePackageDrawer = () => {
    setState((prevState) => ({ ...prevState, showPackageDrawer: false }));
  };

  const handleClosePackageList = () => {
    setState((prevState) => ({ ...prevState, showPackageList: false }));
  };

  useEffect(() => {
    const packageData = packages.find(
      (p) =>
        volume.height === p.height &&
        volume.length === p.length &&
        volume.width === p.width
    );

    if (packageData) {
      setValue(
        `volumes.${index}.packageId` as 'volumes.0.packageId',
        packageData.id
      );
      setState((prevState) => ({ ...prevState, showPackageList: true }));
    }
  }, [index, packages, setValue, volume.height, volume.length, volume.width]);

  useEffect(() => {
    const packageData = packages.find((p) => p.id === packageId);

    if (packageData) {
      const { height, length, width } = packageData;

      setValue(`volumes.${index}.height` as 'volumes.0.height', height);
      setValue(`volumes.${index}.length` as 'volumes.0.length', length);
      setValue(`volumes.${index}.width` as 'volumes.0.width', width);
    }
  }, [index, packageId, packages, setValue]);

  useEffect(() => {
    dispatch(listPackagesThunk());
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <TextField
            className={classes.numberField}
            control={control}
            InputLabelProps={{
              className: classes.label,
              shrink: true,
            }}
            label="Qtde."
            name={`volumes.${index}.quantity`}
            type="number"
          />
        </Grid>
        <Grid item sm={6}>
          {state.showPackageList ? (
            <Select
              control={control}
              defaultValue="no-option"
              emptyOptionsPlaceholder="Nenhuma embalagem cadastrada"
              fullWidth
              label="Dimensões - Comprimento x Largura x Altura (cm) *"
              labelStyle={{ whiteSpace: 'nowrap' }}
              name={`volumes.${index}.packageId`}
              options={packagesOptions}
              placeholder="Escolha uma embalagem"
            />
          ) : (
            <Box display="flex" flexDirection="column" width="100%">
              <Box
                alignItems="baseline"
                display="flex"
                justifyContent="space-between"
              >
                <TextField
                  className={`${classes.dimensions} ${classes.numberField}`}
                  control={control}
                  defaultValue=""
                  InputLabelProps={{
                    className: classes.label,
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                  label="Dimensões - Comprimento x Largura x Altura (cm) *"
                  name={`volumes.${index}.length`}
                  type="number"
                />
                <Typography color="textSecondary">x</Typography>
                <TextField
                  className={`${classes.dimensions} ${classes.numberField}`}
                  control={control}
                  defaultValue=""
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                  name={`volumes.${index}.width`}
                  type="number"
                />
                <Typography color="textSecondary">x</Typography>
                <TextField
                  className={`${classes.dimensions} ${classes.numberField}`}
                  control={control}
                  defaultValue=""
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                  name={`volumes.${index}.height`}
                  type="number"
                />
              </Box>
            </Box>
          )}
          <Typography
            className={classes.togglePackageLink}
            onClick={
              state.showPackageList
                ? handleClosePackageList
                : handleOpenPackageList
            }
          >
            {state.showPackageList
              ? 'Informar as dimensões'
              : 'Escolha uma embalagem cadastrada'}
          </Typography>
        </Grid>
        <Grid item sm={1}>
          <IconButton
            className={classes.removeButton}
            size="small"
            onClick={onRemove}
          >
            <RemoveCircle />
          </IconButton>
        </Grid>
        {showSavePackageButton && (
          <Grid classes={{ item: classes.savePackageGridItem }} item>
            <Button
              startIcon={<SaveIcon />}
              variant="outlined"
              onClick={handleOpenPackageDrawer}
            >
              Salvar embalagem
            </Button>
          </Grid>
        )}
      </Grid>
      <Drawer header="Nova embalagem" open={state.showPackageDrawer}>
        <PackageDrawer
          data={volume}
          onClose={handleClosePackageDrawer}
          onCreate={handleCreatePackage}
        />
      </Drawer>
    </>
  );
};

export default ShipmentVolumeItem;
