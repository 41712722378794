import { isNil, omitBy } from 'lodash';

import { fileToBase64 } from '../../common/services/base64.service';
import { getZordonClient } from '../../app/initializer';
import { HttpResponse } from '../../common/clients/http-client/http.client';
import AccountablePersonData from '../../common/data-types/accountable-person-data';
import ShippingEventFileData from '../../common/data-types/shipping-event-file-data';
import ShippingEventType from '../../common/enums/shipping-event-type.enum';

// #region Typings
interface CreateShippingEventParams {
  codes: Record<string, boolean>;
  comment?: string;
  date: Date;
  files: ShippingEventFileData[];
  responsible?: AccountablePersonData;
  type: ShippingEventType;
}
// #endregion Typings

const zordonClient = getZordonClient();

// #region Private functions
const codesToJSON = (codes: Record<string, boolean>): string[] => {
  const keys = Object.keys(codes);

  return keys.filter((key) => Boolean(codes[key]));
};

const dateToISOString = (date: Date): string => {
  return date.toISOString();
};

const fileToJSON = async ({
  category,
  file,
}: ShippingEventFileData): Promise<Record<string, any>> => {
  return {
    category,
    contentBase64: await fileToBase64(file),
    name: file.name,
  };
};

const responsibleToJSON = (
  responsible: AccountablePersonData
): Record<string, any> => {
  const { documentNumber, name } = responsible;

  return omitBy(
    {
      document: documentNumber,
      name,
    },
    isNil
  );
};

const shippingEventDataToJSON = async ({
  codes,
  comment,
  date,
  files,
  responsible,
}: Omit<CreateShippingEventParams, 'type'>): Promise<Record<string, any>> => {
  return omitBy(
    {
      barCodes: codesToJSON(codes),
      comments: comment || undefined,
      files:
        files && (await Promise.all(files.map((file) => fileToJSON(file)))),
      registeredAt: dateToISOString(date),
      responsible: responsible && responsibleToJSON(responsible),
    },
    isNil
  );
};

const typeToIdentifier = (type: ShippingEventType): string => {
  switch (type) {
    case ShippingEventType.Delivery:
      return 'delivery';
    case ShippingEventType.GiveCustody:
      return 'give-custody';
    case ShippingEventType.Loading:
      return 'loading';
    case ShippingEventType.ReceiveCustody:
      return 'receive-custody';
    case ShippingEventType.Unloading:
      return 'unloading';
    default:
      return '';
  }
};
// #endregion Private functions

// #region Public functions
export const createShippingEventByType = async ({
  codes,
  comment,
  date,
  files,
  responsible,
  type,
}: CreateShippingEventParams): Promise<HttpResponse> => {
  const reqBody = await shippingEventDataToJSON({
    codes,
    comment,
    date,
    files,
    responsible,
  });
  const identifier = typeToIdentifier(type);

  return zordonClient.createShippingEventByIdentifier(identifier, reqBody);
};
// #endregion Public functions
