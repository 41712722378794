import React from 'react';

import { Box, Button, ButtonProps, makeStyles } from '@material-ui/core';

import Spinner from './Spinner';

const useStyles = makeStyles({
  loadingText: {
    visibility: 'hidden',
  },
  spinner: {
    position: 'absolute',
  },
});

export interface SubmitButtonProps extends ButtonProps {
  children: React.ReactChildren;
  isLoading?: boolean;
}

const SubmitButton = ({ children, isLoading = false, ...rest }) => {
  const classes = useStyles();

  return (
    <Button {...rest}>
      {isLoading && <Spinner className={classes.spinner} size={24} />}
      <Box className={isLoading ? classes.loadingText : undefined}>
        {children}
      </Box>
    </Button>
  );
};

export default SubmitButton;
