import React, { useCallback, useState } from 'react';

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import {
  cleanRequests,
  readCteThunk,
} from '../../../features/Shipments/New/shipmentNewSlice';
import { DecodedCTe } from '../../data-types/decoded-fiscal-documents-data';
import { useAppDispatch } from '../../../app/hooks';
import CteFormFields from './CteFormFields';
import FormPlaceholder from '../FormPlaceholder';

export interface CTeFormInputs {
  ctes: DecodedCTe[];
  ctesHasError: boolean;
  hasCTes: boolean;
}

const CteForm = () => {
  const dispatch = useAppDispatch();

  const [isFieldOpen, setIsFieldOpen] = useState<boolean>(false);

  const handleOpenField = useCallback(() => {
    setIsFieldOpen(true);
  }, []);

  const handleOnUpload = useCallback(
    (files: File[]) => {
      dispatch(readCteThunk(files));
    },
    [dispatch]
  );

  const handleCleanRequests = useCallback(() => {
    dispatch(cleanRequests());
  }, [dispatch]);

  return (
    <>
      {isFieldOpen ? (
        <CteFormFields
          onUpload={handleOnUpload}
          onCleanRequests={handleCleanRequests}
        />
      ) : (
        <FormPlaceholder
          icon={<InsertDriveFileIcon />}
          title="Adicionar CTes"
          onOpen={handleOpenField}
        />
      )}
    </>
  );
};

export default CteForm;
