import React, { useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';

import {
  loadSession,
  selectIsAuthenticated,
  selectIsLoadingSession,
} from '../../features/Session/sessionSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';

const Routes = () => {
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isLoadingSession = useAppSelector(selectIsLoadingSession);

  useEffect(() => {
    dispatch(loadSession());
  }, [dispatch]);

  return (
    <BrowserRouter>
      {!isLoadingSession && (
        <>
          {isAuthenticated ? (
            <AuthenticatedRoutes />
          ) : (
            <UnauthenticatedRoutes />
          )}
        </>
      )}
    </BrowserRouter>
  );
};

export default Routes;
