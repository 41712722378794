import React, { ChangeEvent } from 'react';

import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@material-ui/core';
import { Control, useController } from 'react-hook-form';

export interface UncontrolledRadioGroupProps extends MuiRadioGroupProps {
  value?: string | number;
  onChange?: (event: ChangeEvent<any>, value: string | number) => void;
}

export const UncontrolledRadioGroup = ({
  value,
  onChange,
  ...rest
}: UncontrolledRadioGroupProps) => {
  return <MuiRadioGroup value={value} onChange={onChange} {...rest} />;
};

export interface RadioGroupProps
  extends Omit<UncontrolledRadioGroupProps, 'onChange'> {
  children: React.ReactNode;
  control: Control<any>;
  defaultValue?: string | number;
  name: string;
}

const RadioGroup = ({
  control,
  defaultValue,
  name,
  ...rest
}: RadioGroupProps) => {
  const {
    field: { onChange, value },
  } = useController({
    control,
    defaultValue,
    name,
  });

  return <UncontrolledRadioGroup value={value} onChange={onChange} {...rest} />;
};

export default RadioGroup;
