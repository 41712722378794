import React, { useCallback, useEffect } from 'react';

import { Divider, List, ListItem, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import HttpsIcon from '@material-ui/icons/Https';
import Typography from '@material-ui/core/Typography';

import {
  cleanRequests,
  passwordEditThunk,
  selectSessionErrorPasswordEdit,
  selectSessionIsFulfilledPasswordEdit,
  selectSessionIsRequestingPasswordEdit,
} from '../sessionSlice';
import { PAGE_SIZES } from '../../../common/constants/page-size.constants';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import GenericErrorDialog from '../../../common/components/GenericErrorDialog';
import PasswordEditData from '../../../common/data-types/password-edit-data';
import PasswordEditForm from './components/PasswordEditForm';
import SideBarButton from '../../../common/components/SideBarButton';

const useStyles = makeStyles({
  sectionTitle: {
    fontSize: '1.375rem',
    marginBottom: 30,
  },
  title: {
    margin: '30px 0',
  },
});

interface Props {
  title: string;
}

const UserEditPage = ({ title }: Props) => {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const error = useAppSelector(selectSessionErrorPasswordEdit);
  const isLoading = useAppSelector(selectSessionIsRequestingPasswordEdit);
  const isSucceeded = useAppSelector(selectSessionIsFulfilledPasswordEdit);

  const handleOnSubmit = (data: PasswordEditData) => {
    cleanStatuses();
    dispatch(passwordEditThunk(data));
  };

  const cleanStatuses = useCallback(() => {
    dispatch(cleanRequests());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      cleanStatuses();
    };
  }, [cleanStatuses]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Box
      m="auto"
      maxWidth={PAGE_SIZES.maxWidth}
      minWidth={PAGE_SIZES.minWidth}
      pl={PAGE_SIZES.sidePadding}
      pr={PAGE_SIZES.sidePadding}
    >
      <GenericErrorDialog error={error} />
      <Typography
        className={classes.title}
        color="primary"
        component="h2"
        variant="h4"
      >
        Minha Conta
      </Typography>
      <Grid container spacing={7}>
        <Grid item xs={3}>
          <List>
            <ListItem disableGutters>
              <SideBarButton
                icon={<AccountCircleIcon />}
                label="Perfil"
                onSelect={() => history.push('/user/profile')}
              />
            </ListItem>
          </List>
          <Divider style={{ margin: '0.5rem 0' }} />
          <List>
            <ListItem disableGutters>
              <SideBarButton
                icon={<HttpsIcon />}
                isSelected
                label="Alterar Senha"
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={9}>
          <Box
            bgcolor="white"
            border="1px solid #C2C3C9"
            padding="2.5rem 5.625rem"
          >
            <Typography
              className={classes.sectionTitle}
              color="primary"
              component="h3"
              variant="h4"
            >
              Alterar Senha
            </Typography>
            <Box width="25rem">
              <PasswordEditForm
                error={error}
                isLoading={isLoading}
                isSucceeded={isSucceeded}
                onCleanError={cleanStatuses}
                onSubmit={handleOnSubmit}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserEditPage;
