enum EntityResponsabilityType {
  Consignee = 'consignee',
  Dispatcher = 'dispatcher',
  Receiver = 'receiver',
  Recipient = 'recipient',
  Sender = 'sender',
}

export const ENTITY_RESPONSABILITY_LABEL_MAP = new Map<
  EntityResponsabilityType,
  string
>([
  [EntityResponsabilityType.Consignee, 'Tomador'],
  [EntityResponsabilityType.Dispatcher, 'Expedidor'],
  [EntityResponsabilityType.Receiver, 'Recebedor'],
  [EntityResponsabilityType.Recipient, 'Destinatário'],
  [EntityResponsabilityType.Sender, 'Remetente'],
]);

export default EntityResponsabilityType;
