import * as yup from 'yup';

import { ERROR_MESSAGES } from '../constants/error-messages';

const USER_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .email(ERROR_MESSAGES.string.email)
    .required(ERROR_MESSAGES.string.required),
  isAdmin: yup.boolean().required(),
  name: yup
    .string()
    .required(ERROR_MESSAGES.string.required)
    .min(3, ERROR_MESSAGES.string.min)
    .max(60, ERROR_MESSAGES.string.max),
  phone: yup.lazy((value = '') => {
    if (value.length < 1) {
      return yup.string().notRequired();
    }
    return yup
      .string()
      .min(14, ERROR_MESSAGES.string.min)
      .max(16, ERROR_MESSAGES.string.max)
      .required();
  }),
});

export default USER_SCHEMA;
