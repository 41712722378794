import React from 'react';

import { Box, makeStyles, Theme } from '@material-ui/core';

import SubmitButton from '../../../common/components/SubmitButton';
import withPackageForm, {
  PackageFormInputs,
  WithPackageFormProps,
} from '../../../common/hocs/withPackageForm';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    backgroundColor: theme.palette.info.main,
  },
}));

interface PackageNewFormProps {
  isLoading: boolean;
  onSubmit: (data: PackageFormInputs) => void;
}

const PackageNewForm = ({
  children,
  form,
  isLoading = false,
  onSubmit,
}: PackageNewFormProps & WithPackageFormProps) => {
  const classes = useStyles();

  const {
    formState: { isSubmitted, isValid },
    handleSubmit,
  } = form;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box marginBottom={2}>{children}</Box>
      <Box bgcolor="background.default">
        <Box display="flex" justifyContent="flex-end" py={2} width="50rem">
          <SubmitButton
            classes={{ root: classes.button }}
            color="primary"
            disabled={isLoading || (isSubmitted && !isValid)}
            isLoading={isLoading}
            type="submit"
            variant="contained"
          >
            Cadastrar
          </SubmitButton>
        </Box>
      </Box>
    </form>
  );
};

export default withPackageForm<PackageNewFormProps>(PackageNewForm);
