import React, { useCallback, useEffect, useState } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

import {
  listEntitiesThunk,
  selectEntitiesFilter,
  selectEntitiesFirst,
} from '../../features/Entities/entitiesSlice';
import { selectOrganizationId } from '../../features/Session/sessionSlice';
import { UncontrolledTextField } from './TextField';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: 20,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 10,
  },
}));

const EntityNameFilter = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const filter = useAppSelector(selectEntitiesFilter);
  const first = useAppSelector(selectEntitiesFirst);
  const organizationId = useAppSelector(selectOrganizationId);

  const [name, setName] = useState<string>('');

  const handleSearch = useCallback(() => {
    dispatch(
      listEntitiesThunk({
        filter: {
          ...filter,
          displayName: name === '' ? undefined : name,
          organizationIds: [organizationId],
        },
        first,
        page: 1,
      })
    );
  }, [dispatch, filter, first, name, organizationId]);

  const handleOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = event.target;
    setName(value);
  };

  useEffect(() => {
    setName(filter?.displayName || '');
  }, [filter]);

  useEffect(() => {
    if (name === '' && filter?.displayName) {
      handleSearch();
    }
  }, [filter?.displayName, handleSearch, name]);

  return (
    <Box mb={2.5}>
      <Typography className={classes.title}>Nome</Typography>
      <Paper className={classes.paper} square variant="outlined">
        <UncontrolledTextField
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSearch}>
                <SearchIcon color="action" />
              </IconButton>
            ),
          }}
          placeholder="Nome"
          type="text"
          value={name}
          onChange={handleOnChange}
        />
      </Paper>
    </Box>
  );
};

export default EntityNameFilter;
