import {
  darken,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import * as locales from '@material-ui/core/locale';

const theme = createMuiTheme(
  {
    breakpoints: {
      values: {
        lg: 1323,
        md: 960,
        sm: 600,
        xl: 1920,
        xs: 0,
      },
    },
    palette: {
      background: {
        default: '#F2F2F4',
        paper: '#FFFFFF',
      },
      error: {
        dark: darken('#F44336', 0.2),
        main: '#F44336',
      },
      grey: {
        500: '#C2C3C9',
      },
      info: {
        dark: darken('#1976D2', 0.2),
        light: '#017BFE',
        main: '#1976D2',
      },
      primary: {
        main: '#363487',
      },
      secondary: {
        main: '#2F159E',
      },
      success: {
        main: '#3BCF82',
      },
      text: {
        primary: '#606771',
      },
    },
    typography: {
      fontWeightBold: 700,
      h2: {
        color: '#363487',
        fontSize: 26,
        fontWeight: 700,
        margin: '1.875rem 0',
      },
      h3: {
        fontSize: 18,
        fontWeight: 700,
        margin: '1.25rem 0',
      },
      h4: {
        fontWeight: 700,
      },
    },
  },
  locales.ptBR
);

export default theme;
