import React, { useMemo } from 'react';

import {
  Box,
  Grid,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import { decimalNumber } from '../../helpers/mask.helper';
import { getColorByStatus } from '../../helpers/status.helper';
import { SHIPMENT_STATUS_LABEL_MAP } from '../../enums/shipment-status.enum';
import PackageIcon from '../PackageIcon';
import VolumeData from '../../data-types/volume-data';

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    marginRight: '1rem',
  },
  label: {
    fontSize: 12,
    fontWeight: 300,
  },
  packageIcon: {
    color: 'rgba(47, 21, 158, 0.54)',
  },
  sequential: {
    fontSize: 13,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: 1,
  },
  value: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface VolumeListItemProps {
  index: number;
  volume: VolumeData;
}

const VolumeListItem = ({ index, volume }: VolumeListItemProps) => {
  const classes = useStyles();

  const status = useMemo<string | undefined>(() => {
    return SHIPMENT_STATUS_LABEL_MAP.get(volume?.status);
  }, [volume.status]);

  const statusColor = useMemo<string>(() => {
    return getColorByStatus(volume.status);
  }, [volume.status]);

  return (
    <ListItem disableGutters>
      <Grid container>
        <Grid item xs={3}>
          <Box display="flex">
            <Box className={classes.iconContainer}>
              <PackageIcon className={classes.packageIcon} fontSize="large" />
              <Typography
                className={classes.sequential}
                color="textPrimary"
                component="strong"
              >
                {`${volume.sequentialNumber || index + 1}`}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.label}>
                Código do rastreio
              </Typography>
              <Typography
                className={classes.value}
                color="textPrimary"
                component="strong"
              >
                {volume.carrierCode || volume.trackingCode || '---'}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.label}>Situação</Typography>
          <Typography
            className={classes.value}
            component="strong"
            style={{ color: statusColor }}
          >
            {status || '---'}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.label}>Peso (Kg)</Typography>
          <Typography
            className={classes.value}
            color="textPrimary"
            component="strong"
          >
            {volume.weight ? decimalNumber(volume.weight) : '---'}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.label}>Peso cubado (Kg)</Typography>
          <Typography
            className={classes.value}
            color="textPrimary"
            component="strong"
          >
            {volume.cubageWeight ? decimalNumber(volume.cubageWeight) : '---'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.label}>Dimensões (cm)</Typography>
          <Typography
            className={classes.value}
            color="textPrimary"
            component="strong"
          >
            {!volume.length && !volume.width && !volume.height
              ? '---'
              : `${volume.length || '---'} x ${volume.width || '---'} x ${
                  volume.height || '---'
                }`}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default VolumeListItem;
