import React, { useEffect } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

import {
  listPackagesThunk,
  selectPackages,
  selectPackagesIsRejectedList,
  selectPackagesIsRequestingList,
} from './packagesSlice';
import { PAGE_SIZES } from '../../common/constants/page-size.constants';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import EmptyListIndicator from '../../common/components/EmptyListIndicator';
import GenericErrorDialog from '../../common/components/GenericErrorDialog';
import OpenPackageIcon from '../../common/components/OpenPackageIcon';
import PackageList from '../../common/components/PackageList/PackageList';
import Spinner from '../../common/components/Spinner';

const useStyles = makeStyles((theme: Theme) => ({
  buttonLabel: {
    color: '#FFFFFF',
  },
  buttonRoot: {
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
    backgroundColor: theme.palette.success.main,
    boxShadow: 'none',
    marginBottom: 30,
  },
  icon: {
    fontSize: '6.5rem',
  },
  link: {
    color: theme.palette.info.main,
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
  },
}));

interface Props {
  title: string;
}

const PackagesPage = ({ title }: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const error = useAppSelector(selectPackagesIsRejectedList);
  const isLoading = useAppSelector(selectPackagesIsRequestingList);
  const packages = useAppSelector(selectPackages);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    dispatch(listPackagesThunk());
  }, [dispatch]);

  return (
    <Box
      m="auto"
      maxWidth={PAGE_SIZES.maxWidth}
      minWidth={PAGE_SIZES.minWidth}
      pl={PAGE_SIZES.sidePadding}
      pr={PAGE_SIZES.sidePadding}
    >
      <GenericErrorDialog error={error} />
      <Container>
        <Box mb="1.25rem" mt="2.6875rem">
          <Typography color="primary" component="h2" variant="h4">
            Embalagens
          </Typography>
          <Typography>{packages.length} resultados</Typography>
        </Box>
        <Grid container spacing={7}>
          <Grid item xs={3}>
            <Button
              classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}
              component={Link}
              fullWidth
              size="large"
              startIcon={<AddIcon />}
              to="/packages/new"
              variant="contained"
            >
              Nova
            </Button>
          </Grid>
          <Grid item xs={9}>
            {isLoading && packages.length === 0 ? (
              <Box display="flex" justifyContent="center">
                <Spinner theme="primary" />
              </Box>
            ) : packages.length === 0 ? (
              <EmptyListIndicator
                append={
                  <Link className={classes.link} to="/packages/new">
                    Clique aqui para cadastrar
                  </Link>
                }
                message="Nenhuma embalagem cadastrada"
                prepend={
                  <OpenPackageIcon className={classes.icon} color="primary" />
                }
              />
            ) : (
              <PackageList packages={packages} />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PackagesPage;
