import React, { useMemo } from 'react';

import { Box, Button, makeStyles } from '@material-ui/core';
import { compact } from 'lodash';

import { selectUsersListFilters } from '../../usersSlice';
import { useAppSelector } from '../../../../app/hooks';
import { UsersListFilterParams } from '../../usersAPI';
import Chip from '../../../../common/components/Chip';
import UserStatus, {
  USER_STATUS_MAP,
} from '../../../../common/enums/user-status-type.enum';

const useStyles = makeStyles({
  chipRoot: {
    maxWidth: '100%',
  },
  chipValue: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  removeButton: {
    textDecoration: 'underline',
    fontSize: 10,
  },
});

interface UserFilterChipData {
  label?: string;
  value: string;
  onRemove: () => void;
}

interface UserFilterChipsProps {
  onChange: (data: UsersListFilterParams) => void;
}

export const UserFilterChips = ({ onChange }: UserFilterChipsProps) => {
  const classes = useStyles();

  const filters = useAppSelector(selectUsersListFilters);

  const name = useMemo<UserFilterChipData | undefined>(() => {
    if (!filters?.name || filters?.name === '') {
      return undefined;
    }

    return {
      label: 'Nome',
      value: filters?.name || '',
      onRemove: () =>
        onChange({
          ...filters,
          name: '',
        }),
    };
  }, [filters, onChange]);

  const email = useMemo<UserFilterChipData | undefined>(() => {
    if (!filters?.email || filters?.email === '') {
      return undefined;
    }

    return {
      label: 'Email',
      value: filters?.email || '',
      onRemove: () =>
        onChange({
          ...filters,
          email: '',
        }),
    };
  }, [filters, onChange]);

  const statuses = useMemo<UserFilterChipData[]>(() => {
    return (filters?.statuses || []).map((status: UserStatus) => {
      return {
        label: 'Status',
        value: USER_STATUS_MAP.get(status) || '',
        onRemove: () =>
          onChange({
            ...filters,
            statuses: (filters.statuses || []).filter(
              (value) => value !== status
            ),
          }),
      };
    });
  }, [filters, onChange]);

  const handleOnClearFilters = () => {
    onChange({
      name: '',
      email: '',
      statuses: [],
    });
  };

  const filtersList = useMemo<UserFilterChipData[]>(() => {
    const userFilters = [] as UserFilterChipData[];

    if (name) {
      userFilters.push(name);
    }

    if (email) {
      userFilters.push(email);
    }

    return compact([...userFilters, ...statuses]);
  }, [email, name, statuses]);

  return (
    <>
      <Box display="flex" flexWrap="wrap">
        {filtersList.map(({ label, value, onRemove }, index) => (
          <Box key={index} maxWidth="100%" mb={1} mr={1}>
            <Chip
              className={classes.chipRoot}
              label={label}
              value={value}
              onRemove={onRemove}
              classes={{ value: classes.chipValue }}
              color="primary"
              size="medium"
              variant="outlined"
            />
          </Box>
        ))}
      </Box>
      {filtersList.length > 0 && (
        <Button
          className={classes.removeButton}
          variant="text"
          onClick={handleOnClearFilters}
        >
          Limpar Todos
        </Button>
      )}
    </>
  );
};

export default UserFilterChips;
