export enum MaskType {
  Cellphone = 'cellphone',
  CEP = 'cep',
  CNPJ = 'cnpj',
  CPF = 'cpf',
  Phone = 'phone',
  StateRegistration = 'stateRegistration',
}

export const MASK_INPUT_LABEL_MAP = new Map<MaskType, string>([
  [MaskType.Cellphone, '(##) #####-####'],
  [MaskType.CEP, '#####-###'],
  [MaskType.CNPJ, '##.###.###/####-##'],
  [MaskType.CPF, '###.###.###-##'],
  [MaskType.Phone, '(##) ####-####'],
  [MaskType.StateRegistration, '##############'],
]);

export const MASK_INPUT_PLACEHOLDER_LABEL_MAP = new Map<MaskType, string>([
  [MaskType.Cellphone, '(__) _____-____'],
  [MaskType.CEP, '_____-__'],
  [MaskType.CNPJ, '__.___.___/____-__'],
  [MaskType.CPF, '___.___.___-__'],
  [MaskType.Phone, '(__) ____-____'],
  [MaskType.StateRegistration, '______________'],
]);

export default MaskType;
