import PrintableDocumentType from '../enums/printable-document-type.enum';
import ShipmentData from '../data-types/shipment-data';

interface LocalStorageItemOpt {
  expires?: Date;
}

interface LocalStorageParsedValue {
  opt: LocalStorageItemOpt;
  value?: string;
}

interface LocalStorageSetItemOpt extends LocalStorageItemOpt {
  maxAge?: number;
}

const SEPARATOR = ';';

const formatValue = (
  value: string,
  opt: LocalStorageSetItemOpt = {}
): string => {
  const { expires, maxAge } = opt;

  let expiresDate = expires;
  if (!expiresDate && maxAge != null) {
    const date = new Date();
    date.setSeconds(date.getSeconds() + maxAge);
    expiresDate = date;
  }

  if (expiresDate) {
    const expiresStr = expiresDate.toUTCString();
    value = [value, `expires=${expiresStr}`].join(SEPARATOR);
  }

  return value;
};

const isExpired = ({ expires }: LocalStorageSetItemOpt): boolean => {
  if (!expires) {
    return false;
  }

  return new Date() > expires;
};

const parseValue = (formattedValue: string): LocalStorageParsedValue => {
  const parts = formattedValue.split(SEPARATOR);
  const value = parts.shift();

  const opt = parts.reduce((acc, item) => {
    const [expiresDateStr] = item.match(/^expires=(.+)/) || [];

    if (expiresDateStr) {
      const expires = new Date(expiresDateStr);
      return { ...acc, expires };
    }

    return acc;
  }, {});

  return { opt, value };
};

export const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key);
};

export const getLocalStorageItem = (
  key: string,
  fallback?: string
): string | null => {
  const item = localStorage.getItem(key);

  if (item == null) {
    return fallback != null ? fallback : null;
  }

  const { opt, value } = parseValue(item);

  if (!isExpired(opt) && value != null) {
    return value;
  }

  removeLocalStorageItem(key);

  return null;
};

export const setLocalStorageString = (
  key: string,
  value: string,
  opt?: LocalStorageSetItemOpt
): void => {
  const item = formatValue(value, opt);
  localStorage.setItem(key, item);
};

export const getPrintedTags = (): ShipmentData['id'][] => {
  const items = localStorage.getItem('printedTags');

  return items && JSON.parse(items);
};

export const setPrintedTags = (
  value: number,
  documentType: PrintableDocumentType
) => {
  const items = localStorage.getItem(documentType);

  if (!items) {
    localStorage.setItem(documentType, JSON.stringify([value]));
  } else {
    const current = JSON.parse(items);
    const existentCode = current.find((code: number) => code === value);

    if (!existentCode) {
      current.push(value);
      localStorage.setItem(documentType, JSON.stringify([...current]));
    }
  }
};
