import dayjs from 'dayjs';

import { ShipmentStatus } from '../enums/shipment-status.enum';

interface StatusStyles {
  backgroundColor: string;
  etaColor: string;
  etdColor: string;
}

export const getColorByStatus = (status: ShipmentStatus): string => {
  switch (status) {
    case ShipmentStatus.Finished: {
      return '#28A745';
    }
    case ShipmentStatus.InTransit: {
      return '#2F159E';
    }
    case ShipmentStatus.Ready: {
      return '#606771';
    }
    case ShipmentStatus.ToWithdrawal: {
      return '#2196F3';
    }
    default: {
      return '#606771';
    }
  }
};

export const getStylesByStatus = (
  status: ShipmentStatus,
  eta?: string
): StatusStyles => {
  const defaultStyles: StatusStyles = {
    backgroundColor: '#FFFFFF',
    etaColor: 'text.primary',
    etdColor: 'text.primary',
  };

  switch (status) {
    case ShipmentStatus.Finished:
      return {
        backgroundColor: '#EDF7ED',
        etaColor: '#28A745',
        etdColor: '#28A745',
      };
    case ShipmentStatus.Ready:
    case ShipmentStatus.InTransit:
      if (eta && dayjs(eta).diff(dayjs(), 'hour') < 0) {
        return {
          ...defaultStyles,
          backgroundColor: '#FFF4E5',
          etaColor: '#FF9800',
        };
      }
      return defaultStyles;
    case ShipmentStatus.ToWithdrawal:
      return {
        ...defaultStyles,
        backgroundColor: '#E8F4FD',
      };
    default:
      return defaultStyles;
  }
};
