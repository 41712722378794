import React from 'react';

import Skeleton, {
  SkeletonHeightSize,
  SkeletonProps,
  SkeletonWidthSize,
} from './Skeleton';

type SkeletonSizes = 'large' | 'larger' | 'medium' | 'small';

interface SkeletonFieldProps
  extends Omit<SkeletonProps, 'children' | 'height' | 'width'> {
  children: React.ReactNode;
  height?: SkeletonSizes;
  isLoading?: boolean;
  width?: SkeletonSizes;
}

const SKELETON_HEIGHT = {
  large: SkeletonHeightSize.Large,
  larger: SkeletonHeightSize.Larger,
  medium: SkeletonHeightSize.Medium,
  small: SkeletonHeightSize.Small,
};

const SKELETON_WIDTH = {
  large: SkeletonWidthSize.Large,
  larger: SkeletonWidthSize.Larger,
  medium: SkeletonWidthSize.Medium,
  small: SkeletonWidthSize.Small,
};

const SkeletonField = ({
  animation = 'wave',
  children,
  height = 'medium',
  isLoading = false,
  width = 'medium',
  ...rest
}: SkeletonFieldProps) => {
  return (
    <>
      {isLoading ? (
        <Skeleton
          {...rest}
          animation={animation}
          height={SKELETON_HEIGHT[height]}
          width={SKELETON_WIDTH[width]}
        />
      ) : (
        children
      )}
    </>
  );
};

export default SkeletonField;
