import { gql } from '../../common/helpers/gql.helper';
import { REACT_APP_EBB_ORGANIZATION_ID } from '../../.env';

const entityFragment = gql`
  address {
    cep
    complement
    location
    neighborhood
    number
    state
    street
  }
	code
	displayName
	documentNumber
	email
  id
	name
	organizationId
	phone
	shippingSite
	stateRegistration
  type
`;

export const FETCH_ENTITY_QUERY = gql`
  query Entity($id: Int!) {
    entity(id: $id) {
      ${entityFragment}
    }
  }
`;

export const ENTITY_LIST_QUERY = gql`
  query ListEntities($filter: EntitiesFilter, $first: Int, $offset: Int!) {
    entities(filter: $filter, first: $first, offset: $offset) {
      data {
        ${entityFragment}
      }
      total
    }
  }
`;

export const ENTITY_LIST_GROUPED_BY_ORGANIZATION_QUERY = gql`
  query ListEntities($filter: EntitiesFilter) {
    associatedEntities: entities(filter: { organizationIds: [ ${REACT_APP_EBB_ORGANIZATION_ID} ] }) {
      data {
        ${entityFragment}
      }
    }

    entities: entities(filter: $filter) {
      data {
        ${entityFragment}
      }
    }
  }
`;
