import React, { useCallback, useMemo } from 'react';

import {
  Button,
  CircularProgress,
  FormGroup,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ERROR_MESSAGES } from '../../../../common/constants/error-messages';
import TextField from '../../../../common/components/TextField';

interface FormData {
  email: string;
}

interface ForgotPasswordFormProps {
  error: Record<string, any> | null;
  isLoading: boolean;
  onSubmit: (data: FormData) => void;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .required(ERROR_MESSAGES.string.required)
    .email(ERROR_MESSAGES.string.email),
});

const useStyles = makeStyles({
  back: {
    color: '#1976D2',
    marginTop: 10,
  },
  error: {
    marginBottom: 10,
    textAlign: 'center',
  },
  formTitle: {
    fontSize: 23,
    marginBottom: 10,
  },
  formTip: {
    color: '#363487',
    marginTop: 20,
    marginBottom: 10,
  },
  emailInput: {
    marginTop: 10,
    marginBottom: 50,
  },
});

const FormForgotPassword = ({
  error,
  isLoading,
  onSubmit,
}: ForgotPasswordFormProps) => {
  const {
    control,
    formState: { isSubmitted, isValid },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: { email: '' },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const canSubmit = useMemo(() => {
    return isValid && !isLoading;
  }, [isLoading, isValid]);

  const classes = useStyles();

  const history = useHistory();

  const handleBackToLogin = useCallback(() => {
    history.replace('/');
  }, [history]);

  const handleOnSubmit = useCallback((data: FormData) => onSubmit(data), [
    onSubmit,
  ]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Typography
        className={classes.formTitle}
        color="textSecondary"
        component="h2"
      >
        Esqueci minha senha
      </Typography>
      <FormGroup>
        <Typography
          className={classes.formTip}
          color="textSecondary"
          component="p"
        >
          Informe o seu e-mail
        </Typography>
        <TextField
          className={classes.emailInput}
          control={control}
          label="E-mail"
          name="email"
          placeholder="Digite seu endereço de e-mail"
        />
        {error?.code === 'credentials_invalid' ? (
          <Typography className={classes.error} color="error" component="p">
            E-mail não encontrado
          </Typography>
        ) : null}
        <Button
          color="primary"
          disabled={!canSubmit && isSubmitted}
          type="submit"
          variant="contained"
        >
          {isLoading ? <CircularProgress /> : 'Enviar'}
        </Button>
        <Button
          className={classes.back}
          color="secondary"
          onClick={handleBackToLogin}
        >
          Voltar
        </Button>
      </FormGroup>
    </form>
  );
};

export default FormForgotPassword;
