import { useCallback, useMemo } from 'react';

import { useHistory } from 'react-router';

export const useUrlParams = () => {
  const history = useHistory();

  const stringToURLSearchParams = (queryString: string): URLSearchParams => {
    return new URLSearchParams(queryString);
  };

  const params = useMemo<Record<string, string>>(() => {
    const urlParams = Array.from(
      stringToURLSearchParams(history.location.search)
    );

    return urlParams.reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {}
    );
  }, [history.location.search]);

  const getUrlParam = useCallback(
    (key: string): string | undefined => {
      const param = params[key];

      if (param === null) {
        return undefined;
      }

      return params[key];
    },
    [params]
  );

  const replaceUrlParams = useCallback(
    (parameters: Record<string, any>): void => {
      const urlParams = new URLSearchParams();

      Object.entries(parameters).map(([key, value]) => {
        if (value) {
          return urlParams.set(key, value.toString());
        }
        return '';
      });

      history.replace({
        search: urlParams.toString(),
      });
    },
    [history]
  );

  return {
    params,
    getUrlParam,
    replaceUrlParams,
  };
};
