import React from 'react';

import { Grid, makeStyles, Typography } from '@material-ui/core';

import Modal from './Modal';

const useStyles = makeStyles({
  content: {
    padding: '0 2rem 4rem 2rem',
    width: '750px',
  },
  description: {
    fontSize: 18,
    marginBottom: 10,
    wordWrap: 'break-word',
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    marginBottom: 30,
  },
});

interface ErrorModalProps {
  hasError?: boolean;
  image: string;
  message: string;
  open: boolean;
  title: string;
  onClose(): void;
}

const ErrorModal = ({
  hasError = false,
  image,
  message,
  open,
  title,
  onClose,
}: ErrorModalProps) => {
  const classes = useStyles();

  return (
    <Modal
      maxWidth="lg"
      open={open}
      PaperProps={{ elevation: 0, square: true }}
      onClose={onClose}
    >
      <Grid
        alignItems="center"
        className={classes.content}
        container
        justifyContent="space-around"
      >
        <Grid item xs={2}>
          <img alt="Imagem ilustrativa" loading="lazy" src={image} />
        </Grid>
        <Grid item xs={9}>
          <Typography
            className={classes.title}
            variant="h1"
            color={hasError ? 'error' : undefined}
          >
            {title}
          </Typography>
          <Typography className={classes.description}>{message}</Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ErrorModal;
