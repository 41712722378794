import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

type FolderEyeIconProps = SvgIconProps;

const FolderEyeIcon = ({ ...props }: FolderEyeIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M7.3 16H2C0.9 16 0 15.1 0 14V2C0 0.9 0.9 0 2 0H8L10 2H18C19.1 2 20 2.9 20 4V10.6C18.6 9.6 16.9 9 15 9C11.5 9 8.4 11.1 7.1 14.3L6.8 15L7.1 15.7C7.2 15.8 7.2 15.9 7.3 16ZM21 15C20.1 17.3 17.7 19 15 19C12.3 19 9.9 17.3 9 15C9.9 12.7 12.3 11 15 11C17.7 11 20.1 12.7 21 15ZM17.5 15C17.5 13.6 16.4 12.5 15 12.5C13.6 12.5 12.5 13.6 12.5 15C12.5 16.4 13.6 17.5 15 17.5C16.4 17.5 17.5 16.4 17.5 15ZM15 14C14.4 14 14 14.4 14 15C14 15.6 14.4 16 15 16C15.6 16 16 15.6 16 15C16 14.4 15.6 14 15 14Z"
      />
    </SvgIcon>
  );
};

export default FolderEyeIcon;
