import {
  AnyAction,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { keyBy, uniq } from 'lodash';

import {
  BillingFilters,
  BillingGroupedShipmentsData,
  BillingShipmentListData,
  BillingTotalsByPeriodicityData,
  BillingTotalsData,
  findBilling,
  findBillingByPeriodicity,
  listBillingShipments,
  ListBillingShipmentsOpt,
} from './billingAPI';
import { RootState } from '../../app/store';
import ErrorData from '../../common/data-types/error-data';
import RequestData from '../../common/data-types/request-data';
import RequestStatus from '../../common/enums/request-status.enum';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  const startsWith = action.type.startsWith('billing');
  const endsWith = action.type.endsWith('/fulfilled');

  return startsWith && endsWith;
};

const isPendingAction = (action: AnyAction): action is PendingAction => {
  const startsWith = action.type.startsWith('billing');
  const endsWith = action.type.endsWith('/pending');

  return startsWith && endsWith;
};

const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  const startsWith = action.type.startsWith('billing');
  const endsWith = action.type.endsWith('/rejected');

  return startsWith && endsWith;
};

// #region Typings
type BillingShipmentListDataWithPage = BillingShipmentListData & {
  page: number;
};

interface UpdateBillingShipmentListPageActionParams {
  groupId: string;
  page: number;
}
// #endregion Typings

// #region Thunks
const FIND_BILLING_THUNK = 'billing/findBillingThunk';
export const findBillingThunk = createAsyncThunk(
  FIND_BILLING_THUNK,
  async (filters: BillingFilters) => {
    const response = await findBilling(filters);

    return response;
  }
);

const FIND_BILLING_BY_PERIODICITY_THUNK =
  'billing/findBillingByPeriodicityThunk';
export const findBillingByPeriodicityThunk = createAsyncThunk(
  FIND_BILLING_BY_PERIODICITY_THUNK,
  async (filters: BillingFilters) => {
    const response = await findBillingByPeriodicity(filters);

    return response;
  }
);

const LIST_BILLING_SHIPMENTS_THUNK = 'billing/listBillingShipmentsThunk';
export const listBillingShipmentsThunk = createAsyncThunk(
  LIST_BILLING_SHIPMENTS_THUNK,
  async (options: ListBillingShipmentsOpt) => {
    const response = await listBillingShipments(options);

    return response;
  }
);
// #endregion Thunks

// #region Slice
interface BillingState {
  error?: ErrorData;
  groupedShipmentsById: Record<
    BillingGroupedShipmentsData['id'],
    BillingGroupedShipmentsData
  >;
  groupedShipmentIds: BillingGroupedShipmentsData['id'][];
  requestIds: RequestData['id'][];
  requestsById: Record<RequestData['id'], RequestData>;
  shipmentListIds: string[];
  shipmentListsById: Record<string, BillingShipmentListDataWithPage>;
  totals: BillingTotalsData;
  totalsByPeriodicity: BillingTotalsByPeriodicityData[];
}

const initialState: BillingState = {
  groupedShipmentsById: {},
  groupedShipmentIds: [],
  requestIds: [],
  requestsById: {},
  shipmentListIds: [],
  shipmentListsById: {},
  totals: {
    numberOfShipments: 0,
    numberOfVolumes: 0,
    totalValue: 0,
    totalWeight: 0,
  },
  totalsByPeriodicity: [],
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    updateBilingShipmentListPageAction: (
      state,
      action: PayloadAction<UpdateBillingShipmentListPageActionParams>
    ) => {
      const { groupId, page } = action.payload;

      const group = state.shipmentListsById[groupId];

      Object.assign(group, {
        ...group,
        page,
      });
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(findBillingThunk.fulfilled, (state, action) => {
        const { groupedShipments, totals } = action.payload;

        if (groupedShipments) {
          const ids = groupedShipments?.map((group) => group.id);

          state.groupedShipmentIds = uniq([
            ...state.groupedShipmentIds,
            ...ids,
          ]);
          state.groupedShipmentsById = keyBy(groupedShipments, 'id');
          state.totals = totals;
        } else {
          const {
            groupedShipmentsById,
            groupedShipmentIds,
            totals: initialTotals,
          } = initialState;

          state.groupedShipmentIds = groupedShipmentIds;
          state.groupedShipmentsById = groupedShipmentsById;
          state.totals = initialTotals;
        }
      })
      .addCase(findBillingByPeriodicityThunk.fulfilled, (state, action) => {
        state.totalsByPeriodicity = action.payload;
      })
      .addCase(listBillingShipmentsThunk.fulfilled, (state, action) => {
        const { shipments } = action.payload;

        const {
          destinationShipperEntityId,
          originShipperEntityId,
        } = shipments[0];

        const id = [originShipperEntityId, destinationShipperEntityId].join(
          '-'
        );

        const group = state.shipmentListsById[id];

        state.shipmentListIds = uniq([...state.shipmentListIds, id]);
        state.shipmentListsById = {
          ...state.shipmentListsById,
          [id]: { page: group ? group.page : 1, ...action.payload },
        };
      })
      .addMatcher(isFulfilledAction, (state, action: AnyAction) => {
        const { meta } = action;
        const request = state.requestsById[meta.requestId];

        if (!request) {
          return;
        }

        request.error = undefined;
        request.status = RequestStatus.Succeeded;
        request.type = action.type;
      })
      .addMatcher(isPendingAction, (state, action: AnyAction) => {
        const { meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Loading,
          type,
        };

        state.requestsById[requestId] = request;
      })
      .addMatcher(isRejectedAction, (state, action: AnyAction) => {
        const { error, meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Failed,
          type,
          error,
        };

        state.requestsById[requestId] = request;
      }),
});
// #endregion Slice

// #region Selectors
// #region Private selectors
const selectBillingIsRequestingByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const pendingType = [type, 'pending'].join('/');
  const request = selectBillingRequestByType(rootState, pendingType);

  return request ? request.status === RequestStatus.Loading : false;
};

const selectBillingRequestByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData => {
  const requests = selectBillingRequestsByType(rootState, type);

  return requests.reverse()[0];
};

const selectBillingRequests = (rootState: RootState): RequestData[] => {
  const state = selectBillingState(rootState);

  return state.requestIds.map((id) => state.requestsById[id]);
};

const selectBillingRequestsByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData[] => {
  const requests = selectBillingRequests(rootState);

  return requests.filter((r) => r.type === type);
};

const selectBillingShipmentListById = (
  rootState: RootState,
  id: string
): BillingShipmentListDataWithPage => {
  const state = selectBillingState(rootState);

  return state.shipmentListsById[id];
};

const selectBillingState = (rootState: RootState): BillingState => {
  return rootState.billing;
};
// #endregion Private selectors

// #region Public selectors
export const selectBillingGroupedShipmentsById = (
  rootState: RootState,
  id: BillingGroupedShipmentsData['id']
): BillingGroupedShipmentsData => {
  const state = selectBillingState(rootState);

  return state.groupedShipmentsById[id];
};

export const selectBillingGroupedShipmentIds = (
  rootState: RootState
): BillingState['groupedShipmentIds'] => {
  const state = selectBillingState(rootState);

  return state.groupedShipmentIds;
};

export const selectBillingIsRequestingFind = (
  rootState: RootState
): boolean => {
  return selectBillingIsRequestingByType(rootState, FIND_BILLING_THUNK);
};

export const selectBillingIsRequestingFindByPeriodicity = (
  rootState: RootState
): boolean => {
  return selectBillingIsRequestingByType(
    rootState,
    FIND_BILLING_BY_PERIODICITY_THUNK
  );
};

export const selectBillingIsRequestingListShipments = (
  rootState: RootState
): boolean => {
  return selectBillingIsRequestingByType(
    rootState,
    LIST_BILLING_SHIPMENTS_THUNK
  );
};

export const selectBillingShipmentsById = (
  rootState: RootState,
  id: string
): BillingShipmentListData['shipments'] => {
  const state = selectBillingShipmentListById(rootState, id);

  return state ? state.shipments : [];
};

export const selectBillingShipmentsPageById = (
  rootState: RootState,
  id: string
): number => {
  const state = selectBillingShipmentListById(rootState, id);

  return state ? state.page : 1;
};

export const selectBillingShipmentsTotalById = (
  rootState: RootState,
  id: string
): BillingShipmentListData['total'] => {
  const state = selectBillingShipmentListById(rootState, id);

  return state ? state.total : 0;
};

export const selectBillingTotals = (
  rootState: RootState
): BillingState['totals'] => {
  const state = selectBillingState(rootState);

  return state.totals;
};

export const selectBillingTotalsByPeriodicity = (
  rootState: RootState
): BillingState['totalsByPeriodicity'] => {
  const state = selectBillingState(rootState);

  return state.totalsByPeriodicity;
};
// #endregion Public selectors
// #endregion Selectors

export const { updateBilingShipmentListPageAction } = billingSlice.actions;

export const billingReducer = billingSlice.reducer;
