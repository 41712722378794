import React from 'react';

import 'react-image-gallery/styles/css/image-gallery.css';
import Gallery, {
  ReactImageGalleryItem,
  ReactImageGalleryProps,
} from 'react-image-gallery';

export interface ImageGalleryProps
  extends Omit<ReactImageGalleryProps, 'items'> {
  images: ReactImageGalleryItem[];
}

const ImageGallery = ({ images, ...props }: ImageGalleryProps) => {
  return (
    <Gallery
      items={images}
      showBullets={props.showBullets || true}
      showPlayButton={props.showPlayButton || false}
      showThumbnails={props.showThumbnails || false}
      {...props}
    />
  );
};

export default ImageGallery;
