import React, { useCallback, useEffect } from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { PAGE_SIZES } from '../../../common/constants/page-size.constants';
import { selectOrganizationId } from '../../Session/sessionSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectUserFilteredIds,
  selectUserErrorUpdate,
  selectUsersIsRequestingList,
  selectUsersListFilters,
  updateUsersListFiltersAction,
  usersListThunk,
  userUpdateThunk,
} from '../usersSlice';
import { UsersListFilterParams } from '../usersAPI';
import EmptyListIndicator from '../../../common/components/EmptyListIndicator';
import GenericErrorDialog from '../../../common/components/GenericErrorDialog';
import ShipmentFilter from '../../Shipments/List/components/ShipmentFilter';
import UserEmailFilter from './components/UserEmailFilter';
import UserFilterChips from './components/UserFilterChips';
import UserList from './components/UserList/UserList';
import UserListData from '../../../common/data-types/user-list-data';
import UserNameFilter from './components/UserNameFilter';
import UserStatus from '../../../common/enums/user-status-type.enum';
import UserStatusFilter from './components/UserStatusFilter';

const useStyles = makeStyles((theme: Theme) => ({
  buttonLabel: {
    color: '#FFFFFF',
  },
  buttonRoot: {
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
    backgroundColor: theme.palette.success.main,
    boxShadow: 'none',
    marginBottom: 30,
  },
  icon: {
    fontSize: '6.5rem',
  },
  link: {
    color: theme.palette.info.main,
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
  },
}));

interface Props {
  title: string;
}

const UserListPage = ({ title }: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const error = useAppSelector(selectUserErrorUpdate);
  const filters = useAppSelector(selectUsersListFilters);
  const isLoading = useAppSelector(selectUsersIsRequestingList);
  const organizationId = useAppSelector(selectOrganizationId);
  const usersIds = useAppSelector(selectUserFilteredIds);

  const handleListUsers = useCallback(() => {
    dispatch(usersListThunk({ organizationId }));
  }, [dispatch, organizationId]);

  const handleOnNameChange = (name: string) => {
    handleOnFilter({ name });
  };

  const handleOnEmailChange = (email: string) => {
    handleOnFilter({ email });
  };

  const handleOnStatusChange = (statuses: UserStatus[]) => {
    handleOnFilter({ statuses });
  };

  const handleOnFilter = (filter: UsersListFilterParams): void => {
    dispatch(updateUsersListFiltersAction({ ...filters, ...filter }));
  };

  const handleUpdateUserState = useCallback(
    (userId: UserListData['id'], status: boolean) => {
      dispatch(userUpdateThunk({ user: { active: status }, userId }));
    },
    [dispatch]
  );

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    handleListUsers();
  }, [handleListUsers]);

  return (
    <>
      <Box
        m="auto"
        maxWidth={PAGE_SIZES.maxWidth}
        minWidth={PAGE_SIZES.minWidth}
        pl={PAGE_SIZES.sidePadding}
        pr={PAGE_SIZES.sidePadding}
      >
        <Box mb="1.25rem" mt="2.6875rem">
          <Typography color="primary" component="h2" variant="h4">
            Usuários
          </Typography>
          <Typography>{usersIds.length} resultados</Typography>
        </Box>
        <Grid container>
          <Grid item xs={3}>
            <Box mr={4}>
              <Button
                classes={{
                  label: classes.buttonLabel,
                  root: classes.buttonRoot,
                }}
                component={Link}
                fullWidth
                size="large"
                startIcon={<AddIcon />}
                to="/users/new"
                variant="contained"
              >
                Novo
              </Button>
              <UserFilterChips onChange={handleOnFilter} />
              <ShipmentFilter filterName="Nome">
                <UserNameFilter onSubmit={handleOnNameChange} />
              </ShipmentFilter>
              <ShipmentFilter filterName="Email">
                <UserEmailFilter onSubmit={handleOnEmailChange} />
              </ShipmentFilter>
              <ShipmentFilter filterName="Situação">
                <UserStatusFilter onChange={handleOnStatusChange} />
              </ShipmentFilter>
            </Box>
            {/* TODO: New user's button */}
          </Grid>
          <Grid item xs={9}>
            {usersIds.length > 0 || isLoading ? (
              <UserList
                isLoading={isLoading}
                usersIds={usersIds}
                onUpdate={handleUpdateUserState}
              />
            ) : (
              <EmptyListIndicator
                append={
                  <Link className={classes.link} to="/users/new">
                    Clique aqui para cadastrar
                  </Link>
                }
                message="Nenhum Usuário cadastrado"
                prepend={
                  <AccountBoxIcon
                    classes={{ root: classes.icon }}
                    color="primary"
                  />
                }
              />
            )}
          </Grid>
        </Grid>
      </Box>
      {error && <GenericErrorDialog error={error} />}
    </>
  );
};

export default UserListPage;
