import React, { useMemo, useState } from 'react';

import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import { differenceBy } from 'lodash';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {
  ShippingEventData,
  VolumeCodesData,
} from '../data-types/shipping-event-data';
import Accordion from './Accordion';
import TextBoxCheckIcon from './TextBoxCheckIcon';
import TextBoxRemoveIcon from './TextBoxRemoveIcon';
import VolumeData from '../data-types/volume-data';

const useStyles = makeStyles({
  infoIcon: {
    color: '#2196F3',
  },
  rootAccordion: {
    backgroundColor: '#E8F4FD',
    borderRadius: '4px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    color: '#0D3C61',
    width: '100%',
  },
  title: {
    fontSize: '1rem',
    marginLeft: '1rem',
  },
  volumesTitle: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 6,
  },
  volume: {
    color: '#606770',
    fontSize: 12,
    marginBottom: 4,
  },
});

interface FragmentedVolumesProps {
  shipmentVolumes: VolumeData[];
  volumeCodes: ShippingEventData['volumeCodes'];
}

const FragmentedVolumes = ({
  shipmentVolumes,
  volumeCodes,
}: FragmentedVolumesProps) => {
  const classes = useStyles();

  const [isOpen, setOpen] = useState<boolean>(false);

  const absentVolumes = useMemo<ShippingEventData['volumeCodes']>(() => {
    const shipmentVolumeCodes: ShippingEventData['volumeCodes'] = shipmentVolumes.map(
      (volume: VolumeData, index: number) => ({
        carrierCode: volume.carrierCode,
        id: volume.id,
        sequentialNumber: volume.sequentialNumber || index + 1,
        trackingCode: volume.trackingCode,
      })
    );
    return differenceBy(shipmentVolumeCodes, volumeCodes, 'id');
  }, [shipmentVolumes, volumeCodes]);

  const includedVolumes = useMemo<ShippingEventData['volumeCodes']>(() => {
    const volumes: ShippingEventData['volumeCodes'] = volumeCodes.map(
      (volume: VolumeCodesData) => {
        const index = shipmentVolumes.findIndex(
          (v: VolumeData) => v.id === volume.id
        );
        const data = shipmentVolumes[index];
        return {
          carrierCode: data?.carrierCode,
          id: data?.id,
          sequentialNumber: data?.sequentialNumber || index + 1,
          trackingCode: data?.trackingCode,
        };
      }
    );
    return volumes;
  }, [shipmentVolumes, volumeCodes]);

  const handleOnToggle = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <Accordion
      classes={{
        root: classes.rootAccordion,
      }}
      isExpanded={isOpen}
      summary={
        <Box
          alignItems="center"
          display="flex"
          height="3rem"
          justifyContent="space-between"
          paddingLeft="1rem"
        >
          <Box alignItems="center" display="flex">
            <InfoOutlinedIcon className={classes.infoIcon} />
            <Typography className={classes.title}>Carga Fragmentada</Typography>
            <Box
              bgcolor="#0D3C61"
              borderRadius="0.25rem"
              color="white"
              fontSize={12}
              fontWeight={700}
              marginLeft="0.625rem"
              padding="0.125rem"
            >
              {`${volumeCodes?.length}/${shipmentVolumes?.length}`}
            </Box>
          </Box>
          <IconButton>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
      }
      details={
        <Box marginLeft="3.7rem" marginY={2} width="100%">
          <Box alignItems="flex-start" display="flex">
            <TextBoxCheckIcon />
            <Box marginLeft={2}>
              <Typography className={classes.volumesTitle}>
                Volumes Inclusos
              </Typography>
              {includedVolumes &&
                includedVolumes.length > 0 &&
                includedVolumes.map((volume, index) => (
                  <Typography
                    className={classes.volume}
                    key={`volume-${index}`}
                  >
                    {`${volume.sequentialNumber}`}
                    {' - '}
                    {volume.carrierCode || volume.trackingCode}
                  </Typography>
                ))}
            </Box>
          </Box>
          <Box display="flex" alignItems="flex-start" marginTop={3}>
            <TextBoxRemoveIcon />
            <Box marginLeft={2}>
              <Typography className={classes.volumesTitle}>
                Volumes Ausentes
              </Typography>
              {absentVolumes &&
                absentVolumes.length > 0 &&
                absentVolumes.map((absentVolume) => (
                  <Typography
                    className={classes.volume}
                    key={`absent-${absentVolume.id}`}
                  >
                    {`${absentVolume.sequentialNumber}`}
                    {' - '}
                    {absentVolume.carrierCode || absentVolume.trackingCode}
                  </Typography>
                ))}
            </Box>
          </Box>
        </Box>
      }
      onToggle={handleOnToggle}
    />
  );
};

export default FragmentedVolumes;
