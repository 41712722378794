import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Control, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import RoomOutlined from '@material-ui/icons/RoomOutlined';

import {
  clearAddress,
  searchAddressByCepThunk,
  selectAddress,
  selectAddressStatus,
} from '../../../features/Entities/entitiesSlice';
import { ERROR_MESSAGES } from '../../constants/error-messages';
import { State, STATES } from '../../constants/states';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import MaskedInput from '../MaskedInput';
import MaskType from '../../enums/mask-input-type.enum';
import RequestStatus from '../../enums/request-status.enum';
import Select, { SelectOption } from '../Select';
import TextField from '../TextField';

export const addressSchema = {
  cep: yup
    .string()
    .min(8, ERROR_MESSAGES.string.min)
    .max(8, ERROR_MESSAGES.string.max)
    .required(ERROR_MESSAGES.string.required),
  complement: yup.string().max(30, ERROR_MESSAGES.string.max),
  location: yup
    .string()
    .min(2, ERROR_MESSAGES.string.min)
    .max(120, ERROR_MESSAGES.string.max)
    .required(ERROR_MESSAGES.string.required),
  neighborhood: yup
    .string()
    .min(2, ERROR_MESSAGES.string.min)
    .max(60, ERROR_MESSAGES.string.max)
    .required(ERROR_MESSAGES.string.required),
  number: yup
    .string()
    .min(1, ERROR_MESSAGES.string.min)
    .max(10, ERROR_MESSAGES.string.max)
    .required(ERROR_MESSAGES.string.required),
  state: yup.string().required(ERROR_MESSAGES.string.required),
  street: yup
    .string()
    .min(2)
    .max(120, ERROR_MESSAGES.string.max)
    .required(ERROR_MESSAGES.string.required),
};

export interface AddressFormInputs {
  cep: string;
  complement: string;
  location: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
}

export interface AddressFormProps {
  control: Control<any>;
  showAddressFields?: boolean;
  setFormValue: (data: Record<string, any>) => void;
}

const useStyle = makeStyles({
  icon: {
    marginBottom: '-4px',
    marginRight: '5px',
  },
  paddingNone: {
    padding: 'none',
  },
  stateSelect: {
    width: '100%',
  },
  strong: {
    fontWeight: 'bolder',
  },
});

export const AddressForm = ({
  control,
  showAddressFields = false,
  setFormValue,
}: AddressFormProps) => {
  const classes = useStyle();

  const dispatch = useAppDispatch();

  const addressStatus = useAppSelector(selectAddressStatus);

  const address = useAppSelector(selectAddress);

  const formValues = useWatch({ control });

  const [cepError, setCepError] = useState<{
    error: boolean;
    message: string | null;
  }>({
    error: false,
    message: null,
  });

  const buildStateOptions = useCallback((states: State[]): SelectOption[] => {
    return states.map((state: State) => {
      return {
        label: state.acronym,
        value: state.acronym,
      };
    });
  }, []);

  const handleCepChange = useCallback(
    (fieldValue: string) => {
      const cep = fieldValue;
      if (cep && cep.length === 8) {
        dispatch(searchAddressByCepThunk(cep));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (address && addressStatus === RequestStatus.Succeeded) {
      setFormValue(address);
      setCepError({
        error: false,
        message: null,
      });
      dispatch(clearAddress({ resetStatus: false }));
    }
  }, [address, addressStatus, dispatch, setFormValue]);

  useEffect(() => {
    if (addressStatus === RequestStatus.Failed) {
      setCepError({
        error: true,
        message: 'CEP Inválido',
      });
    }
  }, [address, addressStatus, setFormValue]);

  return (
    <Box
      bgcolor="#FFFFFF"
      border={1}
      borderColor="#C2C3C9"
      borderRadius={3}
      mt={3}
      width="50rem"
    >
      <Grid item xs={12}>
        <Grid alignItems="center" container item xs={12}>
          <Box p={2} pb={0}>
            <Typography
              className={classes.strong}
              color="textSecondary"
              variant="h6"
            >
              <RoomOutlined className={classes.icon} />
              Endereço
            </Typography>
          </Box>
        </Grid>
        <Box p={2} pt={2}>
          <Grid item xs={3}>
            <MaskedInput
              control={control}
              error={cepError.error}
              fullWidth
              helperText={cepError?.message}
              label="CEP *"
              maskType={MaskType.CEP}
              name="cep"
              placeholder="CEP *"
              onChange={(value: string) => handleCepChange(value)}
            />
          </Grid>
        </Box>
        {addressStatus === RequestStatus.Loading && (
          <Box pb={2} pt={1} textAlign="center">
            <CircularProgress />
          </Box>
        )}
        {((formValues && addressStatus === RequestStatus.Succeeded) ||
          showAddressFields) && (
          <>
            <Box p={2} pt={0}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={9}>
                  <TextField
                    control={control}
                    disabled
                    fullWidth
                    inputProps={{ maxLength: 120 }}
                    label="Logradouro *"
                    name="street"
                    placeholder="Logradouro *"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    control={control}
                    fullWidth
                    inputProps={{ maxLength: 10 }}
                    label="Nº *"
                    name="number"
                    placeholder="Nº *"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box p={2} pt={0}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    control={control}
                    fullWidth
                    inputProps={{ maxLength: 30 }}
                    label="Complemento"
                    name="complement"
                    placeholder="Complemento"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    control={control}
                    fullWidth
                    inputProps={{ maxLength: 60 }}
                    label="Bairro *"
                    name="neighborhood"
                    placeholder="Bairro *"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box p={2} pt={0}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={9}>
                  <TextField
                    control={control}
                    disabled
                    fullWidth
                    inputProps={{ maxLength: 120 }}
                    label="Cidade *"
                    name="location"
                    placeholder="Cidade *"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl className={classes.stateSelect}>
                    <Select
                      control={control}
                      disabled
                      defaultValue=""
                      fullWidth
                      label="UF *"
                      name="state"
                      options={buildStateOptions(STATES)}
                      placeholder="UF *"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default AddressForm;
