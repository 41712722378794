// #region Typings
type HttpHeaders = { [key: string]: any };
type HttpQueryParams = { [key: string]: any };
type HttpRequestBody = { [key: string]: any } | null | string;

export type HttpMethod = 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT';

export type HttpRequestWithBodyArgs = HttpRequestArgs & {
  body?: HttpRequestBody;
};

export interface HttpRequestArgs {
  headers?: HttpHeaders;
  query?: HttpQueryParams;
  signal?: AbortSignal;
  url: string;
}

export interface HttpResponse<Body = any, Header = any> {
  body: Body;
  header: Header;
  status: number;
}

export interface HttpClientAdapter {
  delete(opt: HttpRequestWithBodyArgs): Promise<HttpResponse>;
  get(opt: HttpRequestArgs): Promise<HttpResponse>;
  getBlob(
    opt: HttpRequestArgs | HttpRequestWithBodyArgs
  ): Promise<HttpResponse<Blob>>;
  patch(opt: HttpRequestWithBodyArgs): Promise<HttpResponse>;
  put(opt: HttpRequestWithBodyArgs): Promise<HttpResponse>;
  post(opt: HttpRequestWithBodyArgs): Promise<HttpResponse>;
}

interface HttpClientDependencies {
  adapter: HttpClientAdapter;
}
// #endregion Typings

export class HttpClient implements HttpClientAdapter {
  private adapter: HttpClientAdapter;

  constructor({ adapter }: HttpClientDependencies) {
    this.adapter = adapter;
  }

  delete(opt: HttpRequestWithBodyArgs): Promise<HttpResponse> {
    return this.adapter.delete(opt);
  }

  get(opt: HttpRequestArgs): Promise<HttpResponse> {
    return this.adapter.get(opt);
  }

  getBlob(
    opt: HttpRequestArgs | HttpRequestWithBodyArgs
  ): Promise<HttpResponse<Blob>> {
    return this.adapter.getBlob(opt);
  }

  patch(opt: HttpRequestWithBodyArgs): Promise<HttpResponse> {
    return this.adapter.patch(opt);
  }

  put(opt: HttpRequestWithBodyArgs): Promise<HttpResponse> {
    return this.adapter.put(opt);
  }

  post(opt: HttpRequestWithBodyArgs): Promise<HttpResponse> {
    return this.adapter.post(opt);
  }
}
