import React, { useMemo } from 'react';

import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';

import { capitalizeFirstLetter } from '../helpers/string.helper';
import EntityData from '../data-types/entity-data';
import Skeleton from './Skeleton';

const useStyles = makeStyles({
  entityName: {
    fontSize: '0.875rem',
    fontWeight: 'bolder',
    margin: '0 0 0.1875rem 0',
    overflow: 'hidden',
    position: 'relative',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
});

interface EntityProps {
  entity?: EntityData;
  isLoading: boolean;
}

const Entity = ({ entity, isLoading }: EntityProps) => {
  const classes = useStyles();

  const entityName = entity?.displayName || entity?.name;

  const locationName = useMemo<string>(() => {
    return entity?.address
      ? `${capitalizeFirstLetter(
          entity?.address?.location
        )}, ${entity?.address?.state?.toUpperCase()}`
      : '---';
  }, [entity?.address]);

  return (
    <Box flexBasis="204px" flexShrink="0" width="204px">
      <Tooltip
        title={`${entityName && entityName?.length > 19 ? entityName : ''}`}
        placement="top"
        arrow
      >
        <Typography className={classes.entityName} color="textPrimary">
          {isLoading ? <Skeleton /> : entityName}
        </Typography>
      </Tooltip>
      <Tooltip
        arrow
        placement="top"
        title={`${locationName.length > 40 ? locationName : ''}`}
      >
        <Box
          fontSize="0.75rem"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {isLoading ? <Skeleton /> : locationName}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default Entity;
