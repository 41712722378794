import { keyBy } from 'lodash';

import { decodeToken } from '../../common/services/jwt.service';
import { getActiveOrganizationId } from '../../common/services/authentication.service';
import { getGandalfClient } from '../../app/initializer';
import OrganizationData from '../../common/data-types/organization-data';
import PasswordEditData from '../../common/data-types/password-edit-data';
import SessionData from '../../common/data-types/session-data';
import TokenType from '../../common/enums/token-type.enum';
import UserData from '../../common/data-types/user-data';

interface LoginParams {
  email: string;
  password: string;
}

const gandalfClient = getGandalfClient();

export const login = async ({
  email,
  password,
}: LoginParams): Promise<SessionData> => {
  const body = await gandalfClient.authenticate({ email, password });

  return sessionDataFromJSON(body);
};

export const requestPasswordReset = (email: string): Promise<void> => {
  return gandalfClient.requestPasswordReset(email);
};

export const passwordEditByToken = ({
  token,
  password,
}: {
  token: string;
  password: string;
}): Promise<void> => {
  return gandalfClient.passwordEditByToken(token, password);
};

export const passwordEdit = async ({
  currentPassword,
  password,
}: PasswordEditData): Promise<SessionData> => {
  const { body } = await gandalfClient.passwordEdit(currentPassword, password);

  return sessionDataFromJSON(body);
};

export const temporaryPasswordEdit = async ({
  currentPassword,
  password,
}: PasswordEditData): Promise<SessionData> => {
  const { body } = await gandalfClient.passwordEdit(
    currentPassword,
    password,
    TokenType.TemporaryPassword
  );

  return sessionDataFromJSON(body);
};

const sessionDataFromJSON = (json: Record<string, any>): SessionData => {
  const { tokens, user } = json;

  const parsedTokens = parseTokens(tokens);

  const activeOrganizationId = getActiveOrganizationId(parsedTokens);

  return {
    activeOrganizationId,
    tokens: parsedTokens,
    user: userFromJSON(user, tokens),
  };
};

const userFromJSON = (
  json: Record<string, any>,
  tokens: string[]
): UserData => {
  const {
    cellphone,
    email,
    id,
    isPasswordTemporary,
    name,
    organizationAdmin,
    organizations,
  } = json;

  return {
    cellphone,
    email,
    id,
    isAdmin: organizationAdmin,
    isPasswordTemporary,
    name,
    organizations: tokens.map((token) =>
      tokenToOrganization(token, organizations)
    ),
  };
};

const parseTokens = (tokens: string[]): Record<number, string> => {
  return keyBy(tokens, (token) => decodeToken(token).organizationId);
};

const tokenToOrganization = (
  token: string,
  organizations: Record<string, any>[]
): OrganizationData => {
  const { organizationId, organizationName, organizationTypes } = decodeToken(
    token
  );

  const organization = organizations.find((o) => o.id === organizationId);

  return {
    carryingCompany: organization?.carryingCompany || false,
    id: organizationId,
    name: organization?.name || organizationName,
    types: organization?.types || organizationTypes,
  };
};
