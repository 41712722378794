import { decode } from './base64.service';
import OrganizationType from '../enums/organization-type.enum';

export interface DecodedSessionTokenData {
  carryingCompany: boolean;
  email: string;
  emailConfirmed: boolean;
  exp?: number;
  organizationId: number;
  organizationName: string;
  organizationTypes?: OrganizationType[];
}

export const decodeToken = (token: string): DecodedSessionTokenData => {
  const payloadToken = token.split('.')[1];

  const decoded = JSON.parse(decode(payloadToken));

  return decodedSessionFromJSON(decoded);
};

const decodedSessionFromJSON = (
  json: Record<string, any>
): DecodedSessionTokenData => {
  const {
    carryingCompany,
    email,
    emailConfirmed,
    organizationId,
    organizationName,
    exp,
    organizationTypes,
  } = json;

  return {
    carryingCompany,
    email,
    emailConfirmed,
    organizationId,
    organizationName,
    exp,
    organizationTypes,
  };
};
