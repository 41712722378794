import React from 'react';

import {
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';

import { decimalNumber } from '../../helpers/mask.helper';
import NFe from '../../data-types/nfe-data';

const useStyles = makeStyles({
  cell: {
    color: '#606771',
    borderColor: '#C2C3C9',
  },
  warningContainer: {
    backgroundColor: '#FFF4E5',
    position: 'relative',
  },
  warningIcon: {
    bottom: 0,
    color: '#ff9800',
    left: 10,
    margin: 'auto',
    position: 'absolute',
    top: 0,
  },
  warningIconHidden: {
    opacity: 0,
  },
});

export interface NfeListItemProps {
  index: number;
  nfe?: NFe;
  onRemove?: (index: number) => void;
  totalValue?: number | null;
}

const NfeListItem = ({
  index,
  nfe,
  onRemove,
  totalValue,
}: NfeListItemProps) => {
  const classes = useStyles();

  return nfe && onRemove ? (
    <TableRow
      className={nfe.isDuplicated ? classes.warningContainer : undefined}
    >
      <TableCell
        align="center"
        className={classes.cell}
        component="th"
        scope="row"
      >
        <Tooltip
          title={
            nfe.isDuplicated ? 'Este documento está em duplicidade' : false
          }
        >
          <WarningIcon
            className={`${classes.warningIcon} ${
              !nfe.isDuplicated ? classes.warningIconHidden : ''
            }`}
            color="inherit"
          />
        </Tooltip>
      </TableCell>
      <TableCell
        align="right"
        className={classes.cell}
        component="th"
        scope="row"
      >
        {nfe?.serie != null ? nfe.serie : '---'}
      </TableCell>
      <TableCell className={classes.cell}>{nfe.number}</TableCell>
      <TableCell className={classes.cell}>
        {typeof nfe.issueDate === 'string'
          ? nfe.issueDate
          : nfe.issueDate?.toLocaleDateString() || '---'}
      </TableCell>
      <TableCell className={classes.cell}>{nfe.order}</TableCell>
      <TableCell align="right" className={classes.cell}>
        {nfe.volumesQuantity || '---'}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {nfe.weight?.toFixed(2).replace(/\./, ',') || '---'}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {nfe.value ? decimalNumber(nfe.value) : '---'}
      </TableCell>
      <TableCell className={classes.cell}>
        <IconButton size="small" onClick={() => onRemove(index)}>
          <RemoveCircle />
        </IconButton>
      </TableCell>
    </TableRow>
  ) : totalValue ? (
    <TableRow>
      <TableCell align="center" component="td" scope="row">
        {' '}
      </TableCell>
      <TableCell align="center" component="td" scope="row">
        {' '}
      </TableCell>
      <TableCell align="center" component="td" scope="row">
        {' '}
      </TableCell>
      <TableCell align="center" component="td" scope="row">
        {' '}
      </TableCell>
      <TableCell align="center" component="td" scope="row">
        {' '}
      </TableCell>
      <TableCell align="center" component="td" scope="row">
        {' '}
      </TableCell>
      <TableCell align="center" component="td" scope="row">
        {' '}
      </TableCell>
      <TableCell align="right" component="td" scope="row">
        <b>Total: </b>
        {decimalNumber(totalValue)}
      </TableCell>
      <TableCell align="center" component="td" scope="row">
        {' '}
      </TableCell>
    </TableRow>
  ) : (
    <></>
  );
};

export default NfeListItem;
