import React, { useCallback } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { selectUsersListFilters } from '../../usersSlice';
import { UncontrolledCheckbox } from '../../../../common/components/Checkbox';
import { useAppSelector } from '../../../../app/hooks';
import UserStatus from '../../../../common/enums/user-status-type.enum';

const useStyles = makeStyles({
  statusItem: {
    '& label': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
});

export interface UserStatusFilterProps {
  onChange: (data: UserStatus[]) => void;
}

const OPTIONS = [
  { label: 'Ativo', value: UserStatus.Active },
  { label: 'Inativo', value: UserStatus.Inactive },
];

const UserStatusFilter = ({ onChange }: UserStatusFilterProps) => {
  const classes = useStyles();

  const filters = useAppSelector(selectUsersListFilters);

  const handleChange = useCallback(
    (value: boolean, option: UserStatus) => {
      let updatedFilter;

      if (value) {
        updatedFilter = [...(filters?.statuses || []), option];
      } else {
        updatedFilter =
          filters?.statuses &&
          filters.statuses.filter((status) => status !== option);
      }

      onChange(updatedFilter);
    },
    [onChange, filters?.statuses]
  );

  return (
    <Box>
      {OPTIONS.map((option, index) => (
        <Box className={classes.statusItem} key={index}>
          <UncontrolledCheckbox
            label={option.label}
            checked={(filters?.statuses || []).some(
              (value) => value === option.value
            )}
            onChange={(e) => handleChange(e.target.checked, option.value)}
          />
        </Box>
      ))}
    </Box>
  );
};

export default UserStatusFilter;
