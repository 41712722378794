import { getActiveToken } from '../services/authentication.service';
import { HttpClient, HttpResponse } from './http-client/http.client';
import { UsersListParams } from '../../features/Users/usersAPI';
import TokenType from '../enums/token-type.enum';
import { GandalfClientMissingActiveTokenError } from '../errors/clients/gandalf-client';

// #region Typings
interface AuthenticateParams {
  email: string;
  password: string;
}

interface GandalfClientDependencies {
  host: string;
  httpClient: HttpClient;
}
// #endregion Typings

class GandalfClient {
  host: string;

  httpClient: HttpClient;

  constructor({ host, httpClient }: GandalfClientDependencies) {
    this.host = host;
    this.httpClient = httpClient;
  }

  async authenticate({
    email,
    password,
  }: AuthenticateParams): Promise<Record<string, any>> {
    const url = this.buildUrl('/v2/users/sessions');
    const { body } = await this.httpClient.post({
      body: { email, password },
      url,
    });

    return body;
  }

  async listUsers({
    organizationId,
  }: UsersListParams): Promise<Record<string, any>> {
    const url = this.buildUrl(`/v2/users?organizationId=${organizationId}`);
    const token = getActiveToken();

    if (!token) {
      throw new GandalfClientMissingActiveTokenError();
    }

    const reqArgs = this.signRequest({ url }, token);

    return this.httpClient.get(reqArgs);
  }

  async meUpdate({
    body,
  }: {
    body: Record<string, any>;
  }): Promise<HttpResponse> {
    const url = this.buildUrl(`/v2/users/me`);
    const token = getActiveToken();

    if (!token) {
      throw new GandalfClientMissingActiveTokenError();
    }

    const reqArgs = this.signRequest({ body, url }, token);
    return this.httpClient.patch(reqArgs);
  }

  async passwordEditByToken(token: string, password: string): Promise<void> {
    const url = this.buildUrl('/v2/users/password');
    const reqArgs = this.signRequest(
      { body: { password }, url },
      token,
      TokenType.PasswordReset
    );
    await this.httpClient.patch(reqArgs);
  }

  async passwordEdit(
    currentPassword: string,
    password: string,
    tokenType?: TokenType
  ): Promise<HttpResponse> {
    const url = this.buildUrl('/v2/users/me/password');
    const token = getActiveToken();

    if (!token) {
      throw new GandalfClientMissingActiveTokenError();
    }

    const reqArgs = this.signRequest(
      { body: { currentPassword, password }, url },
      token,
      tokenType
    );

    return this.httpClient.patch(reqArgs);
  }

  async requestPasswordReset(email: string): Promise<void> {
    const url = this.buildUrl('/v2/users/forgot-password');

    await this.httpClient.post({ body: { email }, url });
  }

  async resetUserPassword(userId: number): Promise<HttpResponse<HttpResponse>> {
    const url = this.buildUrl(`/v2/users/reset-password/${userId}`);
    const token = getActiveToken();

    if (!token) {
      throw new GandalfClientMissingActiveTokenError();
    }

    const reqArgs = this.signRequest({ url }, token);

    return this.httpClient.post(reqArgs);
  }

  async userUpdate({
    userId,
    body,
  }: {
    userId: number;
    body: Record<string, any>;
  }): Promise<HttpResponse> {
    const url = this.buildUrl(`/v2/users/${userId}`);
    const token = getActiveToken();

    if (!token) {
      throw new GandalfClientMissingActiveTokenError();
    }

    const reqArgs = this.signRequest({ body, url }, token);

    return this.httpClient.patch(reqArgs);
  }

  createUser(body: Record<string, any>): Promise<HttpResponse> {
    const url = this.buildUrl('/v2/users/registrations');
    const token = getActiveToken();

    if (!token) {
      throw new GandalfClientMissingActiveTokenError();
    }

    const reqArgs = this.signRequest({ body, url }, token);

    return this.httpClient.post(reqArgs);
  }

  findUser(id: number): Promise<HttpResponse> {
    const url = this.buildUrl(`/v2/users/${id}`);
    const token = getActiveToken();

    if (!token) {
      throw new GandalfClientMissingActiveTokenError();
    }

    const reqArgs = this.signRequest({ url }, token);

    return this.httpClient.get(reqArgs);
  }

  private buildUrl(path: string): string {
    return [this.host, path]
      .filter(Boolean)
      .map((p) => p.replace(/^\//, '').replace(/\/$/, ''))
      .join('/');
  }

  private signRequest<T>(
    reqArgs: T,
    token: string,
    tokenType?: TokenType
  ): T & { headers: { Authorization: string } } {
    return {
      ...(reqArgs as any),
      headers: {
        Authorization: `Bearer ${token}`,
        ...(tokenType ? { 'authorization-token-type': tokenType } : {}),
      },
    };
  }
}

export default GandalfClient;
