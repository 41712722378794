import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { decimalNumber } from '../helpers/mask.helper';
import ContentStatementData from '../data-types/content-statement-data';
import DocumentViewButton from './DocumentViewButton';
import SkeletonField from './SkeletonField';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    alignItems: 'center',
    color: theme.palette.info.light,
    display: 'flex',
    fontSize: 12,
  },
  icon: {
    marginRight: 14,
  },
  label: {
    fontSize: 12,
    fontWeight: 300,
  },
  title: {
    marginBottom: 20,
  },
  value: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface ShipmentContentStatementProps {
  contentStatement?: ContentStatementData;
  isLoading: boolean;
}

const ShipmentContentStatement = ({
  contentStatement,
  isLoading,
}: ShipmentContentStatementProps) => {
  const classes = useStyles();

  return (
    <Box mb={5}>
      <SkeletonField isLoading={isLoading} height="large" width="large">
        <Typography className={classes.title} color="primary">
          Declaração de Conteúdo
        </Typography>
      </SkeletonField>
      <Grid container>
        <Grid item xs={5}>
          <SkeletonField isLoading={isLoading}>
            <Typography className={classes.label}>Valor declarado</Typography>
          </SkeletonField>

          <SkeletonField isLoading={isLoading}>
            <Typography
              className={classes.value}
              color="textPrimary"
              component="strong"
            >
              {`R$ ${decimalNumber(contentStatement?.value || 0)}`}
            </Typography>
          </SkeletonField>
        </Grid>
        <Grid item xs={3}>
          <SkeletonField isLoading={isLoading}>
            <Typography className={classes.label}>
              Número do documento
            </Typography>
          </SkeletonField>
          <SkeletonField isLoading={isLoading}>
            <Typography
              className={classes.value}
              color="textPrimary"
              component="strong"
            >
              {contentStatement?.documentNumber || '---'}
            </Typography>
          </SkeletonField>
        </Grid>
        <Grid item xs={2}>
          {!isLoading && contentStatement?.file && (
            <DocumentViewButton
              fileParams={{ file: contentStatement?.file.url }}
              label="Ver Declaração"
              labelDirection="row"
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShipmentContentStatement;
