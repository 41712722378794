import React, { useCallback, useEffect, useState } from 'react';

import { InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { UncontrolledTextField } from '../../../../common/components/TextField';

export interface ShipmentDocumentIdentifierFilterProps {
  data?: string;
  onSubmit: (data: string) => void;
}

export const ShipmentDocumentIdentifierFilter = ({
  data,
  onSubmit,
}: ShipmentDocumentIdentifierFilterProps) => {
  const [value, setValue] = useState('');

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    if (e.target.value === '') {
      onSubmit('');
    }
  };

  const handleSubmit = useCallback(() => {
    if (value !== '') {
      onSubmit(value);
    }
  }, [onSubmit, value]);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    setValue(data || '');
  }, [data]);

  return (
    <UncontrolledTextField
      helperText="Nota Fiscal ou CTE"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleSubmit}>
              <SearchIcon color="action" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder="Número ou Chave do Documento"
      type="text"
      value={value}
      onChange={handleChangeValue}
      onKeyPress={handleKeyPress}
    />
  );
};
