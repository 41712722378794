import React, { useCallback, useMemo, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Icon from '@material-ui/core/Icon';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PersonIcon from '@material-ui/icons/Person';

import {
  logout,
  selectSessionUserType,
  selectUser,
  selectUserIsAdmin,
} from '../../features/Session/sessionSlice';
import { StateKeys } from './Toolbar';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Menu from './Menu';
import MenuItem from './MenuItem';
import OpenPackageIcon from './OpenPackageIcon';
import OrganizationType from '../enums/organization-type.enum';

const useStyles = makeStyles((theme: Theme) => ({
  activatedLink: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  appbar: {
    minWidth: '80rem',
  },
  containerRoot: {
    padding: 0,
  },
  link: {
    color: theme.palette.primary.main,
    display: 'flex',
    padding: 26,
    textDecoration: 'none',
  },
  linkIconRoot: {
    marginRight: 10,
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  menuLink: {
    color: theme.palette.text.primary,
    display: 'flex',
    textDecoration: 'none',
  },
  toolbar: {
    padding: 0,
  },
}));

type ToolbarState = Record<StateKeys, boolean>;

const UserMenu = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const userType = useAppSelector(selectSessionUserType);
  const userIsAdmin = useAppSelector(selectUserIsAdmin);

  const history = useHistory();

  const [isOpen, setIsOpen] = useState<ToolbarState>({
    isMenuOpen: false,
    isOrganizationModalOpen: false,
  });

  const showPackagesLink = useMemo<boolean>(
    () => [OrganizationType.Shipper, OrganizationType.Admin].includes(userType),
    [userType]
  );

  const handleToggleIsOpen = useCallback((key: StateKeys) => {
    setIsOpen((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  }, []);

  const handleCloseMenu = () => {
    handleClose(StateKeys.isMenuOpen);
  };

  const handleMenuButtonClick = () => {
    handleToggleIsOpen(StateKeys.isMenuOpen);
  };

  const handleClose = useCallback(
    (key: StateKeys) => {
      handleToggleIsOpen(key);
    },
    [handleToggleIsOpen]
  );

  const handleLogout = () => {
    dispatch(logout());
    history.push('/');
  };

  return (
    <Menu
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      buttonChildren={
        <Box display="flex">
          <strong>{user?.name}</strong>
          <KeyboardArrowDownIcon color="action" />
        </Box>
      }
      elevation={1}
      open={isOpen.isMenuOpen}
      onClose={handleCloseMenu}
      onButtonClick={handleMenuButtonClick}
    >
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleCloseMenu}>
        <Link className={classes.menuLink} to="/user/profile">
          <ListItemIcon>
            <PersonIcon color="action" />
          </ListItemIcon>
          Minha Conta
        </Link>
      </MenuItem>
      <Divider />
      {showPackagesLink && (
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={handleCloseMenu}
        >
          <Link className={classes.menuLink} to="/packages">
            <ListItemIcon>
              <OpenPackageIcon color="action" />
            </ListItemIcon>
            Embalagens
          </Link>
        </MenuItem>
      )}
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleCloseMenu}>
        <Link className={classes.menuLink} to="/entities">
          <ListItemIcon>
            <Icon>groups</Icon>
          </ListItemIcon>
          Participantes De Envio
        </Link>
      </MenuItem>
      {userIsAdmin && (
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={handleCloseMenu}
        >
          <Link className={classes.menuLink} to="/users">
            <ListItemIcon>
              <Icon>switch_account</Icon>
            </ListItemIcon>
            Usuários
          </Link>
        </MenuItem>
      )}
      <Divider />
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleLogout}>
        <ListItemIcon>
          <ExitToAppIcon color="action" />
        </ListItemIcon>
        Sair
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
