import React, { useState } from 'react';

import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import { EntityUpdateData } from '../entitiesAPI';
import { PersonType } from '../../../common/enums/person-type.enum';
import ConfirmationModal from '../../../common/components/Modals/ConfirmationModal';
import EntityData from '../../../common/data-types/entity-data';
import SubmitButton from '../../../common/components/SubmitButton';
import withEntityForm, {
  EntityFormInputs,
  WithEntityFormProps,
} from '../../../common/hocs/withEntityForm';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    backgroundColor: theme.palette.info.main,
  },
  displayName: {
    color: theme.palette.text.primary,
  },
  footer: {
    borderTop: '1px solid #C2C3C9',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    width: '100%',
  },
  removeButton: {
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    backgroundColor: theme.palette.error.main,
    marginRight: '0.5rem',
  },
}));

export interface EntityEditFormProps {
  entity: EntityData;
  isLoading: boolean;
  onRemove: () => void;
  onSubmit: (entity: EntityUpdateData['entity']) => void;
}

const EntityEditForm = ({
  entity,
  children,
  form,
  isLoading,
  onRemove,
  onSubmit,
}: EntityEditFormProps & WithEntityFormProps) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const {
    formState: { isSubmitted, isValid },
    handleSubmit,
  } = form;

  const toModel = (data: EntityFormInputs): EntityUpdateData['entity'] => {
    const {
      cep,
      clientCode,
      complement,
      document,
      email,
      location,
      name,
      neighborhood,
      nickname,
      number,
      personType,
      phone,
      state,
      stateRegistration,
      street,
      taxFree,
    } = data;

    return {
      address: {
        cep,
        complement,
        location,
        neighborhood,
        number,
        state,
        street,
      },
      code: clientCode,
      corporation: PersonType.Corporation === personType,
      displayName: nickname,
      documentNumber: document,
      email,
      name,
      phone: phone.replace(/\D/g, ''),
      shippingSite: false,
      stateRegistration: taxFree ? '' : stateRegistration,
    };
  };

  const handleOnSubmit = (data: EntityFormInputs): void => {
    onSubmit(toModel(data));
  };

  return (
    <>
      <ConfirmationModal
        content={
          <Typography component="p">
            Deseja mesmo remover o participante{' '}
            <strong className={classes.displayName}>
              {entity.displayName || entity.name}
            </strong>{' '}
            ? Essa operação não pode ser revertida.
          </Typography>
        }
        open={openModal}
        title="Excluir este participante?"
        onCancel={() => setOpenModal(false)}
        onConfirm={onRemove}
      />
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Box marginBottom={10}>{children}</Box>
        <Box bgcolor="background.default" className={classes.footer}>
          <Box display="flex" justifyContent="flex-end" py={2} width="50rem">
            <SubmitButton
              classes={{ root: classes.removeButton }}
              color="primary"
              disabled={isLoading || (isSubmitted && !isValid)}
              isLoading={isLoading}
              variant="contained"
              onClick={() => setOpenModal(true)}
            >
              Remover
            </SubmitButton>
            <SubmitButton
              classes={{ root: classes.button }}
              color="primary"
              disabled={isLoading || (isSubmitted && !isValid)}
              isLoading={isLoading}
              type="submit"
              variant="contained"
            >
              Atualizar
            </SubmitButton>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default withEntityForm<EntityEditFormProps>(EntityEditForm);
