import React, { ReactNode, useCallback } from 'react';

import {
  DatePickerProps as MuiDatePickerProps,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Control, useController } from 'react-hook-form';
import { isValid as isValidDate } from 'date-fns/esm';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';

export interface UncontrolledDatePickerProps extends MuiDatePickerProps {
  autoClose?: boolean;
  className?: string;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  disableToolbar?: boolean;
  error?: boolean;
  format?: string;
  helperText?: React.ReactNode;
  invalidDateMessage?: ReactNode;
  label?: string;
  maxDate?: Date;
  maxDateMessage?: ReactNode;
  minDate?: Date;
  minDateMessage?: ReactNode;
  name?: string;
  placeholder?: string;
  value: ParsableDate;
  variant?: 'dialog' | 'inline' | 'static';
  onChange: (date: MaterialUiPickersDate, value?: string | null) => void;
}

export const UncontrolledDatePicker = ({
  autoClose = false,
  disableToolbar = true,
  value,
  format = 'dd/MM/yyyy',
  variant = 'inline',
  onChange,
  ...rest
}: UncontrolledDatePickerProps) => {
  const handleChange = useCallback(
    (date: MaterialUiPickersDate) => {
      if (isValidDate(date) || date === null) {
        onChange(date);
      }
    },
    [onChange]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <KeyboardDatePicker
        {...rest}
        autoOk={autoClose}
        disableToolbar={disableToolbar}
        format={format}
        value={value}
        variant={variant}
        onChange={handleChange}
      />
    </MuiPickersUtilsProvider>
  );
};

export interface DatePickerProps
  extends Omit<UncontrolledDatePickerProps, 'value' | 'onChange'> {
  control: Control<any>;
  name: string;
  onChange?: (date: MaterialUiPickersDate, value?: string | null) => void;
}

const DatePicker = ({ control, format, name, ...rest }: DatePickerProps) => {
  const {
    field: { onChange, value },
    fieldState: { invalid },
  } = useController({
    control,
    defaultValue: null,
    name,
  });

  return (
    <UncontrolledDatePicker
      {...rest}
      error={invalid}
      format={format}
      helperText={invalid ? 'Data inválida' : null}
      name={name}
      onChange={onChange}
      value={value || null}
    />
  );
};

export default DatePicker;
