import {
  AnyAction,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { isNil } from 'lodash';

import {
  getSessionData,
  login as authenticate,
  logout as deauthenticate,
  setActiveOrganizationId,
} from '../../common/services/authentication.service';
import {
  login,
  passwordEdit,
  passwordEditByToken,
  requestPasswordReset,
  temporaryPasswordEdit,
} from './sessionAPI';
import { RootState } from '../../app/store';
import { meUpdate } from '../Users/usersAPI';
import OrganizationData from '../../common/data-types/organization-data';
import OrganizationType from '../../common/enums/organization-type.enum';
import PasswordEditData from '../../common/data-types/password-edit-data';
import RequestData from '../../common/data-types/request-data';
import RequestStatus from '../../common/enums/request-status.enum';
import SessionData from '../../common/data-types/session-data';
import UserData from '../../common/data-types/user-data';
import UserEditData from '../../common/data-types/user-edit-data';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  const startsWith = action.type.startsWith('session');
  const endsWith = action.type.endsWith('/fulfilled');

  return startsWith && endsWith;
};

const isPendingAction = (action: AnyAction): action is PendingAction => {
  const startsWith = action.type.startsWith('session');
  const endsWith = action.type.endsWith('/pending');

  return startsWith && endsWith;
};

const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  const startsWith = action.type.startsWith('session');
  const endsWith = action.type.endsWith('/rejected');

  return startsWith && endsWith;
};

interface ResetThunkStatusActionParams {
  requestId: string;
}

// #region Thunks
const LOGIN_THUNK = 'session/login';
export const loginThunk = createAsyncThunk(
  LOGIN_THUNK,
  async ({ email, password }: { email: string; password: string }) => {
    const data = await login({ email, password });

    authenticate(data);

    return data;
  }
);

const PASSWORD_REQUEST_RESET_THUNK = 'session/forgotPassword';
export const requestPasswordResetThunk = createAsyncThunk(
  PASSWORD_REQUEST_RESET_THUNK,
  async (email: string) => {
    await requestPasswordReset(email);
  }
);

const PASSWORD_EDIT_BY_TOKEN_THUNK = 'session/passwordEditByToken';
export const passwordEditByTokenThunk = createAsyncThunk(
  PASSWORD_EDIT_BY_TOKEN_THUNK,
  async ({ token, password }: { token: string; password: string }) => {
    await passwordEditByToken({ token, password });
  }
);

const PASSWORD_EDIT_THUNK = 'session/passwordEdit';
export const passwordEditThunk = createAsyncThunk(
  PASSWORD_EDIT_THUNK,
  async ({ currentPassword, password }: PasswordEditData) => {
    const body = await passwordEdit({ currentPassword, password });

    authenticate(body);

    return body;
  }
);

const TEMPORARY_PASSWORD_EDIT_THUNK = 'session/temporaryPasswordEdit';
export const temporaryPasswordEditThunk = createAsyncThunk(
  TEMPORARY_PASSWORD_EDIT_THUNK,
  async ({ currentPassword, password }: PasswordEditData) => {
    const body = await temporaryPasswordEdit({ currentPassword, password });

    authenticate(body);

    return body;
  }
);

const USER_UPDATE_THUNK = 'session/userUpdate';
interface UserUpdateThunkParams {
  user: UserEditData;
}
export const userUpdateThunk = createAsyncThunk(
  USER_UPDATE_THUNK,
  async ({ user }: UserUpdateThunkParams) => {
    await meUpdate({ user });

    return { cellphone: user.phone, ...user };
  }
);
// #endregion Thunks

// #region Slice
interface SessionState {
  data: SessionData;
  isLoadingSession: boolean;
  isLogoutSucceeded: boolean;
  requestIds: RequestData['id'][];
  requestsById: Record<RequestData['id'], RequestData>;
}

const initialState: SessionState = {
  data: {} as SessionData,
  isLoadingSession: true,
  isLogoutSucceeded: false,
  requestIds: [],
  requestsById: {},
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    changeActiveOrganization: (state, { payload }) => {
      state.data = {
        ...state.data,
        activeOrganizationId: payload,
      };
      setActiveOrganizationId(payload);
    },
    cleanRequests: (state) => {
      state.requestIds = [];
      state.requestsById = {};
      state.isLogoutSucceeded = false;
    },
    resetThunkStatus: (
      state,
      action: PayloadAction<ResetThunkStatusActionParams>
    ) => {
      const request = state.requestsById[action.payload.requestId];

      if (request) {
        Object.assign(request, {
          error: undefined,
          status: RequestStatus.Idle,
          type: action.type,
        });
      }
    },
    loadSession: (state) => {
      const session = getSessionData();

      state.isLoadingSession = false;

      if (isNil(session) || isNil(session.user)) {
        logout();
      } else {
        state.data = session;
      }
    },
    logout: (state) => {
      deauthenticate();
      state.data = initialState.data;
      state.isLogoutSucceeded = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(passwordEditThunk.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(temporaryPasswordEditThunk.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(userUpdateThunk.fulfilled, (state, action) => {
        if (state.data?.user) {
          state.data = {
            ...state.data,
            user: {
              ...state.data.user,
              ...action.payload,
            },
          };
          authenticate(state.data);
        }
      })
      .addMatcher(isFulfilledAction, (state, action: AnyAction) => {
        const { meta } = action;
        const request = state.requestsById[meta.requestId];

        if (!request) {
          return;
        }

        Object.assign(request, {
          error: undefined,
          status: RequestStatus.Succeeded,
          type: action.type,
        });
      })
      .addMatcher(isPendingAction, (state, action: AnyAction) => {
        const { meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Loading,
          type,
        };

        state.requestsById[requestId] = request;
      })
      .addMatcher(isRejectedAction, (state, action: AnyAction) => {
        const { error, meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Failed,
          type,
          error,
        };

        state.requestsById[requestId] = request;
      });
  },
});
// #endregion Slice

// #region Selectors
// #region Private Selectors
const selectSessionData = (rootState: RootState): SessionData => {
  const state = selectSessionState(rootState);

  return state.data;
};

const selectSessionIsFulfilledByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const fulfilledType = [type, 'fulfilled'].join('/');
  const request = selectSessionRequestByType(rootState, fulfilledType);

  return request ? request.status === RequestStatus.Succeeded : false;
};

const selectSessionIsRequestingByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const pendingType = [type, 'pending'].join('/');
  const request = selectSessionRequestByType(rootState, pendingType);

  return request ? request.status === RequestStatus.Loading : false;
};

const selectSessionErrorByType = (
  rootState: RootState,
  type: RequestData['type']
): Record<string, any> | null => {
  const failedType = [type, 'rejected'].join('/');
  const request = selectSessionRequestByType(rootState, failedType);

  return request?.error || null;
};

const selectSessionRequestByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData => {
  const requests = selectSessionRequestsByType(rootState, type);

  return requests.reverse()[0];
};

const selectSessionRequests = (rootState: RootState): RequestData[] => {
  const state = selectSessionState(rootState);

  return state.requestIds.map((id) => state.requestsById[id]);
};

const selectSessionRequestsByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData[] => {
  const requests = selectSessionRequests(rootState);

  return requests.filter((r) => r.type === type);
};

const selectSessionState = (rootState: RootState): SessionState =>
  rootState.session;
// #endregion Private Selectors
// #region Public Selectors
export const selectIsLoadingSession = (rootState: RootState): boolean => {
  const state = selectSessionState(rootState);

  return state.isLoadingSession;
};

export const selectIsAuthenticated = (rootState: RootState): boolean => {
  const state = selectSessionData(rootState);

  return !!state?.tokens && Object.keys(state.tokens).length > 0;
};

export const selectUser = (rootState: RootState): UserData => {
  const state = selectSessionData(rootState);

  return state?.user;
};

export const selectUserIsAdmin = (rootState: RootState): boolean => {
  const user = selectUser(rootState);

  return user?.isAdmin;
};

export const selectOrganizationId = (rootState: RootState): number => {
  const session = selectSessionData(rootState);

  return session?.activeOrganizationId;
};

export const selectSessionOrganizations = (
  rootState: RootState
): OrganizationData[] => {
  const user = selectUser(rootState);

  return user?.organizations;
};

export const selectSessionActiveOrganization = (
  rootState: RootState
): OrganizationData | undefined => {
  const organizations = selectSessionOrganizations(rootState);
  const organizationId = selectOrganizationId(rootState);

  return organizations?.find(
    (organization) => organization.id === organizationId
  );
};

export const selectSessionIsFulfilledLogin = (
  rootState: RootState
): boolean => {
  return selectSessionIsFulfilledByType(rootState, LOGIN_THUNK);
};

export const selectSessionIsFulfilledLogout = (
  rootState: RootState
): boolean => {
  const state = selectSessionState(rootState);
  return state.isLogoutSucceeded;
};

export const selectSessionIsFulfilledRequestResetPassword = (
  rootState: RootState
): boolean => {
  return selectSessionIsFulfilledByType(
    rootState,
    PASSWORD_REQUEST_RESET_THUNK
  );
};

export const selectSessionIsFulfilledPasswordEditByToken = (
  rootState: RootState
): boolean => {
  return selectSessionIsFulfilledByType(
    rootState,
    PASSWORD_EDIT_BY_TOKEN_THUNK
  );
};

export const selectSessionIsFulfilledPasswordEdit = (
  rootState: RootState
): boolean => {
  return selectSessionIsFulfilledByType(rootState, PASSWORD_EDIT_THUNK);
};

export const selectSessionIsFulfilledTemporaryPasswordEdit = (
  rootState: RootState
): boolean => {
  return selectSessionIsFulfilledByType(
    rootState,
    TEMPORARY_PASSWORD_EDIT_THUNK
  );
};

export const selectSessionIsFulfilledUserEdit = (
  rootState: RootState
): boolean => {
  return selectSessionIsFulfilledByType(rootState, USER_UPDATE_THUNK);
};

export const selectSessionIsRequestingLogin = (
  rootState: RootState
): boolean => {
  return selectSessionIsRequestingByType(rootState, LOGIN_THUNK);
};

export const selectSessionIsRequestingRequestResetPassword = (
  rootState: RootState
): boolean => {
  return selectSessionIsRequestingByType(
    rootState,
    PASSWORD_REQUEST_RESET_THUNK
  );
};

export const selectSessionIsRequestingPasswordEditByToken = (
  rootState: RootState
): boolean => {
  return selectSessionIsRequestingByType(
    rootState,
    PASSWORD_EDIT_BY_TOKEN_THUNK
  );
};

export const selectSessionIsRequestingPasswordEdit = (
  rootState: RootState
): boolean => {
  return selectSessionIsRequestingByType(rootState, PASSWORD_EDIT_THUNK);
};

export const selectSessionIsRequestingTemporaryPasswordEdit = (
  rootState: RootState
): boolean => {
  return selectSessionIsRequestingByType(
    rootState,
    TEMPORARY_PASSWORD_EDIT_THUNK
  );
};

export const selectSessionIsRequestingUserEdit = (
  rootState: RootState
): boolean => {
  return selectSessionIsRequestingByType(rootState, USER_UPDATE_THUNK);
};

export const selectSessionErrorLogin = (
  rootState: RootState
): Record<string, any> | null => {
  return selectSessionErrorByType(rootState, LOGIN_THUNK);
};

export const selectSessionErrorRequestResetPassword = (
  rootState: RootState
): Record<string, any> | null => {
  return selectSessionErrorByType(rootState, PASSWORD_REQUEST_RESET_THUNK);
};

export const selectSessionErrorPasswordEditByToken = (
  rootState: RootState
): Record<string, any> | null => {
  return selectSessionErrorByType(rootState, PASSWORD_EDIT_BY_TOKEN_THUNK);
};

export const selectSessionErrorPasswordEdit = (
  rootState: RootState
): Record<string, any> | null => {
  return selectSessionErrorByType(rootState, PASSWORD_EDIT_THUNK);
};

export const selectSessionErrorTemporaryPasswordEdit = (
  rootState: RootState
): Record<string, any> | null => {
  return selectSessionErrorByType(rootState, TEMPORARY_PASSWORD_EDIT_THUNK);
};

export const selectSessionErrorUserEdit = (
  rootState: RootState
): Record<string, any> | null => {
  return selectSessionErrorByType(rootState, USER_UPDATE_THUNK);
};

export const selectSessionIsCarryingCompany = (rootState: RootState) => {
  const activeOrganization = selectSessionActiveOrganization(rootState);
  return activeOrganization?.carryingCompany || false;
};

export const selectSessionUserType = (
  rootState: RootState
): OrganizationType => {
  const types = selectSessionActiveOrganization(rootState)?.types;

  if (types) {
    const isAdmin = types.includes(OrganizationType.Admin);
    const isCarrier = types.includes(OrganizationType.Carrier);
    const isCartageAgent = types.includes(OrganizationType.CartageAgent);
    const isShipper = types.includes(OrganizationType.Shipper);

    if (isAdmin) {
      return OrganizationType.Admin;
    }

    if (isShipper) {
      return OrganizationType.Shipper;
    }

    if (isCarrier) {
      return OrganizationType.Carrier;
    }

    if (isCartageAgent) {
      return OrganizationType.CartageAgent;
    }
  }

  return OrganizationType.Shipper;
};
// #endregion Public Selectors
// #endregion Selectors

export const CHANGE_ORGANIZATION_ACTION = 'session/changeActiveOrganization';

export const {
  changeActiveOrganization,
  cleanRequests,
  loadSession,
  logout,
  resetThunkStatus,
} = sessionSlice.actions;

export const sessionReducer = sessionSlice.reducer;
