import React, { useEffect } from 'react';
import { useAppSelector } from '../../../app/hooks';
import {
  selectErrorDownloadExcel,
  selectIsFulfilledDownloadExcel,
  selectIsRequestingDownloadExcel,
} from '../../../features/Shipments/shipmentsSlice';
import ConfirmationModal from './ConfirmationModal';

interface TagFormatModalProps {
  onClose: () => void;
  onConfirm: () => void;
  onError: () => void;
  open: boolean;
  total: number;
}

const DownloadExcelModal = ({
  onClose,
  onConfirm,
  onError,
  open,
  total,
}: TagFormatModalProps) => {
  const downloadExcelError = useAppSelector(selectErrorDownloadExcel);
  const downloadExcelIsLoading = useAppSelector(
    selectIsRequestingDownloadExcel
  );
  const downloadExcelIsSucceeded = useAppSelector(
    selectIsFulfilledDownloadExcel
  );

  function isButtonDisabled(): boolean {
    if (downloadExcelIsLoading || downloadExcelError !== null) {
      return true;
    }
    return false;
  }

  const handleConfirm = () => {
    onConfirm();
  };

  useEffect(() => {
    if (downloadExcelError) {
      onError();
    }
  }, [downloadExcelError, onError]);

  useEffect(() => {
    if (downloadExcelIsSucceeded) {
      onClose();
    }
  }, [downloadExcelIsSucceeded, onClose]);

  return (
    <ConfirmationModal
      content={`Deseja mesmo baixar um excel com os volumes referente a esses <strong>${total} envios</strong>?`}
      confirmButtonLabel="Download"
      disabledConfirmButton={isButtonDisabled()}
      fullWidth
      hasHTMLcontent
      isLoading={downloadExcelIsLoading}
      onCancel={onClose}
      onConfirm={handleConfirm}
      open={open}
      title="Download de envios e volumes"
      hasWarning={total > 100}
      warningMessage="Somente os <strong>100 primeiros</strong> envios serão exportados."
    />
  );
};

export default DownloadExcelModal;
