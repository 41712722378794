import React, { useEffect } from 'react';

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import {
  cleanRequests,
  createPackageThunk,
  selectPackageIsFulfilledCreate,
  selectPackageIsRejectedCreate,
  selectPackageIsRequestingCreate,
} from './packagesSlice';
import { PackageFormInputs } from '../../common/hocs/withPackageForm';
import { PAGE_SIZES } from '../../common/constants/page-size.constants';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import BackButton from '../../common/components/BackButton';
import GenericErrorDialog from '../../common/components/GenericErrorDialog';
import PackageNewForm from './components/PackageNewForm';

const useStyles = makeStyles({
  arrow: {
    color: '#1976D2',
  },
  container: {
    maxWidth: '50rem',
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
  },
});

interface Props {
  title: string;
}

const PackageNewPage = ({ title }: Props) => {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const error = useAppSelector(selectPackageIsRejectedCreate);
  const isLoading = useAppSelector(selectPackageIsRequestingCreate);
  const isCreated = useAppSelector(selectPackageIsFulfilledCreate);

  const handleOnSubmit = (data: PackageFormInputs) => {
    dispatch(createPackageThunk(data));
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (isCreated) {
      history.goBack();
      dispatch(cleanRequests());
    }
  }, [dispatch, history, isCreated]);

  useEffect(() => {
    return () => {
      dispatch(cleanRequests());
    };
  }, [dispatch, history]);

  return (
    <Box
      m="auto"
      maxWidth={PAGE_SIZES.maxWidth}
      minWidth={PAGE_SIZES.minWidth}
      pl={PAGE_SIZES.sidePadding}
      pr={PAGE_SIZES.sidePadding}
    >
      <GenericErrorDialog error={error} />
      <Container className={classes.container} disableGutters>
        <Box mb={3} mt={4}>
          <Grid alignItems="center" container direction="row" spacing={3}>
            <Grid item>
              <BackButton />
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
                color="primary"
                component="h2"
              >
                Nova Embalagem
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <PackageNewForm isLoading={isLoading} onSubmit={handleOnSubmit} />
      </Container>
    </Box>
  );
};

export default PackageNewPage;
