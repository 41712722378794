import React from 'react';

import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import icon from '../../common/assets/images/bus-alert-icon.svg';
import logo from '../../common/assets/images/ebb-logo-light.svg';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#EFEFEF',
    height: '100vh',
    width: '100%',
  },
  card: {
    margin: 'auto',
    marginTop: -58,
    maxWidth: 764,
    padding: 80,
    paddingTop: 60,

    '& h3': {
      fontWeight: 400,
      wordWrap: 'break-word',
    },
  },
  header: {
    height: '33vh',
  },
  button: {
    backgroundColor: '#1976D2',
    color: 'white',
    fontWeight: 400,
    width: 296,
  },
});

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Box className={classes.header} bgcolor="primary.main" pt={3} px={5}>
        <img alt="Logo da Enviabybus" src={logo} title="Enviabybus" />
      </Box>
      <Box bgcolor="white" className={classes.card}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={3}>
            <img
              alt="Icone de Alerta"
              src={icon}
              title="Página não Encontrada"
            />
          </Grid>
          <Grid item xs={9}>
            <h1>Opa! Página não encontrada</h1>
            <h3>
              Desculpe, a página que você está tentando acessar não foi
              encontrada
            </h3>
            <h3>Posso te indicar um caminho?</h3>
            <Button
              className={classes.button}
              color="primary"
              component={Link}
              disableElevation
              size="large"
              to="/"
              variant="contained"
            >
              Ver meus envios
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default NotFoundPage;
