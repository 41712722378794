import React, { useEffect, useMemo, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ShippingEventType, {
  SHIPPING_EVENT_TYPES,
  SHIPPING_EVENT_TYPE_LABEL_MAP,
} from '../../../common/enums/shipping-event-type.enum';
import { ShippingEventNewFormInputs } from './ShippingEventNewDialog';
import DateTimePicker from '../../../common/components/DateTimePicker';
import Select, { SelectOption } from '../../../common/components/Select';
import ShippingEventFileList from './FileList/FileList';
import ShippingEventVolumeList from './VolumeList/VolumeList';
import TextField from '../../../common/components/TextField';
import VolumeData from '../../../common/data-types/volume-data';

interface ShippingEventNewFormProps {
  volumes: VolumeData[];
  volumesQuantity: number;
}

interface ShippingEventNewFormState {
  showFileList: boolean;
  showResponsibleFields: boolean;
}

const ShippingEventNewForm = ({
  volumes,
  volumesQuantity,
}: ShippingEventNewFormProps) => {
  const { control } = useFormContext<ShippingEventNewFormInputs>();

  const selectedType = useWatch({ control, name: 'type' });

  const [state, setState] = useState<ShippingEventNewFormState>({
    showFileList: false,
    showResponsibleFields: false,
  });

  const responsibleFieldsTitle = useMemo<string>(() => {
    const term =
      selectedType === ShippingEventType.Delivery ||
      selectedType === ShippingEventType.GiveCustody
        ? 'entregou'
        : 'recebeu';

    return `Quem ${term} a carga?`;
  }, [selectedType]);

  const typeOptions = SHIPPING_EVENT_TYPES.map<SelectOption>((type) => {
    return {
      label: SHIPPING_EVENT_TYPE_LABEL_MAP.get(type)!,
      value: type,
    };
  });

  useEffect(() => {
    setState({
      showFileList: ShippingEventType.Delivery === selectedType,
      showResponsibleFields: [
        ShippingEventType.Delivery,
        ShippingEventType.GiveCustody,
        ShippingEventType.ReceiveCustody,
      ].includes(selectedType as ShippingEventType),
    });
  }, [selectedType]);

  return (
    <form>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            control={control}
            fullWidth
            label="Ocorrência *"
            name="type"
            options={typeOptions}
            placeholder="Selecione a ocorrência"
          />
        </Grid>
        <Grid item xs={12}>
          <DateTimePicker
            control={control}
            label="Data e Hora *"
            name="date"
            placeholder="Data e Hora *"
          />
        </Grid>
        {state.showResponsibleFields && (
          <>
            <Grid item xs={12}>
              <Typography>{responsibleFieldsTitle}</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                control={control}
                fullWidth
                label="Nome *"
                name="responsible.name"
                placeholder="Nome"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                control={control}
                fullWidth
                label="Documento *"
                name="responsible.documentNumber"
                placeholder="Documento"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <ShippingEventVolumeList
            volumes={volumes}
            volumesQuantity={volumesQuantity}
          />
        </Grid>
        {state.showFileList && (
          <Grid item xs={12}>
            <ShippingEventFileList />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            control={control}
            fullWidth
            helperText="Alguma observação sobre essa ocorrência?"
            label="Ressalva (opcional)"
            name="comment"
            placeholder="Ressalva"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ShippingEventNewForm;
