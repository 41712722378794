import React, { useCallback, useEffect, useMemo } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import DescriptionIcon from '@material-ui/icons/Description';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { DecodedNFeWithBase64 } from '../../data-types/decoded-fiscal-documents-data';
import {
  selectNewShipmentReadNfeErrorState,
  selectNewShipmentReadNfeIsRequestingState,
  selectNfesListState,
} from '../../../features/Shipments/New/shipmentNewSlice';
import { UncontrolledFileUpload } from '../FileUpload';
import { useAppSelector } from '../../../app/hooks';
import AlertCard from '../AlertCard';
import NfeList from '../NfeList/NfeList';

const useStyles = makeStyles({
  blueText: {
    color: '#1976D2',
  },
  clickableText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  textColor: {
    color: '#606771',
  },
  title: {
    fontSize: 18,
    marginLeft: 15,
  },
});

interface NfeFormFieldsProps {
  className?: string;
  onCleanRequests: () => void;
  onClose?: () => void;
  onRemove: (index: number) => void;
  onUpload: (files: File[]) => void;
}

const NfeFormFields = ({
  className = '',
  onCleanRequests,
  onClose,
  onRemove,
  onUpload,
}: NfeFormFieldsProps) => {
  const classes = useStyles();

  const nfeList = useAppSelector(selectNfesListState);
  const error = useAppSelector(selectNewShipmentReadNfeErrorState);
  const loading = useAppSelector(selectNewShipmentReadNfeIsRequestingState);

  const { setValue } = useFormContext();

  const nfes = useMemo(() => {
    const duplicatedNfes: { [key: string]: string[] } = {};
    const processedNfes: { [key: string]: boolean } = {};

    return nfeList.map((nfe) => {
      const newNfe: DecodedNFeWithBase64 = {
        ...nfe,
        issueDate: new Date(nfe.issueDate!),
      };

      const nfeIdentifier = `${nfe.number}-${nfe.key}`;

      if (processedNfes[nfeIdentifier]) {
        if (duplicatedNfes[nfeIdentifier]) {
          duplicatedNfes[nfeIdentifier].push(nfeIdentifier);
        } else {
          duplicatedNfes[nfeIdentifier] = [nfeIdentifier];
        }

        newNfe.isDuplicated = true;
      } else {
        processedNfes[nfeIdentifier] = true;
        newNfe.isDuplicated = false;
      }

      return {
        ...newNfe,
      };
    });
  }, [nfeList]);

  const duplicatedCount = useMemo(() => {
    return nfes.reduce((total, nfe) => total + (nfe.isDuplicated ? 1 : 0), 0);
  }, [nfes]);

  const totalValue = useMemo(() => {
    return nfeList.reduce((acc, nfe) => {
      if (nfe.value !== undefined) {
        return acc + nfe.value;
      }
      return acc;
    }, 0);
  }, [nfeList]);

  const handleRemoveNfe = useCallback(
    (index: number) => {
      onRemove(index);
    },
    [onRemove]
  );

  const handleChange = useCallback(
    (newFiles: File[]) => {
      onCleanRequests();
      onUpload(newFiles);
    },
    [onCleanRequests, onUpload]
  );

  useEffect(() => {
    setValue('hasNFes', nfes.length >= 1);
    setValue('nfes', nfes);
  }, [nfes, setValue]);

  return (
    <Grid
      bgcolor="#FFFFFF"
      border={1}
      borderColor="#C2C3C9"
      borderRadius={3}
      className={className}
      component={Box}
      container
      direction="column"
      maxWidth={800}
      pb={3}
      pt={3}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        pb={2}
        pl={3}
        pr={3}
        pt={1}
      >
        <Box display="flex">
          <InsertDriveFileIcon className={classes.textColor} />
          <Typography className={`${classes.textColor} ${classes.title}`}>
            <strong>Insira as notas fiscais</strong>
          </Typography>
        </Box>
        {!!onClose && (
          <Box>
            <Typography
              className={`${classes.blueText} ${classes.clickableText}`}
              onClick={() => onClose()}
            >
              Esse envio não possui NFe
            </Typography>
          </Box>
        )}
      </Box>
      <Grid item component={Box} pl={3} pr={3}>
        <UncontrolledFileUpload
          accept=".xml"
          displayText="Arraste o arquivo XML aqui"
          error={!!error}
          loading={loading}
          multiple
          name="nfes"
          onChange={handleChange}
        />
      </Grid>
      {nfes.length > 0 && (
        <>
          <Box justifyContent="space-between" pb={2} pl={4} pr={4} pt={4}>
            <Grid container>
              <Grid item xs={4}>
                <Typography className={`${classes.textColor} ${classes.title}`}>
                  <strong>Notas Fiscais Adicionadas</strong>
                </Typography>
              </Grid>
              <Grid item container xs={2} justifyContent="flex-end">
                <Grid>
                  <DescriptionIcon className={classes.textColor} />
                </Grid>
                <Grid>
                  <Typography className={classes.textColor}>
                    <strong>{nfes.length}</strong> <span>Notas</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid item>
            <NfeList
              nfes={nfes}
              onRemove={handleRemoveNfe}
              totalValue={totalValue}
            />
          </Grid>
          {nfes.some((nfe) => nfe.isDuplicated) && (
            <Grid item component={Box} pl={15} pr={15} pt={3}>
              <AlertCard severity="warning" title="NFes Duplicados">
                {duplicatedCount <= 1
                  ? `Na lista de documentos existe ${duplicatedCount} documento em
                duplicidade. esse documento será ignorados ao cadastrar.`
                  : `Na lista de documentos existem ${duplicatedCount} documentos em
                duplicidade. esses documentos serão ignorados ao cadastrar.`}
              </AlertCard>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default NfeFormFields;
