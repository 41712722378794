import React from 'react';

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import TuneIcon from '@material-ui/icons/TuneOutlined';

import TextField from '../TextField';
import { useAppSelector } from '../../../app/hooks';
import { selectOrganizationId } from '../../../features/Session/sessionSlice';
import { REACT_APP_REQUIRED_ORGS } from '../../../.env';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: '50rem',
  },
  formFields: {
    paddingTop: '1rem',
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: 25,
    marginRight: '5px',
  },
  label: {
    whiteSpace: 'nowrap',
  },
  strong: {
    fontWeight: 'bolder',
  },
}));

export const isRequired = (orgId: number) => {
  if (REACT_APP_REQUIRED_ORGS.includes(orgId)) {
    return true;
  }
  return false;
};

export const ShipmentShipperCodeForm = () => {
  const classes = useStyles();

  const organizationId = useAppSelector(selectOrganizationId);

  const { control } = useFormContext();

  return (
    <Box
      bgcolor="#FFFFFF"
      border={1}
      borderColor="#C2C3C9"
      borderRadius={3}
      p="30px"
      width="50rem"
    >
      <Container className={classes.container} disableGutters>
        <Grid container>
          <Grid alignItems="center" container>
            <TuneIcon classes={{ root: classes.icon }} />
            <Typography
              className={classes.strong}
              color="textSecondary"
              variant="h6"
            >
              Outros
            </Typography>
          </Grid>
        </Grid>
        <Grid
          className={classes.formFields}
          container
          direction="column"
          spacing={3}
        >
          <Grid item xs={10}>
            <TextField
              control={control}
              fullWidth
              InputLabelProps={{ className: classes.label }}
              label={`Código de Referência ${
                isRequired(organizationId)
                  ? ' (Este campo é de responsabilidade do embarcador)'
                  : ''
              }`}
              name="shipperCode"
              required={isRequired(organizationId)}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ShipmentShipperCodeForm;
