import React from 'react';

import { CircularProgress, CircularProgressProps } from '@material-ui/core';

export interface SpinnerProps extends CircularProgressProps {
  size?: number;
  theme?: 'inherit' | 'primary' | 'secondary';
  value?: number;
  variant?: 'determinate' | 'indeterminate' | 'static';
}

const Spinner = ({
  size = 46,
  theme = 'inherit',
  value = 0,
  variant = 'indeterminate',
  ...rest
}: SpinnerProps) => {
  return (
    <CircularProgress
      color={theme}
      size={size}
      value={value}
      variant={variant}
      {...rest}
    />
  );
};

export default Spinner;
