import { isNil, omitBy } from 'lodash';

import { getGandalfClient } from '../../app/initializer';
import { HttpResponse } from '../../common/clients/http-client/http.client';
import UserCreationData from '../../common/data-types/user-creation-data';
import UserEditData from '../../common/data-types/user-edit-data';
import UserFindData from '../../common/data-types/user-find-data';
import UserListData from '../../common/data-types/user-list-data';
import UserStatus from '../../common/enums/user-status-type.enum';

// #region Typings
export interface UserCreationParams {
  user: UserCreationData;
  organizationId: number;
}

export interface UserFindParams {
  userId: number;
}

export interface UsersListFilterParams {
  email?: string;
  name?: string;
  statuses?: UserStatus[];
}

export interface UsersListParams {
  organizationId: number;
}
// #endregion Typings

// #region Functions
const parseUserList = (json: Record<string, any>): UserListData => {
  const { active, cellphone, email, id, organizationAdmin, name } = json;

  return {
    active,
    cellphone,
    email,
    id,
    isAdmin: organizationAdmin,
    name,
  };
};

const parseUser = (json: Record<string, any>): UserFindData => {
  const {
    active,
    cellphone,
    email,
    id,
    organizationAdmin,
    name,
    temporaryPassword,
  } = json;

  return {
    active,
    cellphone,
    email,
    id,
    isAdmin: organizationAdmin,
    name,
    temporaryPassword,
  };
};

const userCreateToJSON = (data: UserCreationParams): Record<string, any> => {
  const { user, organizationId } = data;
  const { email, isAdmin, name, cellphone } = user;

  return omitBy(
    {
      email,
      organizationAdmin: isAdmin,
      name,
      cellphone,
      organizationIds: [organizationId],
    },
    isNil
  );
};

const userEditToJSON = (user: UserEditData): Record<string, any> => {
  const { active, isAdmin, name, phone } = user;

  return omitBy(
    {
      active,
      organizationAdmin: isAdmin,
      name,
      phone,
    },
    isNil
  );
};
// #endregion Functions

const gandalfClient = getGandalfClient();

export const createUser = async (
  data: UserCreationParams
): Promise<UserFindData> => {
  const json = userCreateToJSON(data);

  const {
    body: { user },
  } = await gandalfClient.createUser(json);

  return parseUser(user);
};

export const findUser = async ({
  userId,
}: UserFindParams): Promise<UserFindData> => {
  const { body } = await gandalfClient.findUser(userId);

  return parseUser(body);
};

export const listUsers = async ({
  organizationId,
}: UsersListParams): Promise<UserListData[]> => {
  const { body } = await gandalfClient.listUsers({ organizationId });

  return body.map((user) => parseUserList(user));
};

export const meUpdate = async ({
  user,
}: {
  user: UserEditData;
}): Promise<HttpResponse> => {
  return gandalfClient.meUpdate({ body: userEditToJSON(user) });
};

export const resetUserPassword = async (
  userId: number
): Promise<HttpResponse> => {
  return gandalfClient.resetUserPassword(userId);
};

export const userUpdate = async ({
  userId,
  user,
}: {
  userId: number;
  user: UserEditData;
}): Promise<HttpResponse> => {
  return gandalfClient.userUpdate({ userId, body: userEditToJSON(user) });
};
