import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

type MapSearchIconProps = SvgIconProps;

const MapSearchIcon = ({ ...props }: MapSearchIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M14.4046 10C16.8892 10 18.8769 12 18.8769 14.5C18.8769 15.38 18.6285 16.21 18.1912 16.9L21.2522 20L19.8708 21.39L16.77 18.32C16.0842 18.75 15.2693 19 14.4046 19C11.92 19 9.9323 17 9.9323 14.5C9.9323 12 11.92 10 14.4046 10ZM14.4046 12C13.7457 12 13.1137 12.2634 12.6477 12.7322C12.1818 13.2011 11.92 13.837 11.92 14.5C11.92 15.163 12.1818 15.7989 12.6477 16.2678C13.1137 16.7366 13.7457 17 14.4046 17C15.0636 17 15.6955 16.7366 16.1615 16.2678C16.6275 15.7989 16.8892 15.163 16.8892 14.5C16.8892 13.837 16.6275 13.2011 16.1615 12.7322C15.6955 12.2634 15.0636 12 14.4046 12ZM12.9138 4.11L6.95076 2V13.89L7.9446 14.24V14.5C7.9446 15.14 8.03405 15.76 8.203 16.34L6.95076 15.9L1.64361 17.97L1.48459 18C1.3528 18 1.22641 17.9473 1.13322 17.8536C1.04003 17.7598 0.987671 17.6326 0.987671 17.5V2.38C0.987671 2.15 1.13675 1.97 1.34546 1.9L6.95076 0L12.9138 2.1L18.221 0H18.38C18.5118 0 18.6382 0.0526785 18.7314 0.146447C18.8246 0.240215 18.8769 0.367392 18.8769 0.5V9.81C17.7141 8.69 16.1438 8 14.4046 8C13.9077 8 13.4108 8.06 12.9138 8.17V4.11Z"
      />
    </SvgIcon>
  );
};

export default MapSearchIcon;
