import { maskStringByPattern } from './mask.helper';
import MaskType, { MASK_INPUT_LABEL_MAP } from '../enums/mask-input-type.enum';

const CNPJ_DIGITS_LENGTH = 14;
const CPF_DIGITS_LENGTH = 11;

export const formatDocumentNumber = (documentNumber: string): string => {
  switch (documentNumber.length) {
    case CPF_DIGITS_LENGTH:
      return maskStringByPattern(
        documentNumber,
        MASK_INPUT_LABEL_MAP.get(MaskType.CPF)
      );
    case CNPJ_DIGITS_LENGTH:
      return maskStringByPattern(
        documentNumber,
        MASK_INPUT_LABEL_MAP.get(MaskType.CNPJ)
      );
    default:
      return documentNumber;
  }
};
