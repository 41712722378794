import React, { useCallback, useMemo } from 'react';

import { Box, Button, makeStyles } from '@material-ui/core';
import { compact } from 'lodash';

import {
  ShipmentStatus,
  SHIPMENT_STATUS_LABEL_MAP,
} from '../../../../common/enums/shipment-status.enum';
import Chip from '../../../../common/components/Chip';
import EntityData from '../../../../common/data-types/entity-data';
import ShipmentListFilterData from '../../../../common/data-types/shipment-list-filter-data';

const useStyles = makeStyles({
  removeButton: {
    textDecoration: 'underline',
    fontSize: 10,
  },
  chipRoot: {
    maxWidth: '100%',
  },
  chipValue: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

interface ShipmentFilterChipData {
  label?: string;
  value: string;
  onRemove: () => void;
}

interface ShipmentFilterChipsProps {
  data: ShipmentListFilterData;
  entities: EntityData[];
  onChange: (data: ShipmentListFilterData) => void;
}

export const ShipmentFilterChips = ({
  data,
  entities,
  onChange,
}: ShipmentFilterChipsProps) => {
  const classes = useStyles();

  const findEntityById = useCallback(
    (id: EntityData['id']) => entities.find((entity) => entity.id === id),
    [entities]
  );

  const recipientChips = useMemo<ShipmentFilterChipData[]>(
    () =>
      data.recipientIds &&
      data.recipientIds.map((id: EntityData['id']) => {
        const entity = findEntityById(id);
        return {
          label: 'Destinatário',
          value: entity?.displayName || entity?.name || '',
          onRemove: () =>
            onChange({
              ...data,
              recipientIds: data.recipientIds.filter(
                (recipientId: EntityData['id']) => recipientId !== id
              ),
            }),
        };
      }),
    [data, findEntityById, onChange]
  );

  const shipmentCodesChips = useMemo<ShipmentFilterChipData[]>(
    () =>
      (data.shipmentCodes &&
        data.shipmentCodes.map((shipmentCodes: string) => {
          return {
            label: 'Código',
            value: shipmentCodes || '',
            onRemove: () =>
              onChange({
                ...data,
                shipmentCodes: data?.shipmentCodes?.filter(
                  (code: string) => code !== shipmentCodes
                ),
              }),
          };
        })) ||
      [],
    [data, onChange]
  );

  const senderChips = useMemo<ShipmentFilterChipData[]>(
    () =>
      data.senderIds &&
      data.senderIds.map((id: EntityData['id']) => {
        const entity = findEntityById(id);
        return {
          label: 'Remetente',
          value: entity?.displayName || entity?.name || '',
          onRemove: () =>
            onChange({
              ...data,
              senderIds: data.senderIds.filter(
                (senderId: EntityData['id']) => senderId !== id
              ),
            }),
        };
      }),
    [data, findEntityById, onChange]
  );

  const situationChips = useMemo<ShipmentFilterChipData[]>(
    () =>
      (data.statuses &&
        data.statuses.map((status: ShipmentStatus) => {
          return {
            label: '',
            value: SHIPMENT_STATUS_LABEL_MAP.get(status) || '',
            onRemove: () =>
              onChange({
                ...data,
                statuses: data.statuses?.filter(
                  (statusItem: ShipmentStatus) => statusItem !== status
                ),
              }),
          };
        })) ||
      [],
    [data, onChange]
  );

  const delayedChip = useMemo<ShipmentFilterChipData[]>(() => {
    if (data.delayed) {
      return [
        {
          label: '',
          value: 'Atrasado',
          onRemove: () =>
            onChange({
              ...data,
              delayed: !data.delayed,
            }),
        },
      ];
    }
    return [];
  }, [data, onChange]);

  const syncedChip = useMemo<ShipmentFilterChipData[]>(() => {
    if (data.synced) {
      return [
        {
          label: '',
          value: 'Não Sincronizados',
          onRemove: () =>
            onChange({
              ...data,
              synced: !data.synced,
            }),
        },
      ];
    }
    return [];
  }, [data, onChange]);

  const originChip = useMemo<ShipmentFilterChipData[]>(() => {
    if (data.originEntityLocation) {
      return [
        {
          label: 'Origem',
          value: data.originEntityLocation,
          onRemove: () =>
            onChange({
              ...data,
              originEntityLocation: '',
            }),
        },
      ];
    }
    return [];
  }, [data, onChange]);

  const destinationChip = useMemo<ShipmentFilterChipData[]>(() => {
    if (data.destinationEntityLocation) {
      return [
        {
          label: 'Destino',
          value: data.destinationEntityLocation,
          onRemove: () =>
            onChange({
              ...data,
              destinationEntityLocation: '',
            }),
        },
      ];
    }
    return [];
  }, [data, onChange]);

  const filters = useMemo<ShipmentFilterChipData[]>(
    () =>
      compact([
        data.createdAtEnd &&
          !data.createdAtStart && {
            value: `Até ${new Date(data.createdAtEnd).toLocaleDateString()}`,
            onRemove: () => onChange({ ...data, createdAtEnd: undefined }),
          },
        data.createdAtStart &&
          !data.createdAtEnd && {
            value: `A partir de ${new Date(
              data.createdAtStart
            ).toLocaleDateString()}`,
            onRemove: () => onChange({ ...data, createdAtStart: undefined }),
          },
        data.createdAtStart &&
          data.createdAtEnd && {
            value: `De ${new Date(
              data.createdAtStart
            ).toLocaleDateString()} até ${new Date(
              data.createdAtEnd
            ).toLocaleDateString()}`,
            onRemove: () =>
              onChange({
                ...data,
                createdAtStart: undefined,
                createdAtEnd: undefined,
              }),
          },
        data.documentIdentifier && {
          label: 'Documento',
          value: data.documentIdentifier,
          onRemove: () => onChange({ ...data, documentIdentifier: undefined }),
        },
        ...delayedChip,
        ...destinationChip,
        ...originChip,
        ...recipientChips,
        ...senderChips,
        ...shipmentCodesChips,
        ...situationChips,
        ...syncedChip,
      ]),
    [
      data,
      delayedChip,
      destinationChip,
      onChange,
      originChip,
      recipientChips,
      senderChips,
      shipmentCodesChips,
      situationChips,
      syncedChip,
    ]
  );

  const handleOnClearFilters = () => {
    onChange({
      createdAtEnd: undefined,
      createdAtStart: undefined,
      delayed: false,
      destinationEntityLocation: undefined,
      originEntityLocation: undefined,
      recipientIds: [],
      senderIds: [],
      shipmentCodes: [],
      statuses: [],
      synced: true,
    });
  };

  return (
    <>
      <Box display="flex" flexWrap="wrap">
        {filters.map(({ label, value, onRemove }, index) => (
          <Box key={index} maxWidth="100%" mb={1} mr={1}>
            <Chip
              className={classes.chipRoot}
              label={label}
              value={value}
              onRemove={onRemove}
              classes={{ value: classes.chipValue }}
              color="primary"
              size="medium"
              variant="outlined"
            />
          </Box>
        ))}
      </Box>
      {filters.length > 0 && (
        <Button
          className={classes.removeButton}
          variant="text"
          onClick={handleOnClearFilters}
        >
          Limpar Todos
        </Button>
      )}
    </>
  );
};

export default ShipmentFilterChips;
