import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { AutocompleteOption } from '.';

const useStyles = makeStyles({
  item: {
    '&:hover': {
      backgroundColor: '#eeeeee',
      cursor: 'pointer',
    },
    padding: '5px 7px',
  },
  subTitle: {
    fontSize: 12,
  },
});

export interface OptionsListItemProps {
  options: AutocompleteOption[];
  onClick?: (option: AutocompleteOption) => void;
}

const OptionsListItem = ({ options = [], onClick }: OptionsListItemProps) => {
  const classes = useStyles();

  return (
    <Box>
      {options.map((option: AutocompleteOption) => (
        <Box
          className={classes.item}
          key={option.value}
          onClick={() => onClick && onClick(option)}
        >
          <Typography noWrap>{option.label}</Typography>
          {!!option.subTitle && (
            <Typography className={classes.subTitle}>
              {option.subTitle}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default OptionsListItem;
