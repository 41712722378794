import React, { useState } from 'react';

import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import ConfirmationModal from '../../../common/components/Modals/ConfirmationModal';
import SubmitButton from '../../../common/components/SubmitButton';
import withPackageForm, {
  PackageFormInputs,
  WithPackageFormProps,
} from '../../../common/hocs/withPackageForm';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    backgroundColor: theme.palette.info.main,
  },
  displayName: {
    color: theme.palette.text.primary,
  },
  removeButton: {
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    backgroundColor: theme.palette.error.main,
    marginRight: '0.5rem',
  },
}));

interface PackageEditFormProps {
  isLoading: boolean;
  onRemove: () => void;
  onSubmit: (data: PackageFormInputs) => void;
}

const PackageEditForm = ({
  children,
  form,
  isLoading = false,
  onRemove,
  onSubmit,
}: PackageEditFormProps & WithPackageFormProps) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const {
    formState: { isSubmitted, isValid },
    handleSubmit,
  } = form;

  return (
    <>
      <ConfirmationModal
        content={
          <Typography component="p">
            Deseja mesmo remover essa embalagem ? Essa operação não pode ser
            revertida.
          </Typography>
        }
        open={openModal}
        title="Excluir esta embalagem?"
        onCancel={() => setOpenModal(false)}
        onConfirm={onRemove}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={2}>{children}</Box>
        <Box bgcolor="background.default">
          <Box display="flex" justifyContent="flex-end" py={2} width="50rem">
            <SubmitButton
              classes={{ root: classes.removeButton }}
              color="primary"
              disabled={isLoading || (isSubmitted && !isValid)}
              isLoading={isLoading}
              variant="contained"
              onClick={() => setOpenModal(true)}
            >
              Remover
            </SubmitButton>
            <SubmitButton
              classes={{ root: classes.button }}
              color="primary"
              disabled={isLoading || (isSubmitted && !isValid)}
              isLoading={isLoading}
              type="submit"
              variant="contained"
            >
              Atualizar
            </SubmitButton>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default withPackageForm<PackageEditFormProps>(PackageEditForm);
