import { Middleware } from '@reduxjs/toolkit';

import { HttpClientErrorCode } from '../common/errors/clients/http-client.error';

const AUTHENTICATION_ERROR_CODES: HttpClientErrorCode[] = [
  HttpClientErrorCode.Unauthorized,
  HttpClientErrorCode.CredentialsExpired,
];

const isAuthenticationError = (code: string): boolean =>
  AUTHENTICATION_ERROR_CODES.some((c) => c === code);

export const authenticationErrorHandlerMiddleware: Middleware<
  Record<string, any>
> = () => (next) => (action) => {
  if (
    action.type.match(/\/rejected/) &&
    isAuthenticationError(action.error?.code)
  ) {
    next({ type: 'session/logout' });
  }
  next(action);
};
