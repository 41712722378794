import { isEmpty, isNil, omitBy } from 'lodash';

import {
  DecodedCTe,
  DecodedNFe,
  DecodedNFePartials,
} from '../../../common/data-types/decoded-fiscal-documents-data';
import { fileToBase64 } from '../../../common/services/base64.service';
import { getZordonClient } from '../../../app/initializer';
import { HttpResponse } from '../../../common/clients/http-client/http.client';
import { listEntities } from '../../Entities/entitiesAPI';
import { REACT_APP_EBB_CARRIER_PARENT_ENTITY_ID } from '../../../.env';
import { ShipmentStatus } from '../../../common/enums/shipment-status.enum';
import ContentStatementData from '../../../common/data-types/content-statement-data';
import DispatchType from '../../../common/enums/dispatch-type.enum';
import EntityData from '../../../common/data-types/entity-data';
import RouteLegData from '../../../common/data-types/route-leg-data';
import ShipmentData from '../../../common/data-types/shipment-data';
import ShipmentModality from '../../../common/enums/shipment-modality.enum';
import VolumeData from '../../../common/data-types/volume-data';

// #region Typings
export interface FiscalDocumentXml {
  xmlBase64: string;
}

export interface ContentStatementCreationData
  extends Omit<ContentStatementData, 'file'> {
  file?: File;
}

export interface ShipmentCreationData {
  authorizedReceiver?: EntityData;
  consignee?: EntityData;
  contentStatement?: ContentStatementCreationData;
  declaredValue: ShipmentData['declaredValue'];
  deliveryModality: ShipmentModality;
  destinationEntity?: EntityData;
  destinationShipperEntity: EntityData;
  dispatcher?: EntityData;
  dispatchType?: DispatchType;
  fiscalDocuments?: DecodedNFePartials[];
  originEntity?: EntityData;
  originShipperEntity: EntityData;
  previousDocuments?: DecodedNFePartials[];
  receiptModality: ShipmentModality;
  receiver?: EntityData;
  recipient: EntityData;
  routeLegs?: RouteLegData[];
  sender: EntityData;
  shipperCode?: string;
  status?: ShipmentStatus;
  totalWeight: number;
  volumes: Partial<VolumeData>[];
}
// #endregion Typings

const zordonClient = getZordonClient();

// #region Private functions
const contentStatementToJSON = async (
  contentStatement: ContentStatementCreationData
): Promise<Record<string, any> | undefined> => {
  const { documentNumber, file, value } = contentStatement;

  if (!file) {
    return undefined;
  }

  return omitBy(
    {
      documentNumber,
      fileBase64: await fileToBase64(file),
      fileName: file.name,
      value,
    },
    isNil
  );
};

const cteFromJSON = (cte: Record<string, any>): DecodedCTe => {
  const {
    cfop,
    documentNumber,
    documentSerie,
    goodsValue,
    issueDate,
    key,
    recipientDocumentNumber,
    senderDocumentNumber,
    senderName,
    suframa,
    value,
    volumesQuantity,
  } = cte;

  return {
    cfop,
    goodsValue,
    issueDate,
    key,
    number: documentNumber,
    recipientDocumentNumber,
    senderDocumentNumber,
    senderName,
    serie: documentSerie,
    suframa,
    value,
    volumesQuantity,
  };
};

const convertGramsToKg = (weight: number): number => {
  const weightInKg = weight / 1000;

  return Math.round(weightInKg * 1e2) / 1e2;
};

const newShipmentToJSON = async (
  shipment: ShipmentCreationData
): Promise<Record<string, any>> => {
  const {
    authorizedReceiver,
    consignee,
    contentStatement,
    declaredValue,
    deliveryModality,
    destinationShipperEntity,
    dispatcher,
    dispatchType,
    fiscalDocuments,
    originShipperEntity,
    previousDocuments,
    receiptModality,
    receiver,
    recipient,
    routeLegs,
    sender,
    shipperCode,
    status,
    totalWeight,
    volumes,
  } = shipment;

  return omitBy(
    {
      authorizedReceiverId: authorizedReceiver?.id,
      carrierParentEntityId: +REACT_APP_EBB_CARRIER_PARENT_ENTITY_ID,
      consigneeId: consignee?.id,
      contentStatement: contentStatement
        ? await contentStatementToJSON(contentStatement)
        : undefined,
      declaredValue,
      deliveryModality,
      destinationShipperEntityId:
        deliveryModality === ShipmentModality.Counter
          ? recipient.id
          : destinationShipperEntity.id,
      dispatcherId: dispatcher?.id,
      dispatchType,
      fiscalDocuments,
      originShipperEntityId:
        receiptModality === ShipmentModality.Counter
          ? sender.id
          : originShipperEntity.id,
      previousDocuments,
      receiptModality,
      receiverId: receiver?.id,
      recipientId: recipient.id,
      routeLegs: routeLegs?.map((r) => routeLegsToJSON(r)),
      senderId: sender.id,
      shipperCode,
      status,
      totalWeight: Number((totalWeight * 1000).toFixed()), // Kg to g
      volumes: volumes?.map((v) => volumeToJSON(v)),
    },
    isNil
  );
};

const nfeFromJSON = (nfe: Record<string, any>): DecodedNFe => {
  const {
    documentNumber,
    documentSerie,
    issueDate,
    key,
    order,
    recipientDocumentNumber,
    senderDocumentNumber,
    value,
    volumesQuantity,
    weight,
  } = nfe;

  return {
    issueDate,
    key,
    number: documentNumber,
    order,
    recipientDocumentNumber,
    senderDocumentNumber,
    serie: documentSerie,
    value,
    volumesQuantity,
    weight: weight && convertGramsToKg(weight),
  };
};

const routeLegsToJSON = (
  routeLegs: RouteLegData
): Record<string, any> | null => {
  const entities = omitBy(
    {
      destinationEntityId: routeLegs.destinationEntity?.id,
      originEntityId: routeLegs.originEntity?.id,
    },
    isNil
  );

  if (isEmpty(entities)) {
    return null;
  }

  return entities;
};

const volumeToJSON = (volume: Partial<VolumeData>): Record<string, any> => {
  const { height, id, length, quantity, width } = volume;

  return omitBy({ height, id, length, quantity, width }, isNil);
};
// #endregion Private functions

// #region Public functions
export const createShipment = async ({
  shipment,
  userOrganizationId,
}: {
  shipment: ShipmentCreationData;
  userOrganizationId: number;
}): Promise<HttpResponse> => {
  const consignee = await listEntities({
    filter: {
      disposable: false,
      organizationIds: [userOrganizationId],
      parentCompany: true,
    },
  });

  const setShipmentToCounter = {
    ...shipment,
    deliveryModality: ShipmentModality.Counter,
    receiptModality: ShipmentModality.Counter,
  };

  return zordonClient.createShipment(
    await newShipmentToJSON({
      ...setShipmentToCounter,
      consignee: consignee.entities[0],
    })
  );
};

export const readCte = async (
  ctes: FiscalDocumentXml[]
): Promise<DecodedCTe[]> => {
  const { body } = await zordonClient.readCte(ctes);

  return body.map((cte) => cteFromJSON(cte));
};

export const readNfe = async (
  fiscalDocuments: FiscalDocumentXml[]
): Promise<DecodedNFe[]> => {
  const { body } = await zordonClient.readNfe(fiscalDocuments);

  return body.map((nfe) => nfeFromJSON(nfe));
};
// #endregion Public functions
