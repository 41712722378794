import React, { useMemo } from 'react';

import {
  Box,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import dayjs from 'dayjs';

import { ActivityResponsibleType } from '../enums/activity-responsible-type.enum';
import { maskStringByPattern } from '../helpers/mask.helper';
import { selectSessionUserType } from '../../features/Session/sessionSlice';
import {
  ShippingEventData,
  ShippingEventFileData,
} from '../data-types/shipping-event-data';
import { useAppSelector } from '../../app/hooks';
import AttatchmentsButton from './AttatchmentsButton';
import FragmentedVolumes from './FragmentedVolumes';
import GeocodingButton from './GeocodingButton';
import MaskType, { MASK_INPUT_LABEL_MAP } from '../enums/mask-input-type.enum';
import OrganizationType from '../enums/organization-type.enum';
import ShipmentData from '../data-types/shipment-data';
import ShippingEventFileCategory from '../enums/shipping-event-file-category.enum';
import SkeletonField from './SkeletonField';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: '1.7rem',
  },
  cardData: {
    marginTop: '-1px',
  },
  icon: {
    height: '2rem',
    marginLeft: '-3px',
    width: '2rem',
  },
  observation: {
    color: theme.palette.primary.main,
  },
  observationContainer: {
    textAlign: 'justify',
  },
  overflowLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  responsableName: {
    display: 'block',
    marginLeft: '5px',
  },
  title: {
    fontWeight: 'bolder',
  },
}));

export interface OccurrenceProps {
  isLoading?: boolean;
  occurrence?: ShippingEventData;
  shipment: ShipmentData;
}

const Occurrence = ({ isLoading, occurrence, shipment }: OccurrenceProps) => {
  const classes = useStyles();

  const userType = useAppSelector(selectSessionUserType);

  const attatchments = useMemo<ShippingEventFileData[]>(() => {
    let files: ShippingEventFileData[] = [];

    if (userType === OrganizationType.Shipper && occurrence) {
      files = occurrence.files.filter(
        (f) => f.category === ShippingEventFileCategory.DeliveryPhoto
      );
    }

    return files;
  }, [occurrence, userType]);

  return (
    <Grid className={classes.card} container direction="row" item xs={12}>
      <Grid item xs={1}>
        <Grid item>
          <SkeletonField isLoading={isLoading} width="small">
            {dayjs(occurrence?.registeredAt).format('DD/MM')}
          </SkeletonField>
        </Grid>
        <Grid item>
          <SkeletonField isLoading={isLoading} width="small">
            {dayjs(occurrence?.registeredAt).format('HH:mm')}
          </SkeletonField>
        </Grid>
      </Grid>
      <Grid className={classes.cardData} item xs={11}>
        <Grid container spacing={1}>
          <Grid item>
            <Box pr={2}>
              <SkeletonField isLoading={isLoading}>
                <Tooltip
                  arrow
                  placement="top"
                  title={occurrence?.description || false}
                >
                  <Typography
                    className={`${classes.title} ${classes.overflowLabel}`}
                    component="h2"
                  >
                    {occurrence?.description}
                  </Typography>
                </Tooltip>
              </SkeletonField>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <SkeletonField isLoading={isLoading}>
              {occurrence?.responsible?.type ===
                ActivityResponsibleType.User && (
                <Box display="flex" alignItems="center">
                  <PersonIcon className={classes.icon} />
                  <Tooltip
                    arrow
                    placement="top"
                    title={occurrence?.responsible?.name || false}
                  >
                    <Typography
                      className={`${classes.responsableName} ${classes.overflowLabel}`}
                      component="p"
                    >
                      {occurrence?.responsible?.name}
                    </Typography>
                  </Tooltip>
                </Box>
              )}
            </SkeletonField>
          </Grid>
          {(isLoading ||
            (!isLoading && occurrence?.accountablePerson?.name)) && (
            <Grid item xs={12}>
              <SkeletonField isLoading={isLoading}>
                {occurrence?.accountablePerson && (
                  <Box display="flex">
                    <Typography style={{ marginRight: 30 }}>
                      <strong>Responsável:</strong>{' '}
                      {occurrence?.accountablePerson.name}
                    </Typography>
                    {occurrence?.accountablePerson.documentNumber && (
                      <Typography>
                        <strong>Documento:</strong>{' '}
                        {occurrence?.accountablePerson.documentNumber.length ===
                        11
                          ? maskStringByPattern(
                              occurrence?.accountablePerson.documentNumber,
                              MASK_INPUT_LABEL_MAP.get(MaskType.CPF)
                            )
                          : occurrence?.accountablePerson.documentNumber}
                      </Typography>
                    )}
                  </Box>
                )}
              </SkeletonField>
            </Grid>
          )}
          {(occurrence?.geocoding || attatchments.length > 0) && (
            <Grid item xs={12}>
              <Box>
                <Grid container spacing={2}>
                  {occurrence?.geocoding && (
                    <Grid item>
                      <GeocodingButton
                        geocoding={occurrence?.geocoding}
                        style={{ textTransform: 'capitalize' }}
                      />
                    </Grid>
                  )}
                  {attatchments.length > 0 && (
                    <Grid item>
                      <AttatchmentsButton
                        attatchments={attatchments}
                        style={{ textTransform: 'capitalize' }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          )}
          <Grid className={classes.observationContainer} item xs={12}>
            <SkeletonField isLoading={isLoading} width="large">
              {occurrence?.comments && (
                <Box display="flex">
                  <Typography component="p">
                    <span className={classes.observation}>Ressalva:</span>{' '}
                    {occurrence?.comments}
                  </Typography>
                </Box>
              )}
            </SkeletonField>
          </Grid>
          {occurrence?.volumeCodes &&
            shipment !== undefined &&
            occurrence?.volumeCodes.length < shipment.volumes.length && (
              <Grid item xs={12}>
                <Box display="flex">
                  <FragmentedVolumes
                    shipmentVolumes={shipment.volumes}
                    volumeCodes={occurrence?.volumeCodes}
                  />
                </Box>
              </Grid>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Occurrence;
