import React from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';

import { GeocodingData } from '../data-types/geocoding-data';
import { GOOGLE_MAPS_BASE_URL } from '../../.env';
import MapSearchIcon from './MapSearchIcon';

export interface GeocodingButtonProps extends ButtonProps {
  geocoding: GeocodingData;
  shouldRedirect?: boolean;
}

const GeocodingButton = ({
  geocoding,
  shouldRedirect = false,
  ...rest
}: GeocodingButtonProps) => {
  const buildUrl = (
    baseUrl: string,
    { latitude, longitude }: GeocodingData
  ): string => {
    const queryString = `?api=1&query=${latitude},${longitude}`;

    return [baseUrl, queryString]
      .filter(Boolean)
      .map((p) => p.replace(/^\//, '').replace(/\/$/, ''))
      .join('/');
  };

  const handleOpenLocation = () => {
    const url = buildUrl(GOOGLE_MAPS_BASE_URL, geocoding);

    window.open(url, !shouldRedirect ? '_blank' : undefined);
  };

  return (
    <Button
      startIcon={<MapSearchIcon />}
      variant={rest.variant || 'outlined'}
      onClick={handleOpenLocation}
      {...rest}
    >
      Ver Localização
    </Button>
  );
};

export default GeocodingButton;
