export const capitalizeFirstLetter = (text: string): string => {
  const words = text.split(' ') || [];
  return words.length > 0
    ? words
        .map(
          (word: string) =>
            `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`
        )
        .join(' ')
    : '';
};
