import React, { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import { AccountablePersonData } from '../../../common/data-types/accountable-person-data';
import {
  cleanRequests,
  createShippingEventByTypeThunk,
  selectShippingEventsCreateError,
  selectShippingEventsIsFulfilledCreate,
  selectShippingEventsIsRequestingCreate,
} from '../shippingEventsSlice';
import { ERROR_MESSAGES } from '../../../common/constants/error-messages';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import AsyncFeedback from '../../../common/components/AsyncFeedback';
import Modal from '../../../common/components/Modals/Modal';
import ShippingEventFileCategory from '../../../common/enums/shipping-event-file-category.enum';
import ShippingEventFileData from '../../../common/data-types/shipping-event-file-data';
import ShippingEventNewForm from './ShippingEventNewForm';
import ShippingEventType from '../../../common/enums/shipping-event-type.enum';
import {
  findShipmentVolumeDetailsThunk,
  selectShipmentsInformationsById,
} from '../../Shipments/shipmentsSlice';
import VolumeData from '../../../common/data-types/volume-data';

interface ShippingEventNewDialogProps {
  isOpen: boolean;
  volumesQuantity: number;
  shipmentId: number;
  onClose: () => void;
  onCreate: () => void;
}

export interface ShippingEventNewFormInputs {
  codes: Record<number, boolean>;
  comment: string;
  date: Date;
  files: ShippingEventFileData[];
  responsible: AccountablePersonData;
  type: ShippingEventType | 'no-option';
}

const schema = yup.object().shape({
  comment: yup.string(),
  date: yup.date().required(ERROR_MESSAGES.string.required),
  files: yup.lazy((_, data) => {
    const { type } = data.parent;

    if (type === ShippingEventType.Delivery) {
      return yup
        .array()
        .of(
          yup.object().shape({
            category: yup
              .string()
              .oneOf([
                ShippingEventFileCategory.DeliveryPhoto,
                ShippingEventFileCategory.Signature,
              ])
              .required(ERROR_MESSAGES.string.required),
          })
        )
        .min(1);
    }

    return yup.array().notRequired();
  }),
  responsible: yup.lazy((_, data) => {
    const { type } = data.parent;
    const allowedTypes = [
      ShippingEventType.Delivery,
      ShippingEventType.GiveCustody,
      ShippingEventType.ReceiveCustody,
    ];

    if (allowedTypes.includes(type)) {
      return yup
        .object()
        .shape({
          documentNumber: yup.string().required(ERROR_MESSAGES.string.required),
          name: yup.string().required(ERROR_MESSAGES.string.required),
        })
        .required();
    }

    return yup.object().notRequired();
  }),
  type: yup.string().required(ERROR_MESSAGES.string.required),
});

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.info.main,
  },
  titleIcon: {
    marginRight: 15,
  },
}));

const ShippingEventNewDialog = ({
  isOpen,
  volumesQuantity,
  shipmentId,
  onClose,
  onCreate,
}: ShippingEventNewDialogProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const [volumes, setVolumes] = useState<VolumeData[]>([]);

  const shipmentInformations = useAppSelector((state) =>
    selectShipmentsInformationsById(state, shipmentId)
  );

  const fetchVolumes = useCallback(() => {
    dispatch(findShipmentVolumeDetailsThunk({ id: shipmentId }));
  }, [dispatch, shipmentId]);

  const error = useAppSelector(selectShippingEventsCreateError);
  const isFulfilled = useAppSelector(selectShippingEventsIsFulfilledCreate);
  const isLoading = useAppSelector(selectShippingEventsIsRequestingCreate);

  const methods = useForm<ShippingEventNewFormInputs>({
    defaultValues: {
      codes: [],
      type: 'no-option',
    },
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const whenCreateIsFulfilled = useCallback(() => {
    setTimeout(() => {
      dispatch(cleanRequests());
      onCreate();
      onClose();
    }, 2000);
  }, [dispatch, onClose, onCreate]);

  const createShippingEvent = useCallback(
    (data: ShippingEventNewFormInputs) => {
      dispatch(
        createShippingEventByTypeThunk({
          ...data,
          type: data.type as ShippingEventType,
        })
      ).then(() => {
        whenCreateIsFulfilled();
      });
    },
    [dispatch, whenCreateIsFulfilled]
  );

  const handleSubmit = useCallback(
    (data: ShippingEventNewFormInputs) => {
      createShippingEvent(data);
    },
    [createShippingEvent]
  );

  useEffect(() => {
    if (!shipmentInformations?.volumes) {
      fetchVolumes();
    } else {
      setVolumes(shipmentInformations.volumes);
    }
  }, [fetchVolumes, shipmentInformations]);

  useEffect(() => {
    if (!volumes) return;
    const codes = volumes.reduce<Record<string, boolean>>((acc, volume) => {
      return { ...acc, [volume.carrierCode || volume.trackingCode]: true };
    }, {});

    methods.setValue('codes', codes);
  }, [methods, volumes]);

  return (
    <FormProvider {...methods}>
      <Modal
        actions={
          <>
            {!isLoading && !error && !isFulfilled && (
              <>
                <Button className={classes.button} onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  className={classes.button}
                  type="submit"
                  onClick={methods.handleSubmit(handleSubmit)}
                >
                  Confirmar
                </Button>
              </>
            )}
          </>
        }
        header={
          <>
            {!isLoading && !error && !isFulfilled && (
              <Box alignItems="center" display="flex">
                <EventAvailableIcon className={classes.titleIcon} />
                Registrar Ocorrência
              </Box>
            )}
          </>
        }
        open={isOpen}
        onClick={(e) => e.stopPropagation()}
        onClose={isLoading || isFulfilled ? undefined : onClose}
      >
        {isLoading || error || isFulfilled ? (
          <AsyncFeedback
            error={error}
            errorMessage="Falha em registrar a ocorrência"
            isLoading={isLoading}
            successMessage="Ocorrência registrada com sucesso"
          />
        ) : (
          volumes &&
          !isLoading && (
            <ShippingEventNewForm
              volumes={volumes}
              volumesQuantity={volumesQuantity}
            />
          )
        )}
      </Modal>
    </FormProvider>
  );
};

export default ShippingEventNewDialog;
