import React, { useCallback, useMemo, useEffect } from 'react';

import { Button, FormGroup, makeStyles, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { cleanRequests } from '../../sessionSlice';
import { ERROR_MESSAGES } from '../../../../common/constants/error-messages';
import { useAppDispatch } from '../../../../app/hooks';
import PasswordField from '../../../../common/components/PasswordField';
import SubmitButton from '../../../../common/components/SubmitButton';
import TextField from '../../../../common/components/TextField';
import { HttpClientErrorCode } from '../../../../common/errors/clients/http-client.error';

interface FormData {
  email: string;
  password: string;
}

interface LoginFormProps {
  error: Record<string, any> | null;
  isLoading: boolean;
  onSubmit: (data: FormData) => void;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .required(ERROR_MESSAGES.string.required)
    .email(ERROR_MESSAGES.string.email),
  password: yup
    .string()
    .required(ERROR_MESSAGES.string.required)
    .min(6, ERROR_MESSAGES.string.min)
    .max(255, ERROR_MESSAGES.string.max),
});

const useStyles = makeStyles({
  error: {
    marginBottom: 10,
    textAlign: 'center',
  },
  forgotPasswordButton: {
    color: '#1976D2',
    marginTop: 10,
  },
  formTitle: {
    fontSize: 23,
    marginBottom: 10,
  },
  emailInput: {
    marginBottom: 15,
  },
  passwordInput: {
    marginBottom: 50,
  },
});

const LoginForm = ({ error, isLoading, onSubmit }: LoginFormProps) => {
  const dispatch = useAppDispatch();

  const { control, formState, handleSubmit, reset, watch } = useForm<FormData>({
    defaultValues: { email: '', password: '' },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const fieldValues = watch();

  const { isDirty, isSubmitSuccessful, isSubmitted, isValid } = formState;

  const canSubmit = useMemo(() => {
    return (
      !isSubmitted ||
      (isValid &&
        !isLoading &&
        error?.code !== HttpClientErrorCode.CredentialsInvalid)
    );
  }, [isSubmitted, isValid, isLoading, error]);

  const classes = useStyles();

  const history = useHistory();

  const handleForgotPassword = useCallback(() => {
    history.replace('/forgot-password');
  }, [history]);

  const handleOnSubmit = useCallback((data: FormData) => onSubmit(data), [
    onSubmit,
  ]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(fieldValues, { keepIsSubmitted: true });
    }
  }, [fieldValues, isSubmitSuccessful, reset]);

  useEffect(() => {
    if (!!error && isDirty) {
      dispatch(cleanRequests());
    }
  }, [dispatch, error, isDirty]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Typography
        className={classes.formTitle}
        color="textSecondary"
        component="h2"
      >
        Acesse sua conta
      </Typography>
      <FormGroup>
        <TextField
          className={classes.emailInput}
          control={control}
          label="E-mail"
          name="email"
          placeholder="Digite seu endereço de e-mail"
        />
        <PasswordField
          className={classes.passwordInput}
          control={control}
          label="Senha"
          name="password"
          placeholder="Digite sua senha"
        />
        {(error?.code === 'credentials_invalid' ||
          error?.code === 'unauthorized') && (
          <Typography className={classes.error} color="error" component="p">
            E-mail ou senha inválido
          </Typography>
        )}
        <SubmitButton
          color="primary"
          disabled={!canSubmit}
          isLoading={isLoading}
          type="submit"
          variant="contained"
        >
          Entrar
        </SubmitButton>
        <Button
          className={classes.forgotPasswordButton}
          color="secondary"
          onClick={handleForgotPassword}
        >
          Esqueci minha senha
        </Button>
      </FormGroup>
    </form>
  );
};

export default LoginForm;
