import React, { useCallback, useEffect } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  lighten,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AddIcon from '@material-ui/icons/Add';

import { decimalMask } from '../../helpers/mask.helper';
import PackageIcon from '../PackageIcon';
import ShipmentVolumeItem, { ShipmentVolumeItemData } from './Item';
import TextField from '../TextField';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    borderColor: lighten(theme.palette.info.main, 0.5),
    color: theme.palette.info.main,
    marginTop: 30,
  },
  container: {
    maxWidth: '50rem',
  },
  form: {
    height: '100%',
    marginBottom: '1.7rem',
  },
  formFields: {
    paddingTop: '1rem',
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: 25,
    marginRight: '5px',
  },
  label: {
    whiteSpace: 'nowrap',
  },
  spacing: {
    marginRight: '1rem',
  },
  strong: {
    fontWeight: 'bolder',
  },
  submit: {
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    backgroundColor: theme.palette.info.main,
    marginRight: '1rem',
  },
}));

export const ShipmentVolumesForm = () => {
  const classes = useStyles();

  const {
    control,
    formState: { isSubmitted },
    setValue,
  } = useFormContext();

  const { append, fields, remove } = useFieldArray<
    Record<string, ShipmentVolumeItemData[]>
  >({
    control,
    name: 'volumes',
  });

  const handleAddEmptyVolume = useCallback(() => {
    append({
      height: undefined,
      length: undefined,
      packageId: 'no-option',
      quantity: undefined,
      width: undefined,
    });
  }, [append]);

  const handleOnChangeDeclaredValue = useCallback(
    (event) => {
      const { value } = event.target;

      setValue('declaredValue', decimalMask(value), {
        shouldValidate: isSubmitted,
      });
    },
    [isSubmitted, setValue]
  );

  const handleOnChangeTotalWeight = useCallback(
    (event) => {
      const { value } = event.target;

      setValue('totalWeight', decimalMask(value), {
        shouldValidate: isSubmitted,
      });
    },
    [isSubmitted, setValue]
  );

  const handleOnRemove = useCallback(
    (itemIndex: number) => {
      remove(itemIndex);
    },
    [remove]
  );

  useEffect(() => {
    if (fields.length === 0) {
      handleAddEmptyVolume();
    }
  }, [fields, handleAddEmptyVolume]);

  return (
    <Box
      bgcolor="#FFFFFF"
      border={1}
      borderColor="#C2C3C9"
      borderRadius={3}
      p="30px"
      width="50rem"
    >
      <Container className={classes.container} disableGutters>
        <Grid container>
          <Grid alignItems="center" container>
            <PackageIcon classes={{ root: classes.icon }} />
            <Typography
              className={classes.strong}
              color="textSecondary"
              variant="h6"
            >
              Volumes
            </Typography>
          </Grid>
        </Grid>
        <Grid
          className={classes.formFields}
          container
          direction="column"
          spacing={3}
        >
          <Grid item xs={12}>
            <Grid container spacing={7}>
              <Grid item xs={3}>
                <TextField
                  control={control}
                  InputLabelProps={{
                    className: classes.label,
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Kg</InputAdornment>
                    ),
                  }}
                  label="Peso total da carga *"
                  name="totalWeight"
                  onChange={handleOnChangeTotalWeight}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  control={control}
                  InputLabelProps={{
                    className: classes.label,
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                  label="Valor a ser transportado *"
                  name="declaredValue"
                  onChange={handleOnChangeDeclaredValue}
                />
              </Grid>
            </Grid>
          </Grid>
          {fields.map((field, index) => (
            <Grid item key={field.id}>
              <ShipmentVolumeItem
                index={index}
                onRemove={() => handleOnRemove(index)}
              />
            </Grid>
          ))}
          <Grid item>
            <Button
              className={classes.addButton}
              size="small"
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={handleAddEmptyVolume}
            >
              Adicionar Volume
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ShipmentVolumesForm;
