export const decode = (str: string): string => {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () =>
      typeof reader.result === 'string'
        ? resolve(reader.result.replace(/^data:\D+;base64,/, ''))
        : reject();
    reader.onerror = (error) => reject(error);
  });
};

export const filesToBase64 = (files: File[]): Promise<string[]> => {
  const promises = files.map(async (file) => {
    const base64File = await fileToBase64(file);

    return base64File.replace(/^data:\D+;base64,/, '');
  });
  return Promise.all(promises);
};
