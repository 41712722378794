import React, { useEffect } from 'react';
import { buildShipmentIdentifier } from '../../helpers/shipment-code.helper';
import { useAppSelector } from '../../../app/hooks';
import {
  forceResendMinutaByIdError,
  forceResendMinutaByIdIsFulfilled,
  forceResendMinutaByIdIsRequesting,
} from '../../../features/Shipments/shipmentsSlice';
import ConfirmationModal from './ConfirmationModal';
import ShipmentListData from '../../data-types/shipment-list-data';

interface TagFormatModalProps {
  onClose: () => void;
  onConfirm: () => void;
  onError: () => void;
  onSuccess: () => void;
  open: boolean;
  shipment: ShipmentListData;
}

const ForceResendModal = ({
  onClose,
  onConfirm,
  onError,
  onSuccess,
  open,
  shipment,
}: TagFormatModalProps) => {
  const resendMinutaError = useAppSelector(forceResendMinutaByIdError);
  const resendMinutaIsLoading = useAppSelector(
    forceResendMinutaByIdIsRequesting
  );
  const resendMinutaIsSucceeded = useAppSelector(
    forceResendMinutaByIdIsFulfilled
  );

  function isButtonDisabled(): boolean {
    if (resendMinutaIsLoading || resendMinutaError !== null) {
      return true;
    }
    return false;
  }

  const handleConfirm = () => {
    onConfirm();
  };

  useEffect(() => {
    if (resendMinutaError) {
      onError();
    }
  }, [resendMinutaError, onError]);

  useEffect(() => {
    if (resendMinutaIsSucceeded) {
      onSuccess();
    }
  }, [resendMinutaIsSucceeded, onSuccess]);

  return (
    <ConfirmationModal
      content={`Após clicar no botão, será feito o reenvio da minuta "${buildShipmentIdentifier(
        shipment
      )}"?`}
      confirmButtonLabel="Forçar Reenvio"
      disabledConfirmButton={isButtonDisabled()}
      fullWidth
      isLoading={resendMinutaIsLoading}
      onCancel={onClose}
      onConfirm={handleConfirm}
      open={open}
      title="Deseja forçar reenvio da Minuta?"
    />
  );
};

export default ForceResendModal;
