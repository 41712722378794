import React from 'react';

import {
  Box,
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  makeStyles,
  Typography,
} from '@material-ui/core';

export interface DrawerProps extends MuiDrawerProps {
  anchor?: 'top' | 'left' | 'bottom' | 'right';
  footer?: React.ReactNode;
  header?: string;
}

const useStyles = makeStyles({
  background: {
    backgroundColor: '#F2F2F4',
    overflowY: 'auto',
  },
  body: {
    height: '100%',
  },
  strong: {
    fontWeight: 'bolder',
  },
});

const Drawer = ({
  anchor = 'right',
  children,
  footer,
  header,
  ...rest
}: DrawerProps) => {
  const classes = useStyles();

  return (
    <MuiDrawer
      anchor={anchor}
      classes={{ paper: classes.background }}
      {...rest}
    >
      <Box px={3} py={3}>
        {(
          <Typography className={classes.strong} color="primary" variant="h5">
            {header}
          </Typography>
        ) || null}
      </Box>
      <Box className={classes.body}>{children}</Box>
      <Box>{footer || null}</Box>
    </MuiDrawer>
  );
};

export default Drawer;
