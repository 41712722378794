import React, { useCallback, useMemo, useState } from 'react';

import { Box, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Icon from '@material-ui/core/Icon';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { buildShipmentIdentifier } from '../../helpers/shipment-code.helper';
import {
  cleanRequests,
  downloadMinutaByIDThunk,
  downloadShipmentTagByTypeThunk,
  forceResendMinutaByIDThunk,
  selectTagStatus,
  shipmentListTagStatusThunk,
  updateShipmentInPlaceThunk,
} from '../../../features/Shipments/shipmentsSlice';
import { getStylesByStatus } from '../../helpers/status.helper';
import { DocumentNotFoundModal, ErrorModal, TagFormatModal } from '../Modals';
import {
  selectOrganizationId,
  selectSessionUserType,
} from '../../../features/Session/sessionSlice';
import { toRelativeDate } from '../../helpers/date.helper';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CopyButton from '../CopyButton';
import DownloadMinutaModal from '../Modals/DownloadMinutaModal';
import Entity from '../Entity';
import EstimatedTimes from '../EstimatedTimes';
import ForceResendModal from '../Modals/ForceResendModal';
import Menu from '../Menu';
import MenuItem from '../MenuItem';
import OrganizationType from '../../enums/organization-type.enum';
import PackageIcon from '../PackageIcon';
import SadIcon from '../../assets/images/dissatisfied-icon.svg';
import ShipmentListData from '../../data-types/shipment-list-data';
import ShippingEventNewDialog from '../../../features/ShippingEvents/components/ShippingEventNewDialog';
import SkeletonField from '../SkeletonField';
import Spinner from '../Spinner';
import Statuses from '../Statuses';
import StickyNoteIcon from '../StickyNoteIcon';
import SuccessModal from '../Modals/SuccessModal';
import TagFormat from '../../enums/tag-format-type.enum';
import useInterval from '../../hooks/useInterval';

const useStyles = makeStyles({
  alignLeft: {
    justifyContent: 'left',
  },
  alignment: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  code: {
    textAlign: 'center',
  },
  copyButton: {
    position: 'absolute',
    alignSelf: 'flex-end',
    padding: '0.5rem 0.5rem',
    right: 'calc(-100%/11)',
  },
  copyVisibility: {
    visibility: 'hidden',
  },
  createdAt: {
    color: '##757575',
    fontSize: '0.6875rem',
  },
  defaultIconColor: {
    color: 'text.primary',
  },
  estimatedTime: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  gridRootCarrier: {
    paddingLeft: '1rem',
  },
  gridRootShipper: {
    paddingRight: '1.25rem',
  },
  menuButton: {
    marginRight: '-50px',
    minWidth: 0,
    zIndex: 3,
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  menuItemIcon: {
    marginBottom: '-0.375rem',
    marginRight: '0.3125rem',
  },
  shipmentCode: {
    fontSize: '0.875rem',
    fontWeight: 'bolder',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  statusAlignment: {
    display: 'block',
    marginLeft: 15,
  },
  strong: {
    fontWeight: 'bolder',
    marginRight: 54,
  },
  volumeIcon: {
    alignItems: 'center',
    display: 'flex',
    height: 24,
    justifyContent: 'center',
    marginLeft: 13,
  },
});

export interface ShipmentItemProps {
  isLoading?: boolean;
  shipment?: ShipmentListData;
}

const ShipmentListItem = ({
  isLoading = true,
  shipment,
}: ShipmentItemProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const tagStatus = useAppSelector(selectTagStatus);
  const userType = useAppSelector(selectSessionUserType);
  const userOrganizationId = useAppSelector(selectOrganizationId);

  const [isCodeTooltipEnabled, setIsCodeTooltipEnabled] = useState<boolean>(
    false
  );
  const [
    isShippingEventNewDialogOpen,
    setIsShippingEventNewDialogOpen,
  ] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openTagFormatModal, setOpenTagFormatModal] = useState<boolean>(false);
  const [
    openDownloadMinutaModal,
    setOpenDownloadMinutaModal,
  ] = useState<boolean>(false);
  const [downloadMinutaError, setDownloadMinutaError] = useState<boolean>(
    false
  );
  const [openForceResendModal, setOpenForceResendModal] = useState<boolean>(
    false
  );
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [forceResendModalError, setForceResendModalError] = useState<boolean>(
    false
  );
  const [tagFormatModalError, setTagFormatModalError] = useState<boolean>(
    false
  );

  const [showClipBoard, setShowClipBoard] = useState<boolean>(false);

  const createdAt = useMemo<string>(() => {
    return shipment?.createdAt
      ? `${toRelativeDate(shipment.createdAt, 'DD/MM')} ${dayjs(
          shipment.createdAt
        ).format('HH:mm')}`
      : '---';
  }, [shipment?.createdAt]);

  const isShippingEventButtonVisible = useMemo<boolean>(() => {
    return [
      OrganizationType.Admin,
      OrganizationType.Carrier,
      OrganizationType.CartageAgent,
    ].includes(userType);
  }, [userType]);

  const isForceResendButtonVisible = useMemo<boolean>(() => {
    return [OrganizationType.Admin].includes(userType);
  }, [userType]);

  const handleDownloadTags = (type: TagFormat) => {
    if (shipment) {
      dispatch(downloadShipmentTagByTypeThunk({ id: shipment.id, type }));
    }
  };

  const handleDownloadMinuta = () => {
    if (shipment) {
      dispatch(downloadMinutaByIDThunk({ id: shipment.id }));
    }
  };

  const handleForceResend = () => {
    if (shipment) {
      dispatch(
        forceResendMinutaByIDThunk({
          id: shipment.id,
          userOrganizationId,
        })
      );
    }
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleShippingEventModalClose = () => {
    setIsShippingEventNewDialogOpen(false);
  };

  const handleTagFormatModalClose = () => {
    setOpenTagFormatModal(false);
    onCleanRequests();
  };

  const handleTagFormatModalError = () => {
    setOpenTagFormatModal(false);
    setTagFormatModalError(true);
  };

  const handleCloseDownloadMinutaModal = () => {
    setOpenDownloadMinutaModal(false);
    dispatch(cleanRequests());
  };

  const handleErrorDownloadMinutaModal = () => {
    setOpenDownloadMinutaModal(false);
    setDownloadMinutaError(true);
  };

  const handleCloseForceResendModal = () => {
    setOpenForceResendModal(false);
    dispatch(cleanRequests());
  };

  const handleErrorForceResendModal = () => {
    setOpenForceResendModal(false);
    setForceResendModalError(true);
  };

  const handleCloseForceResendModalOnSuccess = () => {
    setOpenForceResendModal(false);
    setSuccessModal(true);

    dispatch(cleanRequests());
  };

  const isEllipseActive = (element: HTMLDivElement) => {
    return element.offsetWidth < element.scrollWidth;
  };

  const codeRef = useCallback((element) => {
    if (element !== null) {
      setIsCodeTooltipEnabled(isEllipseActive(element));
    }
  }, []);

  const handleOnCreateShippingEvent = useCallback(() => {
    if (shipment) {
      dispatch(updateShipmentInPlaceThunk(shipment.id));
    }
  }, [dispatch, shipment]);

  const onCleanRequests = useCallback(() => {
    dispatch(cleanRequests());
    setDownloadMinutaError(false);
    setTagFormatModalError(false);
    setForceResendModalError(false);
    setSuccessModal(false);
  }, [dispatch]);

  const handleMouseEnter = () => {
    setShowClipBoard(true);
  };

  const handleMouseLeave = () => {
    setShowClipBoard(false);
  };

  useInterval(
    () => {
      if (shipment && !tagStatus[shipment.id] && !shipment?.syncedAt) {
        dispatch(shipmentListTagStatusThunk({ id: shipment.id }));
      }
    },
    30000,
    true
  );

  return (
    <>
      <Box
        alignItems="center"
        bgcolor={
          shipment && shipment.syncedAt
            ? getStylesByStatus(shipment?.status, shipment?.eta).backgroundColor
            : '#E6E6E6'
        }
        border="1px solid"
        borderTop="none"
        borderColor="#C2C3C9"
        display="flex"
        fontSize="0.875rem"
        justifyContent="center"
        minHeight="4.25rem"
        pl={isLoading ? 3 : 0}
        width="100%"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Grid container>
          <Grid className={classes.alignment} item>
            <Box
              justifyContent="center"
              pl={1}
              pr={3}
              textAlign="center"
              width="105px"
              position="relative"
            >
              {(shipment && shipment.syncedAt) || isLoading ? (
                <SkeletonField isLoading={isLoading} width="small">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      isCodeTooltipEnabled
                        ? shipment
                          ? buildShipmentIdentifier(shipment)
                          : ''
                        : ''
                    }
                  >
                    <Typography
                      className={classes.shipmentCode}
                      color="secondary"
                      ref={codeRef}
                    >
                      {shipment && buildShipmentIdentifier(shipment)}
                    </Typography>
                  </Tooltip>
                </SkeletonField>
              ) : (
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  mb={1}
                  mr={2}
                  width="100%"
                >
                  <Spinner size={26} theme="primary" />
                </Box>
              )}
              <SkeletonField
                classes={{ root: classes.alignment }}
                isLoading={isLoading}
                width="small"
              >
                <Typography className={classes.createdAt}>
                  {createdAt}
                </Typography>
              </SkeletonField>
              <Box
                className={
                  showClipBoard
                    ? classes.alignment
                    : (classes.alignment, classes.copyVisibility)
                }
              >
                <CopyButton
                  className={classes.copyButton}
                  value={shipment && buildShipmentIdentifier(shipment)}
                />
              </Box>
            </Box>
          </Grid>
          <Grid className={classes.alignment} item xs={6}>
            <Entity
              entity={shipment?.sender || shipment?.dispatcher}
              isLoading={isLoading}
            />
            <Icon fontSize="large" className={classes.defaultIconColor}>
              arrow_right_alt
            </Icon>
            <Entity
              entity={shipment?.recipient || shipment?.receiver}
              isLoading={isLoading}
            />
          </Grid>
          <Grid className={classes.alignment} item xs={2}>
            <Box className={classes.alignment} flex="1">
              <Grid className={classes.volumeIcon} item xs={1}>
                <PackageIcon />
              </Grid>
              <Grid className={classes.statusAlignment} item xs={10}>
                <Statuses
                  isLoading={isLoading}
                  receiptModality={shipment?.receiptModality}
                  status={shipment?.status}
                  quantity={shipment?.volumesQuantity}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid className={classes.alignment} item xs={2}>
            <Box
              className={
                shipment && !shipment.syncedAt
                  ? classes.alignLeft
                  : classes.estimatedTime
              }
              ml="0.625rem"
            >
              {(shipment && shipment.syncedAt) || isLoading ? (
                <>
                  <EstimatedTimes
                    eta={shipment?.finishedAt || shipment?.eta}
                    etd={shipment?.becameReadyAt || shipment?.etd}
                    isLoading={isLoading}
                    status={shipment?.status}
                  />
                </>
              ) : (
                <Typography className={classes.strong} color="secondary">
                  Processando...
                </Typography>
              )}
            </Box>
            {(shipment && shipment.syncedAt) || isLoading ? (
              <Menu
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                buttonChildren={<MoreVertIcon />}
                buttonClasses={{ root: classes.menuButton }}
                elevation={1}
                open={openMenu}
                onClose={handleMenuClose}
                onButtonClick={() => setOpenMenu((prevState) => !prevState)}
              >
                {isShippingEventButtonVisible && (
                  <MenuItem
                    classes={{ root: classes.menuItem }}
                    onClick={() => {
                      handleMenuClose();
                      setIsShippingEventNewDialogOpen(true);
                    }}
                  >
                    <EventAvailableIcon className={classes.menuItemIcon} />
                    Registrar Ocorrência
                  </MenuItem>
                )}
                <MenuItem
                  classes={{ root: classes.menuItem }}
                  onClick={() => {
                    handleMenuClose();
                    setOpenTagFormatModal(true);
                  }}
                >
                  <StickyNoteIcon className={classes.menuItemIcon} />
                  Imprimir Etiquetas
                </MenuItem>
                <MenuItem
                  classes={{ root: classes.menuItem }}
                  onClick={() => {
                    handleMenuClose();
                    setOpenDownloadMinutaModal(true);
                  }}
                >
                  <StickyNoteIcon className={classes.menuItemIcon} />
                  Imprimir Minuta
                </MenuItem>
              </Menu>
            ) : (
              isForceResendButtonVisible && (
                <Menu
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  buttonChildren={<MoreVertIcon />}
                  buttonClasses={{ root: classes.menuButton }}
                  elevation={1}
                  open={openMenu}
                  onClose={handleMenuClose}
                  onButtonClick={() => setOpenMenu((prevState) => !prevState)}
                >
                  <MenuItem
                    classes={{ root: classes.menuItem }}
                    onClick={() => {
                      handleMenuClose();
                      setOpenForceResendModal(true);
                    }}
                  >
                    <StickyNoteIcon className={classes.menuItemIcon} />
                    Forçar Reenvio
                  </MenuItem>
                </Menu>
              )
            )}
          </Grid>
        </Grid>
      </Box>
      <DocumentNotFoundModal
        open={!!tagFormatModalError || !!downloadMinutaError}
        onClose={onCleanRequests}
        documentType={downloadMinutaError ? 'minutas' : 'etiquetas'}
      />
      {shipment && isShippingEventNewDialogOpen && (
        <ShippingEventNewDialog
          isOpen={isShippingEventNewDialogOpen}
          volumesQuantity={shipment.volumesQuantity}
          onClose={handleShippingEventModalClose}
          onCreate={handleOnCreateShippingEvent}
          shipmentId={shipment.id}
        />
      )}
      {openTagFormatModal && (
        <TagFormatModal
          open={openTagFormatModal}
          isLoading={tagFormatModalError}
          onCancel={handleTagFormatModalClose}
          onConfirm={handleDownloadTags}
          onError={handleTagFormatModalError}
        />
      )}
      {shipment && openDownloadMinutaModal && (
        <DownloadMinutaModal
          onClose={handleCloseDownloadMinutaModal}
          onConfirm={handleDownloadMinuta}
          onError={handleErrorDownloadMinutaModal}
          open={openDownloadMinutaModal}
          shipment={shipment}
        />
      )}
      {shipment && openForceResendModal && (
        <ForceResendModal
          onClose={handleCloseForceResendModal}
          onSuccess={handleCloseForceResendModalOnSuccess}
          onConfirm={handleForceResend}
          onError={handleErrorForceResendModal}
          open={openForceResendModal}
          shipment={shipment}
        />
      )}
      {shipment && forceResendModalError && (
        <ErrorModal
          image={SadIcon}
          message="Aconteceu uma falha ao tentar forçar o reenvio, por favor tente novamente em instantes, caso persista, nos comunique"
          open={forceResendModalError}
          title="Opa! Aconteceu um erro"
          onClose={onCleanRequests}
        />
      )}
      {shipment && successModal && (
        <SuccessModal
          open={successModal}
          title="Reenvio Feito com Sucesso!"
          onClose={onCleanRequests}
        />
      )}
    </>
  );
};

export default ShipmentListItem;
