import {
  AnyAction,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  createShipment,
  readCte,
  readNfe,
  ShipmentCreationData,
} from './shipmentNewAPI';
import {
  DecodedCTe,
  DecodedCTeWithBase64,
  DecodedNFeWithBase64,
} from '../../../common/data-types/decoded-fiscal-documents-data';
import { filesToBase64 } from '../../../common/services/base64.service';
import { RootState } from '../../../app/store';
import ErrorData from '../../../common/data-types/error-data';
import OrganizationData from '../../../common/data-types/organization-data';
import RequestData from '../../../common/data-types/request-data';
import RequestStatus from '../../../common/enums/request-status.enum';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  const startsWith = action.type.startsWith('shipmentNew');
  const endsWith = action.type.endsWith('/fulfilled');

  return startsWith && endsWith;
};

const isPendingAction = (action: AnyAction): action is PendingAction => {
  const startsWith = action.type.startsWith('shipmentNew');
  const endsWith = action.type.endsWith('/pending');

  return startsWith && endsWith;
};

const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  const startsWith = action.type.startsWith('shipmentNew');
  const endsWith = action.type.endsWith('/rejected');

  return startsWith && endsWith;
};

// #region Thunks
interface ShipmentCreateThunkParams {
  shipment: ShipmentCreationData;
  userOrganizationId: OrganizationData['id'];
}

const CREATE_SHIPMENT_THUNK = 'shipmentNew/createShipment';
export const createShipmentThunk = createAsyncThunk(
  CREATE_SHIPMENT_THUNK,
  async ({ shipment, userOrganizationId }: ShipmentCreateThunkParams) => {
    await createShipment({ shipment, userOrganizationId });
  }
);

const READ_CTE_THUNK = 'shipmentNew/readCte';
export const readCteThunk = createAsyncThunk(
  READ_CTE_THUNK,
  async (files: File[]) => {
    const base64Files = await filesToBase64(files);
    const fiscalDocuments = base64Files.map((file: string) => ({
      xmlBase64: file,
    }));

    const ctes = await readCte(fiscalDocuments);

    return ctes.map((cte) => ({
      ...cte,
    }));
  }
);

const READ_NFE_THUNK = 'shipmentNew/readNfe';
export const readNfeThunk = createAsyncThunk(
  READ_NFE_THUNK,
  async (files: File[]) => {
    const base64Files = await filesToBase64(files);

    const fiscalDocuments = base64Files.map((file: string) => ({
      xmlBase64: file,
    }));

    const nfes = await readNfe(fiscalDocuments);

    return nfes.map((nfe) => ({
      ...nfe,
    }));
  }
);
// #endregion Thunks

// #region Slice
export interface ShipmentNewState {
  nfes: DecodedNFeWithBase64[];
  ctes: DecodedCTeWithBase64[];
  nfeStatus: RequestStatus;
  requestIds: RequestData['id'][];
  requestsById: Record<RequestData['id'], RequestData>;
}

const initialState: ShipmentNewState = {
  nfes: [],
  ctes: [],
  nfeStatus: RequestStatus.Idle,
  requestIds: [],
  requestsById: {},
};

const shipmentNewSlice = createSlice({
  name: 'shipmentNew',
  initialState,
  reducers: {
    cleanState: () => initialState,
    cleanRequests: (state) => {
      state.requestIds = [];
      state.requestsById = {};
    },
    // #region CTes
    cleanCtes: (state) => {
      state.ctes = [];
    },
    loadCtes: (state, action: PayloadAction<DecodedNFeWithBase64[]>) => {
      state.ctes = [...state.ctes, ...action.payload];
    },
    removeCte: (
      state,
      { payload: { index } }: { payload: { index: number } }
    ) => {
      state.ctes = state.ctes.filter((_cte, cteIndex) => cteIndex !== index);
    },
    // #endregion CTes
    // #region NFes
    cleanNfes: (state) => {
      state.nfes = [];
    },
    loadNfes: (state, action: PayloadAction<DecodedNFeWithBase64[]>) => {
      state.nfes = [...state.nfes, ...action.payload];
    },
    removeNfe: (
      state,
      { payload: { index } }: { payload: { index: number } }
    ) => {
      state.nfes = state.nfes.filter((_nfe, nfeIndex) => nfeIndex !== index);
    },
    // #endregion CTes
  },
  extraReducers: (builder) => {
    builder
      .addCase(readCteThunk.fulfilled, (state, action) => {
        state.ctes = [...state.ctes, ...action.payload];
      })
      .addCase(readNfeThunk.fulfilled, (state, action) => {
        state.nfes = [...state.nfes, ...action.payload];
      })
      .addMatcher(isFulfilledAction, (state, action: AnyAction) => {
        const { meta } = action;
        const request = state.requestsById[meta.requestId];

        if (!request) {
          return;
        }

        Object.assign(request, {
          error: undefined,
          status: RequestStatus.Succeeded,
          type: action.type,
        });
      })
      .addMatcher(isPendingAction, (state, action: AnyAction) => {
        const { meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Loading,
          type,
        };

        state.requestsById[requestId] = request;
      })
      .addMatcher(isRejectedAction, (state, action: AnyAction) => {
        const { error, meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Failed,
          type,
          error,
        };

        state.requestsById[requestId] = request;
      });
  },
});
// #endregion Slice

// #region Selectors
// #region Private Selectors
const selectNewShipmentState = (state: RootState) => state.shipmentNew;

const selectNewShipmentRequests = (rootState: RootState): RequestData[] => {
  const state = selectNewShipmentState(rootState);
  return state.requestIds.map((id) => state.requestsById[id]);
};

const selectNewShipmentRequestsByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData[] => {
  const requests = selectNewShipmentRequests(rootState);

  return requests.filter((r) => r.type === type);
};

const selectNewShipmentRequestByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData => {
  const requests = selectNewShipmentRequestsByType(rootState, type);

  return requests.reverse()[0];
};

const selectNewShipmentIsFullfilledByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const fullfilledType = [type, 'fulfilled'].join('/');
  const request = selectNewShipmentRequestByType(rootState, fullfilledType);

  return request ? request.status === RequestStatus.Succeeded : false;
};

const selectNewShipmentIsRequestingByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const pendingType = [type, 'pending'].join('/');
  const request = selectNewShipmentRequestByType(rootState, pendingType);

  return request ? request.status === RequestStatus.Loading : false;
};

const selectNewShipmentRequestErrorByType = (
  rootState: RootState,
  type: RequestData['type']
): ErrorData | Record<string, any> | undefined => {
  const rejectedType = [type, 'rejected'].join('/');
  const request = selectNewShipmentRequestByType(rootState, rejectedType);

  return request ? request.error : undefined;
};
// #endregion Private Selectors

// #region Public Selectors
export const selectNewShipmentIsCreating = (rootState: RootState): boolean =>
  selectNewShipmentIsRequestingByType(rootState, CREATE_SHIPMENT_THUNK);

export const selectNewShipmentIsCreated = (rootState: RootState): boolean =>
  selectNewShipmentIsFullfilledByType(rootState, CREATE_SHIPMENT_THUNK);

// #region CTe

export const selectCtesListState = (state: RootState): DecodedCTe[] => {
  const { ctes } = selectNewShipmentState(state);
  return ctes;
};

export const selectNewShipmentReadCteIsRequestingState = (
  state: RootState
): boolean => selectNewShipmentIsRequestingByType(state, READ_CTE_THUNK);

export const selectNewShipmentReadCteIsFulfilledState = (
  state: RootState
): boolean => selectNewShipmentIsFullfilledByType(state, READ_CTE_THUNK);

export const selectNewShipmentReadCteErrorState = (
  state: RootState
): ErrorData | Record<string, any> | undefined =>
  selectNewShipmentRequestErrorByType(state, READ_CTE_THUNK);

// #endregion CTe

// #region NFe

export const selectNfesListState = (
  state: RootState
): DecodedNFeWithBase64[] => {
  const { nfes } = selectNewShipmentState(state);
  return nfes;
};

export const selectNewShipmentReadNfeIsRequestingState = (
  state: RootState
): boolean => selectNewShipmentIsRequestingByType(state, READ_NFE_THUNK);

export const selectNewShipmentReadNfeIsFulfilledState = (
  state: RootState
): boolean => selectNewShipmentIsFullfilledByType(state, READ_NFE_THUNK);

export const selectNewShipmentReadNfeErrorState = (
  state: RootState
): ErrorData | Record<string, any> | undefined => {
  return selectNewShipmentRequestErrorByType(state, READ_NFE_THUNK);
};

// #endregion NFe

// #endregion Public Selectors
// #endregion Selectors

export const {
  cleanCtes,
  cleanNfes,
  cleanState,
  cleanRequests,
  loadCtes,
  loadNfes,
  removeCte,
  removeNfe,
} = shipmentNewSlice.actions;

export const shipmentNewReducer = shipmentNewSlice.reducer;
