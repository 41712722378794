import { isNil, omitBy } from 'lodash';

import {
  ENTITY_LIST_GROUPED_BY_ORGANIZATION_QUERY,
  ENTITY_LIST_QUERY,
  FETCH_ENTITY_QUERY,
} from './entitiesGraphql';
import { EntityCreateData } from '../../common/clients/zordon.client';
import { getGraphqlClient, getZordonClient } from '../../app/initializer';
import { HttpResponse } from '../../common/clients/http-client/http.client';
import { REACT_APP_EBB_CARRIER_PARENT_ENTITY_ID } from '../../.env';
import AddressData from '../../common/data-types/address-data';
import EntityData from '../../common/data-types/entity-data';
import EntityFilterData from '../../common/data-types/entity-filter-data';

// #region Typings
export interface EntitiesGroupedByOrganization {
  associatedEntities: EntityData[];
  entities: EntityData[];
}

export interface EntityList {
  entities: EntityData[];
  total: number;
}

export interface EntityListOptions {
  filter?: EntityFilterData;
  first?: number;
  offset?: number;
}
export interface EntityUpdateData {
  id: EntityData['id'];
  entity: Partial<EntityData>;
}

export interface FetchEntityOptions {
  id: EntityData['id'];
}
// #endregion Typings

const graphqlClient = getGraphqlClient();
const zordonClient = getZordonClient();

export const createEntity = async (
  entity: EntityCreateData
): Promise<EntityData> => {
  return zordonClient.createEntity(entity);
};

export const listEntities = async ({
  filter,
  first,
  offset = 0,
}: EntityListOptions): Promise<EntityList> => {
  const variables = buildVariables({ filter, first, offset });

  const {
    entities: { data, total },
  } = await graphqlClient.executeGraphQL(ENTITY_LIST_QUERY, variables);

  return { entities: [...data], total };
};

export const listAssociatedEntities = async (): Promise<EntityList> => {
  return listEntities({
    filter: {
      organizationIds: [+REACT_APP_EBB_CARRIER_PARENT_ENTITY_ID],
    },
    offset: 0,
  });
};

export const listEntitiesGroupedByOrganization = async (
  organizationId: number
): Promise<EntitiesGroupedByOrganization> => {
  const { associatedEntities, entities } = await graphqlClient.executeGraphQL(
    ENTITY_LIST_GROUPED_BY_ORGANIZATION_QUERY,
    {
      filter: { disposable: false, organizationIds: [organizationId] },
    }
  );

  return {
    associatedEntities: associatedEntities.data,
    entities: entities.data,
  };
};

export const removeEntity = async (
  entityId: EntityData['id']
): Promise<HttpResponse> => {
  return zordonClient.removeEntity(entityId);
};

export const searchAddressByCep = async (cep: string): Promise<AddressData> => {
  return zordonClient.searchAddressByCep(cep);
};

export const updateEntity = async ({
  id,
  entity,
}: EntityUpdateData): Promise<HttpResponse> => {
  return zordonClient.updateEntity(id, entity);
};

export const fetchEntity = async ({
  id,
}: FetchEntityOptions): Promise<EntityData> => {
  const { entity } = await graphqlClient.executeGraphQL(FETCH_ENTITY_QUERY, {
    id,
  });

  return entity;
};

const buildVariables = ({
  filter,
  first,
  offset,
}: EntityListOptions): Record<string, any> => {
  return {
    filter: filter && buildFilter(filter),
    first,
    offset,
  };
};

const buildFilter = (filter: EntityFilterData): Record<string, any> => {
  const {
    displayName,
    disposable,
    location,
    name,
    organizationIds,
    parentCompany,
  } = filter;

  return omitBy(
    {
      displayNames: displayName && [displayName],
      disposable,
      locations: location && [location],
      names: name && [name],
      organizationIds,
      parentCompany,
    },
    isNil
  );
};
