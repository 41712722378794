import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    color: theme.palette.primary.main,
    fontSize: 30,
    fontWeight: theme.typography.fontWeightBold,
  },
  root: {
    padding: '5% 0',
    textAlign: 'center',
  },
}));

export interface EmptyListIndicator {
  append?: React.ReactNode;
  message: string;
  prepend?: React.ReactNode;
}

const EmptyListIndicator = ({
  append,
  message,
  prepend,
}: EmptyListIndicator) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={0} square>
      {prepend}
      <Typography className={classes.message} component="h2">
        {message}
      </Typography>
      {append}
    </Paper>
  );
};

export default EmptyListIndicator;
