import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import MailIcon from '@material-ui/icons/Mail';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PhoneIcon from '@material-ui/icons/Phone';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { maskStringByPattern } from '../../helpers/mask.helper';
import { PersonType } from '../../enums/person-type.enum';
import {
  removeEntityThunk,
  resetThunkStatus,
  selectEntitiesIsFulfilledRemove,
} from '../../../features/Entities/entitiesSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import ConfirmationModal from '../Modals/ConfirmationModal';
import EntityData from '../../data-types/entity-data';
import Menu from '../Menu';
import MenuItem from '../MenuItem';

const useStyles = makeStyles((theme: Theme) => ({
  actionIcon: {
    marginRight: '10px',
  },
  displayName: {
    color: theme.palette.text.primary,
  },
  entityName: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 5,
  },
  entityNameContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  icon: {
    marginRight: 12.5,
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
  },
  link: {
    alignItems: 'center',
    color: 'inherit',
    display: 'flex',
    padding: '0.3rem 1rem',
    textDecoration: 'none',
  },
  menuIcon: {
    color: theme.palette.primary.main,
  },
  menuItem: {
    padding: 0,
  },
  root: {
    '&:first-child': {
      borderTop: '1px solid #C2C3C9',
    },
    backgroundColor: 'white',
    borderBottom: '1px solid #C2C3C9',
    borderLeft: '1px solid #C2C3C9',
    borderRight: '1px solid #C2C3C9',
    padding: '16px 20px',
  },
  value: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface EntityItemProps {
  entity?: EntityData;
  isLoading: boolean;
}

const EntityItem = ({ entity, isLoading }: EntityItemProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const isRemoved = useAppSelector(selectEntitiesIsFulfilledRemove);

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>('');
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleOnRemoveClick = () => {
    handleMenuClose();
    setOpenModal(true);
  };

  const isEllipseActive = (element: HTMLDivElement) => {
    return element.offsetWidth < element.scrollWidth;
  };

  const entityNameRef = useCallback((element) => {
    if (element !== null) {
      setShowTooltip(isEllipseActive(element));
    }
  }, []);

  const handleRemoveEntity = useCallback(() => {
    setOpenModal(false);
    if (entity) {
      const { requestId: id } = dispatch(
        removeEntityThunk({ entityId: entity?.id })
      );
      setRequestId(id);
    }
  }, [dispatch, entity]);

  const documentNumberPattern = useMemo<string>(() => {
    return entity?.documentNumber && entity?.type === PersonType.Individual
      ? '###.###.###-##'
      : '##.###.###/####-##';
  }, [entity]);

  const document = useMemo<string>(() => {
    return entity?.documentNumber
      ? maskStringByPattern(entity?.documentNumber, documentNumberPattern)
      : '---';
  }, [documentNumberPattern, entity]);

  const phone = useMemo<string>(() => {
    return entity?.phone
      ? maskStringByPattern(entity?.phone, '(##) #####-####')
      : '---';
  }, [entity]);

  useEffect(() => {
    return () => {
      if (requestId) {
        dispatch(resetThunkStatus({ requestId }));
      }
    };
  }, [dispatch, isRemoved, requestId]);

  return (
    <Box className={classes.root}>
      <ConfirmationModal
        content={
          <Typography component="p">
            Deseja mesmo remover o participante{' '}
            <strong className={classes.displayName}>
              {entity?.displayName || entity?.name}
            </strong>{' '}
            ? Essa operação não pode ser revertida.
          </Typography>
        }
        open={openModal}
        title="Excluir este participante?"
        onCancel={() => setOpenModal(false)}
        onConfirm={() => handleRemoveEntity()}
      />
      <Grid container spacing={2}>
        <Grid
          className={classes.entityNameContainer}
          item
          ref={entityNameRef}
          xs={6}
        >
          <Tooltip
            arrow
            placement="top"
            title={showTooltip ? entity?.displayName || entity?.name || '' : ''}
          >
            <Typography
              className={classes.entityName}
              color="primary"
              component="span"
            >
              {isLoading ? (
                <Skeleton animation="wave" />
              ) : (
                entity?.displayName || entity?.name
              )}
            </Typography>
          </Tooltip>
          <Typography className={classes.value}>
            {isLoading ? (
              <Skeleton animation="wave" />
            ) : (
              `${entity?.address?.location}, ${entity?.address?.state}`
            )}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.label}>CPF/CNPJ</Typography>
          <Typography className={classes.value} component="p">
            {isLoading ? <Skeleton animation="wave" /> : document}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Box alignItems="center" display="flex">
            <PhoneIcon
              color="primary"
              className={classes.icon}
              fontSize="small"
            />
            <Typography className={classes.value}>
              {isLoading ? <Skeleton animation="wave" width={200} /> : phone}
            </Typography>
          </Box>
          <Box alignItems="center" display="flex">
            <MailIcon
              color="primary"
              className={classes.icon}
              fontSize="small"
            />
            <Tooltip arrow placement="top" title={entity?.email || '---'}>
              <Typography className={classes.value}>
                {isLoading ? (
                  <Skeleton animation="wave" width={200} />
                ) : (
                  entity?.email || '---'
                )}
              </Typography>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Menu
            buttonChildren={
              <MoreVertIcon classes={{ root: classes.menuIcon }} />
            }
            elevation={1}
            open={openMenu}
            onButtonClick={() => setOpenMenu((prevState) => !prevState)}
            onClose={handleMenuClose}
          >
            <MenuItem
              classes={{ root: classes.menuItem }}
              disableGutters
              onClick={handleMenuClose}
            >
              <Link className={classes.link} to={`entities/${entity?.id}/edit`}>
                <EditIcon classes={{ root: classes.actionIcon }} />
                Editar
              </Link>
            </MenuItem>
            <MenuItem onClick={() => handleOnRemoveClick()}>
              <DeleteIcon classes={{ root: classes.actionIcon }} />
              Remover
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EntityItem;
