import React, { useMemo } from 'react';

import {
  Box,
  FormLabel,
  Grid,
  Icon,
  makeStyles,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Control, useController, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { ERROR_MESSAGES } from '../../constants/error-messages';
import { PersonType } from '../../enums/person-type.enum';
import { phoneNumberFormatter } from '../../helpers/phone-input.helper';
import { UncontrolledRadio } from '../Radio';
import Checkbox from '../Checkbox';
import MaskedInput from '../MaskedInput';
import MaskType from '../../enums/mask-input-type.enum';
import TextField from '../TextField';

const cpfSchema = yup
  .string()
  .min(11, ERROR_MESSAGES.string.min)
  .max(11, ERROR_MESSAGES.string.max)
  .required();

const cnpjSchema = yup
  .string()
  .min(14, ERROR_MESSAGES.string.min)
  .max(14, ERROR_MESSAGES.string.max)
  .required();

export const identitySchemas = {
  clientCode: yup.lazy((value = '') => {
    if (value.length < 1) {
      return yup.string().notRequired();
    }
    return yup
      .string()
      .min(1, ERROR_MESSAGES.string.min)
      .max(120, ERROR_MESSAGES.string.max)
      .required();
  }),
  document: yup.string().when('personType', {
    is: PersonType.Corporation,
    then: cnpjSchema,
    otherwise: cpfSchema,
  }),
  email: yup.string().email('Email inválido').max(120),
  name: yup
    .string()
    .min(2, ERROR_MESSAGES.string.min)
    .max(120, ERROR_MESSAGES.string.max)
    .required(ERROR_MESSAGES.string.required),
  nickname: yup
    .string()
    .min(2, ERROR_MESSAGES.string.min)
    .max(120, ERROR_MESSAGES.string.max)
    .required(ERROR_MESSAGES.string.required),
  personType: yup.string().default(PersonType.Corporation).required(),
  phone: yup.lazy((value = '') => {
    if (value.length < 1) {
      return yup.string().notRequired();
    }
    return yup
      .string()
      .min(14, 'O campo deve possuir no mínimo 10 caracteres')
      .max(16, 'O campo deve possuir no máximo 11 caracteres')
      .required();
  }),
  stateRegistration: yup.lazy((_, data) => {
    if (
      data.parent.taxFree ||
      data.parent.personType === PersonType.Individual
    ) {
      return yup.string().notRequired();
    }
    return yup
      .string()
      .min(9, ERROR_MESSAGES.string.min)
      .max(14, ERROR_MESSAGES.string.max)
      .required(ERROR_MESSAGES.string.required);
  }),
  taxFree: yup.boolean().default(false),
};

export interface IdentityFormInputs {
  clientCode: string;
  document: string;
  email: string;
  name: string;
  nickname: string;
  personType: PersonType;
  phone: string;
  stateRegistration: string;
  taxFree: boolean;
}

interface IdentityFormProps {
  control: Control<any>;
}

const useStyle = makeStyles({
  icon: {
    marginBottom: '-4px',
    marginRight: '5px',
  },
  paddingNone: {
    padding: 'none',
  },
  strong: {
    fontWeight: 'bolder',
  },
});

export const IdentityForm = ({ control }: IdentityFormProps) => {
  const classes = useStyle();

  const taxFreeValue = useWatch({ control, name: `taxFree` });

  const phoneNumber = useWatch({ control, name: `phone` });

  const {
    field: { onChange, value: personType },
  } = useController({
    control,
    name: `personType`,
  });

  const isCorporation = useMemo(() => {
    return personType === PersonType.Corporation;
  }, [personType]);

  return (
    <Box
      bgcolor="#FFFFFF"
      border={1}
      borderColor="#C2C3C9"
      borderRadius={3}
      width="50rem"
    >
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Box alignItems="center" p={2} pb={0}>
            <Typography
              className={classes.strong}
              color="textSecondary"
              variant="h6"
            >
              <Icon className={classes.icon}>person</Icon>
              Identificação
            </Typography>
          </Box>
        </Grid>
        <Box p={2} pt={0}>
          <Grid item xs={7}>
            <TextField
              control={control}
              fullWidth
              helperText="Como será exibido no sistema"
              inputProps={{ maxLength: 120 }}
              label="Apelido *"
              margin="normal"
              name="nickname"
              placeholder="Apelido *"
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              control={control}
              fullWidth
              inputProps={{ maxLength: 120 }}
              label="Nome *"
              margin="normal"
              name="name"
              placeholder="Nome *"
            />
          </Grid>
        </Box>
        <Box p={2} pt={0}>
          <Grid item xs={12}>
            <FormLabel component="legend">Pessoa</FormLabel>
            <RadioGroup row value={personType} onChange={onChange}>
              <UncontrolledRadio
                label="Jurídica"
                labelPlacement="end"
                name="corporation"
                value={PersonType.Corporation}
              />
              <UncontrolledRadio
                label="Física"
                labelPlacement="end"
                name="individual"
                value={PersonType.Individual}
              />
            </RadioGroup>
          </Grid>
        </Box>
        <Box p={2} pt={0}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={5}>
              <MaskedInput
                control={control}
                fullWidth
                label={isCorporation ? 'CNPJ *' : 'CPF *'}
                maskType={isCorporation ? MaskType.CNPJ : MaskType.CPF}
                name="document"
                placeholder={isCorporation ? 'CNPJ *' : 'CPF *'}
              />
            </Grid>
            {isCorporation && (
              <>
                <Grid item xs={5}>
                  <MaskedInput
                    control={control}
                    disabled={taxFreeValue}
                    fullWidth
                    label="Inscrição Estadual *"
                    maskType={MaskType.StateRegistration}
                    name="stateRegistration"
                    placeholder="Inscrição Estadual *"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Checkbox control={control} label="Isento" name="taxFree" />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={5}>
              <TextField
                control={control}
                fullWidth
                inputProps={{ maxLength: 120 }}
                label="E-mail"
                margin="normal"
                name="email"
                placeholder="E-mail"
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                control={control}
                fullWidth
                label="Telefone"
                margin="normal"
                name="phone"
                value={phoneNumberFormatter(phoneNumber)}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={5}>
              <TextField
                control={control}
                fullWidth
                helperText="Identificação desse participante no seu sistema"
                inputProps={{ maxLength: 120 }}
                label="Código do Cliente"
                margin="normal"
                name="clientCode"
                placeholder="Código do Cliente"
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default IdentityForm;
