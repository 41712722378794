import { gql } from '../../common/helpers/gql.helper';

const addressFragment = gql`
  location
  state
`;

const entityFragment = gql`
  address {
    ${addressFragment}
  }
  displayName
  id
  name
`;

export const FIND_BILLING_QUERY = gql`
  query FindBilling($endDate: Date!, $organizationId: Int, $startDate: Date!) {
    billing(
      endDate: $endDate
      organizationId: $organizationId
      startDate: $startDate
    ) {
      groupedShipments {
        destinationShipperEntity {
          ${entityFragment}
        }
        originShipperEntity {
          ${entityFragment}
        }
        shipmentsQuantity
        totalValue
        totalWeight
        volumesQuantity
      }
      total {
        numberOfShipments
        numberOfVolumes
        totalValue
        totalWeight
      }
    }
  }
`;

export const FIND_BILLING_BY_PERIODICITY = gql`
  query FindBillingPeriodicity(
    $endDate: Date!
    $organizationId: Int
    $periodicity: BillingPeriodicity!
    $startDate: Date!
  ) {
    billingByPeriodicity(
      endDate: $endDate
      organizationId: $organizationId
      periodicity: $periodicity
      startDate: $startDate
    ) {
      numberOfShipments
      numberOfVolumes
      periodicity
      periodicityValue
      totalValue
      totalWeight
    }
  }
`;

export const LIST_BILLING_SHIPMENTS_QUERY = gql`
  query ListBillingShipments(
    $filter: ShipmentsFilter
    $first: Int
    $offset: Int
    $order: ListOrder
  ) {
    shipments(filter: $filter, first: $first, offset: $offset, order: $order) {
      data {
        becameReadyAt
        cte {
          number
        }
        destinationShipperEntity {
          id
        }
        id
        originShipperEntity {
          id
        }
        shipmentCost {
          advalorem
          firstMile
          icms
          lastMile
          middleMile
          totalTaxes
        }
        status
        totalWeight
        volumesQuantity
      }
      total
    }
  }
`;
