import React, { useCallback } from 'react';

import {
  Box,
  Container,
  Grid,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Control } from 'react-hook-form';

import TextField from './TextField';
import OpenPackageIcon from './OpenPackageIcon';

const useStyles = makeStyles({
  container: {
    maxWidth: '50rem',
    paddingBottom: '1.3rem',
    paddingTop: '1.3rem',
  },
  icon: {
    marginRight: '0.5rem',
  },
  separator: {
    margin: '0 0.8rem',
  },
  strong: {
    fontWeight: 'bolder',
  },
  title: {
    marginBottom: '1rem',
  },
});

interface PackageFormFieldsProps {
  control: Control<any>;
  onChange: (name: string, value: string) => void;
}

const PackageFormFields = ({ control, onChange }: PackageFormFieldsProps) => {
  const classes = useStyles();

  const handleNumberFieldChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      fieldName: string
    ) => {
      const { value } = event.target;
      onChange(fieldName, value.replace(/^0+(\d)/, '$1'));
    },
    [onChange]
  );

  return (
    <Box
      bgcolor="#FFFFFF"
      border={1}
      borderColor="#C2C3C9"
      borderRadius={3}
      paddingBottom="2rem"
      width="50rem"
    >
      <Container className={classes.container}>
        <Grid item xs={12}>
          <Grid alignItems="center" className={classes.title} container>
            <OpenPackageIcon className={classes.icon} />
            <Typography
              className={classes.strong}
              color="textSecondary"
              variant="h6"
            >
              Embalagem
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <TextField
              control={control}
              fullWidth
              helperText="Como será exibida essa embalagem no sistema"
              htmlInputProps={{ maxLength: 120 }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Descrição *"
              name="description"
            />
          </Grid>
          <Grid
            alignItems="baseline"
            container
            item
            justifyContent="space-between"
            sm={7}
            wrap="nowrap"
          >
            <TextField
              control={control}
              htmlInputProps={{ maxLength: 5 }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                ),
              }}
              label="Comprimento *"
              name="length"
              onChange={(e) => handleNumberFieldChange(e, 'length')}
            />
            <Typography className={classes.separator} color="textSecondary">
              x
            </Typography>
            <TextField
              control={control}
              htmlInputProps={{ maxLength: 5 }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                ),
              }}
              label="Largura *"
              name="width"
              onChange={(e) => handleNumberFieldChange(e, 'width')}
            />
            <Typography className={classes.separator} color="textSecondary">
              x
            </Typography>
            <TextField
              control={control}
              htmlInputProps={{ maxLength: 5 }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                ),
              }}
              label="Altura *"
              name="height"
              onChange={(e) => handleNumberFieldChange(e, 'height')}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PackageFormFields;
