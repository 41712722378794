import React from 'react';

import {
  Button,
  ButtonClassKey,
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

export interface MenuProps extends MuiMenuProps {
  buttonChildren?: React.ReactNode;
  buttonClasses?: Partial<ClassNameMap<ButtonClassKey>>;
  children: React.ReactNode;
  open: boolean;
  onButtonClick: () => void;
  onClose: () => void;
}

const Menu = ({
  anchorOrigin = { horizontal: 'center', vertical: 'bottom' },
  buttonChildren,
  buttonClasses,
  children,
  elevation = 0,
  open,
  variant = 'selectedMenu',
  onButtonClick,
  onClose,
  ...rest
}: MenuProps) => {
  const anchorEl = React.useRef<HTMLButtonElement>(null);

  const handleOnButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onButtonClick) {
      e.stopPropagation();
      onButtonClick();
    }
  };

  const handleOnClose = (e: React.MouseEvent<HTMLElement>) => {
    if (onClose) {
      e.stopPropagation();
      onClose();
    }
  };

  return (
    <>
      <Button
        classes={buttonClasses}
        ref={anchorEl}
        onClick={handleOnButtonClick}
      >
        {buttonChildren}
      </Button>
      <MuiMenu
        anchorEl={anchorEl.current}
        anchorOrigin={anchorOrigin}
        elevation={elevation}
        getContentAnchorEl={null}
        keepMounted
        open={open || false}
        variant={variant}
        onBlur={onClose}
        onClose={handleOnClose}
        {...rest}
      >
        {children}
      </MuiMenu>
    </>
  );
};

export default Menu;
