import React from 'react';

import { Box } from '@material-ui/core';

import { getStylesByStatus } from '../../helpers/status.helper';
import { ShipmentStatus } from '../../enums/shipment-status.enum';
import EstimatedTimeItem from './Item';

export interface EstimatedTimesProps {
  eta?: string;
  etd?: string;
  isLoading: boolean;
  status?: ShipmentStatus;
}

const EstimatedTimes = ({
  eta,
  etd,
  isLoading,
  status = ShipmentStatus.Unknown,
}: EstimatedTimesProps) => (
  <Box display="flex" flexDirection="column" fontSize="0.75rem" width="164px">
    <EstimatedTimeItem
      color={getStylesByStatus(status, eta).etdColor}
      date={etd}
      icon={status === 'finished' ? 'check' : 'query_builder'}
      isLoading={isLoading}
      label="Saída"
    />
    <EstimatedTimeItem
      color={getStylesByStatus(status, eta).etaColor}
      date={eta}
      icon={status === 'finished' ? 'check' : 'query_builder'}
      isLoading={isLoading}
      label="Entrega"
    />
  </Box>
);

export default EstimatedTimes;
