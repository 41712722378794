import React, { useState } from 'react';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton, {
  IconButtonProps,
} from '@material-ui/core/IconButton/IconButton';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

interface CopyButtonProps extends IconButtonProps {
  fontSize?: 'default' | 'inherit' | 'large' | 'medium' | 'small';
  tooltipPlacement?: 'bottom' | 'left' | 'right' | 'top';
  tooltipTitle?: string;
  tooptipTimeout?: number;
  value?: string;
}

const CopyButton = ({
  fontSize = 'small',
  tooltipPlacement = 'top',
  tooltipTitle = 'copiado!',
  tooptipTimeout = 2000,
  value = '',
  ...rest
}: CopyButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
    setIsOpen(true);

    setTimeout(() => {
      setIsOpen(false);
    }, tooptipTimeout);
  };

  return (
    <Tooltip
      arrow
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={isOpen}
      placement={tooltipPlacement}
      title={tooltipTitle}
    >
      <IconButton onClick={onCopy} {...rest}>
        <FileCopyIcon fontSize={fontSize} />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButton;
