import React from 'react';

import { makeStyles } from '@material-ui/core';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem as MuiTimelineItem,
  TimelineItemProps as MuiTimelineItemProps,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';

const useStyles = makeStyles({
  connector: {
    backgroundColor: '#DDDDDD',
    width: '3px',
  },
  content: {
    marginTop: '-0.5rem',
    maxWidth: '100%',
  },
  dot: {
    backgroundColor: 'transparent',
    border: '3px solid',
    borderColor: '#DDDDDD',
    boxShadow: 'none',
    boxSizing: 'border-box',
    height: '1rem',
    margin: 0,
    width: '1rem',
  },
  hideOppositeContent: {
    display: 'none',
  },
});

interface TimelineItemProps extends MuiTimelineItemProps {
  showConnector?: boolean;
}

export const TimelineItem = ({
  children,
  showConnector = true,
  ...props
}: TimelineItemProps) => {
  const classes = useStyles();

  return (
    <MuiTimelineItem {...props}>
      <TimelineOppositeContent
        classes={{ root: classes.hideOppositeContent }}
      />
      <TimelineSeparator>
        <TimelineDot classes={{ root: classes.dot }} />
        {showConnector && (
          <TimelineConnector classes={{ root: classes.connector }} />
        )}
      </TimelineSeparator>
      <TimelineContent classes={{ root: classes.content }}>
        {children}
      </TimelineContent>
    </MuiTimelineItem>
  );
};
