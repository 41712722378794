import {
  AnyAction,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { JSONSchema4Type } from 'json-schema';
import { omit, remove } from 'lodash';

import {
  downloadExcel,
  downloadMinutaById,
  downloadShipmentTagByType,
  findShipment,
  findShipmentCost,
  findShipmentDetails,
  findShipmentDocuments,
  findShipmentsShippingEvents,
  findShipmentVolumes,
  forceResendMinutaById,
  listShipments,
  logStatusUpdateError,
  removeShipment,
  restoreShipment,
  restoreShipmentBatch,
  ShipmentsFilter,
  shipmentTagStatus,
  updateShipment,
  updateShipmentBatch,
} from './shipmentsAPI';
import { fileHandler } from '../../common/helpers/file-handler.helper';
import { RootState } from '../../app/store';
import { setPrintedTags } from '../../common/services/local-storage.service';
import { ShipmentUpdateData } from '../../common/data-types/shipment-update-data';
import { ShippingEventData } from '../../common/data-types/shipping-event-data';
import EntityData from '../../common/data-types/entity-data';
import PrintableDocumentType from '../../common/enums/printable-document-type.enum';
import RequestData from '../../common/data-types/request-data';
import RequestStatus from '../../common/enums/request-status.enum';
import ShipmentData from '../../common/data-types/shipment-data';
import ShipmentInformationsData from '../../common/data-types/shipment-informations-data';
import ShipmentListData from '../../common/data-types/shipment-list-data';
import ShipmentListFilterData from '../../common/data-types/shipment-list-filter-data';
import ShipmentModality from '../../common/enums/shipment-modality.enum';
import TagFormat from '../../common/enums/tag-format-type.enum';
import VolumeData from '../../common/data-types/volume-data';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  const startsWith = action.type.startsWith('shipments');
  const endsWith = action.type.endsWith('/fulfilled');

  return startsWith && endsWith;
};

const isPendingAction = (action: AnyAction): action is PendingAction => {
  const startsWith = action.type.startsWith('shipments');
  const endsWith = action.type.endsWith('/pending');

  return startsWith && endsWith;
};

const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  const startsWith = action.type.startsWith('shipments');
  const endsWith = action.type.endsWith('/rejected');

  return startsWith && endsWith;
};

// #region Typings
interface ResetBatchUpdateStatusActionParams {
  requestId: string;
}
// #endregion Typings

// #region Thunks
interface ShipmentDownloadTagsThunkByTypeParams {
  id: ShipmentData['id'];
  type: TagFormat;
}

const DOWNLOAD_SHIPMENT_TAGS_BY_TYPE_THUNK =
  'shipments/downloadShipmentTagByType';

export const downloadShipmentTagByTypeThunk = createAsyncThunk(
  DOWNLOAD_SHIPMENT_TAGS_BY_TYPE_THUNK,
  async ({ id, type }: ShipmentDownloadTagsThunkByTypeParams) => {
    const response = await downloadShipmentTagByType({ id, type });

    return response;
  }
);

interface MinutaDownloadById {
  id: ShipmentData['id'];
}

const DOWNLOAD_MINUTA_BY_ID_THUNK = 'shipments/downloadMinutaById';

export const downloadMinutaByIDThunk = createAsyncThunk(
  DOWNLOAD_MINUTA_BY_ID_THUNK,
  async ({ id }: MinutaDownloadById) => {
    const response = await downloadMinutaById({ id });
    return response;
  }
);

interface ForceResendMinutaById {
  id: ShipmentData['id'];
  userOrganizationId: number;
}

const FORCE_RESEND_MINUTA_BY_ID_THUNK = 'shipments/forceResendMinutaById';

export const forceResendMinutaByIDThunk = createAsyncThunk(
  FORCE_RESEND_MINUTA_BY_ID_THUNK,
  async ({ id, userOrganizationId }: ForceResendMinutaById) => {
    const response = await forceResendMinutaById({ id, userOrganizationId });
    return response;
  }
);

interface ExcelDownloadById {
  filters: ShipmentsFilter;
}

const DOWNLOAD_EXCEL = 'shipments/downloadExcel';

export const downloadExcelThunk = createAsyncThunk(
  DOWNLOAD_EXCEL,
  async ({ filters }: ExcelDownloadById) => {
    const response = await downloadExcel({ filters });

    const newBlob = new Blob([response.body]);

    const blobUrl = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `report.xlsx`);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);

    window.URL.revokeObjectURL(blobUrl);

    return response.body;
  }
);

export interface ShipmentListThunkParams {
  filter?: ShipmentsFilter;
  first?: number;
  offset?: number;
  order?: string;
}

const LIST_SHIPMENTS_THUNK = 'shipments/listShipments';

export const listShipmentsThunk = createAsyncThunk(
  LIST_SHIPMENTS_THUNK,
  async ({ filter, first, offset, order }: ShipmentListThunkParams) => {
    const shipments = await listShipments({ filter, first, offset, order });

    return { shipments, update: !offset };
  }
);

interface ShipmentListShippingEventsThunkParams {
  shipmentId: number;
}

const LIST_SHIPMENTS_SHIPPING_EVENTS_THUNK =
  'shipments/findShipmentsShippingEvents';

export const findShipmentsShippingEventsThunk = createAsyncThunk(
  LIST_SHIPMENTS_SHIPPING_EVENTS_THUNK,
  async ({ shipmentId }: ShipmentListShippingEventsThunkParams) => {
    const response = await findShipmentsShippingEvents({ shipmentId });

    return response;
  }
);

interface ShipmentUpdateThunkParams {
  data: Partial<ShipmentUpdateData>;
  id: ShipmentData['id'];
}
const SHIPMENT_UPDATE_THUNK = 'shipments/updateThunk';
export const updateThunk = createAsyncThunk(
  SHIPMENT_UPDATE_THUNK,
  async ({ id, data }: ShipmentUpdateThunkParams) => {
    await updateShipment({ id, data });
  }
);

interface ShipmentBatchUpdateThunkParams {
  shipment: {
    authorizedReceiver?: EntityData;
    declaredValue?: number;
    deliveryModality?: ShipmentModality;
    destinationShipperEntity?: EntityData;
    originShipperEntity: EntityData;
    recipient?: EntityData;
    routeLegs?: {
      destinationEntity?: EntityData;
      originEntity?: EntityData;
    }[];
    receiptModality: ShipmentModality;
    shipmentIds: number[];
    totalWeight?: number;
    volumes?: VolumeData[];
  };
}

const SHIPMENT_UPDATE_BATCH_THUNK = 'shipments/updateBatchShipment';

export const updateBatchShipmentThunk = createAsyncThunk(
  SHIPMENT_UPDATE_BATCH_THUNK,
  async ({ shipment }: ShipmentBatchUpdateThunkParams) => {
    await updateShipmentBatch({ shipment });
  }
);

interface ShipmentLogStatusUpdateErrorThunkParams {
  context: JSONSchema4Type;
  message: string;
}

const SHIPMENT_LOG_STATUS_UPDATE_ERROR_THUNK = 'shipments/logStatusUpdateError';

export const logStatusUpdateErrorThunk = createAsyncThunk(
  SHIPMENT_LOG_STATUS_UPDATE_ERROR_THUNK,
  async ({ context, message }: ShipmentLogStatusUpdateErrorThunkParams) => {
    await logStatusUpdateError({
      context,
      message,
    });
  }
);

interface ShipmentListTagStatusThunkParams {
  id: ShipmentData['id'];
}

const SHIPMENT_LIST_TAG_STATUS_THUNK = 'shipments/shipmentListTagStatus';

export const shipmentListTagStatusThunk = createAsyncThunk(
  SHIPMENT_LIST_TAG_STATUS_THUNK,
  async ({ id }: ShipmentListTagStatusThunkParams) => {
    const tags = await shipmentTagStatus({
      id,
    });
    return tags;
  }
);

interface ShipmentRestoreThunkParams {
  id: ShipmentData['id'];
}

const RESTORE_SHIPMENT_THUNK = 'shipments/shipmentRestoreThunk';

export const shipmentRestoreThunk = createAsyncThunk(
  RESTORE_SHIPMENT_THUNK,
  async ({ id }: ShipmentRestoreThunkParams) => {
    const response = await restoreShipment({
      id,
    });
    return response;
  }
);

interface ShipmentRestoreBatchThunkParams {
  ids: ShipmentData['id'][];
}

const RESTORE_SHIPMENT_BATCH_THUNK = 'shipments/shipmentRestoreBatchThunk';

export const shipmentRestoreBatchThunk = createAsyncThunk(
  RESTORE_SHIPMENT_BATCH_THUNK,
  async ({ ids }: ShipmentRestoreBatchThunkParams) => {
    const response = await restoreShipmentBatch({
      ids,
    });
    return response;
  }
);

interface FindShipmentThunkParams {
  id?: ShipmentData['id'];
  trackingCode?: ShipmentData['trackingCode'];
}

const FIND_SHIPMENT_THUNK = 'shipments/findShipmentThunk';

export const findShipmentThunk = createAsyncThunk(
  FIND_SHIPMENT_THUNK,
  async ({ id, trackingCode }: FindShipmentThunkParams) => {
    const response = await findShipment({
      id,
      trackingCode,
    });
    return response;
  }
);

interface ShipmentRemoveThunkParams {
  id: ShipmentData['id'];
}
const REMOVE_SHIPMENT_THUNK = 'shipments/removeShipmentThunk';
export const removeShipmentThunk = createAsyncThunk(
  REMOVE_SHIPMENT_THUNK,
  async ({ id }: ShipmentRemoveThunkParams) => {
    await removeShipment(id);

    return { id };
  }
);

interface ShipmentFindDetailsThunkParams {
  id?: ShipmentData['id'];
  trackingCode?: ShipmentData['trackingCode'];
}
const SHIPMENT_FIND_DETAILS_THUNK = 'shipments/findShipmentDetails';
export const findShipmentDetailsThunk = createAsyncThunk(
  SHIPMENT_FIND_DETAILS_THUNK,
  async ({ id, trackingCode }: ShipmentFindDetailsThunkParams) => {
    const response = await findShipmentDetails({ id, trackingCode });
    return response;
  }
);

interface ShipmentFindVolumeDetailsThunkParams {
  id?: ShipmentData['id'];
  trackingCode?: ShipmentData['trackingCode'];
}
const SHIPMENT_FIND_VOLUME_DETAILS_THUNK =
  'shipments/findShipmentVolumeDetails';
export const findShipmentVolumeDetailsThunk = createAsyncThunk(
  SHIPMENT_FIND_VOLUME_DETAILS_THUNK,
  async ({ id, trackingCode }: ShipmentFindVolumeDetailsThunkParams) => {
    const response = await findShipmentVolumes({ id, trackingCode });
    return response;
  }
);

interface ShipmentFindDocumentsThunkParams {
  id?: ShipmentData['id'];
  trackingCode?: ShipmentData['trackingCode'];
}
const SHIPMENT_FIND_DOCUMENTS_THUNK = 'shipments/findShipmentDocuments';
export const findShipmentDocumentsThunk = createAsyncThunk(
  SHIPMENT_FIND_DOCUMENTS_THUNK,
  async ({ id, trackingCode }: ShipmentFindDocumentsThunkParams) => {
    const response = await findShipmentDocuments({ id, trackingCode });
    return response;
  }
);

interface ShipmentFindCostThunkParams {
  id?: ShipmentData['id'];
  trackingCode?: ShipmentData['trackingCode'];
}
const SHIPMENT_FIND_COST_THUNK = 'shipments/findShipmentCost';
export const findShipmentCostThunk = createAsyncThunk(
  SHIPMENT_FIND_COST_THUNK,
  async ({ id, trackingCode }: ShipmentFindCostThunkParams) => {
    const response = await findShipmentCost({ id, trackingCode });
    return response;
  }
);

const SHIPMENT_UPDATE_IN_PLACE_THUNK = 'shipments/updateInPlace';
export const updateShipmentInPlaceThunk = createAsyncThunk(
  SHIPMENT_UPDATE_IN_PLACE_THUNK,
  async (id: number) => {
    const response = await findShipment({ id });

    return response;
  }
);
// #endregion Thunks

// #region Reducers
const handleShipmentInformationsReducer = (
  state: ShipmentsState,
  action: AnyAction
) => {
  const { id } = action.meta.arg;

  state.shipmentsInformationsById[id] = {
    ...state.shipmentsInformationsById[id],
    ...action.payload,
  };
};
// #endregion Reducers

// #region Slice
export interface ShipmentsState {
  error: Record<string, any> | null;
  filters: ShipmentListFilterData;
  page: number;
  requestIds: RequestData['id'][];
  requestsById: Record<RequestData['id'], RequestData>;
  shipment: ShipmentData | null;
  shipments: ShipmentListData[];
  shipmentShippingEvents: ShippingEventData[];
  shipmentsInformationsById: Record<
    ShipmentListData['id'],
    ShipmentInformationsData
  >;
  tagError: Record<
    ShipmentData['id'],
    {
      hasError: boolean;
      error: Record<string, any> | null;
    }
  >;
  tagStatusById: Record<ShipmentData['id'], boolean>;
  total: number;
}

const initialState: ShipmentsState = {
  error: null,
  filters: {
    recipientIds: [],
    senderIds: [],
  },
  page: 1,
  requestIds: [],
  requestsById: {},
  shipment: null,
  shipments: [],
  shipmentShippingEvents: [],
  shipmentsInformationsById: {},
  tagError: {},
  tagStatusById: {},
  total: 0,
};

const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    clearShipment: (state) => {
      state.shipment = null;
    },
    clearShipments: (state) => {
      state.shipments = [];
    },
    resetThunkStatus: (
      state,
      action: PayloadAction<ResetBatchUpdateStatusActionParams>
    ) => {
      const request = state.requestsById[action.payload.requestId];

      if (request) {
        Object.assign(request, {
          error: undefined,
          status: RequestStatus.Idle,
          type: action.type,
        });
      }
    },
    updateShipmentListFiltersAction: (
      state,
      action: PayloadAction<ShipmentListFilterData>
    ) => {
      state.shipments = [];
      state.filters = { ...state.filters, ...action.payload };
    },
    updateShipmentListPageAction: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    cleanRequests: (state) => {
      state.requestIds = [];
      state.requestsById = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadShipmentTagByTypeThunk.fulfilled, (state, action) => {
        const { id } = action.meta.arg;

        state.error = null;

        setPrintedTags(id, PrintableDocumentType.Etiqueta);
        fileHandler({ file: action.payload.body, showPrintOptions: true });
      })
      .addCase(downloadMinutaByIDThunk.fulfilled, (state, action) => {
        const { id } = action.meta.arg;

        state.error = null;

        setPrintedTags(id, PrintableDocumentType.Minuta);
        fileHandler({ file: action.payload.body, showPrintOptions: true });
      })
      .addCase(findShipmentThunk.fulfilled, (state, action) => {
        state.shipment = action.payload;
      })
      .addCase(listShipmentsThunk.fulfilled, (state, action) => {
        const {
          shipments: { shipments, total },
        } = action.payload;

        state.shipments = [...shipments];
        state.shipmentsInformationsById = {};
        state.total = total;
      })
      .addCase(findShipmentsShippingEventsThunk.fulfilled, (state, action) => {
        state.shipmentShippingEvents = action.payload;
      })
      .addCase(shipmentRestoreThunk.fulfilled, (state, action) => {
        const shipmentId = action.meta.arg.id;

        remove(state.shipments, (s) => s.id === shipmentId);

        state.total -= 1;
      })
      .addCase(shipmentRestoreBatchThunk.fulfilled, (state, action) => {
        const shipmentIds = action.meta.arg.ids;

        remove(state.shipments, (s) => shipmentIds.includes(s.id));

        state.total -= shipmentIds.length;
      })
      .addCase(shipmentListTagStatusThunk.fulfilled, (state, action) => {
        const { shipment } = action.payload;
        const shipmentId = action.meta.arg.id;
        const hasVolumes = shipment?.volumes?.length > 0;

        let isTagValid = true;

        state.error = omit(state.error, shipmentId);

        if (hasVolumes) {
          shipment.volumes.forEach(({ carrierLabelFile }) => {
            if (!carrierLabelFile?.filePath) {
              isTagValid = false;
            }
          });
        }

        state.tagStatusById[shipmentId] = isTagValid;

        if (shipment?.syncedAt) {
          const shipmentData = state.shipments.find((s) => s.id === shipmentId);

          if (!shipmentData) {
            return;
          }

          Object.assign(shipmentData, {
            ...shipmentData,
            syncedAt: shipment?.syncedAt,
          });
        }
      })
      .addCase(shipmentListTagStatusThunk.rejected, (state, action) => {
        state.tagError[action.meta.arg.id] = {
          hasError: !!action.error,
          error: action.error,
        };
      })
      .addCase(findShipmentDetailsThunk.fulfilled, (state, action) => {
        handleShipmentInformationsReducer(state, action);
      })
      .addCase(findShipmentVolumeDetailsThunk.fulfilled, (state, action) => {
        handleShipmentInformationsReducer(state, action);
      })
      .addCase(findShipmentDocumentsThunk.fulfilled, (state, action) => {
        handleShipmentInformationsReducer(state, action);
      })
      .addCase(findShipmentCostThunk.fulfilled, (state, action) => {
        handleShipmentInformationsReducer(state, action);
      })
      .addCase(updateShipmentInPlaceThunk.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const { arg } = meta;

        const shipmentIndex = state.shipments.findIndex((s) => s.id === arg);

        if (shipmentIndex !== -1) {
          state.shipments[shipmentIndex] = {
            ...state.shipments[shipmentIndex],
            ...payload,
          };
        }
      })
      .addMatcher(isFulfilledAction, (state, action: AnyAction) => {
        const { meta } = action;
        const request = state.requestsById[meta.requestId];

        if (!request) {
          return;
        }

        Object.assign(request, {
          error: undefined,
          id: meta.requestId,
          status: RequestStatus.Succeeded,
          type: action.type,
        });
      })
      .addMatcher(isPendingAction, (state, action: AnyAction) => {
        const { meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Loading,
          type,
        };

        state.requestsById[requestId] = request;
      })
      .addMatcher(isRejectedAction, (state, action: AnyAction) => {
        const { error, meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Failed,
          type,
          error,
        };

        state.requestsById[requestId] = request;
      });
  },
});
// #endregion Slice

// #region Selectors
// #region Private Selectors
const selectShipmentsRequests = (rootState: RootState): RequestData[] => {
  const state = selectShipmentsState(rootState);

  return state.requestIds.map((id) => state.requestsById[id]);
};

const selectShipmentsIsFulfilledByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const fulfilledType = [type, 'fulfilled'].join('/');
  const request = selectShipmentsRequestByType(rootState, fulfilledType);

  return request ? request.status === RequestStatus.Succeeded : false;
};

const selectShipmentsIsRequestingByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const pendingType = [type, 'pending'].join('/');
  const request = selectShipmentsRequestByType(rootState, pendingType);

  return request ? request.status === RequestStatus.Loading : false;
};

const selectShipmentsErrorByType = (
  rootState: RootState,
  type: RequestData['type']
): Record<string, any> | null => {
  const failedType = [type, 'rejected'].join('/');
  const request = selectShipmentsRequestByType(rootState, failedType);

  return request?.error || null;
};

const selectShipmentsRequestByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData => {
  const requests = selectShipmentsRequestsByType(rootState, type);

  return requests.reverse()[0];
};

const selectShipmentsRequestsByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData[] => {
  const requests = selectShipmentsRequests(rootState);

  return requests.filter((r) => r.type === type);
};

const selectShipmentsState = (state: RootState): ShipmentsState =>
  state.shipments;
// #endregion Private Selectors

// #region Public Selectors
export const selectShipmentFetchError = (
  state: RootState
): ShipmentsState['error'] => {
  const shipments = selectShipmentsState(state);

  return shipments.error;
};

export const selectShipmentFilters = (
  rootState: RootState
): ShipmentsState['filters'] => {
  const state = selectShipmentsState(rootState);

  return state.filters;
};

export const selectShipmentList = (
  rootState: RootState
): ShipmentsState['shipments'] => {
  const { shipments } = rootState.shipments;

  return shipments;
};

export const selectShipmentTotalItems = (
  rootState: RootState
): ShipmentsState['total'] => {
  const { total } = rootState.shipments;

  return total;
};

export const selectShipmentsListIsRequesting = (
  rootState: RootState
): boolean => {
  return selectShipmentsIsRequestingByType(rootState, LIST_SHIPMENTS_THUNK);
};

export const selectShipmentOccurrences = (
  rootState: RootState
): ShipmentsState['shipmentShippingEvents'] => {
  const shipments = selectShipmentsState(rootState);
  return shipments.shipmentShippingEvents;
};

export const selectShipmentOccurrencesIsRequesting = (
  rootState: RootState
): boolean => {
  return selectShipmentsIsRequestingByType(
    rootState,
    LIST_SHIPMENTS_SHIPPING_EVENTS_THUNK
  );
};

export const selectShipment = (
  rootState: RootState
): ShipmentsState['shipment'] => {
  const shipments = selectShipmentsState(rootState);

  return shipments.shipment;
};

export const selectShipmentsInformationsById = (
  rootState: RootState,
  id: number
): ShipmentInformationsData => {
  const shipments = selectShipmentsState(rootState);

  return shipments.shipmentsInformationsById[id];
};

export const selectShipmentListPage = (
  rootState: RootState
): ShipmentsState['page'] => {
  const shipments = selectShipmentsState(rootState);

  return shipments.page;
};

export const selectShipmentTagError = (
  rootState: RootState
): ShipmentsState['tagError'] => {
  const shipments = selectShipmentsState(rootState);

  return shipments.tagError;
};

export const selectTagStatus = (
  rootState: RootState
): ShipmentsState['tagStatusById'] => {
  const shipments = selectShipmentsState(rootState);

  return shipments.tagStatusById;
};

export const selectUpdateModalityisFulfilled = (rootState: RootState) => {
  return selectShipmentsIsFulfilledByType(
    rootState,
    SHIPMENT_UPDATE_BATCH_THUNK
  );
};

export const selectRestoreShipmentBatchisFulfilled = (rootState: RootState) => {
  return selectShipmentsIsFulfilledByType(
    rootState,
    RESTORE_SHIPMENT_BATCH_THUNK
  );
};

export const selectShipmentIsRequesting = (rootState: RootState) => {
  return selectShipmentsIsRequestingByType(rootState, FIND_SHIPMENT_THUNK);
};

export const selectShipmentIsRequestingUpdate = (rootState: RootState) => {
  return selectShipmentsIsRequestingByType(rootState, SHIPMENT_UPDATE_THUNK);
};

export const selectShipmentIsFulfilledUpdate = (rootState: RootState) => {
  return selectShipmentsIsFulfilledByType(rootState, SHIPMENT_UPDATE_THUNK);
};

export const selectShipmentIsRequestingRemove = (rootState: RootState) => {
  return selectShipmentsIsRequestingByType(rootState, REMOVE_SHIPMENT_THUNK);
};

export const selectShipmentIsFulfilledRemove = (rootState: RootState) => {
  return selectShipmentsIsFulfilledByType(rootState, REMOVE_SHIPMENT_THUNK);
};

export const selectShipmentIsFulfilledDownloadTagByType = (
  rootState: RootState
) => {
  return selectShipmentsIsFulfilledByType(
    rootState,
    DOWNLOAD_SHIPMENT_TAGS_BY_TYPE_THUNK
  );
};

export const selectShipmentIsRequestingDetails = (rootState: RootState) => {
  return selectShipmentsIsRequestingByType(
    rootState,
    SHIPMENT_FIND_DETAILS_THUNK
  );
};

export const selectShipmentIsRequestingVolumes = (rootState: RootState) => {
  return selectShipmentsIsRequestingByType(
    rootState,
    SHIPMENT_FIND_VOLUME_DETAILS_THUNK
  );
};

export const selectShipmentIsRequestingDocuments = (rootState: RootState) => {
  return selectShipmentsIsRequestingByType(
    rootState,
    SHIPMENT_FIND_DOCUMENTS_THUNK
  );
};

export const selectShipmentIsRequestingCost = (rootState: RootState) => {
  return selectShipmentsIsRequestingByType(rootState, SHIPMENT_FIND_COST_THUNK);
};

export const selectShipmentIsRequestingDownloadTagByType = (
  rootState: RootState
) => {
  return selectShipmentsIsRequestingByType(
    rootState,
    DOWNLOAD_SHIPMENT_TAGS_BY_TYPE_THUNK
  );
};

export const selectShipmentErrorDownloadTagByType = (rootState: RootState) => {
  return selectShipmentsErrorByType(
    rootState,
    DOWNLOAD_SHIPMENT_TAGS_BY_TYPE_THUNK
  );
};

export const selectIsFulfilledDownloadMinutaById = (rootState: RootState) => {
  return selectShipmentsIsFulfilledByType(
    rootState,
    DOWNLOAD_MINUTA_BY_ID_THUNK
  );
};

export const selectIsRequestingDownloadMinutaById = (rootState: RootState) => {
  return selectShipmentsIsRequestingByType(
    rootState,
    DOWNLOAD_MINUTA_BY_ID_THUNK
  );
};

export const selectErrorDownloadMinutaById = (rootState: RootState) => {
  return selectShipmentsErrorByType(rootState, DOWNLOAD_MINUTA_BY_ID_THUNK);
};

export const selectIsFulfilledDownloadExcel = (rootState: RootState) => {
  return selectShipmentsIsFulfilledByType(rootState, DOWNLOAD_EXCEL);
};

export const selectIsRequestingDownloadExcel = (rootState: RootState) => {
  return selectShipmentsIsRequestingByType(rootState, DOWNLOAD_EXCEL);
};

export const selectErrorDownloadExcel = (rootState: RootState) => {
  return selectShipmentsErrorByType(rootState, DOWNLOAD_EXCEL);
};

export const forceResendMinutaByIdIsRequesting = (rootState: RootState) => {
  return selectShipmentsIsRequestingByType(
    rootState,
    FORCE_RESEND_MINUTA_BY_ID_THUNK
  );
};

export const forceResendMinutaByIdError = (rootState: RootState) => {
  return selectShipmentsErrorByType(rootState, FORCE_RESEND_MINUTA_BY_ID_THUNK);
};

export const forceResendMinutaByIdIsFulfilled = (rootState: RootState) => {
  return selectShipmentsIsFulfilledByType(
    rootState,
    FORCE_RESEND_MINUTA_BY_ID_THUNK
  );
};

// #endregion Public Selectors
// #endregion Selectors

export const {
  cleanRequests,
  clearShipment,
  clearShipments,
  resetThunkStatus,
  updateShipmentListFiltersAction,
  updateShipmentListPageAction,
} = shipmentsSlice.actions;

export const shipmentsReducer = shipmentsSlice.reducer;
