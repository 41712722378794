import React, { useMemo, useState } from 'react';

import {
  makeStyles,
  Theme,
  Box,
  Grid,
  // IconButton,
  Typography,
} from '@material-ui/core';
// import Edit from '@material-ui/icons/Edit';

import { decimalNumber } from '../helpers/mask.helper';
// import { selectSessionUserType } from '../../features/Session/sessionSlice';
// import { ShipmentStatus } from '../enums/shipment-status.enum';
// import { useAppSelector } from '../../app/hooks';
import ShipmentInformationsData from '../data-types/shipment-informations-data';
import Skeleton, { SkeletonWidthSize } from './Skeleton';
import VolumeData from '../data-types/volume-data';
import VolumeInformationData from '../data-types/volume-information-data';
import VolumeList from './VolumeList/VolumeList';
import VolumeUpdateModal from './VolumeUpdateDrawer';
// import OrganizationType from '../enums/organization-type.enum';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    marginBottom: 30,
  },
  label: {
    fontSize: 12,
    fontWeight: 300,
  },
  value: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface ShipmentVolumesProps {
  // hasCTE: () => boolean;
  isLoading?: boolean;
  onFindVolumes: () => void;
  shipment: ShipmentInformationsData;
}

const ShipmentVolumes = ({
  // hasCTE,
  isLoading,
  onFindVolumes,
  shipment,
}: ShipmentVolumesProps) => {
  const classes = useStyles();

  // const userType = useAppSelector(selectSessionUserType);

  const [isVolumesEditModalOpen, setIsVolumesEditModalOpen] = useState<boolean>(
    false
  );

  // const canUpdateVolumes = useMemo(
  //   () =>
  //     !hasCTE() &&
  //     !isLoading &&
  //     shipment.status === ShipmentStatus.Ready &&
  //     [OrganizationType.Shipper, OrganizationType.Admin].includes(userType),
  //   [userType, isLoading, shipment, hasCTE]
  // );

  const volumes = useMemo<VolumeData[]>(() => {
    if (
      shipment.volumesInformations &&
      shipment.volumesInformations.length > 0
    ) {
      return shipment.volumesInformations.flatMap<VolumeData>(
        (item: VolumeInformationData) => {
          return item.codes.reduce((acc: VolumeData[], code: string) => {
            const volumeByCode =
              shipment.volumes &&
              shipment.volumes.length > 0 &&
              shipment.volumes.find((volume) => volume.code === code);

            if (!volumeByCode) {
              return acc;
            }
            return [
              ...acc,
              {
                ...volumeByCode,
                cubageWeight: item.cubageWeight,
                height: item.height,
                length: item.length,
                weight: item.weight,
                width: item.width,
              },
            ];
          }, []);
        }
      );
    }
    return [];
  }, [shipment.volumes, shipment.volumesInformations]);

  // const onOpenVolumesEditModal = () => {
  //   setIsVolumesEditModalOpen(true);
  // };

  const onCloseVolumesEditModal = () => {
    setIsVolumesEditModalOpen(false);
  };

  return (
    <Box>
      <Grid className={classes.gridContainer} container>
        <Grid item xs={2}>
          <Typography className={classes.label}>Quantidade</Typography>
          <Typography
            className={classes.value}
            color="textPrimary"
            component="strong"
          >
            {isLoading ? (
              <Skeleton width={SkeletonWidthSize.Medium} />
            ) : shipment.volumesQuantity ? (
              `
              ${shipment.volumesQuantity} volume${
                shipment.volumesQuantity > 1 ? '(s)' : ''
              }`
            ) : (
              '---'
            )}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.label}>Peso</Typography>
          <Typography
            className={classes.value}
            color="textPrimary"
            component="strong"
          >
            {isLoading ? (
              <Skeleton width={SkeletonWidthSize.Medium} />
            ) : shipment.totalWeight ? (
              `${decimalNumber(shipment.totalWeight)} Kg`
            ) : (
              '---'
            )}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.label}>Peso cubado</Typography>
          <Typography
            className={classes.value}
            color="textPrimary"
            component="strong"
          >
            {isLoading ? (
              <Skeleton width={SkeletonWidthSize.Medium} />
            ) : shipment.cubageWeight ? (
              `${decimalNumber(shipment.cubageWeight)} Kg`
            ) : (
              '---'
            )}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.label}>Valor declarado</Typography>
          <Typography
            className={classes.value}
            color="textPrimary"
            component="strong"
          >
            {isLoading ? (
              <Skeleton width={SkeletonWidthSize.Medium} />
            ) : shipment.declaredValue ? (
              `R$ ${decimalNumber(shipment.declaredValue)}`
            ) : (
              '---'
            )}
          </Typography>
        </Grid>
        {/* {canUpdateVolumes && (
          <Grid item xs={1}>
            <IconButton onClick={onOpenVolumesEditModal}>
              <Edit />
            </IconButton>
          </Grid>
        )} */}
      </Grid>
      <VolumeList volumes={volumes} />
      {isVolumesEditModalOpen && (
        <VolumeUpdateModal
          open={isVolumesEditModalOpen}
          shipment={shipment}
          onClose={onCloseVolumesEditModal}
          onSucceed={onFindVolumes}
        />
      )}
    </Box>
  );
};

export default ShipmentVolumes;
