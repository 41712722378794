import React from 'react';

import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
} from '@material-ui/core';
import { Control, useController } from 'react-hook-form';

export interface UncontrolledCheckboxProps extends MuiCheckboxProps {
  label?: string;
}

export const UncontrolledCheckbox = ({
  label,
  ...rest
}: UncontrolledCheckboxProps) => (
  <FormControlLabel control={<MuiCheckbox {...rest} />} label={label} />
);

export interface CheckboxProps extends UncontrolledCheckboxProps {
  control?: Control<any>;
  name: string;
}

const Checkbox = ({ control, label, name, ...rest }: CheckboxProps) => {
  const {
    field: { ref, value, ...inputProps },
  } = useController({
    name,
    control,
  });

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          checked={value || false}
          ref={ref}
          {...inputProps}
          {...rest}
        />
      }
      label={label}
    />
  );
};

export default Checkbox;
