import { HttpClient } from '../common/clients/http-client/http.client';
import {
  REACT_APP_BRUDAM_SYNCER_HOST,
  REACT_APP_GANDALF_HOST,
  REACT_APP_ZORDON_HOST,
} from '../.env';
import BrudamSyncerClient from '../common/clients/brudam-syncer.client';
import GandalfClient from '../common/clients/gandalf.client';
import GraphqlClient from '../common/clients/graphql/graphql.client';
import GraphqlHttpClient from '../common/clients/graphql/graphql-http.client.ts';
import HttpClientFetchAdapter from '../common/clients/http-client/http-client-fetch.adapter';
import ZordonClient from '../common/clients/zordon.client';

// #region Clients
let httpClient: HttpClient;
export const getHttpClient = (): HttpClient => {
  if (httpClient) {
    return httpClient;
  }

  httpClient = new HttpClient({
    adapter: new HttpClientFetchAdapter(),
  });

  return httpClient;
};

let graphqlHttpClient: GraphqlHttpClient;
export const getGraphqlHttpClient = (): GraphqlHttpClient => {
  if (graphqlHttpClient) {
    return graphqlHttpClient;
  }

  graphqlHttpClient = new GraphqlHttpClient();

  return graphqlHttpClient;
};

let gandalfClient: GandalfClient;
export const getGandalfClient = (): GandalfClient => {
  if (gandalfClient) {
    return gandalfClient;
  }

  gandalfClient = new GandalfClient({
    host: REACT_APP_GANDALF_HOST,
    httpClient: getHttpClient(),
  });

  return gandalfClient;
};

let graphqlClient: GraphqlClient;
export const getGraphqlClient = (): GraphqlClient => {
  if (graphqlClient) {
    return graphqlClient;
  }

  graphqlClient = new GraphqlClient({
    host: REACT_APP_ZORDON_HOST,
    httpClient: getGraphqlHttpClient(),
  });

  return graphqlClient;
};

let zordonClient: ZordonClient;
export const getZordonClient = (): ZordonClient => {
  if (zordonClient) {
    return zordonClient;
  }

  zordonClient = new ZordonClient({
    host: REACT_APP_ZORDON_HOST,
    httpClient: getHttpClient(),
  });

  return zordonClient;
};

let brudamSyncerClient: BrudamSyncerClient;
export const getBrudamSyncerClient = (): BrudamSyncerClient => {
  if (brudamSyncerClient) {
    return brudamSyncerClient;
  }

  brudamSyncerClient = new BrudamSyncerClient({
    host: REACT_APP_BRUDAM_SYNCER_HOST,
    httpClient: getHttpClient(),
  });

  return brudamSyncerClient;
};
// #endregion Clients
