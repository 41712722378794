import React from 'react';

import { Box, makeStyles } from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

// import { selectSessionUserType } from '../../features/Session/sessionSlice';
import { Tabs, Tab } from './Tabs';
// import { useAppSelector } from '../../app/hooks';
// import OrganizationType from '../enums/organization-type.enum';
import PackageIcon from './PackageIcon';
import Spinner from './Spinner';
import UnderscoredCheckIcon from './UnderscoredCheckIcon';

const useStyles = makeStyles({
  tabsFlexContainer: {
    justifyContent: 'space-around',
  },
  tabsIndicator: {
    height: 3,
  },
  tabItemRoot: {
    minHeight: 0,
    minWidth: 0,
  },
  tabIcon: {
    marginRight: '0.5rem',
  },
  tabItemWrapper: {
    flexDirection: 'row',
    fontSize: '0.75rem',
    '& svg': {
      marginBottom: '0 !important',
    },
  },
});

export enum ShipmentTabsOptions {
  Details = 'details',
  Documents = 'documents',
  Occurrences = 'occurrences',
  ShipmentCost = 'shipmentCost',
  Volumes = 'volumes',
}

export const SHIPMENT_TAB_LABEL_MAP: Map<ShipmentTabsOptions, string> = new Map(
  [
    [ShipmentTabsOptions.Details, 'Detalhes'],
    [ShipmentTabsOptions.Documents, 'Documentos'],
    [ShipmentTabsOptions.Occurrences, 'Ocorrências'],
    [ShipmentTabsOptions.ShipmentCost, 'Frete'],
    [ShipmentTabsOptions.Volumes, 'Volumes'],
  ]
);

export interface ShipmentTabsProps {
  isDislabled: boolean;
  isVolumeLoading: boolean;
  onChange: (option: ShipmentTabsOptions) => void;
  value: ShipmentTabsOptions;
}

const ShipmentTabs = ({
  isDislabled,
  isVolumeLoading,
  onChange,
  value,
}: ShipmentTabsProps) => {
  const classes = useStyles();

  // const userType = useAppSelector(selectSessionUserType);

  const handleOnChange = (
    _: React.ChangeEvent,
    newValue: ShipmentTabsOptions
  ) => {
    onChange(newValue);
  };

  return (
    <Box bgcolor="#F1F1F1">
      <Tabs
        classes={{
          flexContainer: classes.tabsFlexContainer,
          indicator: classes.tabsIndicator,
        }}
        centered
        indicatorColor="primary"
        textColor="primary"
        value={value}
        onChange={handleOnChange}
      >
        <Tab
          classes={{
            root: classes.tabItemRoot,
            wrapper: classes.tabItemWrapper,
          }}
          icon={<AssignmentOutlinedIcon className={classes.tabIcon} />}
          label={SHIPMENT_TAB_LABEL_MAP.get(ShipmentTabsOptions.Details)}
          value={ShipmentTabsOptions.Details}
        />
        <Tab
          classes={{
            root: classes.tabItemRoot,
            wrapper: classes.tabItemWrapper,
          }}
          icon={
            isVolumeLoading ? (
              <Spinner size={20} className={classes.tabIcon} />
            ) : (
              <PackageIcon className={classes.tabIcon} />
            )
          }
          disabled={isVolumeLoading || isDislabled}
          label={SHIPMENT_TAB_LABEL_MAP.get(ShipmentTabsOptions.Volumes)}
          value={ShipmentTabsOptions.Volumes}
        />
        <Tab
          classes={{
            root: classes.tabItemRoot,
            wrapper: classes.tabItemWrapper,
          }}
          disabled={isVolumeLoading || isDislabled}
          icon={
            isVolumeLoading ? (
              <Spinner size={20} className={classes.tabIcon} />
            ) : (
              <DescriptionOutlinedIcon className={`${classes.tabIcon}`} />
            )
          }
          label={SHIPMENT_TAB_LABEL_MAP.get(ShipmentTabsOptions.Documents)}
          value={ShipmentTabsOptions.Documents}
        />
        <Tab
          classes={{
            root: classes.tabItemRoot,
            wrapper: classes.tabItemWrapper,
          }}
          disabled={isVolumeLoading || isDislabled}
          icon={
            isVolumeLoading ? (
              <Spinner size={20} className={classes.tabIcon} />
            ) : (
              <UnderscoredCheckIcon className={`${classes.tabIcon}`} />
            )
          }
          label={SHIPMENT_TAB_LABEL_MAP.get(ShipmentTabsOptions.Occurrences)}
          value={ShipmentTabsOptions.Occurrences}
        />
        {/* {userType !== OrganizationType.CartageAgent && (
          <Tab
            classes={{
              root: classes.tabItemRoot,
              wrapper: classes.tabItemWrapper,
            }}
            icon={<AttachMoneyIcon className={`${classes.tabIcon}`} />}
            label={SHIPMENT_TAB_LABEL_MAP.get(ShipmentTabsOptions.ShipmentCost)}
            value={ShipmentTabsOptions.ShipmentCost}
          />
        )} */}
      </Tabs>
    </Box>
  );
};

export default ShipmentTabs;
