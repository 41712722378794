import React, { ChangeEvent } from 'react';

import {
  FormControlLabel,
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
} from '@material-ui/core';
import { Control, useController } from 'react-hook-form';

export interface UncontrolledRadioProps extends MuiRadioProps {
  label?: React.ReactNode;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  value?: string | number;
  onChange?: (event: ChangeEvent<any>, checked: boolean) => void;
}

export const UncontrolledRadio = ({
  label,
  labelPlacement = 'end',
  value,
  onChange,
  ...rest
}: UncontrolledRadioProps) => {
  return (
    <FormControlLabel
      control={<MuiRadio {...rest} />}
      label={label}
      labelPlacement={labelPlacement}
      value={value}
      onChange={onChange && onChange}
    />
  );
};

export interface RadioProps extends Omit<UncontrolledRadioProps, 'onChange'> {
  control: Control<any>;
  defaultValue?: string | number;
  name: string;
}

const Radio = ({ control, defaultValue, name, ...rest }: RadioProps) => {
  const {
    field: { onChange, value },
  } = useController({
    control,
    defaultValue,
    name,
  });

  return <UncontrolledRadio value={value} onChange={onChange} {...rest} />;
};

export default Radio;
