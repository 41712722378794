import React, { useMemo } from 'react';

import {
  Redirect,
  Route as RouteRR,
  RouteProps as RoutePropsRR,
} from 'react-router';

import {
  selectSessionUserType,
  selectUserIsAdmin,
} from '../../features/Session/sessionSlice';
import { useAppSelector } from '../../app/hooks';
import OrganizationType from '../enums/organization-type.enum';

export type RouteProps = RoutePropsRR & {
  allowAdminOnly?: boolean;
  allowedOrganizationTypes?: OrganizationType[];
};

const RouteWithValidation = ({
  allowAdminOnly = false,
  allowedOrganizationTypes = [],
  children,
  ...rest
}: RouteProps) => {
  const userType = useAppSelector(selectSessionUserType);
  const userIsAdmin = useAppSelector(selectUserIsAdmin);

  const hasAccess = useMemo<boolean>(() => {
    return allowAdminOnly
      ? userIsAdmin
      : userType === OrganizationType.Admin ||
          allowedOrganizationTypes.includes(userType);
  }, [allowAdminOnly, allowedOrganizationTypes, userIsAdmin, userType]);

  return (
    <>
      {hasAccess ? (
        <RouteRR {...rest}>{children}</RouteRR>
      ) : (
        <Redirect to="/shipments" />
      )}
    </>
  );
};

export default RouteWithValidation;
