import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import {
  cleanState,
  selectUser,
  selectUsersErrorFind,
  selectUsersIsRequestingFind,
  selectUserIsFulfilledUpdate,
  userFindThunk,
  userUpdateThunk,
  cleanRequests,
  resetUserPasswordThunk,
  selectUserErrorUpdate,
  selectUserErrorResetPassword,
  selectUsersIsFulfilledResetPassword,
} from '../usersSlice';
import { ConfirmationModal } from '../../../common/components/Modals';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import BackButton from '../../../common/components/BackButton';
import ChangeStatusConfirmationDialog from '../../../common/components/ChangeStatusConfirmationDialog';
import EmptyListIndicator from '../../../common/components/EmptyListIndicator';
import GenericErrorDialog from '../../../common/components/GenericErrorDialog';
import Spinner from '../../../common/components/Spinner';
import TemporaryPasswordModal from '../../../common/components/Modals/TemporaryPasswordModal';
import UserEditData from '../../../common/data-types/user-edit-data';
import UserEditForm from './components/UserEditForm';
import UserStatus from '../../../common/enums/user-status-type.enum';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: '50rem',
  },
  icon: {
    fontSize: '6.5rem',
  },
  link: {
    color: theme.palette.info.main,
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
  },
}));

interface Props {
  title: string;
}

const UserEditPage = ({ title }: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [
    isResetPasswordDialogOpen,
    setIsResetPasswordDialogOpen,
  ] = useState<boolean>(false);
  const [
    isTemporaryPasswordDialogOpen,
    setIsTemporaryPasswordDialogOpen,
  ] = useState<boolean>(false);

  const findError = useAppSelector(selectUsersErrorFind);
  const isPasswordReseted = useAppSelector(selectUsersIsFulfilledResetPassword);
  const isRequesting = useAppSelector(selectUsersIsRequestingFind);
  const isUpdated = useAppSelector(selectUserIsFulfilledUpdate);
  const resetPasswordError = useAppSelector(selectUserErrorResetPassword);
  const updateError = useAppSelector(selectUserErrorUpdate);

  const user = useAppSelector(selectUser);

  const currentStatus = useMemo<UserStatus>(() => {
    return user?.active ? UserStatus.Active : UserStatus.Inactive;
  }, [user?.active]);

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleResetPasswordDialogClose = useCallback(() => {
    setIsResetPasswordDialogOpen(false);
  }, []);

  const handleSubmit = useCallback(
    (data: UserEditData) => {
      dispatch(userUpdateThunk({ user: data, userId: Number(id) }));
    },
    [dispatch, id]
  );

  const handleResetUserPassword = useCallback(() => {
    if (user?.id) {
      dispatch(resetUserPasswordThunk({ userId: user.id }));
    }
  }, [dispatch, user?.id]);

  const handleUpdateUserState = useCallback(
    (userId: number, status: boolean) => {
      dispatch(userUpdateThunk({ user: { active: status }, userId }));
    },
    [dispatch]
  );

  const handleChangeUserStatus = () => {
    if (user) {
      handleUpdateUserState(user.id, !user?.active);
    }
  };

  const handleOpenStatusChangeDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleOpenPaswordResetDialog = useCallback(() => {
    setIsResetPasswordDialogOpen(true);
  }, []);

  const handleCancelStatusChange = useCallback(() => {
    handleDialogClose();
  }, [handleDialogClose]);

  const handleCancelPaswordReset = useCallback(() => {
    handleResetPasswordDialogClose();
  }, [handleResetPasswordDialogClose]);

  const handleTemporaryPasswordDialogClose = useCallback(() => {
    setIsTemporaryPasswordDialogOpen(false);
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    dispatch(userFindThunk({ userId: Number(id) }));
  }, [dispatch, id]);

  useEffect(() => {
    if (isUpdated) {
      handleDialogClose();
      dispatch(cleanRequests());
      history.push('/users');
    }
  }, [dispatch, handleDialogClose, history, isUpdated]);

  useEffect(() => {
    if (updateError) {
      handleDialogClose();
    }
  }, [dispatch, updateError, handleDialogClose]);

  useEffect(() => {
    if (resetPasswordError) {
      handleResetPasswordDialogClose();
    }
  }, [dispatch, resetPasswordError, handleResetPasswordDialogClose]);

  useEffect(() => {
    if (isPasswordReseted) {
      setIsTemporaryPasswordDialogOpen(true);
      handleResetPasswordDialogClose();

      dispatch(cleanRequests());
    }
  }, [dispatch, isPasswordReseted, handleResetPasswordDialogClose]);

  useEffect(() => {
    return () => {
      dispatch(cleanState);
    };
  }, [dispatch]);

  return (
    <>
      <Container className={classes.container} disableGutters>
        <Box mb={3} mt={4}>
          <Grid alignItems="center" container direction="row" spacing={3}>
            <Grid item>
              <BackButton />
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
                color="primary"
                component="h2"
              >
                Editar Usuário
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {isRequesting ? (
          <Box>
            <Spinner />
          </Box>
        ) : user ? (
          <UserEditForm
            data={user}
            onResetPassword={handleOpenPaswordResetDialog}
            onSubmit={handleSubmit}
            onUpdateStatus={handleOpenStatusChangeDialog}
          />
        ) : (
          <EmptyListIndicator
            append={
              <Link className={classes.link} to="/users">
                Ver Usuários
              </Link>
            }
            message="Não foi possível encontrar o usuário"
            prepend={
              <AccountBoxIcon
                classes={{ root: classes.icon }}
                color="primary"
              />
            }
          />
        )}
      </Container>
      <ChangeStatusConfirmationDialog
        currentStatus={currentStatus}
        isOpen={isDialogOpen}
        onCancel={handleCancelStatusChange}
        onConfirm={handleChangeUserStatus}
      />
      <ConfirmationModal
        content="Deseja mesmo redefinir a senha desse usuário?"
        open={isResetPasswordDialogOpen}
        title="Redefinir Senha ?"
        onCancel={handleCancelPaswordReset}
        onConfirm={handleResetUserPassword}
      />
      {user?.temporaryPassword && (
        <TemporaryPasswordModal
          open={isTemporaryPasswordDialogOpen}
          password={user?.temporaryPassword}
          onClose={handleTemporaryPasswordDialogClose}
        />
      )}
      {(findError || updateError || resetPasswordError) && (
        <GenericErrorDialog
          error={findError || updateError || resetPasswordError}
        />
      )}
    </>
  );
};

export default UserEditPage;
