import React, { useCallback } from 'react';

import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CloseIcon from '@material-ui/icons/Close';

import { decimalMask } from '../../helpers/mask.helper';
import AlertCard from '../AlertCard';
import FileUpload from '../FileUpload';
import TextField from '../TextField';

const useStyles = makeStyles({
  blueText: {
    color: '#1976D2',
  },
  clickableText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  closedContainer: {
    border: '1px dashed #C2C3C9',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'white',
    },
  },
  label: {
    whiteSpace: 'nowrap',
  },
  textColor: {
    color: '#606771',
  },
  title: {
    fontSize: 18,
    marginLeft: 15,
  },
});

interface ContentStatementFormFieldsProps {
  className?: string;
  fileErrorMessage: string;
  namePrefix?: string;
  onChangeDocumentType?: () => void;
  onChangeFile: (file: File | null) => void;
  onRemoveFile: () => void;
}

const ContentStatementFormFields = ({
  className = '',
  fileErrorMessage,
  namePrefix,
  onChangeDocumentType,
  onChangeFile,
  onRemoveFile,
}: ContentStatementFormFieldsProps) => {
  const classes = useStyles();

  const {
    control,
    formState: { isSubmitted },
    setValue,
    watch,
  } = useFormContext();

  const [
    contentStatement,
    contentStatementHasError,
    hasContentStatement,
  ] = watch([
    'contentStatement',
    'contentStatementHasError',
    'hasContentStatement',
  ]);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const fieldName = namePrefix ? `${namePrefix}.value` : 'value';
      const value = decimalMask(e?.target.value);

      setValue(fieldName as `${string}.${string}`, value, {
        shouldValidate: isSubmitted,
      });
    },
    [isSubmitted, namePrefix, setValue]
  );

  const handleOnChangeFile = useCallback(
    (files: File[]) => {
      onChangeFile(files[0]);
    },
    [onChangeFile]
  );

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const fieldValue = event.target.value;
      const fieldName = event.target.name;

      if (fieldValue === '0,0' || fieldValue === '0,00') {
        setValue(fieldName as `${string}.${string}`, '', {
          shouldValidate: isSubmitted,
        });
      }
    },
    [isSubmitted, setValue]
  );

  return (
    <Grid
      bgcolor="#FFFFFF"
      border={1}
      borderColor="#C2C3C9"
      borderRadius={3}
      className={className}
      component={Box}
      container
      direction="column"
      maxWidth={800}
      pb={3}
      pt={3}
    >
      <Box display="flex" justifyContent="space-between" pb={2} pt={1} px={3}>
        <Box display="flex">
          <InsertDriveFileIcon className={classes.textColor} />
          <Typography className={`${classes.textColor} ${classes.title}`}>
            <strong>Adicionar Declaração de Conteúdo</strong>
          </Typography>
        </Box>
        {!!onChangeDocumentType && (
          <Box>
            <Typography
              className={`${classes.blueText} ${classes.clickableText}`}
              onClick={() => onChangeDocumentType()}
            >
              Possuo NFe desse envio
            </Typography>
          </Box>
        )}
      </Box>
      {hasContentStatement && !contentStatementHasError ? (
        <Grid item component={Box} pl={3} pr={3}>
          <AlertCard
            icon={<InsertDriveFileIcon fontSize="inherit" />}
            action={
              <IconButton
                aria-label="remover"
                color="inherit"
                size="small"
                onClick={() => onRemoveFile()}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {contentStatement?.name ||
              (hasContentStatement && 'Declaração de conteúdo já adicionada')}
          </AlertCard>
        </Grid>
      ) : (
        <Grid item component={Box} pl={3} pr={3}>
          <FileUpload
            accept=".jpg, .jpeg, .png, .gif, .tiff, .bmp, .pdf"
            control={control}
            displayText="Arraste o arquivo da declaração aqui. Aceitamos apenas arquivos com as seguintes extensões: .jpg, .jpeg, .png, .gif, .tiff, .bmp e .pdf."
            error={contentStatementHasError}
            errorMessage={fileErrorMessage}
            name={namePrefix ? `${namePrefix}.file` : 'file'}
            onChange={handleOnChangeFile}
          />
        </Grid>
      )}
      {hasContentStatement && (
        <Grid item container component={Box} spacing={2} pl={3} pr={3}>
          <Grid item container sm={12} spacing={3}>
            <Grid item sm={3}>
              <TextField
                control={control}
                InputLabelProps={{
                  className: classes.label,
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                label="Valor da declaração"
                margin="dense"
                name={namePrefix ? `${namePrefix}.value` : 'value'}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item sm={5}>
              <TextField
                control={control}
                fullWidth
                label="Número do documento"
                margin="dense"
                name={
                  namePrefix ? `${namePrefix}.documentNumber` : 'documentNumber'
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ContentStatementFormFields;
