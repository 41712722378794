import React, { useEffect } from 'react';

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router';

import {
  cleanPackage,
  cleanRequests,
  findPackageThunk,
  packageRemoveThunk,
  packageUpdateThunk,
  selectPackage,
  selectPackageIsFulfilledRemove,
  selectPackageIsRequestingRemove,
  selectPackagesIsFulfilledUpdate,
  selectPackagesIsRejectedUpdate,
  selectPackagesIsRequestingUpdate,
} from './packagesSlice';
import { PackageFormInputs } from '../../common/hocs/withPackageForm';
import { PAGE_SIZES } from '../../common/constants/page-size.constants';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import BackButton from '../../common/components/BackButton';
import GenericErrorDialog from '../../common/components/GenericErrorDialog';
import PackageEditForm from './components/PackageEditForm';
import Spinner from '../../common/components/Spinner';

const useStyles = makeStyles({
  arrow: {
    color: '#1976D2',
  },
  container: {
    maxWidth: '50rem',
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
  },
});

interface Props {
  title: string;
}

const PackageEditPage = ({ title }: Props) => {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { id } = useParams<{ id?: string }>();

  const error = useAppSelector(selectPackagesIsRejectedUpdate);
  const isLoading = useAppSelector(selectPackagesIsRequestingUpdate);
  const isRemoved = useAppSelector(selectPackageIsFulfilledRemove);
  const isRemoving = useAppSelector(selectPackageIsRequestingRemove);
  const isUpdated = useAppSelector(selectPackagesIsFulfilledUpdate);
  const packageData = useAppSelector(selectPackage);

  const handleOnRemove = () => {
    dispatch(packageRemoveThunk({ id: Number(id) }));
  };

  const handleOnSubmit = (data: PackageFormInputs) => {
    dispatch(packageUpdateThunk({ ...data, id: Number(id) }));
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    dispatch(findPackageThunk({ id: Number(id) }));

    return () => {
      dispatch(cleanPackage());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (isUpdated || isRemoved) {
      history.goBack();
      dispatch(cleanRequests());
    }
  }, [dispatch, history, isRemoved, isUpdated]);

  return (
    <Box
      m="auto"
      maxWidth={PAGE_SIZES.maxWidth}
      minWidth={PAGE_SIZES.minWidth}
      pl={PAGE_SIZES.sidePadding}
      pr={PAGE_SIZES.sidePadding}
    >
      <GenericErrorDialog error={error} />
      <Container className={classes.container} disableGutters>
        <Box mb={3} mt={4}>
          <Grid alignItems="center" container direction="row" spacing={3}>
            <Grid item>
              <BackButton />
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
                color="primary"
                component="h2"
              >
                Editar Embalagem
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {packageData ? (
          <PackageEditForm
            data={packageData}
            isLoading={isLoading || isRemoving}
            onRemove={handleOnRemove}
            onSubmit={handleOnSubmit}
          />
        ) : (
          <Box textAlign="center">
            <Spinner />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default PackageEditPage;
