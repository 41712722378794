import React, { useEffect, useMemo } from 'react';

import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { isEmpty, omitBy } from 'lodash';

import { selectShipmentIsRequestingDocuments } from '../../features/Shipments/shipmentsSlice';
import { useAppSelector } from '../../app/hooks';
import ShipmentContentStatement from './ShipmentContentStatement';
import ShipmentCte from './ShipmentCte';
import ShipmentInformationsData from '../data-types/shipment-informations-data';
import ShipmentNF from './ShipmentNF/ShipmentNF';
import ShipmentPreviousDocuments from './ShipmentPreviousDocuments';

const useStyles = makeStyles((theme: Theme) => ({
  emptyListMessage: {
    color: theme.palette.text.hint,
    fontSize: 18,
    fontStyle: 'italic',
    marginTop: '17%',
    textAlign: 'center',
  },
}));

export interface ShipmentDocumentsProps {
  shipment: ShipmentInformationsData;
  onFindDocuments: () => void;
}

const ShipmentDocuments = ({
  shipment,
  onFindDocuments,
}: ShipmentDocumentsProps) => {
  const classes = useStyles();

  const isLoading = useAppSelector(selectShipmentIsRequestingDocuments);

  const isDocumentsEmpty = useMemo<boolean>(() => {
    const {
      contentStatement,
      cte,
      fiscalDocuments,
      previousDocuments,
    } = shipment;

    return isEmpty(
      omitBy(
        [contentStatement, cte, fiscalDocuments, previousDocuments],
        isEmpty
      )
    );
  }, [shipment]);

  useEffect(() => {
    if (isDocumentsEmpty) {
      onFindDocuments();
    }
  }, [isDocumentsEmpty, onFindDocuments]);

  return (
    <Box>
      {(shipment.cte || isLoading) && (
        <ShipmentCte cte={shipment.cte} isLoading={isLoading} />
      )}
      {((shipment.fiscalDocuments && shipment.fiscalDocuments?.length > 0) ||
        isLoading) && (
        <ShipmentNF isLoading={isLoading} nfs={shipment.fiscalDocuments} />
      )}
      {(shipment.previousDocuments || isLoading) &&
        shipment.previousDocuments?.length > 0 && (
          <ShipmentPreviousDocuments
            isLoading={isLoading}
            previousDocuments={shipment.previousDocuments}
          />
        )}
      {(shipment.contentStatement || isLoading) && (
        <ShipmentContentStatement
          contentStatement={shipment.contentStatement}
          isLoading={isLoading}
        />
      )}
      {isDocumentsEmpty && !isLoading && (
        <Typography className={classes.emptyListMessage}>
          Nenhum documento adicionado no envio
        </Typography>
      )}
    </Box>
  );
};

export default ShipmentDocuments;
