import { compact, isNil, omitBy } from 'lodash';

import {
  ContentStatementUpdateData,
  ShipmentUpdateData,
} from '../data-types/shipment-update-data';
import { fileToBase64 } from '../services/base64.service';
import { FiscalDocumentXml } from '../../features/Shipments/New/shipmentNewAPI';
import { getActiveToken } from '../services/authentication.service';
import { HttpClient, HttpResponse } from './http-client/http.client';
import { ShipmentLabelTemplate } from '../enums/tag-template.enum';
import { ShipmentStatus } from '../enums/shipment-status.enum';
import { StatusUpdateError } from '../../features/Shipments/shipmentsAPI';
import AddressData from '../data-types/address-data';
import EntityData from '../data-types/entity-data';
import NFe from '../data-types/nfe-data';
import PackageData from '../data-types/package-data';
import ShipmentData from '../data-types/shipment-data';
import ShipmentModality from '../enums/shipment-modality.enum';
import TagFormat from '../enums/tag-format-type.enum';
import TokenType from '../enums/token-type.enum';
import VolumeData from '../data-types/volume-data';

// #region Typings
interface ZordonClientDependencies {
  host: string;
  httpClient: HttpClient;
}

export interface ContentStatement {
  documentNumber?: number;
  fileBase64: string;
  fileName: string;
  value: number;
}

export interface EntityCreateData extends Partial<EntityData> {
  address: AddressData;
  code: string;
  corporation: boolean;
  displayName: string;
  documentNumber: string;
  email: string;
  name: string;
  organizationId: EntityData['organizationId'];
  phone: string;
  shippingSite: boolean;
  stateRegistration: string;
}

export interface ShipmentBatchUpdateData extends Partial<ShipmentUpdateData> {
  originShipperEntity: EntityData;
  receiptModality: ShipmentModality;
  shipmentIds: number[];
}
// #endregion Typings

class ZordonClient {
  private host: string;

  private httpClient: HttpClient;

  constructor({ host, httpClient }: ZordonClientDependencies) {
    this.host = host;
    this.httpClient = httpClient;
  }

  async associateShipmentsToPickup(
    ids: number[],
    identifier: string
  ): Promise<HttpResponse<void, any>> {
    const url = this.buildUrl(`/scheduled-pickups/${identifier}/shipments`);
    const reqArgs = this.signRequest({ body: { ids }, url });

    return this.httpClient.post(reqArgs);
  }

  async createEntity(entity: EntityCreateData): Promise<EntityData> {
    const url = this.buildUrl('entities');
    const reqArgs = this.signRequest({
      body: this.entityToJSON(entity),
      url,
    });

    const { body } = await this.httpClient.post(reqArgs);

    return this.entityFromJSON(body);
  }

  async createPackage(body: Record<string, any>): Promise<HttpResponse> {
    const url = this.buildUrl('packages');
    const reqArgs = this.signRequest({ body, url });

    return this.httpClient.post(reqArgs);
  }

  async createShipment(
    body: Record<string, any>
  ): Promise<HttpResponse<void, any>> {
    const url = this.buildUrl('shipments');
    const reqArgs = this.signRequest({ body, url });

    return this.httpClient.post(reqArgs);
  }

  async downloadExcel(filters: any): Promise<HttpResponse<Blob>> {
    const url = this.buildUrl(`/shipment/export/excel`);

    const labels = {
      carrierCode: 'Cod Transportador',
      createdAt: 'Data de Criação',
      destination: 'Destino',
      height: 'Altura',
      length: 'Comprimento',
      origin: 'Origem',
      shipperCode: 'Cod Embarcador',
      totalCubageWeight: 'Peso Cubado Total',
      totalValue: 'Valor Mercadoria NF',
      totalWeight: 'Peso Real Total',
      volumeNumber: 'Num. Volume',
      volumesQty: 'Qtd Volume',
      width: 'Largura',
    };

    const filtersWithLabels = {
      ...filters,
      labels,
    };

    const reqArgs = this.signRequest({ body: filtersWithLabels, url });

    return this.httpClient.getBlob(reqArgs);
  }

  async forceResendMinutaById(
    id: number,
    userOrganizationId: number
  ): Promise<HttpResponse> {
    const url = this.buildUrl(`/shipments/resend`);

    const body = { id, organizationId: userOrganizationId };

    const reqArgs = this.signRequest({ body, url });

    return this.httpClient.post(reqArgs);
  }

  async createShippingEventByIdentifier(
    identifier: string,
    body: Record<string, any>
  ): Promise<HttpResponse> {
    const url = this.buildUrl(`shipping-events/volumes/events/${identifier}`);
    const reqArgs = this.signRequest({ body, url });

    return this.httpClient.post(reqArgs);
  }

  async disassociateShipmentsFromPickup(
    ids: number[],
    identifier: string
  ): Promise<HttpResponse<void, any>> {
    const url = this.buildUrl(
      `/scheduled-pickups/${identifier}/shipments/disassociate `
    );
    const reqArgs = this.signRequest({ body: { ids }, url });

    return this.httpClient.post(reqArgs);
  }

  async downloadShipmentTag(
    idOrTrackingCode: number | string
  ): Promise<HttpResponse<Blob>> {
    const url = this.buildUrl(
      `/shipments/${idOrTrackingCode}/volume/carrier-label/download`
    );
    const reqArgs = this.signRequest({ url });

    return this.httpClient.getBlob(reqArgs);
  }

  async downloadShipmentTagByType(
    id: number,
    type: TagFormat,
    template: ShipmentLabelTemplate
  ): Promise<HttpResponse<Blob>> {
    const url = this.buildUrl(
      `/shipments/${id}/labels/download?format=${type}&template=${template}`
    );
    const reqArgs = this.signRequest({ url });

    return this.httpClient.getBlob(reqArgs);
  }

  async logStatusUpdateError({
    context,
    message,
  }: StatusUpdateError): Promise<HttpResponse> {
    const path = 'error-report';
    const url = this.buildUrl(path);
    const reqArgs = this.signRequest({
      url,
      body: {
        context,
        message,
      },
    });

    return this.httpClient.post(reqArgs);
  }

  async readCte(
    fiscalDocuments: FiscalDocumentXml[]
  ): Promise<HttpResponse<NFe[], any>> {
    const url = this.buildUrl('fiscal-documents/cte');
    const reqArgs = this.signRequest({ body: { fiscalDocuments }, url });

    return this.httpClient.post(reqArgs);
  }

  async readNfe(
    fiscalDocuments: FiscalDocumentXml[]
  ): Promise<HttpResponse<NFe[], any>> {
    const url = this.buildUrl('fiscal-documents/nfe');
    const reqArgs = this.signRequest({ body: { fiscalDocuments }, url });

    return this.httpClient.post(reqArgs);
  }

  async removeEntity(entityId: EntityData['id']): Promise<HttpResponse> {
    const url = this.buildUrl(`/entities/${entityId}`);
    const reqArgs = this.signRequest({ url });

    return this.httpClient.delete(reqArgs);
  }

  async removePackage(packageId: PackageData['id']): Promise<HttpResponse> {
    const url = this.buildUrl(`/packages/${packageId}`);
    const reqArgs = this.signRequest({ url });

    return this.httpClient.delete(reqArgs);
  }

  async removeShipment(shipmentId: number): Promise<HttpResponse<void, any>> {
    const url = this.buildUrl(`/shipments/${shipmentId}`);
    const reqArgs = this.signRequest({ url });

    return this.httpClient.delete(reqArgs);
  }

  async restoreShipment(shipmentId: ShipmentData['id']): Promise<HttpResponse> {
    const url = this.buildUrl(`/shipments/${shipmentId}/restore`);
    const reqArgs = this.signRequest({ url });

    return this.httpClient.post(reqArgs);
  }

  async restoreShipmentBatch(
    shipmentIds: ShipmentData['id'][]
  ): Promise<HttpResponse> {
    const url = this.buildUrl('/shipments/restore');
    const reqArgs = this.signRequest({ body: { ids: shipmentIds }, url });

    return this.httpClient.post(reqArgs);
  }

  async searchAddressByCep(cep: string): Promise<AddressData> {
    const url = this.buildUrl(`/addresses/${cep} `);
    const reqArgs = this.signRequest({ url });

    const { body } = await this.httpClient.get(reqArgs);

    return this.addressFromJSON(body);
  }

  async updateBatchShipments(
    shipment: ShipmentBatchUpdateData
  ): Promise<HttpResponse> {
    const url = this.buildUrl('shipments');
    const reqArgs = this.signRequest({
      url,
      body: this.updateBatchShipmentsToJSON(shipment),
    });

    return this.httpClient.patch(reqArgs);
  }

  async updateEntity(
    id: EntityData['id'],
    entity: Partial<EntityData>
  ): Promise<HttpResponse> {
    const path = ['entities', id].join('/');
    const url = this.buildUrl(path);
    const reqArgs = this.signRequest({
      url,
      body: this.entityToJSON(entity),
    });

    return this.httpClient.patch(reqArgs);
  }

  async updatePackage(
    id: number,
    data: Record<string, any>
  ): Promise<HttpResponse> {
    const url = this.buildUrl(`packages/${id}`);
    const reqArgs = this.signRequest({
      body: data,
      url,
    });

    return this.httpClient.patch(reqArgs);
  }

  async updateShipment(
    id: number,
    shipment: Partial<ShipmentUpdateData>
  ): Promise<HttpResponse> {
    const path = ['shipments', id].join('/');
    const url = this.buildUrl(path);
    const reqArgs = this.signRequest({
      url,
      body: await this.updateShipmentToJSON(shipment),
    });

    return this.httpClient.patch(reqArgs);
  }

  async updateShipmentStatus(
    id: number,
    shipmentStatus: ShipmentStatus
  ): Promise<HttpResponse> {
    const path = `shipments/${id}/status`;
    const url = this.buildUrl(path);
    const reqArgs = this.signRequest({
      url,
      body: { status: shipmentStatus },
    });

    return this.httpClient.patch(reqArgs);
  }

  private addressFromJSON(json: Record<string, any>): AddressData {
    return {
      cep: json.cep,
      complement: json.complement,
      location: json.location.name,
      neighborhood: json.neighborhood.name,
      number: json.number,
      state: json.stateAcronym,
      street: json.street.name,
    };
  }

  private addressToJSON(address: AddressData): Record<string, any> | null {
    const {
      cep,
      complement,
      location,
      neighborhood,
      number,
      state,
      street,
    } = address;

    return omitBy(
      {
        cep,
        complement: complement === '' ? undefined : complement,
        location,
        neighborhood,
        number,
        state,
        street,
      },
      isNil
    );
  }

  private buildUrl(path: string): string {
    return [this.host, path]
      .filter(Boolean)
      .map((p) => p.replace(/^\//, '').replace(/\/$/, ''))
      .join('/');
  }

  private async contentStatementToJSON(
    contentStatement: ContentStatementUpdateData
  ): Promise<Record<string, any>> {
    const { documentNumber, file, value } = contentStatement;

    return {
      documentNumber,
      fileBase64: file && (await fileToBase64(file)),
      fileName: file?.name,
      value,
    };
  }

  private entityFromJSON(json: Record<string, any>): EntityData {
    const {
      address,
      code,
      corporation,
      displayName,
      documentNumber,
      email,
      id,
      name,
      organizationId,
      parentCompany,
      phone,
      shippingSite,
      stateRegistration,
    } = json;
    return {
      address: address && this.addressFromJSON(address),
      code,
      corporation,
      displayName,
      documentNumber,
      email,
      id,
      name,
      organizationId: organizationId && organizationId.toString(),
      parentCompany,
      phone,
      shippingSite,
      stateRegistration,
    };
  }

  private entityToJSON(entity: Partial<EntityData>): Record<string, any> {
    const {
      address,
      corporation,
      displayName,
      documentNumber,
      email,
      code,
      name,
      organizationId,
      parentCompany,
      phone,
      shippingSite,
      stateRegistration,
    } = entity;

    return omitBy(
      {
        address: address && this.addressToJSON(address),
        corporation,
        displayName,
        documentNumber,
        email: email === '' ? null : email,
        code: code === '' ? null : code,
        name,
        organizationId: organizationId && organizationId?.toString(),
        parentCompany,
        phone: phone === '' ? null : phone,
        shippingSite,
        stateRegistration: stateRegistration === '' ? null : stateRegistration,
      },
      isNil
    );
  }

  private signRequest<T>(
    reqArgs: T,
    tokenType?: TokenType
  ): T & { headers: { Authorization: string } } {
    const token = getActiveToken();
    return {
      ...(reqArgs as any),
      headers: {
        Authorization: `Bearer ${token}`,
        ...(tokenType ? { 'authorization-Token-Type': tokenType } : {}),
      },
    };
  }

  private updateBatchShipmentsToJSON(
    shipment: ShipmentBatchUpdateData
  ): Record<string, any> {
    const { originShipperEntity, receiptModality, shipmentIds } = shipment;

    return omitBy(
      {
        originShipperEntityId: originShipperEntity?.id,
        receiptModality,
        shipmentIds,
      },
      isNil
    );
  }

  private async updateShipmentToJSON(
    shipment: Partial<ShipmentUpdateData>
  ): Promise<Record<string, any>> {
    const {
      authorizedReceiver,
      contentStatement,
      declaredValue,
      deliveryModality,
      destinationShipperEntity,
      dispatcher,
      dispatchType,
      fiscalDocuments,
      originShipperEntity,
      previousDocuments,
      receiptModality,
      receiver,
      recipient,
      routeLegs,
      sender,
      shipperCode,
      totalWeight,
      volumes,
    } = shipment;

    return omitBy(
      {
        authorizedReceiverId: authorizedReceiver?.id,
        contentStatement:
          contentStatement &&
          (await this.contentStatementToJSON(contentStatement)),
        declaredValue,
        deliveryModality,
        destinationShipperEntityId: destinationShipperEntity?.id,
        dispatcher: dispatcher?.id,
        dispatchType,
        fiscalDocuments:
          fiscalDocuments &&
          compact(
            fiscalDocuments.map(
              (nfe) => nfe.xmlBase64 && { xmlBase64: nfe.xmlBase64 }
            )
          ),
        fiscalDocumentsReferences:
          fiscalDocuments && compact(fiscalDocuments.map((nfe) => nfe.id)),
        originShipperEntityId: originShipperEntity?.id,
        previousDocuments:
          previousDocuments &&
          compact(
            previousDocuments.map(
              (cte) => cte.xmlBase64 && { xmlBase64: cte.xmlBase64 }
            )
          ),
        receiptModality,
        receiverId: receiver?.id,
        recipientId: recipient?.id,
        routeLegs: routeLegs?.map((r) => {
          const { destinationEntity, originEntity } = r;

          return {
            destinationEntityId: destinationEntity?.id,
            originEntityId: originEntity?.id,
          };
        }),
        senderId: sender?.id,
        shipperCode,
        totalWeight: totalWeight && totalWeight * 1000, // Kg to g
        volumes: volumes?.map((v) => this.updateVolumeToJSON(v)),
      },
      isNil
    );
  }

  private updateVolumeToJSON(volume: Partial<VolumeData>): Record<string, any> {
    const { height, id, length, quantity, width } = volume;

    return omitBy({ height, id, length, quantity, width }, isNil);
  }
}

export default ZordonClient;
