/* eslint-disable @typescript-eslint/no-unused-vars */
import { JSONSchema4Object, JSONSchema4Type } from 'json-schema';

import { BaseErrorDetails } from '../../errors/base.error';
import HttpClientError, {
  HttpClientErrorCode,
  HttpClientUnexpectedError,
} from '../../errors/clients/http-client.error';
import {
  HttpClientAdapter,
  HttpMethod,
  HttpRequestArgs,
  HttpRequestWithBodyArgs,
  HttpResponse,
} from './http.client';

interface HandleErrorParams {
  method: HttpMethod;
  response: Response;
}

type HandleResponseParams = HandleErrorParams;

class HttpClientFetchAdapter implements HttpClientAdapter {
  delete(args: HttpRequestWithBodyArgs): Promise<HttpResponse> {
    return this.makeRequest('DELETE', args);
  }

  get(args: HttpRequestArgs): Promise<HttpResponse> {
    return this.makeRequest('GET', args);
  }

  getBlob(
    args: HttpRequestArgs | HttpRequestWithBodyArgs
  ): Promise<HttpResponse<Blob>> {
    return this.makeBlobRequest('body' in args ? 'POST' : 'GET', args);
  }

  patch(args: HttpRequestWithBodyArgs): Promise<HttpResponse> {
    return this.makeRequest('PATCH', args);
  }

  put(args: HttpRequestWithBodyArgs): Promise<HttpResponse> {
    return this.makeRequest('PUT', args);
  }

  post(args: HttpRequestWithBodyArgs): Promise<HttpResponse> {
    return this.makeRequest('POST', args);
  }

  private async handleError({
    method,
    response,
  }: HandleErrorParams): Promise<void> {
    const { status, statusText, url } = response;

    const details: BaseErrorDetails = {
      method,
      status,
      url,
    };

    const text = await response.text();

    const [err, json] = this.safeJSONParse(text);

    if (!err && json) {
      const { code, details: jsonDetails, message } = json;

      throw new HttpClientError({
        code: this.httpErrorCodeFromJSON(code),
        details: details || (jsonDetails as JSONSchema4Object),
        message: message?.toString() || statusText,
      });
    }

    throw new HttpClientUnexpectedError({
      code: HttpClientErrorCode.Unexpected,
      details: {
        status,
        body: text,
      },
      message: 'Unexpected Error',
    });
  }

  private async handleBlobResponse({
    method,
    response,
  }: HandleResponseParams): Promise<HttpResponse<Blob>> {
    const { headers, status } = response;
    let body;

    if (status >= 400) {
      await this.handleError({ method, response });
    }

    await response.blob().then((blob: Blob) => {
      body = blob;
    });

    return { body, header: headers, status };
  }

  private async handleResponse({
    method,
    response,
  }: HandleResponseParams): Promise<HttpResponse> {
    const { headers, status } = response;

    let body = {};

    if (status >= 400) {
      await this.handleError({ method, response });
    }

    if (status !== 204) {
      const text = await response.text();
      const [_, json] = this.safeJSONParse(text);

      body = json;
    }

    return { body, header: headers, status };
  }

  private httpErrorCodeFromJSON(code?: JSONSchema4Type): HttpClientErrorCode {
    switch (code) {
      case 'credentials_invalid':
        return HttpClientErrorCode.CredentialsInvalid;
      case 'credentials_expired':
        return HttpClientErrorCode.CredentialsExpired;
      case 'invalid_param':
      case 'invalid_params':
        return HttpClientErrorCode.InvalidParam;
      case 'not_found':
        return HttpClientErrorCode.NotFound;
      case 'unauthorized':
        return HttpClientErrorCode.Unauthorized;
      case 'user_email_not_unique':
        return HttpClientErrorCode.Uniqueness;
      case 'shipment_has_already_issued_cte_to_update':
        return HttpClientErrorCode.ShipmentHasCte;
      default:
        return HttpClientErrorCode.Generic;
    }
  }

  private async makeBlobRequest(
    method: HttpMethod,
    args: HttpRequestWithBodyArgs
  ): Promise<HttpResponse<Blob>> {
    const { body, headers, signal, url } = args;

    const response = await fetch(url, {
      body: JSON.stringify(body),
      headers: {
        'Content-Type': body ? 'application/json' : 'application/pdf',
        ...headers,
      },
      method,
      mode: 'cors',
      signal,
    });

    return this.handleBlobResponse({ method, response });
  }

  private async makeRequest(
    method: HttpMethod,
    args: HttpRequestWithBodyArgs
  ): Promise<HttpResponse> {
    const { body, headers, signal, url } = args;

    const response = await fetch(url, {
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json', ...headers },
      method,
      mode: 'cors',
      signal,
    });

    return this.handleResponse({ method, response });
  }

  private safeJSONParse(text: string): JSONSchema4Object[] {
    try {
      return [null, JSON.parse(text)];
    } catch (err) {
      return [err as JSONSchema4Object];
    }
  }
}

export default HttpClientFetchAdapter;
