import React from 'react';

import { Box, Typography, List } from '@material-ui/core';

import VolumeListItem from './Item';
import VolumeData from '../../data-types/volume-data';

interface VolumeListProps {
  volumes: VolumeData[];
}

const VolumeList = ({ volumes }: VolumeListProps) => {
  return (
    <Box>
      <Typography color="primary">Volumes</Typography>
      <List>
        {volumes.map((volume: VolumeData, index: number) => (
          <VolumeListItem index={index} key={volume.code} volume={volume} />
        ))}
      </List>
    </Box>
  );
};

export default VolumeList;
