enum ShipmentModality {
  Counter = 'counter',
  Door = 'door',
  Undefined = 'undefined',
}

export const SHIPMENT_MODALITIES = [
  ShipmentModality.Counter,
  ShipmentModality.Door,
  ShipmentModality.Undefined,
];

export default ShipmentModality;
