import React from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  blueText: {
    color: '#1976D2',
  },
  clickableText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  closedContainer: {
    '&:hover': {
      backgroundColor: 'white',
    },
    border: '1px dashed #C2C3C9',
    cursor: 'pointer',
  },
  title: {
    fontSize: 18,
    marginLeft: 15,
  },
});

interface FormPlaceholderProps {
  icon: React.ReactNode;
  title: string;
  onOpen: () => void;
}

const FormPlaceholder = ({ icon, title, onOpen }: FormPlaceholderProps) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.closedContainer}
      borderRadius="3px"
      display="flex"
      minHeight={80}
      pl={3}
      onClick={onOpen ? () => onOpen() : undefined}
    >
      <Box alignSelf="center">
        <Grid container>
          <Grid className={classes.blueText}>{icon}</Grid>
          <Grid>
            <Typography className={`${classes.blueText} ${classes.title}`}>
              <strong>{title}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FormPlaceholder;
