import React, { useCallback, useState } from 'react';

import { Control, RegisterOptions, useController } from 'react-hook-form';
import useMask from '@ryuuji3/react-mask-hook';

import MaskType, {
  MASK_INPUT_LABEL_MAP,
  MASK_INPUT_PLACEHOLDER_LABEL_MAP,
} from '../enums/mask-input-type.enum';
import { UncontrolledTextField, UncontrolledTextFieldProps } from './TextField';

export interface UncontrolledMaskedInputProps
  extends Omit<UncontrolledTextFieldProps, 'type' | 'onChange'> {
  maskType: MaskType;
  name: string | `${string}.${string}` | `${string}.${number}`;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  shouldUnregister?: boolean;
  value?: string;
  onChange?: (data: string) => void;
}

export const UncontrolledMaskedInput = ({
  maskType,
  onChange,
  ...rest
}: UncontrolledMaskedInputProps) => {
  const [value, setValue] = useState<string>('');

  const handleChange = useCallback(
    (inputValue: string) => {
      if (onChange) {
        onChange(inputValue);
      }
      setValue(inputValue);
    },
    [onChange]
  );

  const maskProps = useMask({
    mask: `${MASK_INPUT_LABEL_MAP.get(maskType)}`,
    placeholder: `${MASK_INPUT_PLACEHOLDER_LABEL_MAP.get(maskType)}`, // will render mask exactly like this
    value,
    onChange: handleChange,
  });

  return <UncontrolledTextField {...maskProps} {...rest} />;
};

export interface MaskedInputProps
  extends Omit<UncontrolledMaskedInputProps, 'value' | 'onChange'> {
  control: Control<any>;
  defaultValue?: unknown;
  onChange?: (data: string) => void;
}

const MaskedInput = ({
  control,
  defaultValue = '',
  error,
  helperText,
  maskType,
  name,
  rules,
  shouldUnregister,
  onChange,
  ...rest
}: MaskedInputProps) => {
  const {
    field: { onChange: onFieldChange, value },
    fieldState: { error: fieldError, invalid: fieldInvalid },
  } = useController({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  const handleChange = useCallback(
    (inputValue: string) => {
      if (onChange) {
        onChange(inputValue);
      }
      onFieldChange(inputValue);
    },
    [onChange, onFieldChange]
  );

  const maskProps = useMask({
    mask: `${MASK_INPUT_LABEL_MAP.get(maskType)}`,
    placeholder: `${MASK_INPUT_PLACEHOLDER_LABEL_MAP.get(maskType)}`, // will render mask exactly like this
    value,
    onChange: handleChange,
  });

  return (
    <UncontrolledTextField
      error={error || fieldInvalid}
      helperText={helperText || fieldError?.message}
      name={name}
      {...maskProps}
      {...rest}
    />
  );
};

export default MaskedInput;
