import React from 'react';

import { Control } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import PasswordField from '../PasswordField';

export interface PasswordEditFieldsProps {
  control: Control<any>;
}

const PasswordEditFields = ({ control }: PasswordEditFieldsProps) => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PasswordField
          control={control}
          fullWidth
          label="Senha atual"
          name="currentPassword"
        />
      </Grid>
      <Grid item>
        <PasswordField
          control={control}
          fullWidth
          label="Nova senha"
          name="password"
        />
      </Grid>
      <Grid item>
        <PasswordField
          control={control}
          fullWidth
          label="Confirme sua senha"
          name="confirmation"
        />
      </Grid>
    </Grid>
  );
};

export default PasswordEditFields;
