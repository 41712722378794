import React, { useMemo } from 'react';

import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { decimalNumber } from '../helpers/mask.helper';
import { selectShipmentIsRequestingCost } from '../../features/Shipments/shipmentsSlice';
import { useAppSelector } from '../../app/hooks';
import ShipmentCostData from '../data-types/shipment-cost-data';
import SkeletonField from './SkeletonField';

const useStyles = makeStyles((theme: Theme) => ({
  emptyList: {
    color: theme.palette.text.hint,
    fontStyle: 'italic',
    fontSize: '1.125rem',
  },
  item: {
    '&>:last-child': {
      borderRadius: '4px',
    },
    borderBottom: '1px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'space-between',
    opacity: 1,
    padding: '0.4rem 0.8rem',
  },
  list: {
    border: '1px solid #E0E0E0',
    borderBottom: 'none',
    borderRadius: '4px',
    boxShadow: '0px 2px 2px #00000033',
    width: '21.25rem',
  },
  total: {
    padding: '0.5rem 0.85rem 0.8rem 0.85rem',
  },
  totalLabel: {
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
    marginRight: '1.5rem',
  },
  totalValue: {
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
    fontWeight: 'bolder',
  },
  value: {
    fontWeight: 'bolder',
  },
}));

interface ShipmentCostProps {
  shipmentCost?: ShipmentCostData;
  onFindCost: () => void;
}

const ShipmentCost = ({ shipmentCost, onFindCost }: ShipmentCostProps) => {
  const classes = useStyles();

  const isLoading = useAppSelector(selectShipmentIsRequestingCost);

  const otherTaxes = useMemo(() => {
    const keys = Object.keys(shipmentCost || {});
    let taxesTotal = 0;

    keys.forEach((key) => {
      if (key !== 'totalTaxes') {
        taxesTotal += (shipmentCost && shipmentCost[key]) || 0;
      }
    });

    return (shipmentCost?.totalTaxes || 0) - taxesTotal;
  }, [shipmentCost]);

  useMemo(() => {
    if (!shipmentCost) {
      onFindCost();
    }
  }, [onFindCost, shipmentCost]);

  return (
    <>
      {isLoading || shipmentCost ? (
        <>
          <Grid className={classes.total} container direction="row">
            <SkeletonField height="larger" isLoading={isLoading} width="larger">
              <Typography className={classes.totalLabel}>Frete</Typography>
              <Typography className={classes.totalValue}>
                R$ {decimalNumber(shipmentCost?.totalTaxes || 0)}
              </Typography>
            </SkeletonField>
          </Grid>
          <Grid className={classes.list} container direction="column">
            <Grid className={classes.item} container direction="row">
              <Grid item>
                <SkeletonField isLoading={isLoading}>
                  <Typography>Frete Peso</Typography>
                </SkeletonField>
              </Grid>
              <Grid item>
                <SkeletonField isLoading={isLoading}>
                  <Typography className={classes.value}>
                    R$ {decimalNumber(shipmentCost?.middleMile || 0)}
                  </Typography>
                </SkeletonField>
              </Grid>
            </Grid>
            {((shipmentCost && shipmentCost?.firstMile > 0) || isLoading) && (
              <Grid className={classes.item} container direction="row">
                <Grid item>
                  <SkeletonField isLoading={isLoading}>
                    <Typography>Taxa de Coleta</Typography>
                  </SkeletonField>
                </Grid>
                <Grid item>
                  <SkeletonField isLoading={isLoading}>
                    <Typography className={classes.value}>
                      R$ {decimalNumber(shipmentCost?.firstMile || 0)}
                    </Typography>
                  </SkeletonField>
                </Grid>
              </Grid>
            )}
            {((shipmentCost && shipmentCost?.lastMile > 0) || isLoading) && (
              <Grid className={classes.item} container direction="row">
                <Grid item>
                  <SkeletonField isLoading={isLoading}>
                    <Typography>Taxa de Entrega</Typography>
                  </SkeletonField>
                </Grid>
                <Grid item>
                  <SkeletonField isLoading={isLoading}>
                    <Typography className={classes.value}>
                      R$ {decimalNumber(shipmentCost?.lastMile || 0)}
                    </Typography>
                  </SkeletonField>
                </Grid>
              </Grid>
            )}
            {((shipmentCost && !shipmentCost?.freightIncludesIcms) ||
              isLoading) && (
              <Grid className={classes.item} container direction="row">
                <Grid item>
                  <SkeletonField isLoading={isLoading}>
                    <Typography>ICMS</Typography>
                  </SkeletonField>
                </Grid>
                <Grid item>
                  <SkeletonField isLoading={isLoading}>
                    <Typography className={classes.value}>
                      R$ {decimalNumber(shipmentCost?.icms || 0)}
                    </Typography>
                  </SkeletonField>
                </Grid>
              </Grid>
            )}
            {((shipmentCost && shipmentCost?.advalorem > 0) || isLoading) && (
              <Grid className={classes.item} container direction="row">
                <Grid item>
                  <SkeletonField isLoading={isLoading}>
                    <Typography>Ad Valorem</Typography>
                  </SkeletonField>
                </Grid>
                <Grid item>
                  <SkeletonField isLoading={isLoading}>
                    <Typography className={classes.value}>
                      R$ {decimalNumber(shipmentCost?.advalorem || 0)}
                    </Typography>
                  </SkeletonField>
                </Grid>
              </Grid>
            )}
            {(otherTaxes > 0 || isLoading) && (
              <Grid className={classes.item} container direction="row">
                <Grid item>
                  <SkeletonField isLoading={isLoading}>
                    <Typography>Outras Taxas</Typography>
                  </SkeletonField>
                </Grid>
                <Grid item>
                  <SkeletonField isLoading={isLoading}>
                    <Typography className={classes.value}>
                      R$ {decimalNumber(otherTaxes)}
                    </Typography>
                  </SkeletonField>
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <Box
          alignItems="center"
          className={classes.emptyList}
          display="flex"
          height="21.125rem"
          justifyContent="center"
        >
          Envio não possui frete
        </Box>
      )}
    </>
  );
};

export default ShipmentCost;
