import React, { useCallback, useEffect, useMemo } from 'react';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import {
  cleanRequests,
  selectShipmentDraftIsErrorUpdate,
  selectShipmentDraftIsFulfilledUpdate,
  selectShipmentDraftIsRequestingUpdate,
  updateShipmentDraftThunk,
} from '../../features/Shipments/Draft/shipmentDraftSlice';
import { decimalNumber, parseNumberMask } from '../helpers/mask.helper';
import { HttpClientErrorCode } from '../errors/clients/http-client.error';
import { ShipmentUpdateData } from '../data-types/shipment-update-data';
import { ShipmentVolumeItemData, ShipmentVolumesForm } from './VolumesForm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { VOLUME_FORM_SCHEMAS } from '../schemas/shipment-schema';
import { volumesArrayBuilder } from '../helpers/volumes.helper';
import Drawer from './Drawer';
import FeedbackMessage from './FeedbackMessage';
import ShipmentData from '../data-types/shipment-data';
import SubmitButton from './SubmitButton';

const VOLUMES_SCHEMA = yup.object({
  ...VOLUME_FORM_SCHEMAS,
});

const useStyles = makeStyles({
  buttonGroup: {
    borderTop: '1px solid #C2C3C9',
  },
  scrollable: {
    height: 'calc(100vh - 150px)',
    overflowY: 'auto',
  },
  submit: {
    backgroundColor: '#1976D2',
    '&:hover': {
      backgroundColor: '#1560AB',
    },
    marginRight: '1rem',
  },
  updateButton: {
    backgroundColor: '#1976D2',
    '&:hover': {
      backgroundColor: '#1560AB',
    },
  },
});

export interface VolumesFormData {
  declaredValue: string;
  totalWeight: string;
  volumes: ShipmentVolumeItemData[];
}

interface VolumeUpdateDrawer {
  open: boolean;
  shipment: ShipmentData;
  onClose: () => void;
  onSucceed?: () => void;
}

const VolumeUpdateDrawer = ({
  open,
  shipment,
  onClose,
  onSucceed = () => {},
}: VolumeUpdateDrawer) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectShipmentDraftIsRequestingUpdate);
  const isFulfilled = useAppSelector(selectShipmentDraftIsFulfilledUpdate);
  const error = useAppSelector(selectShipmentDraftIsErrorUpdate);

  const defaultValues = useMemo<VolumesFormData>(
    () => ({
      declaredValue: decimalNumber(shipment?.declaredValue || 0),
      totalWeight: decimalNumber(shipment?.totalWeight || 0),
      volumes: shipment?.volumesInformations?.map(
        (v) =>
          ({
            height: v.height || 0,
            length: v.length || 0,
            width: v.width || 0,
            packageId: 'no-option',
            quantity: v.quantity,
          } || [])
      ),
    }),
    [shipment]
  );

  const methods = useForm<VolumesFormData>({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(VOLUMES_SCHEMA),
    reValidateMode: 'onChange',
  });

  const {
    formState: { isSubmitted, isValid },
    handleSubmit,
    reset,
  } = methods;

  const toModel = (data: VolumesFormData): Partial<ShipmentUpdateData> => {
    const { declaredValue, totalWeight, volumes } = data;

    return {
      declaredValue: parseNumberMask(declaredValue || ''),
      totalWeight: parseNumberMask(totalWeight || ''),
      volumes: volumesArrayBuilder(volumes),
      volumesQuantity: volumes.reduce((acc, volume) => {
        return acc + Number(volume.quantity);
      }, 0),
    };
  };

  const handleUpdate = (data: VolumesFormData) => {
    dispatch(
      updateShipmentDraftThunk({
        data: toModel(data),
        id: shipment.id,
      })
    );
  };

  const handleCancel = useCallback(() => {
    dispatch(cleanRequests());
    reset(defaultValues);
    onClose();
  }, [defaultValues, dispatch, onClose, reset]);

  useEffect(() => {
    if (isFulfilled && open) {
      dispatch(cleanRequests());
      onSucceed();
      onClose();
    }
  }, [onClose, isFulfilled, open, onSucceed, dispatch]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <FormProvider {...methods}>
      <Drawer header="Especificar Volumes" open={open}>
        <Grid container direction="column" justifyContent="space-between">
          <Grid className={classes.scrollable} component={Box} p={3} item>
            <ShipmentVolumesForm />
            {error?.code === HttpClientErrorCode.ShipmentHasCte && (
              <FeedbackMessage
                message="O Envio já possui CTE e não será possível alterá-lo. Por favor atualize a página."
                severity="error"
                hasMarginTop
              />
            )}
          </Grid>
          <Grid className={classes.buttonGroup} component={Box} item p={2}>
            <SubmitButton
              classes={{ root: classes.submit }}
              color="primary"
              disabled={isLoading || (isSubmitted && !isValid)}
              isLoading={isLoading}
              variant="contained"
              onClick={handleSubmit(handleUpdate)}
            >
              Atualizar
            </SubmitButton>
            <Button variant="text" onClick={handleCancel}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </FormProvider>
  );
};

export default VolumeUpdateDrawer;
