import React from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CopyButton from '../CopyButton';
import Modal from './Modal';

const useStyles = makeStyles((theme: Theme) => ({
  confirmationButton: {
    color: theme.palette.info.main,
  },
  passwordCard: {
    alignItems: 'center',
    backgroundColor: '#F4F4F4',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18,
    marginTop: 28,
    paddingBottom: 8,
    paddingLeft: 24,
    paddingTop: 8,
  },
  passwordText: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: 16,
  },
}));

interface TemporaryPasswordModalProps {
  open: boolean;
  password: string;
  onClose: () => void;
}

const TemporaryPasswordModal = ({
  open,
  password,
  onClose,
}: TemporaryPasswordModalProps) => {
  const classes = useStyles();

  const handleOnClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Modal
      actions={
        <Button className={classes.confirmationButton} onClick={handleOnClose}>
          Confirmar
        </Button>
      }
      header="Senha Temporária Gerada"
      open={open}
      onClose={onClose}
    >
      <Typography>
        Foi gerada uma senha temporária necessária no primeiro acesso do
        usuário.
      </Typography>
      <Typography>
        Copie ou anote a senha temporária abaixo e clique em Confirmar.
      </Typography>
      <Box display="flex" justifyContent="center">
        <Box className={classes.passwordCard}>
          <Typography className={classes.passwordText}>
            {password.toUpperCase()}
          </Typography>
          <CopyButton value={password} />
        </Box>
      </Box>
    </Modal>
  );
};

export default TemporaryPasswordModal;
