import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

type UnderscoredCheckIconProps = SvgIconProps;

const UnderscoredCheckIcon = ({ ...props }: UnderscoredCheckIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M0 9.2125L6.56166 15.5375L19.9703 2.75L17.1174 0L6.56166 10.175L2.8529 6.6L0 9.2125ZM19.9703 19.25H0V22H19.9703V19.25Z"
      />
    </SvgIcon>
  );
};

export default UnderscoredCheckIcon;
