import React from 'react';

import { Box, Typography } from '@material-ui/core';

interface ShipmentFilterProps {
  children: React.ReactNode;
  filterName: string;
}

const ShipmentFilter = ({ children, filterName }: ShipmentFilterProps) => (
  <Box mb={3} p={0}>
    <Box mb={1}>
      <Typography color="textPrimary" variant="h6">
        {filterName}
      </Typography>
    </Box>
    <Box border={1} borderColor="#C2C3C9" bgcolor="white" p={2}>
      {children}
    </Box>
  </Box>
);

export default ShipmentFilter;
