import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

type ThermalPrinterIconProps = SvgIconProps;

const ThermalPrinterIcon = ({ ...props }: ThermalPrinterIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.33341 15H10.8334L9.16675 16.6667H3.33341C2.91675 16.6667 2.47508 16.4917 2.15841 16.175C1.84175 15.8583 1.66675 15.4167 1.66675 15V6.66666H3.33341V15ZM14.6917 4.03333C14.3917 3.60833 13.8917 3.33333 13.3334 3.33333H6.66675C5.75008 3.33333 5.00008 4.08333 5.00008 4.99999V11.6667C5.00008 12.5833 5.75008 13.3333 6.66675 13.3333H13.3334C13.8917 13.3333 14.3917 13.05 14.6917 12.625L18.3334 8.33333L14.6917 4.03333ZM13.3334 11.6667H6.66675V4.99999H13.3334L16.2917 8.33333L13.3334 11.6667Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ThermalPrinterIcon;
