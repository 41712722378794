import { isNil, omitBy } from 'lodash';

import { getGraphqlClient, getZordonClient } from '../../app/initializer';
import { HttpResponse } from '../../common/clients/http-client/http.client';
import { PACKAGE_FIND_QUERY, PACKAGE_LIST_QUERY } from './packagesGraphql';
import PackageData from '../../common/data-types/package-data';

// #region Typings
interface PackageFindOptions {
  id: PackageData['id'];
}

interface PackageList {
  packages: PackageData[];
}

export interface PackageCreateParams {
  description: string;
  height: string;
  length: string;
  width: string;
}

export interface PackageUpdateParams {
  description?: string;
  height?: string;
  id: number;
  length?: string;
  width?: string;
}

export interface PackageRemoveOptions {
  id: PackageData['id'];
}
// #endregion Typings

const graphqlClient = getGraphqlClient();
const zordonClient = getZordonClient();

// #region Private functions
const packageFromJSON = (json: Record<string, any>): PackageData => {
  const { height, id, length, name, organizationId, width } = json;

  return {
    height,
    id,
    length,
    name,
    organizationId,
    width,
  };
};

const packageDataToJSON = ({
  description,
  height,
  length,
  width,
}: PackageCreateParams): Record<string, any> => {
  return { height, length, name: description, width };
};

const packageUpdateDataToJSON = ({
  description,
  height,
  length,
  width,
}: Omit<PackageUpdateParams, 'id'>) => {
  return omitBy(
    {
      height,
      length,
      name: description,
      width,
    },
    isNil
  );
};
// #endregion Private functions

// #region Public functions
export const createPackage = async ({
  description,
  height,
  length,
  width,
}: PackageCreateParams): Promise<PackageData> => {
  const reqBody = packageDataToJSON({ description, height, length, width });

  const { body } = await zordonClient.createPackage(reqBody);

  return packageFromJSON(body);
};

export const findPackage = async ({
  id,
}: PackageFindOptions): Promise<PackageData> => {
  const { package: packageData } = await graphqlClient.executeGraphQL(
    PACKAGE_FIND_QUERY,
    {
      id,
    }
  );

  return packageData;
};

export const listPackages = async (): Promise<PackageList> => {
  const { packages } = await graphqlClient.executeGraphQL(PACKAGE_LIST_QUERY);

  return { packages };
};

export const removePackage = async ({
  id,
}: PackageRemoveOptions): Promise<HttpResponse> => {
  const response = await zordonClient.removePackage(id);

  return response;
};

export const updatePackage = async ({
  description,
  height,
  id,
  length,
  width,
}: PackageUpdateParams): Promise<HttpResponse> => {
  const reqBody = packageUpdateDataToJSON({
    description,
    height,
    length,
    width,
  });

  return zordonClient.updatePackage(id, reqBody);
};
// #endregion Public functions
