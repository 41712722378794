import React, { useCallback } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import {
  ShipmentStatus,
  SHIPMENT_STATUS_LABEL_MAP,
} from '../../../../common/enums/shipment-status.enum';
import { UncontrolledCheckbox } from '../../../../common/components/Checkbox';

const useStyles = makeStyles({
  statusItem: {
    '& label': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  statusList: {
    maxHeight: 200,
    overflowY: 'auto',
  },
});

interface SituationOption {
  status: ShipmentStatus;
  label: string;
}

interface SituationFilterProps {
  delayed?: boolean;
  statuses?: ShipmentStatus[];
  synced?: boolean;
  onChange: (statuses: ShipmentStatus[]) => void;
  onDelayedChange: (delayed: boolean) => void;
  onSyncedChange: (synced: boolean) => void;
}

const SituationFilter = ({
  delayed = false,
  statuses = [],
  synced = undefined,
  onChange,
  onDelayedChange,
  onSyncedChange,
}: SituationFilterProps) => {
  const classes = useStyles();

  const getShipmentStatus = useCallback((event: string): ShipmentStatus => {
    switch (event) {
      case 'finished':
        return ShipmentStatus.Finished;
      case 'inTransit':
        return ShipmentStatus.InTransit;
      case 'ready':
        return ShipmentStatus.Ready;
      case 'toWithdrawal':
        return ShipmentStatus.ToWithdrawal;
      default:
        return ShipmentStatus.Ready;
    }
  }, []);

  const buildFilterOptions = useCallback((): SituationOption[] => {
    const situations = Array.from(SHIPMENT_STATUS_LABEL_MAP);

    return situations.map(([status, label]) => {
      return {
        status: getShipmentStatus(status),
        label,
      };
    });
  }, [getShipmentStatus]);

  const handleOnChange = useCallback(
    (checked: boolean, status: string) => {
      const currentStatus = [...statuses];

      if (checked) {
        const shipmentStatus = getShipmentStatus(status);

        currentStatus.push(shipmentStatus);

        onChange(currentStatus);
      } else {
        onChange(currentStatus.filter((s) => s !== status));
      }
    },
    [statuses, getShipmentStatus, onChange]
  );

  return (
    <Box>
      <Box className={classes.statusList}>
        {buildFilterOptions().map((item: any) => (
          <Box className={classes.statusItem} key={item.status}>
            <UncontrolledCheckbox
              checked={statuses.includes(item.status)}
              label={item.label}
              onChange={(e) => handleOnChange(e.target.checked, item.status)}
            />
          </Box>
        ))}
        <Box className={classes.statusItem} key="delayed">
          <UncontrolledCheckbox
            checked={delayed}
            label="Atrasado"
            onChange={(e) => onDelayedChange(e.target.checked)}
          />
        </Box>
        <Box className={classes.statusItem} key="synced">
          <UncontrolledCheckbox
            checked={!!synced}
            label="Não Sincronizados"
            onChange={(e) => onSyncedChange(e.target.checked)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SituationFilter;
