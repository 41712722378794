import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import FiscalDocument from '../../data-types/fiscal-document-data';
import ShipmentNFItem from './Item';
import SkeletonField from '../SkeletonField';

interface ShipmentNFProps {
  nfs?: FiscalDocument[];
  isLoading: boolean;
}

const ShipmentNF = ({ nfs, isLoading }: ShipmentNFProps) => (
  <Box mb={5}>
    <SkeletonField height="large" isLoading={isLoading} width="large">
      <Typography color="primary" style={{ fontSize: 16 }}>
        Notas Fiscais ({nfs?.length})
      </Typography>
    </SkeletonField>
    {nfs?.map((nf) => (
      <Box key={nf.id} mt="20px">
        <ShipmentNFItem isLoading={isLoading} nf={nf} />
      </Box>
    ))}
    {isLoading && (
      <Box mt="20px">
        <ShipmentNFItem isLoading={isLoading} nf={undefined} />
      </Box>
    )}
  </Box>
);

export default ShipmentNF;
