import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import Box from '@material-ui/core/Box';

import { ShippingEventNewFormInputs } from '../ShippingEventNewDialog';
import Checkbox from '../../../../common/components/Checkbox';
import VolumeData from '../../../../common/data-types/volume-data';

interface ShippingEventVolumeListItemProps {
  volume: VolumeData;
}

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
  },
});

const ShippingEventVolumeListItem = ({
  volume,
}: ShippingEventVolumeListItemProps) => {
  const classes = useStyles();

  const { control } = useFormContext<ShippingEventNewFormInputs>();

  return (
    <Box className={classes.root}>
      <Checkbox
        control={control}
        label={`${volume.sequentialNumber}. ${
          volume.carrierCode || volume.trackingCode
        }`}
        name={`codes.${volume.carrierCode || volume.trackingCode}`}
      />
    </Box>
  );
};

export default ShippingEventVolumeListItem;
