import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';

import {
  cleanNfes,
  cleanState,
  createShipmentThunk,
  selectNewShipmentIsCreated,
  selectNewShipmentIsCreating,
} from './shipmentNewSlice';
import { listPackagesThunk } from '../../Packages/packagesSlice';
import { selectOrganizationId } from '../../Session/sessionSlice';
import { ShipmentCreationData } from './shipmentNewAPI';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import BackButton from '../../../common/components/BackButton';
import ShipmentNewForm from './components/ShipmentNewForm';

const useStyles = makeStyles({
  container: {
    maxWidth: '50rem',
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
  },
});

interface Props {
  title: string;
}

const ShipmentNewPage = ({ title }: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const [shipment, setShipment] = useState<ShipmentCreationData>();

  const isPending = useAppSelector(selectNewShipmentIsCreating);
  const isSucceeded = useAppSelector(selectNewShipmentIsCreated);
  const userOrganizationId = useAppSelector(selectOrganizationId);

  const handleSubmit = useCallback(
    (data: ShipmentCreationData) => {
      setShipment(data);

      dispatch(
        createShipmentThunk({
          userOrganizationId,
          shipment: data,
        })
      );
    },
    [dispatch, userOrganizationId]
  );

  useEffect(() => {
    if (isSucceeded && shipment) {
      dispatch(cleanState());

      const { deliveryModality, originShipperEntity } = shipment;
      const address = originShipperEntity?.address;

      history.push(
        `/shipments${
          address
            ? `?location=${address.location}&state=${address.state}&modality=${deliveryModality}`
            : ''
        }`
      );
    }
  }, [dispatch, history, isSucceeded, shipment]);

  useEffect(() => {
    dispatch(listPackagesThunk());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(cleanNfes);
    };
  }, [dispatch]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Container className={classes.container} disableGutters>
      <Box mt={4} mb={3}>
        <Grid container direction="row" alignItems="center" spacing={3}>
          <Grid item>
            <BackButton />
          </Grid>
          <Grid item>
            <Typography
              className={classes.title}
              color="primary"
              component="h2"
            >
              Novo Envio
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <ShipmentNewForm isLoading={isPending} onSubmit={handleSubmit} />
    </Container>
  );
};

export default ShipmentNewPage;
