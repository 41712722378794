import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  Theme,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { isNil, omitBy } from 'lodash';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import dayjs from 'dayjs';

import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { ReactComponent as DownloadIcon } from '../../../common/assets/images/exceldownload.svg';
import {
  clearEntities,
  listEntitiesThunk,
  selectEntities,
  selectEntitiesIsRequestingList,
} from '../../Entities/entitiesSlice';
import {
  cleanRequests,
  clearShipments,
  downloadExcelThunk,
  listShipmentsThunk,
  selectShipmentFetchError,
  selectShipmentFilters,
  selectShipmentList,
  selectShipmentListPage,
  selectShipmentsListIsRequesting,
  selectShipmentTotalItems,
  updateShipmentListFiltersAction,
  updateShipmentListPageAction,
} from '../shipmentsSlice';
import SortShipmentsField from './components/SortShipmentsField';
import { PAGE_SIZES } from '../../../common/constants/page-size.constants';
import { REACT_APP_EBB_ORGANIZATION_ID } from '../../../.env';
import {
  selectOrganizationId,
  selectSessionUserType,
} from '../../Session/sessionSlice';
import { ShipmentCodeFilter } from './components/ShipmentCodeFilter';
import { ShipmentDocumentIdentifierFilter } from './components/ShipmentDocumentIdentifierFilter';
import { ShipmentStatus } from '../../../common/enums/shipment-status.enum';
import { UncontrolledDatePicker } from '../../../common/components/DatePicker';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useUrlParams } from '../../../common/hooks/useUrlParams';
import CityNameFilter from './components/CityNameFilter';
import DownloadExcelModal from '../../../common/components/Modals/DownloadExcelModal';
import EntityData from '../../../common/data-types/entity-data';
import EntityFilter from './components/EntityFilter';
import EntityResponsabilityType from '../../../common/enums/entity-responsability-type.enum';
import ExcelDownloadError from '../../../common/components/Modals/ExcelDownloadError';
import GenericErrorDialog from '../../../common/components/GenericErrorDialog';
import OrganizationType from '../../../common/enums/organization-type.enum';
import RefreshButton from '../../../common/components/RefreshButton';
import ShipmentFilter from './components/ShipmentFilter';
import ShipmentFilterChips from './components/ShipmentFilterChips';
import ShipmentList from '../../../common/components/ShipmentList/ShipmentList';
import ShipmentListFilterData from '../../../common/data-types/shipment-list-filter-data';
import SituationFilter from './components/SituationFilter';
import SortOption from '../../../common/enums/sort-option-type.enum';

const useStyles = makeStyles((theme: Theme) => ({
  buttonLabel: {
    color: '#FFFFFF',
  },
  butttonRoot: {
    backgroundColor: theme.palette.success.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  alignment: {
    alignItems: 'center',
    justifyContent: 'space between',
    display: 'flex',
    gap: '1rem',
  },
}));

const DEFAULT_PAGE = 1;

interface Props {
  title: string;
}

const ShipmentListPage = ({ title }: Props) => {
  const classes = useStyles();
  const { getUrlParam, replaceUrlParams } = useUrlParams();

  const dispatch = useAppDispatch();

  const entities = useAppSelector(selectEntities);
  const error = useAppSelector(selectShipmentFetchError);
  const filters = useAppSelector(selectShipmentFilters);
  const isLoading = useAppSelector(selectShipmentsListIsRequesting);
  const isLoadingEntities = useAppSelector(selectEntitiesIsRequestingList);
  const organizationId = useAppSelector(selectOrganizationId);
  const page = useAppSelector(selectShipmentListPage);
  const shipments = useAppSelector(selectShipmentList);
  const total = useAppSelector(selectShipmentTotalItems);
  const userType = useAppSelector(selectSessionUserType);

  const [isUrlParamsLoaded, setIsUrlParamsLoaded] = useState<boolean>(false);
  const [openDownloadExcelModal, setOpenDownloadExcelModal] = useState<boolean>(
    false
  );
  const [downloadExcelError, setDownloadExcelError] = useState<boolean>(false);

  const userEntities = useMemo<EntityData[]>(() => {
    return entities.filter((entity) => {
      return entity.organizationId !== +REACT_APP_EBB_ORGANIZATION_ID;
    });
  }, [entities]);

  const allowedOrganizationTypes = [
    OrganizationType.Admin,
    OrganizationType.Shipper,
  ];

  const mayButtonBeVisible = useCallback(
    (types: OrganizationType[]) => types.includes(userType),
    [userType]
  );

  const handleOnDocumentIdentifierChange = (
    documentIdentifier: string
  ): void => {
    handleOnFilter({ documentIdentifier });
  };

  const handleOnOrderChange = (order: SortOption): void => {
    handleOnFilter({ order });
  };

  const handleOnCreatedAtEndChange = (date: MaterialUiPickersDate): void => {
    handleOnFilter({
      createdAtEnd: date ? dayjs(date).endOf('day').toISOString() : undefined,
    });
  };

  const handleOnCreatedAtStartChange = (date: MaterialUiPickersDate): void => {
    handleOnFilter({
      createdAtStart: date
        ? dayjs(date).startOf('day').toISOString()
        : undefined,
    });
  };

  const handleCloseDownloadEcxel = () => {
    setOpenDownloadExcelModal(false);
    dispatch(cleanRequests());
  };

  const handleDownloadExcel = () => {
    if (filters) {
      const {
        createdAtEnd,
        createdAtStart,
        delayed,
        destinationEntityLocation,
        documentIdentifier,
        originEntityLocation,
        recipientIds,
        senderIds,
        shipmentCodes,
        statuses,
        synced,
      } = filters;

      dispatch(
        downloadExcelThunk({
          filters: {
            date: {
              end: createdAtEnd,
              start: createdAtStart,
            },
            delayed,
            documentIdentifier,
            location: {
              destination: destinationEntityLocation,
              origin: originEntityLocation,
            },
            recipientIds,
            senderIds,
            shipmentCodes,
            statuses:
              statuses && statuses.length > 0
                ? statuses
                : [
                    ShipmentStatus.Finished,
                    ShipmentStatus.InTransit,
                    ShipmentStatus.Ready,
                    ShipmentStatus.ToWithdrawal,
                  ],
            synced,
          },
        })
      );
    }
    return null;
  };

  const handleErrorDownloadExcelModal = () => {
    setOpenDownloadExcelModal(false);
    setDownloadExcelError(true);
  };

  const handleOnLocationFilterChange = (
    field: keyof Pick<
      ShipmentListFilterData,
      'destinationEntityLocation' | 'originEntityLocation'
    >,
    data: string | undefined
  ) => {
    handleOnFilter({ [field]: data || undefined });
  };

  const handleOnRecipientIdsChange = (recipientIds: EntityData['id'][]) => {
    handleOnFilter({ recipientIds });
  };

  const handleOnSenderIdsChange = (senderIds: EntityData['id'][]) => {
    handleOnFilter({ senderIds });
  };

  const handleOnShipperCodeChange = (shipmentCodes: string[]) => {
    handleOnFilter({ shipmentCodes });
  };

  const handleOnStatusesChange = (statuses: ShipmentStatus[]) => {
    handleOnFilter({ statuses });
  };

  const handleOnDelayedChange = (delayed: boolean) => {
    handleOnFilter({ delayed });
  };

  const handleOnSyncedChange = (synced: boolean) => {
    handleOnFilter({ synced });
  };

  const handleOnPageChange = useCallback(
    (pageNumber: number) => {
      dispatch(updateShipmentListPageAction(pageNumber));
    },
    [dispatch]
  );

  const handleOnFilter = (filter: Partial<ShipmentListFilterData>): void => {
    if (isUrlParamsLoaded) {
      dispatch(updateShipmentListPageAction(1));
    }

    dispatch(updateShipmentListFiltersAction({ ...filters, ...filter }));
  };

  const handleListShipments = useCallback(
    (filterValues: ShipmentListFilterData, fetchPage: number) => {
      const {
        createdAtEnd,
        createdAtStart,
        delayed,
        destinationEntityLocation,
        documentIdentifier,
        order,
        originEntityLocation,
        recipientIds,
        senderIds,
        shipmentCodes,
        statuses,
        synced,
      } = filterValues;

      dispatch(
        listShipmentsThunk({
          filter: {
            date: {
              end: createdAtEnd,
              start: createdAtStart,
            },
            delayed,
            documentIdentifier,
            location: {
              destination: destinationEntityLocation,
              origin: originEntityLocation,
            },
            recipientIds,
            senderIds,
            shipmentCodes,
            statuses:
              statuses && statuses.length > 0
                ? statuses
                : [
                    ShipmentStatus.Finished,
                    ShipmentStatus.InTransit,
                    ShipmentStatus.Ready,
                    ShipmentStatus.ToWithdrawal,
                  ],
            synced,
          },
          offset: ((fetchPage || page) - 1) * 20,
          order,
        })
      )
        .unwrap()
        .then(() => window.scrollTo(0, 0));
    },
    [dispatch, page]
  );

  const onCleanRequests = useCallback(() => {
    dispatch(cleanRequests());
    setDownloadExcelError(false);
  }, [dispatch]);

  const getURLOrder = useCallback(
    (defaultValue: SortOption): SortOption => {
      const param = getUrlParam('order')?.toUpperCase();

      return param === SortOption.Asc || param === SortOption.Desc
        ? param
        : defaultValue;
    },
    [getUrlParam]
  );

  const getURLDelayed = useCallback(
    (key: string): boolean => {
      return !!getUrlParam(key);
    },
    [getUrlParam]
  );

  const getURLSynced = useCallback(
    (key: string): boolean => {
      return !!getUrlParam(key);
    },
    [getUrlParam]
  );

  const getURLEntityIds = useCallback(
    (key: string): EntityData['id'][] => {
      return (
        getUrlParam(key)
          ?.split(',')
          .map((id: string) => {
            return +id;
          }) || []
      );
    },
    [getUrlParam]
  );

  const getURLShipmentCodes = useCallback(
    (key: string): string[] => {
      return (
        getUrlParam(key)
          ?.split(',')
          .map((code: string) => {
            return code;
          }) || []
      );
    },
    [getUrlParam]
  );

  const getURLStatuses = useCallback((): ShipmentStatus[] => {
    return (
      getUrlParam('statuses')
        ?.split(',')
        .reduce((acc: ShipmentStatus[], status: string) => {
          if (
            Object.values(ShipmentStatus).includes(status as ShipmentStatus)
          ) {
            return [...acc, status as ShipmentStatus];
          }
          return acc;
        }, []) || []
    );
  }, [getUrlParam]);

  function getTooltipMessage(totalMessage: number): string {
    switch (true) {
      case totalMessage < 1:
        return 'Nenhum envio disponível';
      case totalMessage === 1:
        return 'Baixar Envio';
      case totalMessage < 100:
        return 'Baixar Envios';
      default:
        return 'Somente os 100 primeiros envios serão exportados';
    }
  }

  const handleRefreshList = useCallback(() => {
    dispatch(clearShipments());
    handleOnPageChange(DEFAULT_PAGE);
    handleListShipments(filters, DEFAULT_PAGE);
  }, [dispatch, filters, handleListShipments, handleOnPageChange]);

  useEffect(() => {
    if (isUrlParamsLoaded) {
      const {
        createdAtEnd,
        createdAtStart,
        delayed,
        destinationEntityLocation,
        documentIdentifier,
        order,
        originEntityLocation,
        recipientIds,
        senderIds,
        shipmentCodes,
        statuses,
        synced,
      } = filters;

      replaceUrlParams(
        omitBy(
          {
            delayed,
            destination: destinationEntityLocation,
            documentIdentifier,
            end: createdAtEnd || undefined,
            order,
            origin: originEntityLocation,
            page,
            recipientIds: recipientIds?.length > 0 ? recipientIds : undefined,
            senderIds: senderIds?.length > 0 ? senderIds : undefined,
            shipmentCodes:
              shipmentCodes && shipmentCodes.length > 0
                ? shipmentCodes
                : undefined,
            start: createdAtStart || undefined,
            statuses: statuses?.length !== 0 ? statuses : undefined,
            synced,
          },
          isNil
        )
      );
    }
  }, [filters, isUrlParamsLoaded, page, replaceUrlParams]);

  useEffect(() => {
    dispatch(
      updateShipmentListPageAction(
        getUrlParam('page') ? Number(getUrlParam('page')) : 1
      )
    );

    dispatch(
      updateShipmentListFiltersAction({
        createdAtEnd: getUrlParam('end'),
        createdAtStart: getUrlParam('start'),
        delayed: getURLDelayed('delayed'),
        destinationEntityLocation: getUrlParam('destination'),
        documentIdentifier: getUrlParam('documentIdentifier'),
        order: getURLOrder(SortOption.Desc),
        originEntityLocation: getUrlParam('origin'),
        recipientIds: getURLEntityIds('recipientIds'),
        senderIds: getURLEntityIds('senderIds'),
        shipmentCodes: getURLShipmentCodes('shipmentCodes'),
        statuses: getURLStatuses(),
        synced: getURLSynced('synced'),
      })
    );
    setIsUrlParamsLoaded(true);
  }, [
    dispatch,
    getURLDelayed,
    getURLEntityIds,
    getURLStatuses,
    getUrlParam,
    getURLOrder,
    getURLShipmentCodes,
    getURLSynced,
  ]);

  useEffect(() => {
    if (isUrlParamsLoaded) {
      handleListShipments(filters, page);
    }
  }, [filters, handleListShipments, isUrlParamsLoaded, page]);

  useEffect(() => {
    if (organizationId) {
      dispatch(clearEntities());
      dispatch(
        listEntitiesThunk({
          filter: { disposable: false, organizationIds: [organizationId] },
        })
      );
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Box>
        <GenericErrorDialog error={error} />
        <Box
          m="auto"
          maxWidth={PAGE_SIZES.maxWidth}
          minWidth={PAGE_SIZES.minWidth}
          pl={PAGE_SIZES.sidePadding}
          pr={PAGE_SIZES.sidePadding}
        >
          <Grid container>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              item
              xs={12}
            >
              <Box mb={3} mt={5}>
                <Typography color="secondary" variant="h4">
                  Envios
                </Typography>
                <Typography component="p">{total} Resultados</Typography>
              </Box>

              <Grid
                item
                xs={mayButtonBeVisible(allowedOrganizationTypes) ? 4 : 2}
              >
                <Box className={classes.alignment}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={getTooltipMessage(total)}
                  >
                    <Box width="100%">
                      <Button
                        disabled={total < 1 || false}
                        fullWidth
                        size="large"
                        startIcon={<DownloadIcon />}
                        onClick={() => {
                          setOpenDownloadExcelModal(true);
                        }}
                        variant="text"
                      >
                        Exportar Excel
                      </Button>
                    </Box>
                  </Tooltip>

                  {mayButtonBeVisible(allowedOrganizationTypes) && (
                    <Button
                      classes={{
                        label: classes.buttonLabel,
                        root: classes.butttonRoot,
                      }}
                      component={RouterLink}
                      fullWidth
                      size="large"
                      startIcon={<AddIcon />}
                      to="/shipments/new"
                      variant="contained"
                    >
                      Novo Envio
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Box mr={4}>
                <Container disableGutters>
                  <ShipmentFilterChips
                    data={filters}
                    entities={entities}
                    onChange={handleOnFilter}
                  />
                </Container>
                <ShipmentFilter filterName="Data">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <UncontrolledDatePicker
                        autoClose
                        disableFuture
                        format="dd/MM/yy"
                        name="createdAtStart"
                        placeholder="Início"
                        value={filters.createdAtStart || null}
                        onChange={handleOnCreatedAtStartChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <UncontrolledDatePicker
                        autoClose
                        disableFuture
                        format="dd/MM/yy"
                        name="createdAtEnd"
                        placeholder="Até"
                        value={filters.createdAtEnd || null}
                        onChange={handleOnCreatedAtEndChange}
                      />
                    </Grid>
                  </Grid>
                </ShipmentFilter>
                <ShipmentFilter filterName="Código">
                  <ShipmentCodeFilter
                    data={filters.shipmentCodes}
                    onSubmit={handleOnShipperCodeChange}
                  />
                </ShipmentFilter>
                <ShipmentFilter filterName="Documento">
                  <ShipmentDocumentIdentifierFilter
                    data={filters.documentIdentifier}
                    onSubmit={handleOnDocumentIdentifierChange}
                  />
                </ShipmentFilter>
                <ShipmentFilter filterName="Situação">
                  <SituationFilter
                    delayed={filters?.delayed}
                    statuses={filters?.statuses}
                    synced={filters?.synced}
                    onChange={handleOnStatusesChange}
                    onDelayedChange={handleOnDelayedChange}
                    onSyncedChange={handleOnSyncedChange}
                  />
                </ShipmentFilter>
                <ShipmentFilter filterName="Origem">
                  <CityNameFilter
                    data={filters.originEntityLocation}
                    type="origin"
                    onSubmit={(data) =>
                      handleOnLocationFilterChange('originEntityLocation', data)
                    }
                  />
                </ShipmentFilter>
                <ShipmentFilter filterName="Destino">
                  <CityNameFilter
                    data={filters.destinationEntityLocation}
                    type="destination"
                    onSubmit={(data) =>
                      handleOnLocationFilterChange(
                        'destinationEntityLocation',
                        data
                      )
                    }
                  />
                </ShipmentFilter>
                {![
                  OrganizationType.Carrier,
                  OrganizationType.CartageAgent,
                ].includes(userType) && (
                  <>
                    <ShipmentFilter filterName="Remetente">
                      <EntityFilter
                        data={filters.senderIds}
                        entities={userEntities}
                        entityResponsabilityType={
                          EntityResponsabilityType.Sender
                        }
                        isLoading={isLoadingEntities}
                        onChange={handleOnSenderIdsChange}
                      />
                    </ShipmentFilter>
                    <ShipmentFilter filterName="Destinatário">
                      <EntityFilter
                        data={filters.recipientIds}
                        entities={userEntities}
                        entityResponsabilityType={
                          EntityResponsabilityType.Recipient
                        }
                        isLoading={isLoadingEntities}
                        onChange={handleOnRecipientIdsChange}
                      />
                    </ShipmentFilter>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={9}>
              {!isLoading && shipments.length > 0 && (
                <Grid
                  alignItems="center"
                  container
                  direction="row"
                  justifyContent="space-between"
                  item
                  xs={12}
                >
                  <Box mb="0.75rem">
                    <RefreshButton onRefresh={handleRefreshList} />
                  </Box>
                  <Box mb="0.75rem">
                    <SortShipmentsField
                      data={filters.order || SortOption.Desc}
                      onChange={handleOnOrderChange}
                    />
                  </Box>
                </Grid>
              )}

              <ShipmentList
                currentPage={page || 1}
                data={shipments}
                isLoading={isLoading}
                total={total}
                onPageChange={handleOnPageChange}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {openDownloadExcelModal && total && (
        <DownloadExcelModal
          onClose={handleCloseDownloadEcxel}
          onConfirm={handleDownloadExcel}
          onError={handleErrorDownloadExcelModal}
          open={openDownloadExcelModal}
          total={total}
        />
      )}

      <ExcelDownloadError
        open={!!downloadExcelError}
        onClose={onCleanRequests}
      />
    </>
  );
};

export default ShipmentListPage;
