import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { UncontrolledCheckbox } from '../../../../common/components/Checkbox';
import EntityData from '../../../../common/data-types/entity-data';
import EntityResponsabilityType, {
  ENTITY_RESPONSABILITY_LABEL_MAP,
} from '../../../../common/enums/entity-responsability-type.enum';
import SearchFilter from '../../../../common/components/SearchFilter';
import Spinner from '../../../../common/components/Spinner';

const useStyles = makeStyles({
  entityItem: {
    '& label': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  entityList: {
    maxHeight: 200,
    overflowY: 'scroll',
  },
});

interface EntityFilterProps {
  data: EntityData['id'][];
  entities: EntityData[];
  entityResponsabilityType: EntityResponsabilityType;
  isLoading: boolean;
  onChange: (data: EntityData['id'][]) => void;
}

const EntityFilter = ({
  data,
  entities,
  entityResponsabilityType,
  isLoading = false,
  onChange,
}: EntityFilterProps) => {
  const classes = useStyles();

  const [filteredEntities, setFilteredEntities] = useState(entities);

  const entityName = useMemo(() => {
    return ENTITY_RESPONSABILITY_LABEL_MAP.get(entityResponsabilityType);
  }, [entityResponsabilityType]);

  const handleOnFilter = useCallback((items: EntityData[]) => {
    setFilteredEntities(items);
  }, []);

  const handleOnChange = useCallback(
    (value: boolean, changedId: EntityData['id']) => {
      if (value) {
        onChange([...data, changedId]);
      } else {
        onChange(data.filter((id) => id !== changedId));
      }
    },
    [data, onChange]
  );

  useEffect(() => {
    setFilteredEntities(entities);
  }, [entities]);

  return (
    <Box>
      <Box mb={3}>
        <SearchFilter
          fullWidth
          items={entities}
          onFilter={handleOnFilter}
          placeholder={`Procure um ${entityName?.toLowerCase()}`}
        />
      </Box>
      {isLoading ? (
        <Box textAlign="center">
          <Spinner />
        </Box>
      ) : null}
      {filteredEntities.length <= 0 && !isLoading ? (
        <Typography align="center" color="textPrimary" component="p">
          Nenhum {entityName?.toLowerCase()} encontrado
        </Typography>
      ) : (
        <Box
          className={classes.entityList}
          display="flex"
          flexDirection="column"
        >
          {filteredEntities.map((entity) => (
            <Box className={classes.entityItem} key={entity.id}>
              <UncontrolledCheckbox
                label={entity.displayName || entity.name}
                checked={data.some((id) => entity.id === id)}
                onChange={(e) => handleOnChange(e.target.checked, entity.id)}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default EntityFilter;
