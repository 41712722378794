import React, { useCallback, useEffect } from 'react';

import { Box, makeStyles, Theme } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { parseNumberMask } from '../../../../common/helpers/mask.helper';
import { selectEntities } from '../../../Entities/entitiesSlice';
import {
  selectOrganizationId,
  selectSessionIsCarryingCompany,
} from '../../../Session/sessionSlice';
import { SHIPMENT_FORM_DEFAULT_VALUES } from '../../../../common/constants/shipment-form-default-value.constant';
import { ShipmentCreationData } from '../shipmentNewAPI';
import { ShipmentStatus } from '../../../../common/enums/shipment-status.enum';
import { useAppSelector } from '../../../../app/hooks';
import { volumesArrayBuilder } from '../../../../common/helpers/volumes.helper';
import DispatchType from '../../../../common/enums/dispatch-type.enum';
import EntityData from '../../../../common/data-types/entity-data';
import SubmitButton from '../../../../common/components/SubmitButton';
import withShipmentForm, {
  ShipmentFormInputs,
  WithShipmentFormProps,
} from '../../../../common/hocs/withShipmentForm';
import {
  DecodedCTe,
  DecodedCTePartials,
  DecodedNFe,
  DecodedNFePartials,
} from '../../../../common/data-types/decoded-fiscal-documents-data';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    backgroundColor: theme.palette.info.main,
  },
  displayName: {
    color: theme.palette.text.primary,
  },
  footer: {
    borderTop: '1px solid #C2C3C9',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    width: '100%',
  },
  removeButton: {
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    backgroundColor: theme.palette.error.main,
    marginRight: '0.5rem',
  },
}));

export interface ShipmentNewFormProps {
  isLoading: boolean;
  onSubmit: (shipment: ShipmentCreationData) => void;
}

const ShipmentNewForm = ({
  children,
  isLoading,
  onSubmit,
}: ShipmentNewFormProps & WithShipmentFormProps) => {
  const classes = useStyles();

  const entities = useAppSelector(selectEntities);
  const organizationId = useAppSelector(selectOrganizationId);
  const isCarryingCompany = useAppSelector(selectSessionIsCarryingCompany);

  const {
    formState: { isSubmitted, isValid },
    handleSubmit,
    reset,
    watch,
  } = useFormContext();

  const [nfesHasError, contentStatementHasError] = watch([
    'nfesHasError',
    'contentStatementHasError',
  ]);

  const getDispatchType = useCallback(() => {
    if (!isCarryingCompany) {
      return DispatchType.Regular;
    }
    return DispatchType.IntermediateRedispatch;
  }, [isCarryingCompany]);

  const toModel = useCallback(
    (data: ShipmentFormInputs): ShipmentCreationData => {
      const {
        authorizedReceiverId,
        contentStatement,
        ctes,
        declaredValue,
        destinationEntity,
        destinationShipperEntityId,
        hasContentStatement,
        nfes,
        originEntity,
        originShipperEntityId,
        recipientId,
        senderId,
        shipperCode,
        totalWeight,
        volumes,
        ...rest
      } = data;

      const sender = entities?.find(
        (entity) => entity.id === senderId
      ) as EntityData;
      const recipient = entities.find((entity) => entity.id === recipientId);

      return {
        ...rest,
        authorizedReceiver: entities?.find(
          (entity) => entity.id === authorizedReceiverId
        ),
        contentStatement: hasContentStatement
          ? {
              file: contentStatement.file,
              id: contentStatement.id,
              value: parseNumberMask(contentStatement.value),
              documentNumber: contentStatement.documentNumber,
            }
          : undefined,
        declaredValue: parseNumberMask(declaredValue),
        destinationShipperEntity: entities.find(
          (entity) => entity.id === destinationShipperEntityId
        ) as EntityData,
        dispatchType: getDispatchType(),
        dispatcher: sender,
        fiscalDocuments: nfes
          ?.filter((nfe) => !nfe.isDuplicated)
          .map((nfe) => buildFiscalDocument(nfe)),
        originShipperEntity: entities.find(
          (entity) => entity.id === originShipperEntityId
        ) as EntityData,
        previousDocuments: ctes
          ?.filter((cte) => !cte.isDuplicated)
          .map((cte) => buildPreviousDocument(cte)),
        receiver: destinationEntity
          ? entities?.find((entity) => entity.id === destinationEntity)
          : recipient,
        recipient: entities?.find(
          (entity) => entity.id === recipientId
        ) as EntityData,
        routeLegs:
          destinationEntity || originEntity
            ? [
                {
                  destinationEntity: destinationEntity
                    ? entities?.find(
                        (entity) => entity.id === destinationEntity
                      )
                    : undefined,
                  originEntity: originEntity
                    ? entities?.find((entity) => entity.id === originEntity)
                    : undefined,
                },
              ]
            : undefined,
        sender,
        shipperCode: shipperCode || undefined,
        status: ShipmentStatus.Ready,
        totalWeight: parseNumberMask(totalWeight),
        volumes: volumes && volumesArrayBuilder(volumes),
      };
    },
    [entities, getDispatchType]
  );

  function buildPreviousDocument(cte: DecodedCTe): DecodedCTePartials {
    const previousDocument = {
      cfop: cte.cfop,
      goodsValue: cte.goodsValue,
      issueDate: cte.issueDate,
      issuerDocumentNumber: cte.senderDocumentNumber,
      issuerName: cte.senderName,
      key: cte.key,
      suframa: cte.suframa,
      value: cte.value,
      volumesQuantity: cte.volumesQuantity,
    };

    return previousDocument;
  }

  function buildFiscalDocument(nfe: DecodedNFe): DecodedNFePartials {
    const previousDocument = {
      cfop: nfe.cfop,
      goodsValue: nfe.goodsValue,
      issueDate: nfe.issueDate,
      issuerDocumentNumber: nfe.senderDocumentNumber,
      issuerName: nfe.senderName,
      key: nfe.key,
      order: nfe.order,
      suframa: nfe.suframa,
      value: nfe.value,
      volumesQuantity: nfe.volumesQuantity,
      weight: (nfe.weight || 0) * 1000,
    };

    return previousDocument;
  }

  const handleOnSubmit = useCallback(
    (data: ShipmentFormInputs): void => {
      onSubmit(toModel(data));
    },
    [onSubmit, toModel]
  );

  const onSubmitForm = useCallback(
    (e) => {
      const submitterName = e.nativeEvent.submitter.name;
      if (submitterName === 'newShipmentSubmitter') {
        e.preventDefault();
        handleSubmit(handleOnSubmit)();
      }
    },
    [handleOnSubmit, handleSubmit]
  );

  useEffect(() => {
    if (organizationId) {
      reset(SHIPMENT_FORM_DEFAULT_VALUES);
    }
  }, [organizationId, reset]);

  return (
    <Box>
      <form onSubmit={onSubmitForm}>
        <Box marginBottom={10}>{children}</Box>
        <Box bgcolor="background.default" className={classes.footer}>
          <Box display="flex" justifyContent="flex-end" py={2} width="50rem">
            <SubmitButton
              classes={{ root: classes.button }}
              color="primary"
              disabled={
                (isSubmitted && !isValid) ||
                nfesHasError ||
                contentStatementHasError
              }
              isLoading={isLoading}
              name="newShipmentSubmitter"
              type="submit"
              variant="contained"
            >
              Cadastrar
            </SubmitButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default withShipmentForm<ShipmentNewFormProps>(ShipmentNewForm);
