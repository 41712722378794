import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { APP_NAME } from '../../.env';
import { selectUser } from '../../features/Session/sessionSlice';
import { useAppSelector } from '../hooks';
// import BillingDatePage from '../../features/Billing/BillingDatePage';
// import BillingPage from '../../features/Billing/BillingPage';
import EntitiesPage from '../../features/Entities/EntitiesPage';
import EntityEditPage from '../../features/Entities/EntityEditPage';
import EntityNewPage from '../../features/Entities/EntityNewPage';
import NotFoundPage from './NotFoundPage';
import OrganizationType from '../../common/enums/organization-type.enum';
import PackageEditPage from '../../features/Packages/PackageEditPage';
import PackageNewPage from '../../features/Packages/PackageNewPage';
import PackagesPage from '../../features/Packages/PackagesPage';
import PasswordEditPage from '../../features/Session/PasswordEditPage/PasswordEditPage';
import RouteWithToolbar from '../../common/components/RouteWithToolbar';
import ShipmentListPage from '../../features/Shipments/List/ShipmentListPage';
import ShipmentNewPage from '../../features/Shipments/New/ShipmentNewPage';
import TemporaryPasswordEditPage from '../../features/Session/TemporaryPasswordEditPage/TemporaryPasswordEditPage';
import UserEditPage from '../../features/Users/Edit/UserEditPage';
import UserListPage from '../../features/Users/List/UserListPage';
import UserNewPage from '../../features/Users/New/UserNewPage';
import UserProfileEditPage from '../../features/Session/UserProfileEditPage/UserProfileEditPage';

const CARRIER = OrganizationType.Carrier;
const CARTAGE_AGENT = OrganizationType.CartageAgent;
const SHIPPER = OrganizationType.Shipper;

const ShipperRoutes = () => {
  const user = useAppSelector(selectUser);

  const CARRIER_CARTAGEAGENT_SHIPPER = [CARRIER, CARTAGE_AGENT, SHIPPER];
  const CARRIER_SHIPPER = [CARRIER, SHIPPER];

  return (
    <Switch>
      {/* region Root */}
      <Route exact path="/">
        {user.isPasswordTemporary ? (
          <Redirect to="/password/temporary" />
        ) : (
          <Redirect to="/shipments" />
        )}
      </Route>
      {/* endregion Root */}

      {/* region Password */}
      <Route path="/password/temporary">
        <TemporaryPasswordEditPage title={`${APP_NAME} | Alteração de Senha`} />
      </Route>
      {/* endregion Password */}

      {/* region Shipments */}
      <RouteWithToolbar
        allowedOrganizationTypes={CARRIER_CARTAGEAGENT_SHIPPER}
        exact
        path="/shipments"
      >
        <ShipmentListPage title={`${APP_NAME} | Envios`} />
      </RouteWithToolbar>
      <RouteWithToolbar
        allowedOrganizationTypes={[SHIPPER]}
        path="/shipments/new"
      >
        <ShipmentNewPage title={`${APP_NAME} | Novo Envio`} />
      </RouteWithToolbar>
      {/* endregion Shipments */}

      {/* region Entities */}
      <RouteWithToolbar
        allowedOrganizationTypes={CARRIER_CARTAGEAGENT_SHIPPER}
        exact
        path="/entities"
      >
        <EntitiesPage title={`${APP_NAME} | Participantes`} />
      </RouteWithToolbar>
      <RouteWithToolbar
        allowedOrganizationTypes={CARRIER_CARTAGEAGENT_SHIPPER}
        path="/entities/new"
      >
        <EntityNewPage title={`${APP_NAME} | Novo Participante`} />
      </RouteWithToolbar>
      <RouteWithToolbar
        allowedOrganizationTypes={CARRIER_CARTAGEAGENT_SHIPPER}
        path="/entities/:id/edit"
      >
        <EntityEditPage title={`${APP_NAME} | Editar Participante`} />
      </RouteWithToolbar>
      {/* endregion Entities */}

      {/* region User */}
      <RouteWithToolbar
        allowedOrganizationTypes={CARRIER_CARTAGEAGENT_SHIPPER}
        path="/user/password"
      >
        <PasswordEditPage title={`${APP_NAME} | Minha Conta`} />
      </RouteWithToolbar>
      <RouteWithToolbar
        allowedOrganizationTypes={CARRIER_CARTAGEAGENT_SHIPPER}
        path="/user/profile"
      >
        <UserProfileEditPage title={`${APP_NAME} | Minha Conta`} />
      </RouteWithToolbar>
      {/* endregion User */}

      {/* region Packages */}
      <RouteWithToolbar
        allowedOrganizationTypes={[SHIPPER]}
        exact
        path="/packages"
      >
        <PackagesPage title={`${APP_NAME} | Embalagens`} />
      </RouteWithToolbar>
      <RouteWithToolbar
        allowedOrganizationTypes={[SHIPPER]}
        path="/packages/new"
      >
        <PackageNewPage title={`${APP_NAME} | Nova Embalagem`} />
      </RouteWithToolbar>
      <RouteWithToolbar
        allowedOrganizationTypes={[SHIPPER]}
        path="/packages/:id/edit"
      >
        <PackageEditPage title={`${APP_NAME} | Editar Embalagem`} />
      </RouteWithToolbar>
      {/* endregion Packages */}

      {/* region Billing */}
      {/* <RouteWithToolbar
        allowedOrganizationTypes={[SHIPPER]}
        path="/billing/details"
      >
        <BillingPage title={`${APP_NAME} | Faturamento`} />
      </RouteWithToolbar>
      <RouteWithToolbar allowedOrganizationTypes={[SHIPPER]} path="/billing">
        <BillingDatePage title={`${APP_NAME} | Data de Faturamento`} />
      </RouteWithToolbar> */}
      {/* endregion Billing */}

      {/* region Users */}
      <RouteWithToolbar
        allowAdminOnly
        allowedOrganizationTypes={CARRIER_SHIPPER}
        exact
        path="/users"
      >
        <UserListPage title={`${APP_NAME} | Lista de Usuários`} />
      </RouteWithToolbar>
      <RouteWithToolbar
        allowAdminOnly
        allowedOrganizationTypes={CARRIER_CARTAGEAGENT_SHIPPER}
        path="/users/new"
      >
        <UserNewPage title={`${APP_NAME} | Novo Usuário`} />
      </RouteWithToolbar>
      <RouteWithToolbar
        allowAdminOnly
        allowedOrganizationTypes={CARRIER_CARTAGEAGENT_SHIPPER}
        path="/users/:id/edit"
      >
        <UserEditPage title={`${APP_NAME} | Editar Usuário`} />
      </RouteWithToolbar>
      {/* endregion Users */}

      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default ShipperRoutes;
