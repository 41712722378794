import React, { useEffect } from 'react';

import { Box, makeStyles, Theme } from '@material-ui/core';
import { useWatch } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PasswordEditFields } from '../../../../common/components/PasswordEditForm';
import FeedbackMessage from '../../../../common/components/FeedbackMessage';
import PasswordEditData from '../../../../common/data-types/password-edit-data';
import withPasswordForm, {
  WithPasswordFormProps,
  PasswordEditFormData,
} from '../../../../common/hocs/withPasswordForm';

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    backgroundColor: theme.palette.info.main,
  },
}));

export interface PasswordEditFormProps extends WithPasswordFormProps {
  error: Record<string, any> | null;
  isLoading: boolean;
  isSucceeded: boolean;
  onCleanError: () => void;
  onSubmit: (data: PasswordEditData) => void;
}

const PasswordEditForm = ({
  error,
  form,
  isLoading,
  isSucceeded,
  onCleanError,
  onSubmit,
}: PasswordEditFormProps) => {
  const classes = useStyles();

  const {
    control,
    formState: { isSubmitted, isValid, isSubmitSuccessful, isDirty },
    handleSubmit,
    reset,
  } = form;

  const fieldValues = useWatch({
    control,
  });

  const canSubmit = !error && !isLoading;

  const handleOnSubmit = (data: PasswordEditFormData) => {
    const { currentPassword, password } = data;

    if (canSubmit) {
      onSubmit({
        currentPassword,
        password,
      });
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(fieldValues, { keepIsSubmitted: true });
    }
  }, [fieldValues, isSubmitSuccessful, reset]);

  useEffect(() => {
    if ((isSucceeded || error) && isDirty) {
      onCleanError();
    }
  }, [error, isDirty, isSucceeded, onCleanError]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <PasswordEditFields control={control} />
      <Box marginTop={7}>
        <Button
          className={classes.submitButton}
          color="primary"
          disabled={isSubmitted && (!canSubmit || !isValid)}
          fullWidth
          type="submit"
          variant="contained"
        >
          {isLoading ? <CircularProgress /> : 'Alterar Senha'}
        </Button>
        <Box display="flex" justifyContent="center" mt={3}>
          {error?.code === 'invalid_param' && (
            <FeedbackMessage message="Senha atual inválida" severity="error" />
          )}
          {isSucceeded && (
            <FeedbackMessage
              message="Senha alterada"
              severity="success"
              showIcon
            />
          )}
        </Box>
      </Box>
    </form>
  );
};

export default withPasswordForm<
  Omit<PasswordEditFormProps, keyof WithPasswordFormProps>
>(PasswordEditForm);
