import React, { useCallback, useEffect, useMemo } from 'react';

import { darken, makeStyles, Theme } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  cleanRequests,
  selectUsersErrorCreate,
  selectUsersIsRequestingCreate,
} from '../../usersSlice';
import { HttpClientErrorCode } from '../../../../common/errors/clients/http-client.error';
import SubmitButton from '../../../../common/components/SubmitButton';
import USER_SCHEMA from '../../../../common/schemas/user-schema';
import UserCreationData from '../../../../common/data-types/user-creation-data';
import UserFormFields from '../../../../common/components/UserFormFields';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&:hover': {
      backgroundColor: darken('#1976D2', 0.2),
    },
    backgroundColor: '#1976D2',
  },
  container: {
    backgroundColor: '#FFFFFF',
    border: '1px solid',
    borderColor: '#C2C3C9',
    borderRadius: 3,
    padding: 30,
    width: 800,
    marginBottom: 200,
  },
  errorText: {
    color: theme.palette.error.dark,
    marginTop: 32,
  },
  footer: {
    borderTop: '1px solid #C2C3C9',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    width: '100vw',
  },
}));

export interface UserNewFormData {
  email: string;
  isAdmin: boolean;
  name: string;
  phone: string;
}

interface UserNewFormProps {
  onSubmit: (data: UserCreationData) => void;
}

const UserNewForm = ({ onSubmit }: UserNewFormProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectUsersIsRequestingCreate);
  const error = useAppSelector(selectUsersErrorCreate);

  const methods = useForm<UserNewFormData>({
    defaultValues: {
      email: '',
      isAdmin: false,
      name: '',
      phone: '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(USER_SCHEMA),
    reValidateMode: 'onChange',
  });

  const {
    formState: { isValid, isSubmitted, isSubmitSuccessful, isDirty },
    handleSubmit,
    watch,
    reset,
  } = methods;

  const fieldValues = watch();

  const errorMessage = useMemo<string | undefined>(() => {
    if (error?.code === HttpClientErrorCode.Uniqueness) {
      return 'E-mail já cadastrado';
    }
    if (error?.code === HttpClientErrorCode.InvalidParam) {
      return 'E-mail inválido';
    }
    return undefined;
  }, [error]);

  const toModel = (data: UserNewFormData): UserCreationData => {
    const { email, name, isAdmin, phone } = data;

    const parsedPhone = phone.replace(/\D/g, '');

    return {
      cellphone: parsedPhone !== '' ? parsedPhone : undefined,
      email,
      isAdmin,
      name,
    };
  };

  const handleOnSubmit = useCallback(
    (data: UserNewFormData) => {
      onSubmit(toModel(data));
    },
    [onSubmit]
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(fieldValues, { keepIsSubmitted: true });
    }
  }, [fieldValues, isSubmitSuccessful, reset]);

  useEffect(() => {
    if (error && isDirty) {
      dispatch(cleanRequests());
    }
  }, [dispatch, error, isDirty]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <FormProvider {...methods}>
        <Box className={classes.container}>
          <UserFormFields />
          {!!error && !!errorMessage && (
            <Typography className={classes.errorText}>
              {errorMessage}
            </Typography>
          )}
        </Box>
        <Box bgcolor="background.default" className={classes.footer}>
          <Box display="flex" justifyContent="flex-end" py={2} width="50rem">
            <SubmitButton
              classes={{ root: classes.button }}
              color="primary"
              disabled={(isSubmitted && !isValid) || isLoading || !!error}
              isLoading={isLoading}
              type="submit"
              variant="contained"
            >
              Cadastrar
            </SubmitButton>
          </Box>
        </Box>
      </FormProvider>
    </form>
  );
};

export default UserNewForm;
