import React, { useCallback, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { ERROR_MESSAGES } from '../../constants/error-messages';
import ContentStatementFormFields from './ContentStatementFormFields';

const STRING_SCHEMA = yup.string().required(ERROR_MESSAGES.string.required);

export const CONTENT_STATEMENT_SCHEMA = yup.object().shape({
  documentNumber: STRING_SCHEMA,
  value: STRING_SCHEMA,
});

const FILE_MAX_SIZE = {
  statementFile: 2 * 1000 * 1000, // 2MB
};

const ALLOWED_EXTENSIONS = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.tiff',
  '.bmp',
  '.pdf',
];

export interface ContentStatementFormInputs {
  documentNumber?: string;
  file?: File;
  id?: number;
  value: string;
}

interface ContentStatementFormProps {
  onChangeDocumentType?: () => void;
}

const ContentStatementForm = ({
  onChangeDocumentType,
}: ContentStatementFormProps) => {
  const { setValue } = useFormContext();

  const [hasTypeError, setHasTypeError] = useState(false);

  const handleRemoveFile = useCallback(() => {
    setHasTypeError(false);

    setValue('contentStatement', {
      documentNumber: '',
      file: undefined,
      value: '',
    });

    setValue('hasContentStatement', false);
  }, [setValue]);

  const handleOnChangeFile = useCallback(
    (file: File | null) => {
      setHasTypeError(false);

      if (file) {
        // Verifica a extensão do arquivo
        const fileExtension = file.name
          .slice(file.name.lastIndexOf('.') + 1)
          .toLowerCase();
        if (!ALLOWED_EXTENSIONS.includes(`.${fileExtension.toLowerCase()}`)) {
          setValue('contentStatementHasError', true);
          setHasTypeError(true);

          return;
        }
        if (file && file.size >= FILE_MAX_SIZE.statementFile) {
          setValue('contentStatementHasError', true);

          return;
        }
        setValue('contentStatementHasError', false);
        setValue('hasContentStatement', false);
      }

      setValue('contentStatement.file', file);
      setValue('hasContentStatement', true);
    },
    [setValue]
  );

  return (
    <ContentStatementFormFields
      fileErrorMessage={
        hasTypeError
          ? ERROR_MESSAGES.file.fileTipe
          : ERROR_MESSAGES.file.fileSize('2 MB')
      }
      namePrefix="contentStatement"
      onChangeDocumentType={onChangeDocumentType}
      onChangeFile={handleOnChangeFile}
      onRemoveFile={handleRemoveFile}
    />
  );
};

export default ContentStatementForm;
