import React, { useEffect } from 'react';
import { buildShipmentIdentifier } from '../../helpers/shipment-code.helper';
import { useAppSelector } from '../../../app/hooks';
import {
  selectErrorDownloadMinutaById,
  selectIsFulfilledDownloadMinutaById,
  selectIsRequestingDownloadMinutaById,
} from '../../../features/Shipments/shipmentsSlice';
import ConfirmationModal from './ConfirmationModal';
import ShipmentListData from '../../data-types/shipment-list-data';

interface TagFormatModalProps {
  onClose: () => void;
  onConfirm: () => void;
  onError: () => void;
  open: boolean;
  shipment: ShipmentListData;
}

const DownloadMinutaModal = ({
  onClose,
  onConfirm,
  onError,
  open,
  shipment,
}: TagFormatModalProps) => {
  const downloadMinutaError = useAppSelector(selectErrorDownloadMinutaById);
  const downloadMinutaIsLoading = useAppSelector(
    selectIsRequestingDownloadMinutaById
  );
  const downloadMinutaIsSucceeded = useAppSelector(
    selectIsFulfilledDownloadMinutaById
  );

  function isButtonDisabled(): boolean {
    if (downloadMinutaIsLoading || downloadMinutaError !== null) {
      return true;
    }
    return false;
  }

  const handleConfirm = () => {
    onConfirm();
  };

  useEffect(() => {
    if (downloadMinutaError) {
      onError();
    }
  }, [downloadMinutaError, onError]);

  useEffect(() => {
    if (downloadMinutaIsSucceeded) {
      onClose();
    }
  }, [downloadMinutaIsSucceeded, onClose]);

  return (
    <ConfirmationModal
      content={`Para baixar a minuta "${buildShipmentIdentifier(
        shipment
      )}" clique no botão de Download.`}
      confirmButtonLabel="Download"
      disabledConfirmButton={isButtonDisabled()}
      fullWidth
      isLoading={downloadMinutaIsLoading}
      onCancel={onClose}
      onConfirm={handleConfirm}
      open={open}
      title="Deseja fazer download da minuta?"
    />
  );
};

export default DownloadMinutaModal;
