enum ShippingEventFileCategory {
  DeliveryPhoto = 'deliveryPhoto',
  Signature = 'signature',
}

export const SHIPPING_EVENT_FILE_CATEGORIES = [
  ShippingEventFileCategory.DeliveryPhoto,
  ShippingEventFileCategory.Signature,
];

export const SHIPPING_EVENT_FILE_CATEGORY_LABEL_MAP = new Map([
  [ShippingEventFileCategory.DeliveryPhoto, 'Foto da Carga'],
  [ShippingEventFileCategory.Signature, 'Assinatura'],
]);

export default ShippingEventFileCategory;
