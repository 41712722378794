import React, { useCallback } from 'react';

import { Box } from '@material-ui/core';

import { packageRemoveThunk } from '../../../features/Packages/packagesSlice';
import { useAppDispatch } from '../../../app/hooks';
import PackageData from '../../data-types/package-data';
import PackageListItem from './Item';

interface PackageListProps {
  packages: PackageData[];
}

const PackageList = ({ packages }: PackageListProps) => {
  const dispatch = useAppDispatch();

  const handlePackageRemove = useCallback(
    (id: PackageData['id']) => {
      dispatch(packageRemoveThunk({ id }));
    },
    [dispatch]
  );

  return (
    <Box>
      {packages.map((p, index) => (
        <PackageListItem
          key={index}
          packageItem={p}
          onRemove={handlePackageRemove}
        />
      ))}
    </Box>
  );
};

export default PackageList;
