import React, { useCallback, useEffect, useState } from 'react';

import { Box, Card, CardContent, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';

import {
  cleanRequests,
  passwordEditByTokenThunk,
  selectSessionErrorPasswordEditByToken,
  selectSessionIsFulfilledPasswordEditByToken,
  selectSessionIsRequestingPasswordEditByToken,
} from '../sessionSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useUrlParams } from '../../../common/hooks/useUrlParams';
import GenericErrorDialog from '../../../common/components/GenericErrorDialog';
import logo from '../../../common/assets/images/ebb-logo-light.svg';
import PasswordForm from './components/PasswordForm';

const useStyles = makeStyles({
  card: {
    margin: 'auto',
    marginTop: -110,
    maxWidth: 424,
  },
  cardContent: {
    padding: 64,
    paddingTop: 32,
  },
});

interface Props {
  title: string;
}

const ResetPasswordPage = ({ title }: Props) => {
  const classes = useStyles();

  const history = useHistory();

  const { getUrlParam } = useUrlParams();

  const dispatch = useAppDispatch();

  const error = useAppSelector(selectSessionErrorPasswordEditByToken);
  const isLoading = useAppSelector(
    selectSessionIsRequestingPasswordEditByToken
  );
  const isSucceeded = useAppSelector(
    selectSessionIsFulfilledPasswordEditByToken
  );

  const [token, setToken] = useState<string>('');

  const handleSubmit = useCallback(
    (data) => {
      const { password } = data;
      dispatch(
        passwordEditByTokenThunk({
          token,
          password,
        })
      );
    },
    [dispatch, token]
  );

  useEffect(() => {
    if (isSucceeded) {
      history.replace('/');
    }
  }, [history, isSucceeded]);

  useEffect(() => {
    const urlToken = getUrlParam('token');
    setToken(urlToken || '');
  }, [getUrlParam]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    return () => {
      dispatch(cleanRequests());
    };
  }, [dispatch]);

  return (
    <>
      <GenericErrorDialog error={error} />
      <Box bgcolor="primary.main" pb={24} pt={3} px={5}>
        <img alt="Logo da Enviabybus" src={logo} title="Enviabybus" />
      </Box>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <PasswordForm
            error={error}
            isLoading={isLoading}
            onSubmit={handleSubmit}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default ResetPasswordPage;
