import React from 'react';

import { Theme } from '@material-ui/core/styles/createTheme';
import Box from '@material-ui/core/Box';
import dayjs from 'dayjs';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import CopyButton from './CopyButton';
import CteData from '../data-types/cte-data';
import DocumentViewButton from './DocumentViewButton';
import SkeletonField from './SkeletonField';

const useStyles = makeStyles((theme: Theme) => ({
  copyButton: {
    padding: '0 0.75rem',
  },
  label: {
    fontSize: 12,
    fontWeight: 300,
  },
  title: {
    marginBottom: 20,
  },
  value: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface ShipmentCteProps {
  cte?: CteData;
  isLoading: boolean;
}

const ShipmentCte = ({ cte, isLoading }: ShipmentCteProps) => {
  const classes = useStyles();

  return (
    <Box mb={5}>
      <SkeletonField height="large" isLoading={isLoading} width="large">
        <Typography className={classes.title} color="primary">
          CT-e (Conhecimento de Transporte Eletrônico)
        </Typography>
      </SkeletonField>
      <Grid alignItems="center" container>
        <Grid item lg={5} md={7}>
          <SkeletonField isLoading={isLoading} width="small">
            <Typography className={classes.label}>Chave</Typography>
          </SkeletonField>
          <Box>
            <SkeletonField isLoading={isLoading} width="large">
              <Typography
                className={classes.value}
                color="textPrimary"
                component="strong"
              >
                {cte?.key}
              </Typography>
              <CopyButton className={classes.copyButton} value={cte?.key} />
            </SkeletonField>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <SkeletonField isLoading={isLoading} width="small">
            <Typography className={classes.label}>Número</Typography>
          </SkeletonField>
          <SkeletonField isLoading={isLoading} width="small">
            <Typography
              className={classes.value}
              color="textPrimary"
              component="strong"
            >
              {cte?.number || '---'}
            </Typography>
          </SkeletonField>
        </Grid>
        <Grid item xs={1}>
          <SkeletonField isLoading={isLoading} width="small">
            <Typography className={classes.label}>Série</Typography>
          </SkeletonField>
          <SkeletonField isLoading={isLoading} width="small">
            <Typography
              className={classes.value}
              color="textPrimary"
              component="strong"
            >
              {cte?.serie != null ? cte.serie : '---'}
            </Typography>
          </SkeletonField>
        </Grid>
        <Grid item xs={2}>
          <SkeletonField isLoading={isLoading} width="small">
            <Typography className={classes.label}>Data Emissão</Typography>
          </SkeletonField>
          <SkeletonField isLoading={isLoading} width="small">
            <Typography
              className={classes.value}
              color="textPrimary"
              component="strong"
            >
              {dayjs(cte?.issueDate).format('DD/MM/YYYY HH:mm')}
            </Typography>
          </SkeletonField>
        </Grid>
        <Grid item xs={2}>
          {cte?.auxiliaryDocument && (
            <DocumentViewButton
              label="Ver DACTe"
              fileParams={{ file: cte?.auxiliaryDocument.url }}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShipmentCte;
