import React, { useMemo } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormContext, useWatch } from 'react-hook-form';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import { selectShipmentIsRequestingVolumes } from '../../../Shipments/shipmentsSlice';
import { ShippingEventNewFormInputs } from '../ShippingEventNewDialog';
import { useAppSelector } from '../../../../app/hooks';
import PackageIcon from '../../../../common/components/PackageIcon';
import ShippingEventVolumeListItem from './Item';
import Spinner from '../../../../common/components/Spinner';
import VolumeData from '../../../../common/data-types/volume-data';

interface ShippingEventVolumeListProps {
  volumes: VolumeData[];
  volumesQuantity: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  accordionDetailsRoot: {
    display: 'block',
  },
  accordionRoot: {
    border: '1px solid #C2C3C9',
  },
  accordionSummaryRoot: {
    alignItems: 'center',
    display: 'flex',
  },
  packageIconRoot: {
    marginRight: 13,
  },
  volumesQuantity: {
    backgroundColor: theme.palette.info.main,
    borderRadius: 4,
    padding: '0 5px',
    display: 'flex',
    alignItems: 'center',
  },
  volumesQuantityTypographyRoot: {
    color: 'white',
    fontWeight: theme.typography.fontWeightBold,
  },
  spinner: {
    color: 'white',
  },
  volumesTypographyRoot: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: 11,
  },
}));

const ShippingEventVolumeList = ({
  volumes,
  volumesQuantity = 0,
}: ShippingEventVolumeListProps) => {
  const classes = useStyles();

  const isLoading = useAppSelector(selectShipmentIsRequestingVolumes);

  const { control } = useFormContext<ShippingEventNewFormInputs>();

  const codes = useWatch({ control, name: 'codes' });

  const selectedCodesQuantity = useMemo<number>(() => {
    const values = Object.values(codes);
    const selected = values.filter((value) => Boolean(value));

    return selected.length;
  }, [codes]);

  return (
    <Accordion
      classes={{ root: classes.accordionRoot }}
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
    >
      <AccordionSummary
        classes={{ root: classes.accordionSummaryRoot }}
        expandIcon={<ExpandMoreIcon />}
      >
        <PackageIcon classes={{ root: classes.packageIconRoot }} />
        <Typography classes={{ root: classes.volumesTypographyRoot }}>
          Volumes
        </Typography>
        <Box className={classes.volumesQuantity}>
          {isLoading ? (
            <Spinner size={12} classes={{ root: classes.spinner }} />
          ) : (
            <Typography
              classes={{ root: classes.volumesQuantityTypographyRoot }}
            >
              {selectedCodesQuantity} / {volumesQuantity}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
        {volumes.map((volume) => (
          <ShippingEventVolumeListItem key={volume.id} volume={volume} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default ShippingEventVolumeList;
