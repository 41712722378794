import {
  AnyAction,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';

import { createShippingEventByType } from './shippingEventsAPI';
import { RootState } from '../../app/store';
import AccountablePersonData from '../../common/data-types/accountable-person-data';
import ErrorData from '../../common/data-types/error-data';
import RequestData from '../../common/data-types/request-data';
import RequestStatus from '../../common/enums/request-status.enum';
import ShippingEventFileData from '../../common/data-types/shipping-event-file-data';
import ShippingEventType from '../../common/enums/shipping-event-type.enum';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  const startsWith = action.type.startsWith('shippingEvents');
  const endsWith = action.type.endsWith('/fulfilled');

  return startsWith && endsWith;
};

const isPendingAction = (action: AnyAction): action is PendingAction => {
  const startsWith = action.type.startsWith('shippingEvents');
  const endsWith = action.type.endsWith('/pending');

  return startsWith && endsWith;
};

const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  const startsWith = action.type.startsWith('shippingEvents');
  const endsWith = action.type.endsWith('/rejected');

  return startsWith && endsWith;
};

// #region Thunks
const CREATE_SHIPPING_EVENT_BY_TYPE_THUNK = 'shippingEvents/createByType';
interface CreateShippingEventByTypeThunkParams {
  codes: Record<string, boolean>;
  comment?: string;
  date: Date;
  files: ShippingEventFileData[];
  responsible?: AccountablePersonData;
  type: ShippingEventType;
}
export const createShippingEventByTypeThunk = createAsyncThunk(
  CREATE_SHIPPING_EVENT_BY_TYPE_THUNK,
  async (params: CreateShippingEventByTypeThunkParams) => {
    const response = await createShippingEventByType(params);

    return response;
  }
);
// #endregion Thunks

// #region Slice
interface ShippingEventsState {
  requestIds: RequestData['id'][];
  requestsById: Record<RequestData['id'], RequestData>;
}

const initialState: ShippingEventsState = {
  requestIds: [],
  requestsById: {},
};

const shippingEventsSlice = createSlice({
  name: 'shippingEvents',
  initialState,
  reducers: {
    cleanRequests: (state: ShippingEventsState): void => {
      const { requestIds, requestsById } = initialState;

      state.requestIds = requestIds;
      state.requestsById = requestsById;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        isFulfilledAction,
        (state: ShippingEventsState, action: AnyAction): void => {
          const { meta } = action;
          const request = state.requestsById[meta.requestId];

          if (!request) {
            return;
          }

          request.error = undefined;
          request.status = RequestStatus.Succeeded;
          request.type = action.type;
        }
      )
      .addMatcher(
        isPendingAction,
        (state: ShippingEventsState, action: AnyAction): void => {
          const { meta, type } = action;
          const { requestId } = meta;

          state.requestIds.push(requestId);

          const request: RequestData = {
            id: requestId,
            status: RequestStatus.Loading,
            type,
          };

          state.requestsById[requestId] = request;
        }
      )
      .addMatcher(
        isRejectedAction,
        (state: ShippingEventsState, action: AnyAction): void => {
          const { error, meta, type } = action;
          const { requestId } = meta;

          state.requestIds.push(requestId);

          const request: RequestData = {
            id: requestId,
            status: RequestStatus.Failed,
            type,
            error,
          };

          state.requestsById[requestId] = request;
        }
      ),
});
// #endregion Slice

// #region Selectors
// #region Private Selectors
const selectShippingEventsIsFulfilledByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const fullfiledType = [type, 'fulfilled'].join('/');
  const request = selectShippingEventsRequestByType(rootState, fullfiledType);

  return request ? request.status === RequestStatus.Succeeded : false;
};

const selectShippingEventsIsRequestingByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const pendingType = [type, 'pending'].join('/');
  const request = selectShippingEventsRequestByType(rootState, pendingType);

  return request ? request.status === RequestStatus.Loading : false;
};

const selectShippingEventsRequestByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData => {
  const requests = selectShippingEventsRequestsByType(rootState, type);

  return requests.reverse()[0];
};

const selectShippingEventsRequestErrorByType = (
  rootState: RootState,
  type: RequestData['type']
): ErrorData | Record<string, any> | undefined => {
  const rejectedType = [type, 'rejected'].join('/');
  const request = selectShippingEventsRequestByType(rootState, rejectedType);

  return request ? request.error : undefined;
};

const selectShippingEventsRequests = (rootState: RootState): RequestData[] => {
  const state = selectShippingEventsState(rootState);

  return state.requestIds.map((id) => state.requestsById[id]);
};

const selectShippingEventsRequestsByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData[] => {
  const requests = selectShippingEventsRequests(rootState);

  return requests.filter((r) => r.type === type);
};

const selectShippingEventsState = (
  rootState: RootState
): ShippingEventsState => {
  return rootState.shippingEvents;
};
// #endregion Private Selectors

// #region Public Selectors
export const selectShippingEventsCreateError = (
  rootState: RootState
): RequestData['error'] =>
  selectShippingEventsRequestErrorByType(
    rootState,
    CREATE_SHIPPING_EVENT_BY_TYPE_THUNK
  );

export const selectShippingEventsIsRequestingCreate = (
  rootState: RootState
): boolean => {
  return selectShippingEventsIsRequestingByType(
    rootState,
    CREATE_SHIPPING_EVENT_BY_TYPE_THUNK
  );
};

export const selectShippingEventsIsFulfilledCreate = (
  rootState: RootState
): boolean =>
  selectShippingEventsIsFulfilledByType(
    rootState,
    CREATE_SHIPPING_EVENT_BY_TYPE_THUNK
  );
// #endregion Public Selectors
// #endregion Selectors

export const { cleanRequests } = shippingEventsSlice.actions;

export const shippingEventsReducer = shippingEventsSlice.reducer;
