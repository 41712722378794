import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

type TextBoxRemoveIconProps = SvgIconProps;

const TextBoxRemoveIcon = ({ ...props }: TextBoxRemoveIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M11.46 12.88L12.88 11.46L15 13.59L17.12 11.46L18.54 12.88L16.41 15L18.54 17.12L17.12 18.54L15 16.41L12.88 18.54L11.46 17.12L13.59 15L11.46 12.88ZM2 0H16C17.11 0 18 0.89 18 2V9.8C17.39 9.45 16.72 9.2 16 9.08V2H2V16H9.08C9.2 16.72 9.45 17.39 9.8 18H2C0.89 18 0 17.11 0 16V2C0 0.89 0.89 0 2 0ZM4 4H14V6H4V4ZM4 8H14V9.08C13.15 9.22 12.37 9.54 11.68 10H4V8ZM4 12H9V14H4V12Z"
      />
    </SvgIcon>
  );
};

export default TextBoxRemoveIcon;
