import React, { useCallback } from 'react';

import { selectUsersListFilters } from '../../usersSlice';
import { useAppSelector } from '../../../../app/hooks';
import TextSearchField from '../../../../common/components/TextSearchField';

export interface UserEmailFilterFilterProps {
  onSubmit: (data: string) => void;
}

const UserEmailFilter = ({ onSubmit }: UserEmailFilterFilterProps) => {
  const filters = useAppSelector(selectUsersListFilters);

  const handleSubmit = useCallback(
    (text: string) => {
      onSubmit(text);
    },
    [onSubmit]
  );

  const handleKeyPress = (text: string) => {
    handleSubmit(text);
  };

  return (
    <TextSearchField
      placeholder="Email"
      query={filters?.email}
      onKeyPress={handleKeyPress}
      onSubmit={handleSubmit}
    />
  );
};

export default UserEmailFilter;
