import React from 'react';

import { Control, RegisterOptions, useController } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@material-ui/core';
import { SELECT_PLACEHOLDER_VALUE } from '../constants/select-placeholder-value';

export interface SelectOption {
  label: string;
  value: string | number;
}

export interface UncontrolledSelectProps extends MuiSelectProps {
  emptyOptionsPlaceholder?: string;
  options: SelectOption[];
  placeholder?: string;
  value?: string | number;
}

export const UncontrolledSelect = ({
  emptyOptionsPlaceholder = 'Sem opções',
  options = [],
  placeholder,
  value,
  ...rest
}: UncontrolledSelectProps) => (
  <MuiSelect value={value} {...rest}>
    {placeholder && options.length > 0 && (
      <MenuItem disabled value={SELECT_PLACEHOLDER_VALUE}>
        {placeholder}
      </MenuItem>
    )}
    {emptyOptionsPlaceholder && options.length === 0 && (
      <MenuItem disabled value={SELECT_PLACEHOLDER_VALUE}>
        {emptyOptionsPlaceholder}
      </MenuItem>
    )}
    {options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </MuiSelect>
);

export interface SelectProps extends UncontrolledSelectProps {
  control?: Control<any>;
  defaultValue?: unknown;
  label?: string;
  labelStyle?: React.CSSProperties;
  name: string | `${string}.${string}` | `${string}.${number}`;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  shouldUnregister?: boolean;
}

const Select = ({
  control,
  defaultValue,
  fullWidth,
  label,
  labelStyle,
  name,
  rules,
  shouldUnregister,
  ...rest
}: SelectProps) => {
  const {
    field: { onBlur, onChange, value },
    fieldState: { error, invalid },
  } = useController({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <FormControl error={invalid} fullWidth={fullWidth}>
      {label && (
        <InputLabel style={labelStyle} id="select-label" shrink>
          {label}
        </InputLabel>
      )}
      <UncontrolledSelect
        {...rest}
        labelId="select-label"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
      {error?.message && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
