import React, { useCallback, useState } from 'react';

import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import Spinner from './Spinner';

const useStyles = makeStyles({
  able: {
    opacity: 1,
    '& input': {
      cursor: 'pointer',
    },
  },
  backdrop: {
    backgroundColor: 'black',
    height: '100%',
    left: 0,
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  borderColor: {
    borderColor: '#C2C3C9',
  },
  button: {
    backgroundColor: '#1976D2',
  },
  disabled: {
    opacity: 0.6,
    '& input': {
      cursor: 'not-allowed',
    },
  },
  displayText: {
    color: '#606770',
    fontSize: 18,
    textAlign: 'center',
  },
  dragOverText: {
    color: 'white',
    fontSize: 18,
    position: 'relative',
    zIndex: 1,
  },
  dropArea: {
    backgroundColor: '#FAFAFA',
    borderStyle: 'dashed',
    borderWidth: '1px',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '2rem',
    position: 'relative',
    width: '100%',

    '& input': {
      height: '100%',
      left: 0,
      opacity: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 2,
    },
  },
  errorBorder: {
    borderColor: 'red',
  },
  icon: {
    fontSize: '3rem',
    position: 'relative',
  },
  iconColor: {
    color: '#B0C1D4',
  },
});

interface UncontrolledFileUploadProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  buttonText?: string;
  displayText?: string;
  dragOverText?: string;
  error?: boolean;
  errorMessage?: string;
  loading?: boolean;
  onChange?: (files: File[]) => void;
}

const Icon = ({ error, loading }: { error?: boolean; loading?: boolean }) => {
  const classes = useStyles();

  if (error) {
    return <HighlightOffIcon className={classes.icon} color="error" />;
  }
  if (loading) {
    return <Spinner className={classes.icon} />;
  }
  return (
    <InsertDriveFileIcon className={`${classes.icon} ${classes.iconColor}`} />
  );
};

export const UncontrolledFileUpload = ({
  buttonText = 'Ou escolha de seu computador',
  displayText = 'Arraste o arquivo aqui',
  dragOverText = 'Solte aqui',
  error = false,
  errorMessage = 'Arquivo inválido',
  loading = false,
  onChange = () => {},
  ...rest
}: UncontrolledFileUploadProps) => {
  const classes = useStyles();

  const [isDraggingOver, setDraggingOver] = useState(false);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files) {
        onChange(Array.from(files));
      } else {
        onChange([]);
      }
    },
    [onChange]
  );

  return (
    <label
      className={`${classes.dropArea} ${
        error ? classes.errorBorder : classes.borderColor
      } ${loading ? classes.disabled : classes.able}`}
      htmlFor="file-upload"
      onDragLeave={() => setDraggingOver(false)}
      onDragOver={() => setDraggingOver(true)}
      onDrop={() => setDraggingOver(false)}
    >
      <input disabled={loading} type="file" {...rest} onChange={handleChange} />
      {isDraggingOver && <Box className={classes.backdrop} />}
      <Grid alignItems="center" container direction="column" spacing={2}>
        <Grid item>
          <Icon error={error} loading={loading} />
        </Grid>
        <Grid item>
          {isDraggingOver ? (
            <Typography className={classes.dragOverText}>
              {dragOverText}
            </Typography>
          ) : (
            <Typography className={classes.displayText}>
              {error ? errorMessage : displayText}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            color="primary"
            size="large"
            variant="contained"
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </label>
  );
};

export interface FileUploadProps extends UncontrolledFileUploadProps {
  control?: Control<any>;
  name: string | `${string}.${string}` | `${string}.${number}`;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  shouldUnregister?: boolean;
}

const FileUpload = ({
  control,
  name,
  rules,
  shouldUnregister,
  ...rest
}: FileUploadProps) => {
  const {
    field: { onBlur, onChange },
  } = useController({ name, control, rules, shouldUnregister });

  return (
    <UncontrolledFileUpload
      name={name}
      value=""
      onBlur={onBlur}
      onChange={onChange}
      {...rest}
    />
  );
};

export default FileUpload;
