export const SHIPMENT_FORM_DEFAULT_VALUES = {
  authorizedReceiverId: 0,
  contentStatementHasError: false,
  contentStatement: {
    documentNumber: '',
    hasContentStatement: false,
    value: '',
  },
  declaredValue: 0,
  deliveryModality: undefined,
  destinationEntity: 0,
  destinationShipperEntityId: 0,
  hasContentStatement: false,
  hasNFes: false,
  nfes: [],
  nfesHasError: false,
  originEntity: 0,
  originShipperEntityId: 0,
  receiptModality: undefined,
  recipientId: 0,
  senderId: 0,
  totalWeight: 0,
  volumes: [],
};
