import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

type TextBoxCheckIconProps = SvgIconProps;

const TextBoxCheckIcon = ({ ...props }: TextBoxCheckIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M14 18L11.25 15L12.41 13.84L14 15.43L17.59 11.84L18.75 13.25L14 18ZM9.8 18H2C0.89 18 0 17.11 0 16V2C0 0.89 0.89 0 2 0H16C17.11 0 18 0.89 18 2V9.8C17.39 9.45 16.72 9.2 16 9.08V2H2V16H9.08C9.2 16.72 9.45 17.39 9.8 18ZM9 14H4V12H9V14ZM11.68 10H4V8H14V9.08C13.15 9.22 12.37 9.54 11.68 10ZM14 6H4V4H14"
      />
    </SvgIcon>
  );
};

export default TextBoxCheckIcon;
