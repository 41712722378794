import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

type TermicPrintIconProps = SvgIconProps;

const TermicPrintIcon = ({ ...props }: TermicPrintIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M2.49992 5.00001V18.3333H17.4999V20H2.49992C1.58325 20 0.833252 19.25 0.833252 18.3333V5.00001H2.49992ZM13.3333 7.50001H17.9166L13.3333 2.91667V7.50001ZM5.83325 1.66667H14.1666L19.1666 6.66667V15C19.1666 15.9167 18.4166 16.6667 17.4999 16.6667H5.83325C4.90825 16.6667 4.16658 15.9167 4.16658 15V3.33334C4.16658 2.41667 4.91658 1.66667 5.83325 1.66667ZM5.83325 3.33334V15H17.4999V9.16667H11.6666V3.33334H5.83325Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default TermicPrintIcon;
