import React, { useCallback, useMemo, useState } from 'react';

import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ERROR_MESSAGES } from '../../../../common/constants/error-messages';
import PasswordField from '../../../../common/components/PasswordField';

const schema = yup.object().shape({
  password: yup
    .string()
    .required(ERROR_MESSAGES.string.required)
    .min(6, ERROR_MESSAGES.string.min)
    .max(255, ERROR_MESSAGES.string.max),
  passwordConfirmation: yup.string().required(ERROR_MESSAGES.string.required),
});

const useStyles = makeStyles({
  confirmationInput: {
    marginBottom: 50,
  },
  error: {
    marginBottom: 10,
    textAlign: 'center',
  },
  formTitle: {
    fontSize: 23,
    marginBottom: 10,
  },
  passwordInput: {
    marginBottom: 15,
  },
});

interface FormData {
  password: string;
  passwordConfirmation: string;
}

export interface PasswordFormProps {
  error: Record<string, any> | null;
  isLoading: boolean;
  onSubmit(data: FormData): void;
}

const PasswordForm = ({ error, isLoading, onSubmit }: PasswordFormProps) => {
  const [matchError, setMatchError] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);

  const {
    control,
    formState: { isSubmitted, isValid },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: { password: '', passwordConfirmation: '' },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const fieldValues = useWatch({
    control,
  });

  useMemo(() => {
    if (fieldValues.password !== fieldValues.passwordConfirmation) {
      setMatchError(true);
    } else {
      setMatchError(false);
    }
  }, [fieldValues]);

  useMemo(() => {
    if (isSubmitted && (matchError || !isValid || isLoading)) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [isLoading, isSubmitted, isValid, matchError]);

  const classes = useStyles();

  const handleOnSubmit = useCallback(
    (data: FormData) => {
      if (canSubmit && !matchError) {
        setMatchError(false);
        onSubmit(data);
      }
    },
    [canSubmit, matchError, onSubmit]
  );

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Typography
        className={classes.formTitle}
        color="textSecondary"
        component="h2"
      >
        Alteração de Senha
      </Typography>
      <PasswordField
        className={classes.passwordInput}
        control={control}
        label="Senha"
        name="password"
        placeholder="Digite sua senha"
        fullWidth
      />
      <PasswordField
        className={classes.confirmationInput}
        control={control}
        label="Confirme sua senha"
        name="passwordConfirmation"
        placeholder="Digite sua senha novamente"
        fullWidth
      />
      {error && (
        <Typography className={classes.error} color="error" component="p">
          Não foi possível redefinir a senha.
        </Typography>
      )}
      {isSubmitted && matchError && (
        <Typography className={classes.error} color="error" component="p">
          Senha e confirmação devem ser iguais.
        </Typography>
      )}
      <Button
        color="primary"
        disabled={!canSubmit}
        type="submit"
        variant="contained"
        fullWidth
      >
        {isLoading ? <CircularProgress /> : 'Entrar'}
      </Button>
    </form>
  );
};

export default PasswordForm;
