import React, { useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  closeButton: {
    marginLeft: 'auto',
  },
  dialogContent: {
    overflowY: 'initial',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
  },
});

type ModalCloseReason = 'backdropClick' | 'escapeKeyDown';

export interface ModalProps extends DialogProps {
  actions?: React.ReactNode;
  children: React.ReactNode;
  header?: React.ReactNode;
  onClose?: () => void;
}

const Modal = ({ actions, children, header, onClose, ...rest }: ModalProps) => {
  const styles = useStyles();

  const handleOnClose = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      reason?: ModalCloseReason
    ) => {
      event.stopPropagation();

      if (reason !== 'backdropClick' && onClose) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <Dialog {...rest} onClose={handleOnClose}>
      <Box className={styles.header}>
        <DialogTitle className={styles.header}>{header}</DialogTitle>
        {onClose && (
          <IconButton className={styles.closeButton} onClick={handleOnClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        )}
      </Box>
      <DialogContent className={styles.dialogContent}>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default Modal;
