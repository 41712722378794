import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

type StickyNoteIconProps = SvgIconProps;

const StickyNoteIcon = ({ ...props }: StickyNoteIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M16.3242 0H2.18445C1.07427 0 0.176025 0.9 0.176025 2L0.186118 16C0.186118 17.1 1.08436 18 2.19454 18H12.2871L18.3427 12V2C18.3427 0.9 17.4344 0 16.3242 0ZM4.21306 5H14.3057V7H4.21306V5ZM9.25936 11H4.21306V9H9.25936V11ZM11.2779 16.5V11H16.8288L11.2779 16.5Z"
      />
    </SvgIcon>
  );
};

export default StickyNoteIcon;
