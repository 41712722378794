import { combineReducers, AnyAction, CombinedState } from '@reduxjs/toolkit';

import { billingReducer } from '../features/Billing/billingSlice';
import {
  CHANGE_ORGANIZATION_ACTION,
  sessionReducer,
} from '../features/Session/sessionSlice';
import { entitiesReducer } from '../features/Entities/entitiesSlice';
import { packagesReducer } from '../features/Packages/packagesSlice';
import { shipmentDraftReducer } from '../features/Shipments/Draft/shipmentDraftSlice';
import { shipmentNewReducer } from '../features/Shipments/New/shipmentNewSlice';
import { shipmentsReducer } from '../features/Shipments/shipmentsSlice';
import { shippingEventsReducer } from '../features/ShippingEvents/shippingEventsSlice';
import { usersReducer } from '../features/Users/usersSlice';

export const appReducer = combineReducers({
  billing: billingReducer,
  entities: entitiesReducer,
  packages: packagesReducer,
  session: sessionReducer,
  shipmentDraft: shipmentDraftReducer,
  shipmentNew: shipmentNewReducer,
  shipments: shipmentsReducer,
  shippingEvents: shippingEventsReducer,
  users: usersReducer,
});

const rootReducer = (state: CombinedState<any>, action: AnyAction) => {
  if (action.type === 'session/logout') {
    return appReducer(state, action);
  }

  if (action.type === CHANGE_ORGANIZATION_ACTION) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
