import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { isNil } from 'lodash';
import { useHistory } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectOrganizationId } from '../../Session/sessionSlice';
import {
  cleanState,
  selectUser,
  selectUsersErrorCreate,
  selectUsersIsFulfilledCreate,
  usersCreateThunk,
} from '../usersSlice';
import BackButton from '../../../common/components/BackButton';
import UserCreationData from '../../../common/data-types/user-creation-data';
import UserNewForm from './components/UserNewForm';
import TemporaryPasswordModal from '../../../common/components/Modals/TemporaryPasswordModal';
import GenericErrorDialog from '../../../common/components/GenericErrorDialog';

const useStyles = makeStyles({
  container: {
    maxWidth: '50rem',
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
  },
});

interface Props {
  title: string;
}

const UserNewPage = ({ title }: Props) => {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const organizationId = useAppSelector(selectOrganizationId);
  const user = useAppSelector(selectUser);
  const error = useAppSelector(selectUsersErrorCreate);

  const isFulfilled = useAppSelector(selectUsersIsFulfilledCreate);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSubmit = useCallback(
    (data: UserCreationData) => {
      dispatch(usersCreateThunk({ organizationId, user: data }));
    },
    [dispatch, organizationId]
  );

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    dispatch(cleanState());
    history.push('/users');
  }, [dispatch, history]);

  useEffect(() => {
    if (isFulfilled && !isNil(user?.temporaryPassword)) {
      setIsOpen(true);
    }
  }, [isFulfilled, user?.temporaryPassword]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    return () => {
      dispatch(cleanState());
    };
  }, [dispatch]);

  return (
    <Container className={classes.container} disableGutters>
      <GenericErrorDialog error={error || null} />
      <TemporaryPasswordModal
        open={isOpen}
        password={user?.temporaryPassword || ''}
        onClose={handleCloseModal}
      />
      <Box mb={3} mt={4}>
        <Grid alignItems="center" container direction="row" spacing={3}>
          <Grid item>
            <BackButton />
          </Grid>
          <Grid item>
            <Typography
              className={classes.title}
              color="primary"
              component="h2"
            >
              Novo Usuário
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <UserNewForm onSubmit={handleSubmit} />
    </Container>
  );
};

export default UserNewPage;
