import React, { useCallback } from 'react';

import { selectUsersListFilters } from '../../usersSlice';
import { useAppSelector } from '../../../../app/hooks';
import TextSearchField from '../../../../common/components/TextSearchField';

export interface UserNameFilterProps {
  onSubmit: (data: string) => void;
}

const UserNameFilter = ({ onSubmit }: UserNameFilterProps) => {
  const filters = useAppSelector(selectUsersListFilters);

  const handleSubmit = useCallback(
    (text: string) => {
      onSubmit(text);
    },
    [onSubmit]
  );

  const handleKeyPress = (text: string) => {
    handleSubmit(text);
  };

  return (
    <TextSearchField
      placeholder="Nome"
      query={filters?.name}
      onKeyPress={handleKeyPress}
      onSubmit={handleSubmit}
    />
  );
};

export default UserNameFilter;
