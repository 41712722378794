import React from 'react';

import { RouteProps as RoutePropsRR } from 'react-router';

import RouteWithValidation, { RouteProps } from './RouteWithValidation';
import Toolbar from './Toolbar';

type RouteToolbarProps = RoutePropsRR & RouteProps;

const RouteWithToolbar = ({ children, ...rest }: RouteToolbarProps) => {
  return (
    <>
      <Toolbar />
      <RouteWithValidation {...rest}>{children}</RouteWithValidation>
    </>
  );
};

export default RouteWithToolbar;
