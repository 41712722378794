import React, { useCallback, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import ContentStatementForm, {
  ContentStatementFormInputs,
} from '../ContentStatementForm/ContentStatementForm';
import {
  cleanNfes,
  cleanRequests,
  readNfeThunk,
  removeNfe,
} from '../../../features/Shipments/New/shipmentNewSlice';
import { useAppDispatch } from '../../../app/hooks';
import FormPlaceholder from '../FormPlaceholder';
import NfeFormFields from './NfeFormFields';
import { DecodedNFe } from '../../data-types/decoded-fiscal-documents-data';

export enum FiscalDocumentsFieldType {
  Closed = 'closed',
  ContentStatement = 'ContentStatement',
  FiscalDocument = 'nfe',
}

export interface FiscalDocumentsFormInputs {
  contentStatement: ContentStatementFormInputs;
  contentStatementHasError: boolean;
  hasContentStatement: boolean;
  hasNFes: boolean;
  nfes: DecodedNFe[];
  nfesHasError: boolean;
}

interface FiscalDocumentsProps {
  initialFieldState: FiscalDocumentsFieldType;
  onChangeDocumentType?: (type: FiscalDocumentsFieldType) => void;
}

const FiscalDocuments = ({
  initialFieldState = FiscalDocumentsFieldType.FiscalDocument,
  onChangeDocumentType,
}: FiscalDocumentsProps) => {
  const dispatch = useAppDispatch();

  const { setValue } = useFormContext();

  const [
    documentFieldState,
    setDocumentFieldState,
  ] = useState<FiscalDocumentsFieldType>(initialFieldState);

  const handleDocumentTypeChange = useCallback(
    (type: FiscalDocumentsFieldType) => {
      let fieldName;

      setDocumentFieldState(type);

      if (type === FiscalDocumentsFieldType.ContentStatement) {
        fieldName = 'hasNFes';

        dispatch(cleanNfes());
        setValue('nfes', []);
      }

      if (type === FiscalDocumentsFieldType.FiscalDocument) {
        fieldName = 'hasContentStatement';

        setValue('contentStatement', {
          documentNumber: '',
          file: undefined,
          value: '',
        });
      }

      setValue(fieldName, false);

      if (onChangeDocumentType) {
        onChangeDocumentType(type);
      }
    },
    [dispatch, onChangeDocumentType, setValue]
  );

  const handleRemoveNfe = (index: number) => {
    dispatch(removeNfe({ index }));
  };

  const handleOnUpload = useCallback(
    (files: File[]) => {
      dispatch(readNfeThunk(files));
    },
    [dispatch]
  );

  const handleCleanRequests = useCallback(() => dispatch(cleanRequests()), [
    dispatch,
  ]);
  return (
    <>
      {documentFieldState === FiscalDocumentsFieldType.ContentStatement && (
        <ContentStatementForm
          onChangeDocumentType={() =>
            handleDocumentTypeChange(FiscalDocumentsFieldType.FiscalDocument)
          }
        />
      )}
      {documentFieldState === FiscalDocumentsFieldType.FiscalDocument && (
        <NfeFormFields
          onClose={() =>
            handleDocumentTypeChange(FiscalDocumentsFieldType.ContentStatement)
          }
          onCleanRequests={handleCleanRequests}
          onRemove={handleRemoveNfe}
          onUpload={handleOnUpload}
        />
      )}
      {documentFieldState === FiscalDocumentsFieldType.Closed && (
        <FormPlaceholder
          icon={<InsertDriveFileIcon />}
          title="Adicionar Notas Fiscais ou Declaração de conteúdo"
          onOpen={() =>
            handleDocumentTypeChange(FiscalDocumentsFieldType.FiscalDocument)
          }
        />
      )}
    </>
  );
};

export default FiscalDocuments;
