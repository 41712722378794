import { JSONSchema4Type } from 'json-schema';
import { isNil, omitBy } from 'lodash';

import { fiscalDocumentToNfe } from '../../common/parsers/fiscal-documents.parser';
import {
  getBrudamSyncerClient,
  getGraphqlClient,
  getZordonClient,
} from '../../app/initializer';
import { HttpResponse } from '../../common/clients/http-client/http.client';
import { ShipmentBatchUpdateData } from '../../common/clients/zordon.client';
import { ShippingEventData } from '../../common/data-types/shipping-event-data';
import { ShipmentStatus } from '../../common/enums/shipment-status.enum';
import {
  SHIPMENT_CARRIER_LABEL_QUERY,
  SHIPMENT_DETAILS_QUERY,
  SHIPMENT_DOCUMENTS_QUERY,
  SHIPMENT_LIST_QUERY,
  SHIPMENT_QUERY,
  SHIPMENT_SHIPMENT_COST_QUERY,
  SHIPMENT_SHIPPING_EVENTS_QUERY,
  SHIPMENT_VOLUMES_QUERY,
  SHIPMENTS_GROUPED_BY_ORIGIN_QUERY,
} from './shipmentsGraphql';
import { ShipmentLabelTemplate } from '../../common/enums/tag-template.enum';
import { ShipmentUpdateData } from '../../common/data-types/shipment-update-data';
import AddressData from '../../common/data-types/address-data';
import EntityData from '../../common/data-types/entity-data';
import FiscalDocumentType from '../../common/enums/fiscal-document-type.enum';
import PickupData from '../../common/data-types/pickup-data';
import ShipmentCostData from '../../common/data-types/shipment-cost-data';
import ShipmentData from '../../common/data-types/shipment-data';
import ShipmentDetailsData from '../../common/data-types/shipment-details-data';
import ShipmentDocumentsData from '../../common/data-types/shipment-documents-data';
import ShipmentDraftData from '../../common/data-types/shipment-draft-data';
import ShipmentListData from '../../common/data-types/shipment-list-data';
import ShipmentListFilterData from '../../common/data-types/shipment-list-filter-data';
import ShipmentModality from '../../common/enums/shipment-modality.enum';
import ShipmentVolumeDetails from '../../common/data-types/shipment-volume-details-data';
import TagFormat from '../../common/enums/tag-format-type.enum';
import VolumeData from '../../common/data-types/volume-data';
import VolumeDraftData from '../../common/data-types/volume-draft-data';
import VolumeInformationData from '../../common/data-types/volume-information-data';

const brudamSyncerClient = getBrudamSyncerClient();
const graphqlClient = getGraphqlClient();
const zordonClient = getZordonClient();

// #region Typings
export interface DropOffsGroupedByOrigin extends UndefinedGroupedByOrigin {
  shipments: ShipmentDraftData[];
}

export interface EntityAndShipments {
  entity: EntityData;
  shipments: ShipmentData[];
}

export interface FiscalDocument {
  number: number;
  type: FiscalDocumentType;
}

export interface ListShipmentsGroupedByOriginOptions {
  carrierParentEntityId: number;
  pickupsLimit?: number;
}

export interface PickupsGroupedByOrigin extends UndefinedGroupedByOrigin {
  pickups: PickupData[];
}

export interface ShipmentByIdOrTrackingCode {
  id?: ShipmentData['id'];
  trackingCode?: ShipmentData['trackingCode'];
}

export interface ShipmentBatchUpdate {
  shipment: ShipmentBatchUpdateData;
}

export interface ShipmentsGroupedByOrigin {
  dropOffs: DropOffsGroupedByOrigin[];
  pickups: PickupsGroupedByOrigin[];
  undefined: UndefinedGroupedByOrigin[];
}

export interface ShipmentsFilter {
  becameReadyAtEnd?: string;
  becameReadyAtStart?: string;
  date?: {
    end?: string;
    start?: string;
  };
  delayed?: boolean;
  deletedDateRange?: {
    end?: string;
    start?: string;
  };
  documentIdentifier?: string;
  location?: {
    destination?: string;
    origin?: string;
  };
  onlyDeleted?: boolean;
  recipientIds?: ShipmentListFilterData['recipientIds'];
  senderIds?: ShipmentListFilterData['senderIds'];
  shipmentCodes?: ShipmentListFilterData['shipmentCodes'];
  statuses?: ShipmentStatus[];
  synced?: boolean;
}

export interface ShipmentList {
  shipments: ShipmentListData[];
  total: number;
}

export interface ShipmentListEntity {
  address: {
    location: string;
    state: string;
  };
  displayName?: string;
  id: number;
  name: string;
}

export interface ShipmentListOptions {
  filter?: ShipmentsFilter;
  first?: number;
  offset?: number;
  order?: string;
}

export interface ShipmentShippingEventsList {
  shipmentId: number;
}

export interface ShipmentRestoreData {
  id: ShipmentData['id'];
}

export interface ShipmentRestoreBatchData {
  ids: ShipmentData['id'][];
}

export interface ShipmentUpdateOpt {
  data: Partial<ShipmentUpdateData>;
  id: ShipmentData['id'];
}

export interface ShipmentUpdateStatus {
  id: number;
  status: ShipmentStatus;
}

export interface StatusUpdateError {
  context: JSONSchema4Type;
  message: string;
}

export interface UndefinedGroupedByOrigin {
  origin: EntityData;
  pendingShipments: ShipmentDraftData[];
}
// #endregion Typings

// #region Private functions
const buildFilters = ({
  date,
  deletedDateRange,
  delayed,
  documentIdentifier,
  location,
  onlyDeleted,
  recipientIds,
  senderIds,
  shipmentCodes,
  statuses,
  synced,
}: ShipmentsFilter) => {
  return omitBy(
    {
      createdAtEnd: date && date.end,
      createdAtStart: date && date.start,
      delayed: delayed || undefined,
      deletedAtEnd: deletedDateRange && deletedDateRange.end,
      deletedAtStart: deletedDateRange && deletedDateRange.start,
      destinationEntityLocation: location && location.destination,
      documentIdentifier: documentIdentifier || undefined,
      onlyDeleted,
      originEntityLocation: location && location.origin,
      recipientIds:
        recipientIds && recipientIds.length > 0 ? recipientIds : undefined,
      senderIds: senderIds && senderIds.length > 0 ? senderIds : undefined,
      shipmentCode:
        shipmentCodes && shipmentCodes.length > 0
          ? shipmentCodes[0]
          : undefined,
      statuses: statuses && statuses.length > 0 ? statuses : undefined,
      synced: synced ? !synced : undefined,
    },
    isNil
  );
};

const buildOptions = ({
  filter = {},
  first = 20,
  offset = 0,
  order = 'DESC',
}: ShipmentListOptions) => {
  return omitBy<ShipmentListOptions>(
    {
      filter: buildFilters(filter),
      first,
      offset,
      order,
    },
    isNil
  );
};

const convertGramsToKilograms = (weight: number): number => {
  const kilograms = weight / 1000;

  return Number(kilograms.toFixed(2));
};

const convertMetersToCentimeters = (measure: number): number => {
  return measure * 100;
};

const convertShipmentMeasures = (
  shipment: ShipmentDetailsData
): ShipmentDetailsData => {
  const { cubageWeight, totalWeight, volumesInformations } = shipment;

  return {
    ...shipment,
    cubageWeight: cubageWeight && convertGramsToKilograms(cubageWeight),
    totalWeight: totalWeight && convertGramsToKilograms(totalWeight),
    volumesInformations:
      volumesInformations &&
      volumesInformations.map((vi) => convertVolumeInformationMeasures(vi)),
  };
};

const convertVolumeInformationMeasures = (
  volume: VolumeInformationData
): VolumeInformationData => {
  const { cubageWeight, height, length, weight, width } = volume;

  return {
    ...volume,
    cubageWeight: cubageWeight && convertGramsToKilograms(cubageWeight),
    height: height && convertMetersToCentimeters(height),
    length: length && convertMetersToCentimeters(length),
    weight: weight && convertGramsToKilograms(weight),
    width: width && convertMetersToCentimeters(width),
  };
};

export const setVolumesStatusesByShipmentStatus = (
  volumes: VolumeData[],
  status: ShipmentStatus
): VolumeData[] => {
  if (status === ShipmentStatus.Finished) {
    return volumes.map((volume) => ({
      ...volume,
      status: ShipmentStatus.Finished,
    }));
  }

  return volumes;
};

const parseAddress = (json: Record<string, any>): AddressData => {
  return {
    cep: json?.cep,
    complement: json?.complement,
    location: json.location,
    neighborhood: json?.neighborhood,
    number: json?.number,
    state: json.state,
    street: json?.street,
  };
};

const parseEntity = (json: Record<string, any>): EntityData => {
  return {
    address: json?.address && parseAddress(json.address),
    code: json?.code,
    displayName: json?.displayName,
    documentNumber: json?.documentNumber,
    email: json?.email,
    id: json?.id,
    name: json?.name,
    phone: json?.phone,
    stateRegistration: json?.stateRegistration,
    type: json?.type,
  };
};

const parseShipmentModality = (modality: string): ShipmentModality => {
  if (modality === ShipmentModality.Counter) {
    return ShipmentModality.Counter;
  }
  if (modality === ShipmentModality.Door) {
    return ShipmentModality.Door;
  }

  return ShipmentModality.Undefined;
};

const parseShipmentStatus = (status: string): ShipmentStatus => {
  switch (status) {
    case ShipmentStatus.Cancelled:
      return ShipmentStatus.Cancelled;
      break;
    case ShipmentStatus.Draft:
      return ShipmentStatus.Draft;
      break;
    case ShipmentStatus.Finished:
      return ShipmentStatus.Finished;
      break;
    case ShipmentStatus.InTransit:
      return ShipmentStatus.InTransit;
      break;
    case ShipmentStatus.Ready:
      return ShipmentStatus.Ready;
      break;
    case ShipmentStatus.ToWithdrawal:
      return ShipmentStatus.ToWithdrawal;
      break;
    default:
      return ShipmentStatus.Unknown;
      break;
  }
};

const parseVolume = (json: Record<string, any>): VolumeDraftData => {
  return {
    carrierCode: json.carrierCode,
    code: json.code,
    id: json.id,
    sequentialNumber: json.sequentialNumber,
    status: json.status,
    trackingCode: json.trackingCode,
  };
};

const parseVolumeInformations = (json: Record<string, any>) => {
  return {
    codes: json.codes,
    cubageWeight: json.cubageWeight,
    height: json.height,
    length: json.length,
    quantity: json.quantity,
    weight: json.weight,
    width: json.width,
  };
};

const parseShipmentDraft = (json: Record<string, any>): ShipmentDraftData => {
  const {
    authorizedReceiver,
    contentStatement,
    cte,
    declaredValue,
    deliveryModality,
    destinationShipperEntity,
    fiscalDocuments,
    id,
    organizationId,
    originShipperEntity,
    receiptModality,
    receiver,
    recipient: recipientData,
    routeLegs,
    sender: senderData,
    status,
    syncedAt,
    totalWeight,
    trackingCode,
    volumes,
    volumesInformations,
    volumesQuantity,
  } = json;

  const recipient = parseEntity(recipientData);
  const sender = parseEntity(senderData);

  return {
    authorizedReceiver: parseEntity(authorizedReceiver),
    contentStatement,
    cte,
    declaredValue,
    deliveryModality: parseShipmentModality(deliveryModality),
    destinationShipperEntity: parseEntity(destinationShipperEntity),
    fiscalDocuments: fiscalDocuments.map((f) =>
      fiscalDocumentToNfe(f, recipient.documentNumber, sender.documentNumber)
    ),
    id,
    organizationId: organizationId && +organizationId,
    originShipperEntity: parseEntity(originShipperEntity),
    receiptModality: parseShipmentModality(receiptModality),
    receiver,
    recipient: parseEntity(recipient),
    routeLegs,
    sender: parseEntity(sender),
    status: parseShipmentStatus(status),
    syncedAt,
    totalWeight,
    trackingCode,
    volumes: volumes.map((v) => parseVolume(v)),
    volumesInformations: volumesInformations.map((information) =>
      parseVolumeInformations(information)
    ),
    volumesQuantity,
  };
};

const parseShipmentList = (json: Record<string, any>): ShipmentListData => {
  const {
    becameReadyAt,
    carrierCode,
    carrierParentEntity,
    consignee,
    createdAt,
    declaredValue,
    destinationShipperEntity,
    dispatcher,
    eta,
    etd,
    finishedAt,
    id,
    organizationId,
    originShipperEntity,
    pickup,
    previousDocuments,
    receiptModality,
    receiver,
    recipient,
    routeLegs,
    sender,
    shipperCode,
    status,
    syncedAt,
    totalWeight,
    trackingCode,
    updatedAt,
    volumesQuantity,
  } = json;

  return {
    ...json,
    becameReadyAt,
    carrierCode,
    carrierParentEntity:
      carrierParentEntity && parseEntity(carrierParentEntity),
    consignee: consignee && parseEntity(consignee),
    createdAt,
    declaredValue,
    destinationShipperEntity:
      destinationShipperEntity && parseEntity(destinationShipperEntity),
    dispatcher: dispatcher && parseEntity(dispatcher),
    eta,
    etd,
    finishedAt,
    id,
    organizationId,
    originShipperEntity:
      originShipperEntity && parseEntity(originShipperEntity),
    pickup,
    previousDocuments,
    receiptModality: receiptModality && parseShipmentModality(receiptModality),
    receiver: receiver && parseEntity(receiver),
    recipient: recipient && parseEntity(recipient),
    routeLegs,
    sender: sender && parseEntity(sender),
    shipperCode,
    status: status && parseShipmentStatus(status),
    syncedAt,
    totalWeight: totalWeight && convertGramsToKilograms(totalWeight),
    trackingCode,
    updatedAt,
    volumesQuantity,
  };
};

const parsePickups = (json: Record<string, any>) => {
  return {
    pickupDate: json?.pickupDate,
    cutoffDate: json?.cutoffDate,
    identifier: json?.identifier,
    shipments: json?.shipments.map((s) => parseShipmentDraft(s)),
  };
};

const parseShipmentDropOffs = (
  dropOff: Record<string, any>
): DropOffsGroupedByOrigin => {
  const { origin, pendingShipments, shipments } = dropOff;

  return {
    origin,
    pendingShipments: pendingShipments.map((p) => parseShipmentDraft(p)),
    shipments: shipments.map((s) => parseShipmentDraft(s)),
  };
};

const parseShipmentPickups = (
  pickup: Record<string, any>
): PickupsGroupedByOrigin => {
  const { origin, pendingShipments, pickups } = pickup;

  return {
    origin,
    pendingShipments: pendingShipments.map((p) => parseShipmentDraft(p)),
    pickups: pickups.map((s) => parsePickups(s)),
  };
};

const parseUndefinedShipments = (
  undefinedShipment: Record<string, any>
): UndefinedGroupedByOrigin => {
  const { origin, pendingShipments } = undefinedShipment;

  return {
    origin,
    pendingShipments: pendingShipments.map((p) => parseShipmentDraft(p)),
  };
};

const parseShipmentsGroupedByOrigin = (
  groupedShipments: Record<string, any>
): ShipmentsGroupedByOrigin => {
  const dropOffs = groupedShipments?.dropOffs.map(
    (dropOff: DropOffsGroupedByOrigin) => parseShipmentDropOffs(dropOff)
  );
  const pickups = groupedShipments?.pickups.map(
    (pickup: DropOffsGroupedByOrigin) => parseShipmentPickups(pickup)
  );
  const undefined = groupedShipments?.undefined.map(
    (undefinedShipment: DropOffsGroupedByOrigin) =>
      parseUndefinedShipments(undefinedShipment)
  );

  return {
    dropOffs,
    pickups,
    undefined,
  };
};
// #endregion Private functions

// #region Public functions
export const associateShipmentToPickup = (
  id: ShipmentData['id'],
  identifier: PickupData['identifier']
): Promise<HttpResponse> => {
  return zordonClient.associateShipmentsToPickup([id], identifier);
};

export const disassociateShipmentFromPickup = async (
  id: number,
  identifier: string
): Promise<HttpResponse> => {
  return zordonClient.disassociateShipmentsFromPickup([id], identifier);
};

export const downloadShipmentTagByType = async ({
  id,
  type,
}: {
  id: number;
  type: TagFormat;
}): Promise<HttpResponse<Blob>> => {
  return zordonClient.downloadShipmentTagByType(
    id,
    type,
    ShipmentLabelTemplate.Brudam1114QrCode
  );
};

export const downloadMinutaById = async ({
  id,
}: {
  id: number;
}): Promise<HttpResponse<Blob>> => {
  return brudamSyncerClient.downloadMinutaById(id);
};

export const forceResendMinutaById = async ({
  id,
  userOrganizationId,
}: {
  id: number;
  userOrganizationId: number;
}): Promise<HttpResponse> => {
  return zordonClient.forceResendMinutaById(id, userOrganizationId);
};

export const downloadExcel = async ({
  filters,
}: {
  filters: ShipmentsFilter;
}): Promise<HttpResponse<Blob>> => {
  return zordonClient.downloadExcel(buildFilters(filters));
};

export const findShipment = async ({
  id,
  trackingCode,
}: ShipmentByIdOrTrackingCode): Promise<ShipmentData> => {
  const { shipment } = await graphqlClient.executeGraphQL(
    SHIPMENT_QUERY,
    { id, trackingCode },
    true
  );

  return convertShipmentMeasures(shipment);
};

export const findShipmentCost = async ({
  id,
  trackingCode,
}: ShipmentByIdOrTrackingCode): Promise<ShipmentCostData> => {
  const { shipment } = await graphqlClient.executeGraphQL(
    SHIPMENT_SHIPMENT_COST_QUERY,
    { id, trackingCode },
    true
  );

  return shipment;
};

export const findShipmentDetails = async ({
  id,
  trackingCode,
}: ShipmentByIdOrTrackingCode): Promise<ShipmentDetailsData> => {
  const { shipment } = await graphqlClient.executeGraphQL(
    SHIPMENT_DETAILS_QUERY,
    { id, trackingCode },
    true
  );

  return convertShipmentMeasures(shipment);
};

export const findShipmentDocuments = async ({
  id,
  trackingCode,
}: ShipmentByIdOrTrackingCode): Promise<ShipmentDocumentsData> => {
  const { shipment } = await graphqlClient.executeGraphQL(
    SHIPMENT_DOCUMENTS_QUERY,
    { id, trackingCode },
    true
  );

  return shipment;
};

export const findShipmentsShippingEvents = async ({
  shipmentId,
}: ShipmentShippingEventsList): Promise<ShippingEventData[]> => {
  const { shipmentShippingEvents } = await graphqlClient.executeGraphQL(
    SHIPMENT_SHIPPING_EVENTS_QUERY,
    { shipmentId },
    true
  );

  return shipmentShippingEvents;
};

export const findShipmentVolumes = async ({
  id,
  trackingCode,
}: ShipmentByIdOrTrackingCode): Promise<ShipmentVolumeDetails> => {
  const { shipment } = await graphqlClient.executeGraphQL(
    SHIPMENT_VOLUMES_QUERY,
    { id, trackingCode },
    true
  );

  return convertShipmentMeasures({
    ...shipment,
    volumes: setVolumesStatusesByShipmentStatus(
      shipment.volumes,
      shipment.status
    ),
  });
};

export const listShipments = async ({
  filter,
  first = 20,
  offset = 0,
  order,
}: ShipmentListOptions): Promise<ShipmentList> => {
  const options = buildOptions({
    filter,
    first,
    offset,
    order,
  });

  const {
    shipments: { data: shipments, total },
  } = await graphqlClient.executeGraphQL(SHIPMENT_LIST_QUERY, options, true);

  return {
    shipments: shipments.map((shipment) => parseShipmentList(shipment)),
    total,
  };
};

export const listShipmentsGroupedByOrigin = async ({
  carrierParentEntityId,
  pickupsLimit = 2,
}: ListShipmentsGroupedByOriginOptions): Promise<ShipmentsGroupedByOrigin> => {
  const { shipmentsGroupedByOrigin } = await graphqlClient.executeGraphQL(
    SHIPMENTS_GROUPED_BY_ORIGIN_QUERY,
    {
      carrierParentEntityId,
      pickupsLimit,
    }
  );

  const data = parseShipmentsGroupedByOrigin(shipmentsGroupedByOrigin);

  return data;
};

export const logStatusUpdateError = async ({
  context,
  message,
}: StatusUpdateError) => {
  const response = await zordonClient.logStatusUpdateError({
    context,
    message,
  });

  return response;
};

export const removeShipment = async (
  shipmentId: number
): Promise<HttpResponse> => {
  return zordonClient.removeShipment(shipmentId);
};

export const restoreShipment = async ({
  id,
}: ShipmentRestoreData): Promise<HttpResponse> => {
  const response = zordonClient.restoreShipment(id);

  return response;
};

export const restoreShipmentBatch = async ({
  ids,
}: ShipmentRestoreBatchData): Promise<HttpResponse> => {
  const response = zordonClient.restoreShipmentBatch(ids);

  return response;
};

export const shipmentTagStatus = async ({ id }: { id: number }) => {
  const response = await graphqlClient.executeGraphQL(
    SHIPMENT_CARRIER_LABEL_QUERY,
    { id },
    true
  );

  return response;
};

export const updateShipment = async ({
  id,
  data,
}: ShipmentUpdateOpt): Promise<HttpResponse> => {
  const response = await zordonClient.updateShipment(id, { ...data });

  return response;
};

export const updateShipmentBatch = async ({
  shipment,
}: ShipmentBatchUpdate): Promise<HttpResponse> => {
  const response = await zordonClient.updateBatchShipments(shipment);

  return response;
};

export const updateShipmentStatus = async ({
  id,
  status,
}: ShipmentUpdateStatus): Promise<HttpResponse> => {
  const response = await zordonClient.updateShipmentStatus(id, status);

  return response;
};
// #endregion Public functions
