import {
  AnyAction,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { keyBy } from 'lodash';

import {
  createUser,
  findUser,
  listUsers,
  resetUserPassword,
  UsersListFilterParams,
  userUpdate,
} from './usersAPI';
import { RootState } from '../../app/store';
import ErrorData from '../../common/data-types/error-data';
import RequestData from '../../common/data-types/request-data';
import RequestStatus from '../../common/enums/request-status.enum';
import UserCreationData from '../../common/data-types/user-creation-data';
import UserEditData from '../../common/data-types/user-edit-data';
import UserFindData from '../../common/data-types/user-find-data';
import UserListData from '../../common/data-types/user-list-data';
import UserStatus from '../../common/enums/user-status-type.enum';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  const startsWith = action.type.startsWith('users');
  const endsWith = action.type.endsWith('/fulfilled');

  return startsWith && endsWith;
};

const isPendingAction = (action: AnyAction): action is PendingAction => {
  const startsWith = action.type.startsWith('users');
  const endsWith = action.type.endsWith('/pending');

  return startsWith && endsWith;
};

const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  const startsWith = action.type.startsWith('users');
  const endsWith = action.type.endsWith('/rejected');

  return startsWith && endsWith;
};

// #region Thunks
interface UsersListThunkParams {
  organizationId: number;
}
const LIST_USERS_THUNK = 'users/list';
export const usersListThunk = createAsyncThunk(
  LIST_USERS_THUNK,
  async ({ organizationId }: UsersListThunkParams) => {
    return listUsers({ organizationId });
  }
);

interface UserCreateThunkParams {
  user: UserCreationData;
  organizationId: number;
}
const CREATE_USER_THUNK = 'users/new';
export const usersCreateThunk = createAsyncThunk(
  CREATE_USER_THUNK,
  async ({ user, organizationId }: UserCreateThunkParams) => {
    return createUser({ user, organizationId });
  }
);

const FIND_USER_THUNK = 'users/find';
interface UserFindThunkParams {
  userId: number;
}
export const userFindThunk = createAsyncThunk(
  FIND_USER_THUNK,
  async ({ userId }: UserFindThunkParams) => {
    return findUser({ userId });
  }
);

const RESET_USER_PASSWORD_THUNK = 'users/resetUserPassword';
interface ResetUserPasswordThunkParams {
  userId: number;
}
export const resetUserPasswordThunk = createAsyncThunk(
  RESET_USER_PASSWORD_THUNK,
  async ({ userId }: ResetUserPasswordThunkParams) => {
    return resetUserPassword(userId);
  }
);

const USER_UPDATE_THUNK = 'users/userUpdate';
interface UserUpdateThunkParams {
  user: UserEditData;
  userId: number;
}
export const userUpdateThunk = createAsyncThunk(
  USER_UPDATE_THUNK,
  async ({ user, userId }: UserUpdateThunkParams) => {
    await userUpdate({ user, userId });
  }
);
// #endregion Thunks

// #region Private Functions
const filterUsersList = (
  users: UserListData[],
  filters: UsersListFilterParams
): UserListData[] => {
  if (filters?.name) {
    users = users.filter((user) =>
      user.name.toLowerCase().includes(filters.name?.toLowerCase() || '')
    );
  }
  if (filters?.email) {
    users = users.filter((user) =>
      user.email.toLowerCase().includes(filters.email?.toLowerCase() || '')
    );
  }
  if (filters?.statuses) {
    users = users.filter((user) => {
      if (filters?.statuses?.includes(UserStatus.Active) && user.active) {
        return true;
      }
      if (filters?.statuses?.includes(UserStatus.Inactive) && !user.active) {
        return true;
      }

      return !((filters?.statuses || []).length > 0);
    });
  }

  return users;
};
// #endregion Private Functions

// #region Slice
interface UsersState {
  error: Record<string, any> | null;
  filters: UsersListFilterParams;
  requestIds: RequestData['id'][];
  requestsById: Record<RequestData['id'], RequestData>;
  total: number;
  user: UserFindData | null;
  usersById: Record<UserListData['id'], UserListData>;
  usersIds: UserListData['id'][];
}

const initialState: UsersState = {
  error: null,
  filters: {
    statuses: [],
    email: '',
    name: '',
  },
  requestIds: [],
  requestsById: {},
  total: 0,
  user: null,
  usersById: {},
  usersIds: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    cleanRequests: (state) => {
      state.requestIds = [];
      state.requestsById = {};
    },
    cleanState: () => initialState,
    updateUsersListFiltersAction: (
      state,
      action: PayloadAction<UsersListFilterParams>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(usersListThunk.fulfilled, (state, action) => {
        const users = action.payload;

        state.usersById = keyBy(users, 'id');
        state.usersIds = users.map((u) => u.id);
        state.total = users.length;
      })
      .addCase(resetUserPasswordThunk.fulfilled, (state, action) => {
        const { temporaryPassword } = action.payload.body;

        if (state.user) {
          state.user = {
            ...state.user,
            temporaryPassword,
          };
        }
      })
      .addCase(usersCreateThunk.fulfilled, (state, action) => {
        const user = action.payload;

        state.user = user;
      })
      .addCase(userFindThunk.fulfilled, (state, action) => {
        const user = action.payload;

        state.user = user;
      })
      .addCase(userUpdateThunk.fulfilled, (state, action) => {
        const { meta } = action;
        const { user, userId } = meta.arg;

        const updatedUser = state.usersById[userId];

        if (!updatedUser) {
          return;
        }

        if (updatedUser) {
          Object.assign(updatedUser, {
            ...updatedUser,
            ...user,
          });
        }
      })
      .addMatcher(isFulfilledAction, (state, action: AnyAction) => {
        const { meta } = action;
        const request = state.requestsById[meta.requestId];

        if (!request) {
          return;
        }

        Object.assign(request, {
          error: undefined,
          status: RequestStatus.Succeeded,
          type: action.type,
        });
      })
      .addMatcher(isPendingAction, (state, action: AnyAction) => {
        const { meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Loading,
          type,
        };

        state.requestsById[requestId] = request;
      })
      .addMatcher(isRejectedAction, (state, action: AnyAction) => {
        const { error, meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Failed,
          type,
          error,
        };

        state.requestsById[requestId] = request;
      });
  },
});
// #endregion Slice

// #region Selectors
// #region Private Selectors
const selectUsersRequestByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData => {
  const requests = selectUsersRequestsByType(rootState, type);

  return requests.reverse()[0];
};

const selectUsersIsFulfilledByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const fulfilledType = [type, 'fulfilled'].join('/');
  const request = selectUsersRequestByType(rootState, fulfilledType);

  return request ? request.status === RequestStatus.Succeeded : false;
};

const selectUsersIsRequestingByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const pendingType = [type, 'pending'].join('/');
  const request = selectUsersRequestByType(rootState, pendingType);

  return request ? request.status === RequestStatus.Loading : false;
};

const selectUsersRequestErrorByType = (
  rootState: RootState,
  type: RequestData['type']
): ErrorData | Record<string, any> | undefined => {
  const rejectedType = [type, 'rejected'].join('/');
  const request = selectUsersRequestByType(rootState, rejectedType);

  return request ? request.error : undefined;
};

const selectUsersRequests = (rootState: RootState): RequestData[] => {
  const state = selectUsersState(rootState);

  return state.requestIds.map((id) => state.requestsById[id]);
};

const selectUsersRequestsByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData[] => {
  const requests = selectUsersRequests(rootState);

  return requests.filter((r) => r.type === type);
};

const selectUsersState = (state: RootState): UsersState => state.users;
// #endregion Private Selectors

// #region Public Selectors
export const selectUsersIsFulfilledCreate = (rootState: RootState): boolean => {
  return selectUsersIsFulfilledByType(rootState, CREATE_USER_THUNK);
};

export const selectUsersIsFulfilledFind = (rootState: RootState): boolean => {
  return selectUsersIsFulfilledByType(rootState, FIND_USER_THUNK);
};

export const selectUsersIsFulfilledList = (rootState: RootState): boolean => {
  return selectUsersIsFulfilledByType(rootState, LIST_USERS_THUNK);
};

export const selectUserIsFulfilledUpdate = (rootState: RootState): boolean => {
  return selectUsersIsFulfilledByType(rootState, USER_UPDATE_THUNK);
};

export const selectUsersIsFulfilledResetPassword = (
  rootState: RootState
): boolean => {
  return selectUsersIsFulfilledByType(rootState, RESET_USER_PASSWORD_THUNK);
};

export const selectUsersIsRequestingFind = (rootState: RootState): boolean => {
  return selectUsersIsRequestingByType(rootState, FIND_USER_THUNK);
};

export const selectUsersIsRequestingCreate = (
  rootState: RootState
): boolean => {
  return selectUsersIsRequestingByType(rootState, CREATE_USER_THUNK);
};

export const selectUsersIsRequestingList = (rootState: RootState): boolean => {
  return selectUsersIsRequestingByType(rootState, LIST_USERS_THUNK);
};

export const selectUserIsRequestingUpdate = (rootState: RootState): boolean => {
  return selectUsersIsRequestingByType(rootState, USER_UPDATE_THUNK);
};

export const selectUsersIsRequestingResetPassword = (
  rootState: RootState
): boolean => {
  return selectUsersIsRequestingByType(rootState, RESET_USER_PASSWORD_THUNK);
};

export const selectUsersErrorFind = (
  rootState: RootState
): ErrorData | Record<string, any> | undefined => {
  return selectUsersRequestErrorByType(rootState, CREATE_USER_THUNK);
};

export const selectUsersIsRequestingErrorList = (
  rootState: RootState
): ErrorData | Record<string, any> | undefined => {
  return selectUsersRequestErrorByType(rootState, LIST_USERS_THUNK);
};

export const selectUsersErrorCreate = (
  rootState: RootState
): ErrorData | Record<string, any> | undefined => {
  return selectUsersRequestErrorByType(rootState, CREATE_USER_THUNK);
};

export const selectUserErrorResetPassword = (
  rootState: RootState
): ErrorData | Record<string, any> | undefined => {
  return selectUsersRequestErrorByType(rootState, RESET_USER_PASSWORD_THUNK);
};

export const selectUserErrorUpdate = (
  rootState: RootState
): ErrorData | Record<string, any> | undefined => {
  return selectUsersRequestErrorByType(rootState, USER_UPDATE_THUNK);
};

export const selectUsersById = (
  rootState: RootState
): Record<UserListData['id'], UserListData> => {
  const state = selectUsersState(rootState);

  return state.usersById;
};

export const selectUserIds = (rootState: RootState): number[] => {
  const state = selectUsersState(rootState);

  return state.usersIds;
};

export const selectUserFilteredIds = (rootState: RootState): number[] => {
  const filters = selectUsersListFilters(rootState);
  const usersById = selectUsersById(rootState);
  const usersIds = selectUserIds(rootState);

  const users = usersIds.map((id) => usersById[id]);

  return filterUsersList(users, filters).map((u) => u.id);
};

export const selectUsersListFilters = (
  rootState: RootState
): UsersListFilterParams => {
  const state = selectUsersState(rootState);

  return state.filters;
};

export const selectUsersTotal = (rootState: RootState): number => {
  const state = selectUsersState(rootState);

  return state.total;
};

export const selectUserTemporaryPassword = (
  rootState: RootState
): string | undefined => {
  const state = selectUsersState(rootState);

  return state.user?.temporaryPassword;
};

export const selectUser = (rootState: RootState): UserFindData | null => {
  const state = selectUsersState(rootState);

  return state.user;
};

// #endregion Public Selectors
// #endregion Selectors

export const {
  cleanRequests,
  cleanState,
  updateUsersListFiltersAction,
} = usersSlice.actions;

export const usersReducer = usersSlice.reducer;
