import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

type UserCheckIconProps = SvgIconProps;

const UserCheckIcon = ({ ...props }: UserCheckIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M21.1,12.5L22.5,13.91L15.97,20.5L12.5,17L13.9,15.59L15.97,17.67L21.1,12.5M10,17L13,20H3V18C3,15.79 6.58,14 11,14L12.89,14.11L10,17M11,4A4,4 0 0,1 15,8A4,4 0 0,1 11,12A4,4 0 0,1 7,8A4,4 0 0,1 11,4Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default UserCheckIcon;
