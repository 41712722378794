import React, { useMemo } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Typography from '@material-ui/core/Typography';

import {
  SHIPPING_EVENT_FILE_CATEGORIES,
  SHIPPING_EVENT_FILE_CATEGORY_LABEL_MAP,
} from '../../../../common/enums/shipping-event-file-category.enum';
import Select, { SelectOption } from '../../../../common/components/Select';

interface ShippingEventFileListItemProps {
  index: number;
  onRemove: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  iconGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: 'none',
    borderRight: 'none',
  },
}));

const ShippingEventFileListItem = ({
  index,
  onRemove,
}: ShippingEventFileListItemProps) => {
  const classes = useStyles();

  const { control, getValues } = useFormContext();

  const categoryOptions = SHIPPING_EVENT_FILE_CATEGORIES.map<SelectOption>(
    (category) => {
      return {
        label: SHIPPING_EVENT_FILE_CATEGORY_LABEL_MAP.get(category)!,
        value: category,
      };
    }
  );

  const fileName = useMemo<string>(() => {
    const file = getValues(`files.${index}.file` as 'files.0.file');

    return file ? file.name : '';
  }, [getValues, index]);

  return (
    <Grid alignItems="center" className={classes.root} container spacing={4}>
      <Grid item xs={4}>
        <Select
          control={control}
          fullWidth
          name={`files.${index}.category`}
          options={categoryOptions}
          placeholder="Selecione um tipo de comprovante"
        />
      </Grid>
      <Grid item xs={6}>
        <Typography>{fileName}</Typography>
      </Grid>
      <Grid classes={{ item: classes.iconGrid }} item xs={2}>
        <IconButton size="small" onClick={onRemove}>
          <RemoveCircle />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ShippingEventFileListItem;
