import React from 'react';

import { Box, Grid, Icon, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import dayjs from 'dayjs';

import { toRelativeDate } from '../../helpers/date.helper';
import Skeleton, { SkeletonWidthSize } from '../Skeleton';

const useStyles = makeStyles({
  deliveryIconsRoot: {
    alignSelf: 'center',
    display: 'flex',
    marginLeft: '9px',
  },
  deliveryRow: {
    padding: '1px 0',
  },
  label: {
    color: grey[500],
  },
});

export interface EstimatedTimeItemProps {
  color: string;
  date?: string;
  icon: string;
  isLoading: boolean;
  label: string;
}

const EstimatedTimeItem = ({
  color,
  date,
  icon,
  isLoading,
  label,
}: EstimatedTimeItemProps) => {
  const classes = useStyles();

  return (
    <Grid alignItems="flex-end" className={classes.deliveryRow} container>
      <Grid className={classes.label} item xs={4}>
        {label}
      </Grid>
      <Grid item xs={6}>
        {isLoading ? (
          <Skeleton width={SkeletonWidthSize.Small} />
        ) : (
          <Box color={color} fontWeight="bold" textAlign="right">
            {date
              ? `${toRelativeDate(date, 'DD/MM')} ${dayjs(date).format(
                  'HH:mm'
                )}`
              : '---'}
          </Box>
        )}
      </Grid>
      <Grid item xs={2}>
        <Box color={color} width="1.75rem">
          <Icon classes={{ root: classes.deliveryIconsRoot }} fontSize="small">
            {icon}
          </Icon>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EstimatedTimeItem;
