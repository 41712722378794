import React, { useCallback, useEffect, useState } from 'react';

import { InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { UncontrolledTextField, UncontrolledTextFieldProps } from './TextField';

export interface UserNameFilterProps
  extends Pick<
    UncontrolledTextFieldProps,
    'placeholder' | 'onChange' | 'value'
  > {
  query?: string;
  onKeyPress: (data: string) => void;
  onSubmit: (data: string) => void;
}

const TextSearchField = ({
  placeholder,
  query = '',
  onKeyPress,
  onSubmit,
}: UserNameFilterProps) => {
  const [value, setValue] = useState('');

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    setValue(text);

    if (text === '') {
      onSubmit(text);
    }
  };

  const handleSubmit = useCallback(() => {
    onSubmit(value);
  }, [onSubmit, value]);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onKeyPress(value);
    }
  };

  useEffect(() => {
    setValue(query);
  }, [query]);

  return (
    <UncontrolledTextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleSubmit}>
              <SearchIcon color="action" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder={placeholder}
      type="text"
      value={value}
      onChange={handleChangeValue}
      onKeyPress={handleKeyPress}
    />
  );
};

export default TextSearchField;
