import React from 'react';

import { Control, useController } from 'react-hook-form';
import {
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';

type UncontrolledDateTimePickerProps = KeyboardDateTimePickerProps;

const UncontrolledDateTimePicker = ({
  ampm = false,
  autoOk = true,
  format = 'dd/MM/yyyy HH:mm',
  variant = 'inline',
  ...props
}: UncontrolledDateTimePickerProps) => {
  return (
    <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        ampm={ampm}
        autoOk={autoOk}
        format={format}
        variant={variant}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export interface DateTimePickerProps
  extends Omit<UncontrolledDateTimePickerProps, 'onChange' | 'value'> {
  control: Control<any>;
  name: string;
}

const DateTimePicker = ({ control, name, ...rest }: DateTimePickerProps) => {
  const {
    field: { onChange, value },
    fieldState: { invalid },
  } = useController({
    control,
    defaultValue: null,
    name,
  });

  return (
    <UncontrolledDateTimePicker
      error={invalid}
      helperText={invalid ? 'Data Inválida' : null}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

export default DateTimePicker;
