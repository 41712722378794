import React, { useCallback } from 'react';

import { useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ERROR_MESSAGES } from '../constants/error-messages';
import PackageData from '../data-types/package-data';
import PackageFormFields from '../components/PackageFormFields';

const SIZE_SCHEMA = yup
  .number()
  .positive(ERROR_MESSAGES.number.positive)
  .max(99999, 'O campo deve possuir no máximo 5 dígitos')
  .required(ERROR_MESSAGES.string.required)
  .typeError(ERROR_MESSAGES.string.number);

const formSchema = yup.object().shape({
  description: yup
    .string()
    .min(1, ERROR_MESSAGES.string.min)
    .max(120, ERROR_MESSAGES.string.max)
    .required(),
  height: SIZE_SCHEMA,
  length: SIZE_SCHEMA,
  width: SIZE_SCHEMA,
});

export interface PackageFormInputs {
  description: string;
  height: string;
  length: string;
  width: string;
}

export type WithPackageFormProps = {
  data?: PackageData;
  children: React.ReactNode;
  form: UseFormReturn<PackageFormInputs>;
};

function withPackageForm<P>(
  Component: React.ComponentType<P & WithPackageFormProps>
) {
  return (props: P & { data?: PackageData }) => {
    const { data } = props;

    const form = useForm<PackageFormInputs>({
      defaultValues: {
        description: data?.name,
        height: data?.height.toString().replace('.', ','),
        length: data?.length.toString().replace('.', ','),
        width: data?.width.toString().replace('.', ','),
      },
      mode: 'onSubmit',
      resolver: yupResolver(formSchema),
      reValidateMode: 'onChange',
    });

    const {
      formState: { isSubmitted },
      setValue,
    } = form;

    const handleFormDimensionsValueChanges = useCallback(
      (name: string, value: string) => {
        setValue(name as any, value, {
          shouldValidate: isSubmitted,
        });
      },
      [isSubmitted, setValue]
    );

    return (
      <Component form={form} {...props}>
        <PackageFormFields
          control={form.control}
          onChange={handleFormDimensionsValueChanges}
        />
      </Component>
    );
  };
}

export default withPackageForm;
