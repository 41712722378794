import React, { useEffect, useState } from 'react';

import { Box, makeStyles, RadioGroup } from '@material-ui/core';

import { UncontrolledRadio } from '../Radio';
import ConfirmationModal from './ConfirmationModal';
import MultipleNotesIcon from '../MultipleNotesIcon';
import TagFormat from '../../enums/tag-format-type.enum';
import ThermalPrinterIcon from '../ThermalPrinterIcon';
import { useAppSelector } from '../../../app/hooks';
import {
  selectShipmentErrorDownloadTagByType,
  selectShipmentIsFulfilledDownloadTagByType,
  selectShipmentIsRequestingDownloadTagByType,
} from '../../../features/Shipments/shipmentsSlice';

const useStyles = makeStyles({
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    width: '34.0625rem',
  },
  thermalPrinterIcon: {
    color: '#F0AD4E',
  },
});

interface TagFormatModalProps {
  isLoading: boolean;
  open: boolean;
  onCancel: () => void;
  onConfirm: (format: TagFormat) => void;
  onError: () => void;
}

const TagFormatModal = ({
  isLoading,
  open,
  onCancel,
  onConfirm,
  onError,
}: TagFormatModalProps) => {
  const classes = useStyles();

  const printTagsError = useAppSelector(selectShipmentErrorDownloadTagByType);

  const printTagsIsLoading = useAppSelector(
    selectShipmentIsRequestingDownloadTagByType
  );
  const printTagsIsSucceeded = useAppSelector(
    selectShipmentIsFulfilledDownloadTagByType
  );

  const [tagFormat, setTagFormat] = useState<TagFormat | null>(null);

  const handleChange = (_, value: any) => {
    setTagFormat(value);
  };

  const handleCancel = () => {
    setTagFormat(null);
    onCancel();
  };

  const handleConfirm = () => {
    if (tagFormat) {
      onConfirm(tagFormat);
      setTagFormat(null);
    }
  };

  function isButtonDisabled(): boolean {
    if (printTagsIsLoading || printTagsError !== null) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (printTagsIsSucceeded) {
      onCancel();
    }
  }, [onCancel, printTagsIsSucceeded]);

  useEffect(() => {
    if (printTagsError) {
      onError();
    }
  }, [onError, printTagsError]);

  return (
    <ConfirmationModal
      content={
        <Box>
          <RadioGroup value={tagFormat} onChange={handleChange}>
            <UncontrolledRadio
              label={
                <Box className={classes.label}>
                  <MultipleNotesIcon />
                  Papel A4
                </Box>
              }
              labelPlacement="end"
              name="corporation"
              value={TagFormat.A4}
            />
            <UncontrolledRadio
              label={
                <Box className={classes.label}>
                  <ThermalPrinterIcon className={classes.thermalPrinterIcon} />
                  Impressora térmica
                </Box>
              }
              labelPlacement="end"
              name="individual"
              value={TagFormat.Raw}
            />
          </RadioGroup>
        </Box>
      }
      disabledConfirmButton={tagFormat === null || isLoading}
      fullWidth
      isLoading={isButtonDisabled()}
      open={open}
      title="Qual será o formato das etiquetas?"
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  );
};

export default TagFormatModal;
