import { gql } from '../../common/helpers/gql.helper';

const fileFragment = gql`
  filePath
  url
`;

const cteFragment = gql`
  auxiliaryDocument {
    ${fileFragment}
  }
  file {
    ${fileFragment}
  }
  id
  issueDate
  key
  number
  serie
  type
`;

const contentStatementFragment = gql`
  documentNumber
  file {
    ${fileFragment}
  }
  value
`;

const entityFragment = gql`
  address {
    cep
    complement
    location
    neighborhood
    number
    state
    street
  }
  code
  displayName
  documentNumber
  email
  id
  name
  type
  phone
  stateRegistration
`;

const volumeFragment = gql`
  carrierCode
  code
  id
  sequentialNumber
  status
  trackingCode
`;

const volumeInformationsFragment = gql`
  codes
  cubageWeight
  height
  length
  quantity
  weight
  width
`;

const fiscalDocumentFragment = gql`
  file {
    filePath
    url
  }
  id
  issueDate
  number
  order
  serie
  type
  value
  volumesQuantity
  weight
`;

const shipmentCostFragment = gql`
  advalorem
  firstMile
  freightIncludesIcms
  icms
  lastMile
  middleMile
  totalTaxes
`;

const shipmentFragment = gql`
  authorizedReceiver {
    ${entityFragment}
  }
  contentStatement {
    ${contentStatementFragment}
  }
  cte {
    ${cteFragment}
  }
  declaredValue
  deliveryModality
  destinationShipperEntity {
    ${entityFragment}
  }
  fiscalDocuments {
    ${fiscalDocumentFragment}
  }
  id
  organizationId
  originShipperEntity {
    ${entityFragment}
  }
  receiptModality
  receiver {
    ${entityFragment}
  }
  recipient {
    ${entityFragment}
  }
  routeLegs {
    destinationEntity {
      ${entityFragment}
    }
    originEntity {
      ${entityFragment}
    }
  }
  sender {
    ${entityFragment}
  }
  shipperCode
  status
  syncedAt
  totalWeight
  trackingCode
  volumesQuantity
  volumes {
    ${volumeFragment}
  }
  volumesInformations {
    ${volumeInformationsFragment}
  }
`;

const shipmentListItemFragment = gql`
  becameReadyAt
  carrierCode
  createdAt
  cte {
    id
  }
  dispatcher {
    displayName
    name
    address {
      location
      state
    }
  }
  eta
  etd
  finishedAt
  id
  pickup {
    pickupDate
  }
  receiptModality
  receiver {
    displayName
    name
    address {
      location
      state
    }
  }
  recipient {
    displayName
    name
    address {
      location
      state
    }
  }
  sender {
    displayName
    name
    address {
      location
      state
    }
  }
  shipperCode
  status
  syncedAt
  trackingCode
  volumesQuantity
`;

const storedFileFragment = gql`
  category
  filePath
  url
`;

export const SHIPMENT_QUERY = gql`
  query Shipment($id: Int, $trackingCode: String) {
    shipment(id: $id, trackingCode: $trackingCode) {
      ${shipmentFragment}
    }
  }
`;

export const SHIPMENT_DETAILS_QUERY = gql`
  query Shipment($id: Int, $trackingCode: String) {
    shipment(id: $id, trackingCode: $trackingCode) {
      authorizedReceiver {
        ${entityFragment}
      }
      carrierCode
      consignee {
        ${entityFragment}
      }
      createdAt
      cubageWeight
      declaredValue
      destinationShipperEntity {
        ${entityFragment}
      }
      dispatcher {
        ${entityFragment}
      }
      eta
      etd
      id
      originShipperEntity{
        ${entityFragment}
      }
      receiptModality
      receiver{
        ${entityFragment}
      }
      recipient{
        ${entityFragment}
      }
      routeLegs {
        destinationEntity{
          ${entityFragment}
        }
        originEntity{
          ${entityFragment}
        }
      }
      sender{
        ${entityFragment}
      }
      status
      totalWeight
      trackingCode
      updatedAt
      volumesQuantity
    }
  }
`;

export const SHIPMENT_VOLUMES_QUERY = gql`
  query Shipment($id: Int, $trackingCode: String) {
    shipment(id: $id, trackingCode: $trackingCode) {
      cubageWeight
      declaredValue
      status
      id
      totalWeight
      volumes {
        ${volumeFragment}
      }
      volumesQuantity
      volumesInformations {
        ${volumeInformationsFragment}
      }
    }
  }
`;

export const SHIPMENT_DOCUMENTS_QUERY = gql`
  query Shipment($id: Int, $trackingCode: String) {
    shipment(id: $id, trackingCode: $trackingCode) {
      contentStatement {
        value
        documentNumber
        file {
          url
        }
      }
      cte {
        auxiliaryDocument {
          url
        }
        issueDate
        key
        number
        serie
      }
      fiscalDocuments {
        id
        issueDate
        key
        number
        order
        serie
        value
        weight
      }
      id
      previousDocuments {
        id
        issueDate
        number
        serie
        value
      }
    }
  }
`;

export const SHIPMENT_SHIPPING_EVENTS_QUERY = gql`
  query ListShipmentsShippingEvents($shipmentId: Int!) {
    shipmentShippingEvents(shipmentId: $shipmentId) {
      accountablePerson {
        documentNumber
        name
      }
      comments
      description
      files {
        ${storedFileFragment}
      }
      geocoding {
        latitude
        longitude
      }
      id
      registeredAt
      responsible {
        type
        name
      }
      volumeCodes {
        carrierCode
        id
        trackingCode
      }
    }
  }
`;

export const SHIPMENT_SHIPMENT_COST_QUERY = gql`
  query Shipment($id: Int, $trackingCode: String) {
    shipment(id: $id, trackingCode: $trackingCode) {
      id
      shipmentCost {
        ${shipmentCostFragment}
      }
    }
  }
`;

export const SHIPMENT_CARRIER_LABEL_QUERY = gql`
  query ShipmentLabel($id: Int!) {
    shipment(id: $id) {
      id
      syncedAt
      volumes {
        carrierLabelFile {
          filePath
          url
        }
      }
    }
  }
`;

export const SHIPMENT_LIST_QUERY = gql`
  query ListShipments(
    $first: Int!
    $offset: Int!
    $order: ListOrder
    $filter: ShipmentsFilter!
  ) {
    shipments(first: $first, offset: $offset, order: $order, filter: $filter) {
      data {
        ${shipmentListItemFragment}
      }
      total
    }
  }
`;

export const SHIPMENTS_GROUPED_BY_ORIGIN_QUERY = gql`
  query ListShipmentsGroupedByOrigin(
    $carrierParentEntityId: Int!
    $pickupsLimit: Int!
  ) {
    shipmentsGroupedByOrigin(
      carrierParentEntityId: $carrierParentEntityId
      pickupsLimit: $pickupsLimit
    ) {
      dropOffs {
        origin {
          ${entityFragment}
        }
        pendingShipments {
          ${shipmentFragment}
        }
        shipments {
          ${shipmentFragment}
        }
      }
      pickups {
        origin {
          ${entityFragment}
        }
        pendingShipments {
          ${shipmentFragment}
        }
        pickups {
          pickupDate
          cutoffDate
          identifier
          shipments {
            ${shipmentFragment}
          }
        }
      }
      undefined {
        origin {
          ${entityFragment}
        }
        pendingShipments {
          ${shipmentFragment}
        }
      }
    }
  }
`;
