import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';

import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Check from '@material-ui/icons/Check';

import { ERROR_MESSAGES } from '../../constants/error-messages';
import { phoneNumberFormatter } from '../../helpers/phone-input.helper';
import UserProfileFormFields from './UserProfileFormFields';
import UserData from '../../data-types/user-data';
import UserEditData from '../../data-types/user-edit-data';

const useStyles = makeStyles((theme: Theme) => ({
  helperTextContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1.5rem',
  },
  submitButton: {
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    backgroundColor: theme.palette.info.main,
    marginTop: 50,
  },
}));

interface UserProfileFormData {
  email: string;
  name: string;
  organizationName: string;
  phone: string;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required(ERROR_MESSAGES.string.required)
    .min(3, ERROR_MESSAGES.string.min)
    .max(60, ERROR_MESSAGES.string.max),
  phone: yup.lazy((value = '') => {
    if (!value || value.length < 1) {
      return yup.string().notRequired();
    }
    return yup
      .string()
      .min(14, 'O campo deve possuir no mínimo 10 caracteres')
      .max(15, 'O campo deve possuir no máximo 11 caracteres');
  }),
});

export interface UserProfileFormProps {
  error: Record<string, any> | null;
  isLoading: boolean;
  isSucceeded: boolean;
  user: UserData;
  onCleanError: () => void;
  onSubmit: (data: UserEditData) => void;
}

const UserProfileForm = ({
  isLoading,
  isSucceeded,
  user,
  error,
  onCleanError,
  onSubmit,
}: UserProfileFormProps) => {
  const classes = useStyles();

  const {
    control,
    formState: { isValid, isSubmitted, isSubmitSuccessful, isDirty },
    handleSubmit,
    reset,
    setValue,
  } = useForm<UserProfileFormData>({
    defaultValues: {
      email: user.email || '',
      name: user.name || '',
      phone: user.cellphone ? phoneNumberFormatter(user.cellphone) : '',
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const fieldValues = useWatch({
    control,
  });

  const canSubmit = !isLoading && !error;

  const toModel = (data: UserProfileFormData): UserEditData => {
    const parsedPhone = data.phone.replace(/\D/g, '');
    return {
      name: data.name,
      phone: parsedPhone !== '' ? parsedPhone : undefined,
    };
  };

  const handleOnSubmit = (data: UserProfileFormData) => {
    onSubmit(toModel(data));
  };

  const handleOnChange = (fieldName: string, value: string) => {
    if (fieldName === 'phone') {
      setValue('phone', phoneNumberFormatter(value));
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(fieldValues, { keepIsSubmitted: true });
    }
  }, [fieldValues, isSubmitSuccessful, reset]);

  useEffect(() => {
    if ((error || isSucceeded) && isDirty) {
      onCleanError();
    }
  }, [error, isDirty, isSucceeded, onCleanError]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <UserProfileFormFields control={control} onChange={handleOnChange} />
      <Button
        className={classes.submitButton}
        color="primary"
        disabled={(isSubmitted && !isValid) || !canSubmit}
        fullWidth
        type="submit"
        variant="contained"
      >
        {isLoading ? <CircularProgress /> : 'Alterar'}
      </Button>
      {error && (
        <Box className={classes.helperTextContainer} color="error.main">
          <Typography>Ocorreu um erro na alteração de usuário</Typography>
        </Box>
      )}
      {isSucceeded && (
        <Box className={classes.helperTextContainer} color="success.main">
          <Check />
          <Typography style={{ marginLeft: '0.5rem' }}>
            Alteração realizada com sucesso!
          </Typography>
        </Box>
      )}
    </form>
  );
};

export default UserProfileForm;
