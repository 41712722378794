import React, { useCallback, useEffect } from 'react';

import { darken, makeStyles, Theme } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import {
  cleanRequests,
  selectUserIsFulfilledUpdate,
  selectUserIsRequestingUpdate,
} from '../../usersSlice';
import { phoneNumberFormatter } from '../../../../common/helpers/phone-input.helper';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import SubmitButton from '../../../../common/components/SubmitButton';
import USER_SCHEMA from '../../../../common/schemas/user-schema';
import UserFindData from '../../../../common/data-types/user-find-data';
import UserFormFields from '../../../../common/components/UserFormFields';
import UserEditData from '../../../../common/data-types/user-edit-data';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&:hover': {
      backgroundColor: darken('#1976D2', 0.2),
    },
    backgroundColor: '#1976D2',
  },
  changeStatusButton: {
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    backgroundColor: theme.palette.error.main,
    marginRight: '0.5rem',
  },
  container: {
    backgroundColor: '#FFFFFF',
    border: '1px solid',
    borderColor: '#C2C3C9',
    borderRadius: 3,
    padding: 30,
    width: 800,
    marginBottom: 200,
  },
  footer: {
    borderTop: '1px solid #C2C3C9',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    width: '100vw',
  },
  resetPasswordButton: {
    '&:hover': {
      color: theme.palette.info.dark,
    },
    color: theme.palette.info.main,
  },
}));

export interface UserEditFormData {
  email: string;
  isAdmin: boolean;
  name: string;
  phone: string;
}

interface UserEditFormProps {
  data: UserFindData;
  onResetPassword: () => void;
  onSubmit: (data: Partial<UserEditData>) => void;
  onUpdateStatus: () => void;
}

const UserEditForm = ({
  data,
  onResetPassword,
  onSubmit,
  onUpdateStatus,
}: UserEditFormProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectUserIsRequestingUpdate);
  const error = useAppSelector(selectUserIsFulfilledUpdate);

  const methods = useForm<UserEditFormData>({
    defaultValues: {
      email: data?.email || '',
      isAdmin: data?.isAdmin || false,
      name: data?.name || '',
      phone: data.cellphone ? phoneNumberFormatter(data.cellphone) : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(USER_SCHEMA),
    reValidateMode: 'onChange',
  });

  const {
    formState: { isValid, isSubmitted, isSubmitSuccessful, isDirty },
    handleSubmit,
    watch,
    reset,
  } = methods;

  const fieldValues = watch();

  const toModel = (user: UserEditFormData): UserEditData => {
    const { name, isAdmin, phone } = user;

    const parsedPhone = phone.replace(/\D/g, '');

    return {
      phone: parsedPhone !== '' ? parsedPhone : undefined,
      isAdmin,
      name,
    };
  };

  const handleOnSubmit = useCallback(
    (user: UserEditFormData) => {
      onSubmit(toModel(user));
    },
    [onSubmit]
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(fieldValues, { keepIsSubmitted: true });
    }
  }, [fieldValues, isSubmitSuccessful, reset]);

  useEffect(() => {
    if (error && isDirty) {
      dispatch(cleanRequests());
    }
  }, [dispatch, error, isDirty]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <FormProvider {...methods}>
        <Box className={classes.container}>
          <UserFormFields disabledFields={{ email: true }} />
        </Box>
        <Box bgcolor="background.default" className={classes.footer}>
          <Box
            display="flex"
            justifyContent="space-between"
            py={2}
            width="50rem"
          >
            <Button
              classes={{ root: classes.resetPasswordButton }}
              color="primary"
              disabled={isLoading}
              variant="text"
              onClick={onResetPassword}
            >
              Redefinir Senha
            </Button>
            <Box display="flex">
              <Button
                classes={{ root: classes.changeStatusButton }}
                color="primary"
                disabled={isLoading}
                variant="contained"
                onClick={onUpdateStatus}
              >
                {data?.active ? 'Desativar' : 'Ativar'}
              </Button>
              <SubmitButton
                classes={{ root: classes.button }}
                color="primary"
                disabled={isLoading || (isSubmitted && !isValid)}
                isLoading={isLoading}
                type="submit"
                variant="contained"
              >
                Alterar
              </SubmitButton>
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </form>
  );
};

export default UserEditForm;
