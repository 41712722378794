import React from 'react';

import { Button, makeStyles, Theme } from '@material-ui/core';

import Modal, { ModalProps } from './Modal';
import SubmitButton from '../SubmitButton';
import AlertCard from '../AlertCard';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    color: theme.palette.info.main,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '1.25rem',
    fontWeight: 'bolder',
  },
}));

interface ConfirmationModalProps
  extends Omit<ModalProps, 'title' | 'children'> {
  confirmButtonLabel?: string;
  content: React.ReactNode | string;
  disabledConfirmButton?: boolean;
  hasHTMLcontent?: boolean;
  hasWarning?: boolean;
  isLoading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  title: React.ReactNode | string;
  warningMessage?: string;
}

const ConfirmationModal = ({
  confirmButtonLabel = 'Confirmar',
  content,
  disabledConfirmButton = false,
  fullWidth = false,
  hasHTMLcontent = false,
  hasWarning = false,
  isLoading = false,
  onCancel,
  onConfirm,
  open,
  title,
  warningMessage,
  ...rest
}: ConfirmationModalProps) => {
  const classes = useStyles();

  function HTMLParser({ html }) {
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  return (
    <Modal
      {...rest}
      actions={
        <>
          <Button
            className={classes.actionButton}
            onClick={(e) => {
              e.stopPropagation();
              onCancel();
            }}
          >
            Cancelar
          </Button>
          <SubmitButton
            className={classes.actionButton}
            disabled={disabledConfirmButton}
            isLoading={isLoading}
            onClick={(e) => {
              e.stopPropagation();
              onConfirm();
            }}
          >
            {confirmButtonLabel}
          </SubmitButton>
        </>
      }
      fullWidth={fullWidth}
      header={title}
      open={open}
      onClick={(e) => e.stopPropagation()}
    >
      {hasHTMLcontent ? <HTMLParser html={content} /> : content}
      {hasWarning && (
        <AlertCard
          style={{ marginTop: '1em' }}
          severity="warning"
          title="Atenção"
        >
          <HTMLParser html={warningMessage} />
        </AlertCard>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
