import React, { useEffect } from 'react';

import { Box, makeStyles, Theme } from '@material-ui/core';
import { useWatch } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import PasswordEditData from '../../data-types/password-edit-data';
import PasswordEditFields from './PasswordEditFields';
import withPasswordForm, {
  PasswordEditFormData,
  WithPasswordFormProps,
} from '../../hocs/withPasswordForm';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    marginBottom: 10,
    textAlign: 'center',
  },
  submitButton: {
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    backgroundColor: theme.palette.info.main,
  },
}));

export interface PasswordEditFormProps extends WithPasswordFormProps {
  error: Record<string, any> | null;
  isLoading: boolean;
  onCleanError: () => void;
  onSubmit: (data: PasswordEditData) => void;
}

const PasswordEditForm = ({
  error,
  form,
  isLoading,
  onCleanError,
  onSubmit,
}: PasswordEditFormProps) => {
  const classes = useStyles();

  const {
    control,
    formState: { isSubmitted, isValid, isSubmitSuccessful, isDirty },
    handleSubmit,
    reset,
  } = form;

  const fieldValues = useWatch({
    control,
  });

  const canSubmit = !error && !isLoading;

  const handleOnSubmit = (data: PasswordEditFormData) => {
    const { currentPassword, password } = data;

    if (canSubmit) {
      onSubmit({
        currentPassword,
        password,
      });
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(fieldValues, { keepIsSubmitted: true });
    }
  }, [fieldValues, isSubmitSuccessful, reset]);

  useEffect(() => {
    if (error && isDirty) {
      onCleanError();
    }
  }, [error, isDirty, onCleanError]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <PasswordEditFields control={control} />
      <Box marginTop={7}>
        {error?.code === 'invalid_param' && (
          <Typography className={classes.error} color="error" component="p">
            Senha atual inválida.
          </Typography>
        )}
        <Button
          className={classes.submitButton}
          color="primary"
          disabled={isSubmitted && (!canSubmit || !isValid)}
          fullWidth
          type="submit"
          variant="contained"
        >
          {isLoading ? <CircularProgress /> : 'Alterar Senha'}
        </Button>
      </Box>
    </form>
  );
};

export default withPasswordForm<
  Omit<PasswordEditFormProps, keyof WithPasswordFormProps>
>(PasswordEditForm);
