import React, { useEffect, useState } from 'react';

import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  cleanRequests,
  selectPackageIsFulfilledRemove,
} from '../../../features/Packages/packagesSlice';
import { decimalNumber } from '../../helpers/mask.helper';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import ConfirmationModal from '../Modals/ConfirmationModal';
import Menu from '../Menu';
import MenuItem from '../MenuItem';
import PackageData from '../../data-types/package-data';

const useStyles = makeStyles((theme: Theme) => ({
  actionIcon: {
    marginRight: '10px',
  },
  icon: {
    marginRight: 12.5,
  },
  label: {
    fontSize: '0.75rem',
    marginBottom: '3px',
  },
  link: {
    alignItems: 'center',
    color: 'inherit',
    display: 'flex',
    padding: '0.3rem 1rem',
    textDecoration: 'none',
  },
  menu: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  menuIcon: {
    color: theme.palette.primary.main,
  },
  menuItem: {
    padding: 0,
  },
  root: {
    '&:first-child': {
      borderTop: '1px solid #C2C3C9',
    },
    backgroundColor: 'white',
    borderBottom: '1px solid #C2C3C9',
    borderLeft: '1px solid #C2C3C9',
    borderRight: '1px solid #C2C3C9',
    padding: '16px 20px',
  },
  value: {
    fontSize: '0.75rem',
    fontWeight: 'bolder',
  },
}));

interface PackageListItemProps {
  packageItem: PackageData;
  onRemove: (id: PackageData['id']) => void;
}

const PackageListItem = ({ packageItem, onRemove }: PackageListItemProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const isPackageRemoved = useAppSelector(selectPackageIsFulfilledRemove);

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleCancelRemove = () => {
    setOpenModal(false);
    setOpenMenu(false);
  };

  useEffect(() => {
    if (isPackageRemoved) {
      setOpenModal(false);
      setOpenMenu(false);
      dispatch(cleanRequests());
    }
  }, [dispatch, isPackageRemoved]);

  return (
    <>
      <ConfirmationModal
        content={
          <Typography component="p">
            Deseja mesmo remover esta embalagem? Essa operação não pode ser
            revertida.
          </Typography>
        }
        open={openModal}
        title="Excluir esta embalagem?"
        onCancel={handleCancelRemove}
        onConfirm={() => onRemove(packageItem.id)}
      />
      <Box className={classes.root}>
        <Grid container>
          <Grid item xs={5}>
            <Box>
              <Typography className={classes.label}>Descrição</Typography>
            </Box>
            <Box>
              <Typography className={classes.value}>
                {packageItem.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography className={classes.label}>Comprimento</Typography>
            </Box>
            <Box>
              <Typography className={classes.value}>
                {decimalNumber(packageItem.length)} cm
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography className={classes.label}>Largura</Typography>
            </Box>
            <Box>
              <Typography className={classes.value}>
                {decimalNumber(packageItem.width)} cm
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography className={classes.label}>Altura</Typography>
            </Box>
            <Box>
              <Typography className={classes.value}>
                {decimalNumber(packageItem.height)} cm
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.menu}>
            <Menu
              buttonChildren={
                <MoreVertIcon classes={{ root: classes.menuIcon }} />
              }
              elevation={1}
              open={openMenu}
              onButtonClick={() => setOpenMenu((prevState) => !prevState)}
              onClose={handleMenuClose}
            >
              <MenuItem classes={{ root: classes.menuItem }} disableGutters>
                <Link
                  className={classes.link}
                  to={`packages/${packageItem.id}/edit`}
                >
                  <EditIcon classes={{ root: classes.actionIcon }} />
                  Editar
                </Link>
              </MenuItem>
              <MenuItem onClick={() => setOpenModal(true)}>
                <DeleteIcon classes={{ root: classes.actionIcon }} />
                Remover
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PackageListItem;
