export const phoneNumberFormatter = (phoneNumber = '') => {
  const num = phoneNumber.replace(/\D/g, '') || '';
  let numberParts;

  if (num.length <= 10) {
    numberParts = num.match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
  } else {
    numberParts = num.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
  }

  return !numberParts[2]
    ? `${numberParts[1]}`
    : `(${numberParts[1]}) ${numberParts[2]}${
        numberParts[3] ? `-${numberParts[3]}` : ''
      }`;
};
