import React, { useCallback } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import {
  listEntitiesThunk,
  selectEntities,
  selectEntitiesFilter,
  selectEntitiesFirst,
  selectEntitiesIsRequestingList,
  selectEntitiesPage,
  selectEntitiesTotal,
} from '../../../features/Entities/entitiesSlice';
import { selectOrganizationId } from '../../../features/Session/sessionSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import EntityItem from './Item';

const EntityList = () => {
  const dispatch = useAppDispatch();

  const entities = useAppSelector(selectEntities);
  const filter = useAppSelector(selectEntitiesFilter);
  const first = useAppSelector(selectEntitiesFirst);
  const isLoading = useAppSelector(selectEntitiesIsRequestingList);
  const organizationId = useAppSelector(selectOrganizationId);
  const page = useAppSelector(selectEntitiesPage);
  const total = useAppSelector(selectEntitiesTotal);

  const handleFetchEntities = useCallback(() => {
    dispatch(
      listEntitiesThunk({
        filter: {
          ...filter,
          disposable: false,
          organizationIds: [organizationId],
        },
        first,
        page: page + 1,
      })
    );
  }, [dispatch, filter, first, organizationId, page]);

  return (
    <InfiniteScroll
      dataLength={entities.length}
      hasMore={entities.length < total}
      loader={Array.from(Array(3)).map((index) => (
        <EntityItem isLoading key={index} />
      ))}
      next={handleFetchEntities}
    >
      {Array.from(
        isLoading && entities.length <= 0 ? new Array(3) : entities
      ).map((entity, index) => (
        <EntityItem
          entity={entity}
          isLoading={isLoading && entities.length <= 0}
          key={entity?.id || index}
        />
      ))}
    </InfiniteScroll>
  );
};

export default EntityList;
