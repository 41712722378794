import React from 'react';

import Button, { ButtonClassKey } from '@material-ui/core/Button';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { makeStyles, Theme } from '@material-ui/core';

interface SideBarButtonProps {
  classes?: Partial<ClassNameMap<ButtonClassKey | 'selected'>>;
  endIcon?: React.ReactNode;
  icon?: React.ReactNode;
  isSelected?: boolean;
  label: string;
  onSelect?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  endIcon: {
    marginLeft: 'auto',
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    justifyContent: 'start',
    textTransform: 'capitalize',
  },
  root: {
    borderRadius: 0,
    color: theme.palette.text.primary,
  },
  selected: {
    color: theme.palette.info.main,
  },
  startIcon: {
    marginRight: 17,
  },
}));

const SideBarButton = ({
  classes,
  endIcon,
  icon,
  isSelected = false,
  label,
  onSelect,
}: SideBarButtonProps) => {
  const styles = useStyles();

  const { selected, ...rest } = classes || {};

  return (
    <Button
      classes={{
        endIcon: `${classes?.endIcon} ${styles.endIcon}`,
        label: `${classes?.label} ${styles.label}`,
        root: `${classes?.root} ${styles.root}`,
        startIcon: `${classes?.startIcon} ${styles.startIcon}`,
        ...rest,
      }}
      className={isSelected ? `${selected} ${styles.selected}` : undefined}
      endIcon={endIcon}
      fullWidth
      startIcon={icon}
      variant="text"
      onClick={onSelect}
    >
      {label}
    </Button>
  );
};

export default SideBarButton;
