import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Box,
  IconButton,
  InputAdornment,
  makeStyles,
  Popper,
} from '@material-ui/core';
import { Control, useController } from 'react-hook-form';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClearIcon from '@material-ui/icons/Clear';

import OptionList from './List';
import SearchFilter from '../SearchFilter';

const useStyles = makeStyles({
  clear: {
    color: '#000000',
    fontSize: 20,
    opacity: 0.5,
  },
  dropdown: {
    color: '#000000',
    fontSize: 24,
    opacity: 0.5,
  },
  iconButton: {
    padding: '4px',
  },
  list: {
    padding: 0,
    position: 'relative',
  },
  popper: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 1300,
  },
});

export interface AutocompleteOption {
  label: string;
  subTitle?: string;
  value: number;
}

export interface UncontrolledAutocompleteProps {
  append?: React.ReactNode;
  className?: string;
  clearButton?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  label?: string;
  name?: string;
  options: AutocompleteOption[];
  value?: any;
  onChange: (event: any) => void;
  onClick?: (value: AutocompleteOption | null) => void;
}

export const UncontrolledAutocomplete = ({
  append,
  className = '',
  clearButton = true,
  disabled,
  hasError,
  label,
  name,
  options = [],
  value,
  onChange,
  onClick,
  ...rest
}: UncontrolledAutocompleteProps) => {
  const classes = useStyles();

  const fieldRef = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [fieldOptions, setFieldOptions] = useState<AutocompleteOption[]>(
    options
  );
  const [showList, setShowList] = useState<boolean>(false);
  const [textInputValue, setTextInputValue] = useState<string>('');

  const toggleOptionList = useCallback(
    (toggleValue: boolean) => {
      if (toggleValue) {
        setShowList(toggleValue);
      } else {
        setTimeout(() => {
          setShowList(toggleValue);
          setFieldOptions(options);
        }, 150);
      }
    },
    [options]
  );

  const setValue = useCallback(() => {
    const selectedOpt = options.find(
      (opt: AutocompleteOption) => opt.value === value
    );

    if (selectedOpt) {
      setTextInputValue(selectedOpt.label);
    }
  }, [options, value]);

  const handleOnClick = useCallback(
    (option: AutocompleteOption | null) => {
      setTextInputValue(option?.label || '');

      if (onClick) {
        onClick(option);
      }

      onChange(option?.value);
    },
    [onChange, onClick]
  );

  const handleOnFilter = (items: AutocompleteOption[], term?: string) => {
    setFieldOptions([...items]);
    setTextInputValue(term || '');
  };

  const handleFieldClear = useCallback(() => {
    handleOnClick(null);
  }, [handleOnClick]);

  useEffect(() => {
    setFieldOptions(options);
  }, [options]);

  useEffect(() => {
    setValue();
  }, [setValue, value]);

  useEffect(() => {
    if (!value) {
      setTextInputValue('');
    }
  }, [setTextInputValue, value]);

  const handleMouseOver = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const inputProps = useMemo(() => {
    return {
      endAdornment: clearButton && (
        <InputAdornment position="end">
          {textInputValue && textInputValue !== '' && (
            <IconButton
              className={classes.iconButton}
              onClick={handleFieldClear}
            >
              <ClearIcon className={classes.clear} />
            </IconButton>
          )}
          <IconButton
            className={classes.iconButton}
            onClick={() => toggleOptionList(!showList)}
          >
            {showList ? (
              <ArrowDropUpIcon className={classes.dropdown} />
            ) : (
              <ArrowDropDownIcon className={classes.dropdown} />
            )}
          </IconButton>
        </InputAdornment>
      ),
    };
  }, [
    classes.clear,
    classes.dropdown,
    classes.iconButton,
    clearButton,
    handleFieldClear,
    showList,
    textInputValue,
    toggleOptionList,
  ]);

  return (
    <div
      className={`${classes.list} ${className}`}
      ref={fieldRef}
      onBlur={() => toggleOptionList(false)}
    >
      <SearchFilter
        autoComplete="off"
        disabled={disabled}
        error={hasError}
        fullWidth
        InputProps={clearButton && !disabled ? inputProps : {}}
        items={options}
        label={label}
        name={name}
        type="text"
        value={textInputValue}
        onBlur={() => toggleOptionList(false)}
        onFilter={handleOnFilter}
        onFocus={() => toggleOptionList(true)}
        onMouseOver={handleMouseOver}
        {...rest}
      />
      <Popper
        anchorEl={anchorEl}
        className={classes.popper}
        disablePortal
        open={Boolean(anchorEl)}
      >
        <OptionList
          append={append}
          options={fieldOptions}
          showList={showList}
          onClick={handleOnClick}
        />
        <Box>{showList && append}</Box>
      </Popper>
    </div>
  );
};

export interface AutocompleteProps
  extends Omit<
    UncontrolledAutocompleteProps,
    'onClick' | 'onChange' | 'value'
  > {
  control?: Control<any>;
  defaultValue?: number;
  name: string;
  onClick?: (value: AutocompleteOption | null) => void;
}

const Autocomplete = ({
  control,
  defaultValue,
  name,
  options,
  onClick,
  ...rest
}: AutocompleteProps) => {
  const {
    field: { onChange, value },
    fieldState: { error, invalid },
  } = useController({
    control,
    defaultValue,
    name,
  });

  const handleSelect = useCallback(
    (option: AutocompleteOption | null) => {
      if (onClick) {
        onClick(option);
      }
    },
    [onClick]
  );

  return (
    <UncontrolledAutocomplete
      hasError={invalid}
      helperText={error?.message}
      value={value}
      onChange={onChange}
      onClick={handleSelect}
      options={options}
      {...rest}
    />
  );
};

export default Autocomplete;
