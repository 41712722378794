import React, { useEffect } from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../hooks';
import {
  cleanRequests,
  selectIsAuthenticated,
  selectSessionIsFulfilledLogout,
} from '../../features/Session/sessionSlice';
import ForgotPasswordPage from '../../features/Session/ForgotPasswordPage/ForgotPasswordPage';
import LoginPage from '../../features/Session/LoginPage/LoginPage';
import NotFoundPage from './NotFoundPage';
import ResetPasswordPage from '../../features/Session/ResetPasswordPage/ResetPasswordPage';

const UnauthenticatedRoutes = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isLogoutSucceeded = useAppSelector(selectSessionIsFulfilledLogout);

  useEffect(() => {
    if (isLogoutSucceeded) {
      history.push('/');
      dispatch(cleanRequests());
    }
  }, [dispatch, history, isLogoutSucceeded]);

  return (
    <>
      {!isAuthenticated && (
        <Switch>
          <Route exact path="/">
            <LoginPage title="Dashboard | Login" />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPasswordPage title="Dashboard | Esqueci minha senha" />
          </Route>
          <Route exact path="/reset-password">
            <ResetPasswordPage title="Dashboard | Esqueci minha senha" />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      )}
    </>
  );
};

export default UnauthenticatedRoutes;
