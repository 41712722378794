import React from 'react';

import { Box, Collapse, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  summary: {
    cursor: 'pointer',
  },
});

export interface AccordionStyles {
  details?: string;
  root?: string;
  summary?: string;
}

export interface AccordionProps {
  classes?: AccordionStyles;
  details: React.ReactNode;
  isExpanded: boolean;
  summary: React.ReactNode;
  unmountOnExit?: boolean;
  onToggle: () => void;
}

const Accordion = ({
  classes,
  details,
  isExpanded,
  summary,
  unmountOnExit = false,
  onToggle,
}: AccordionProps) => {
  const styles = useStyles();

  const handleOnClick = () => {
    onToggle();
  };

  return (
    <Box className={classes?.root}>
      <Box
        className={`${classes?.summary} ${styles.summary}`}
        onClick={handleOnClick}
      >
        {summary}
      </Box>
      <Collapse
        className={classes?.details}
        in={isExpanded}
        unmountOnExit={unmountOnExit}
      >
        {details}
      </Collapse>
    </Box>
  );
};

export default Accordion;
