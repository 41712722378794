import React, { useCallback, useState } from 'react';

import {
  AppBar,
  Box,
  Button,
  Container,
  Icon,
  makeStyles,
  Theme,
  Toolbar as MuiToolbar,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MyLocationIcon from '@material-ui/icons/MyLocation';

import {
  changeActiveOrganization,
  loadSession,
  logout,
  selectOrganizationId,
  selectSessionActiveOrganization,
  selectSessionUserType,
  selectUser,
} from '../../features/Session/sessionSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import logo from '../assets/images/ebb-logo-primary.svg';
import OrganizationChangeModal from './Modals/OrganizationChangeModal';
import OrganizationType from '../enums/organization-type.enum';
import ToolbarLink, { ToolbarLinkProps } from './ToolbarLink';
import UserMenu from './UserMenu';

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {
    minWidth: '80rem',
  },
  containerRoot: {
    padding: 0,
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  menuLink: {
    color: theme.palette.text.primary,
    display: 'flex',
    textDecoration: 'none',
  },
  toolbar: {
    padding: 0,
  },
}));

export enum StateKeys {
  isMenuOpen = 'isMenuOpen',
  isOrganizationModalOpen = 'isOrganizationModalOpen',
}

type ToolbarState = Record<StateKeys, boolean>;

interface LinkAttributes extends ToolbarLinkProps {
  allowedOrganizationTypes: OrganizationType[];
}

const links: LinkAttributes[] = [
  {
    label: 'Rastreio',
    icon: <MyLocationIcon />,
    to: '/shipments',
    allowedOrganizationTypes: [
      OrganizationType.Admin,
      OrganizationType.Carrier,
      OrganizationType.CartageAgent,
      OrganizationType.Shipper,
    ],
  },
  // {
  //   label: 'Faturamento',
  //   icon: <AttachMoneyIcon />,
  //   to: '/billing',
  //   allowedOrganizationTypes: [
  //     OrganizationType.Admin,
  //     OrganizationType.Shipper,
  //   ],
  // },
];

const Toolbar = () => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState<ToolbarState>({
    isMenuOpen: false,
    isOrganizationModalOpen: false,
  });

  const history = useHistory();

  const dispatch = useAppDispatch();

  const organization = useAppSelector(selectSessionActiveOrganization);
  const organizationId = useAppSelector(selectOrganizationId);
  const user = useAppSelector(selectUser);
  const userType = useAppSelector(selectSessionUserType);

  const mayLinkBeVisible = useCallback(
    (types: OrganizationType[]) => types.includes(userType),
    [userType]
  );

  const handleSetIsOpen = useCallback((key: StateKeys, value: boolean) => {
    setIsOpen((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  const handleOpen = useCallback(
    (key: StateKeys) => {
      if (user.organizations.length > 1) {
        handleSetIsOpen(key, true);
      }
    },
    [handleSetIsOpen, user.organizations.length]
  );

  const handleClose = useCallback(
    (key: StateKeys) => {
      handleSetIsOpen(key, false);
    },
    [handleSetIsOpen]
  );

  const handleRouteChange = useCallback(() => {
    const path = history.location.pathname;
    const destination = path.split('/')[1];

    history.replace(`/${destination}`);
  }, [history]);

  const handleOrganizationChange = (id: number) => {
    try {
      handleClose(StateKeys.isOrganizationModalOpen);
      dispatch(changeActiveOrganization(id));
      dispatch(loadSession());

      if (history.location.pathname.includes('edit')) {
        handleRouteChange();
      }
    } catch (err) {
      dispatch(logout());
    }
  };

  return (
    <>
      <OrganizationChangeModal
        open={isOpen.isOrganizationModalOpen}
        organizationId={organizationId}
        organizations={user.organizations}
        onConfirm={handleOrganizationChange}
        onCancel={() => handleClose(StateKeys.isOrganizationModalOpen)}
      />
      <AppBar
        classes={{ root: classes.appbar }}
        position="static"
        color="inherit"
      >
        <Container classes={{ root: classes.containerRoot }}>
          <MuiToolbar className={classes.toolbar}>
            <Link to="/">
              <img alt="Logo da Enviabybus" src={logo} title="Enviabybus" />
            </Link>
            <Box paddingX={3}>
              <Button
                disableRipple
                style={{ textTransform: 'none' }}
                onClick={() => handleOpen(StateKeys.isOrganizationModalOpen)}
              >
                <Icon style={{ marginRight: 4 }}>corporate_fare</Icon>
                {organization?.name}
                {user.organizations.length > 1 && <ArrowDropDown />}
              </Button>
            </Box>

            {links.map(
              ({ icon, label, to, allowedOrganizationTypes }, index) =>
                mayLinkBeVisible(allowedOrganizationTypes) && (
                  <ToolbarLink key={index} icon={icon} label={label} to={to} />
                )
            )}

            <Box ml="auto">
              <UserMenu />
            </Box>
          </MuiToolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Toolbar;
