import BaseError, { BaseErrorAttributes } from '../base.error';

// #region Typings
export enum HttpClientErrorCode {
  CredentialsExpired = 'credentials_expired',
  CredentialsInvalid = 'credentials_invalid',
  Forbidden = 'forbidden',
  Generic = 'http_client_error',
  InvalidParam = 'invalid_param',
  NotFound = 'not_found',
  ShipmentHasCte = 'shipment_has_already_issued_cte_to_update',
  Unauthorized = 'unauthorized',
  Unexpected = 'unexpected_error',
  Uniqueness = 'NOT_UNIQUE',
}

export interface HttpClientErrorAttributes
  extends Omit<BaseErrorAttributes, 'code'> {
  code: HttpClientErrorCode;
}
// #endregion Typings

export class HttpClientError
  extends BaseError
  implements HttpClientErrorAttributes {
  code: HttpClientErrorCode;

  constructor({ code, details, message }: HttpClientErrorAttributes) {
    super({ details, message });
    this.code = code;
  }
}

export class HttpClientUnexpectedError extends HttpClientError {}

export default HttpClientError;
