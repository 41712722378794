import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';

import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

import { ERROR_MESSAGES } from '../constants/error-messages';
import { PasswordEditFields } from '../components/PasswordEditForm';

const schema = yup.object().shape({
  confirmation: yup
    .string()
    .required(ERROR_MESSAGES.string.required)
    .oneOf(
      [yup.ref('password'), null],
      'Senha e confirmação devem ser iguais.'
    ),
  currentPassword: yup
    .string()
    .required(ERROR_MESSAGES.string.required)
    .min(6, ERROR_MESSAGES.string.min)
    .max(255, ERROR_MESSAGES.string.max),
  password: yup
    .string()
    .required(ERROR_MESSAGES.string.required)
    .min(6, ERROR_MESSAGES.string.min)
    .max(255, ERROR_MESSAGES.string.max),
});

export interface PasswordEditFormData {
  confirmation: string;
  currentPassword: string;
  password: string;
}

export type WithPasswordFormProps = {
  children: React.ReactNode;
  form: UseFormReturn<PasswordEditFormData>;
};

function withPasswordForm<P>(
  Component: React.ComponentType<P & WithPasswordFormProps>
) {
  return (props: P) => {
    const form = useForm<PasswordEditFormData>({
      defaultValues: {
        confirmation: '',
        currentPassword: '',
        password: '',
      },
      mode: 'onSubmit',
      resolver: yupResolver(schema),
      reValidateMode: 'onChange',
    });

    return (
      <Component form={form} {...props}>
        <PasswordEditFields control={form.control} />
      </Component>
    );
  };
}

export default withPasswordForm;
