import React from 'react';

import { Alert, AlertClassKey, AlertProps, AlertTitle } from '@material-ui/lab';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

export interface AlertCardProps extends Omit<AlertProps, 'classes'> {
  classes?: Partial<ClassNameMap<AlertClassKey | 'title'>>;
  title?: string;
}

const AlertCard = ({ children, classes, title, ...args }: AlertCardProps) => {
  const { title: titleClassName, ...classNames } = classes || {};

  return (
    <Alert classes={classNames} {...args}>
      {title && <AlertTitle className={titleClassName}>{title}</AlertTitle>}
      {children}
    </Alert>
  );
};

export default AlertCard;
