import flatMap from 'lodash/flatMap';

import { ShipmentVolumeItemData } from '../components/VolumesForm';
import VolumeData from '../data-types/volume-data';
import VolumeInformationData from '../data-types/volume-information-data';

const toCentimeters = (value: number, decimals = 2): number => {
  return Number((value * 100).toFixed(decimals));
};

const toMeters = (value: number, decimals = 2): number => {
  return Number((value / 100).toFixed(decimals));
};

export const volumesArrayBuilder = (
  volumes: ShipmentVolumeItemData[]
): VolumeData[] => {
  return flatMap(volumes, (volume: ShipmentVolumeItemData) => {
    return Array(volume.quantity).fill({
      height: toMeters(volume.height),
      length: toMeters(volume.length),
      quantity: 1,
      width: toMeters(volume.width),
    });
  });
};

export const buildVolumeItemData = (
  volumes: VolumeInformationData[]
): ShipmentVolumeItemData[] => {
  return volumes.map((volume: VolumeInformationData) => {
    return {
      height: toCentimeters(volume.height || 0),
      length: toCentimeters(volume.length || 0),
      packageId: 'no-option',
      quantity: volume.quantity || 0,
      width: toCentimeters(volume.width || 0),
    };
  });
};
