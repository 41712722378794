import React from 'react';

import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import dayjs from 'dayjs';
import ptBr from 'dayjs/locale/pt-br';

import { store } from './store';
import Routes from './navigation/Routes';
import theme from './theme';

dayjs.locale(ptBr);

const App = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default App;
