import React, { useEffect, useMemo } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import dayjs from 'dayjs';

import { buildShipmentIdentifier } from '../helpers/shipment-code.helper';
import { decimalNumber } from '../helpers/mask.helper';
import { getColorByStatus } from '../helpers/status.helper';
import { selectShipmentIsRequestingDetails } from '../../features/Shipments/shipmentsSlice';
import { SHIPMENT_STATUS_LABEL_MAP } from '../enums/shipment-status.enum';
import { useAppSelector } from '../../app/hooks';
import EntityDetails from './EntityDetails';
import ShipmentInformationsData from '../data-types/shipment-informations-data';
import ShipmentModality from '../enums/shipment-modality.enum';
import Skeleton, { SkeletonWidthSize } from './Skeleton';

const useStyles = makeStyles({
  emptyList: {
    fontStyle: 'italic',
    fontSize: '1.125rem',
  },
  label: {
    fontSize: '0.75rem',
  },
  value: {
    fontSize: '0.75rem',
    fontWeight: 'bolder',
    marginTop: '0.1875rem',
  },
});

export interface ShipmentDetailsProps {
  shipment: ShipmentInformationsData;
  onFindDetails: () => void;
}

const ShipmentDetails = ({ shipment, onFindDetails }: ShipmentDetailsProps) => {
  const classes = useStyles();

  const isLoading = useAppSelector(selectShipmentIsRequestingDetails);

  const statusColor = useMemo<string>(() => {
    return getColorByStatus(shipment?.status);
  }, [shipment?.status]);

  useEffect(() => {
    if (!shipment) {
      onFindDetails();
    }
  }, [onFindDetails, shipment]);

  return (
    <>
      {(isLoading || shipment) && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography className={classes.label} color="textPrimary">
                Código de Rastreio
              </Typography>
              {isLoading ? (
                <Skeleton width={SkeletonWidthSize.Medium} />
              ) : (
                <Typography className={classes.value}>
                  {shipment ? buildShipmentIdentifier(shipment) : '---'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.label} color="textPrimary">
                Situação
              </Typography>
              {isLoading ? (
                <Skeleton width={SkeletonWidthSize.Medium} />
              ) : (
                <Typography
                  className={classes.value}
                  style={{ color: statusColor }}
                >
                  {(shipment?.status &&
                    SHIPMENT_STATUS_LABEL_MAP.get(shipment?.status)) ||
                    '---'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.label} color="textPrimary">
                Data de criação
              </Typography>
              {isLoading ? (
                <Skeleton width={SkeletonWidthSize.Medium} />
              ) : (
                <Typography className={classes.value}>
                  {shipment?.createdAt
                    ? dayjs(shipment?.createdAt).format('DD/MM/YYYY HH:mm')
                    : '---'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.label} color="textPrimary">
                Data de atualização
              </Typography>
              {isLoading ? (
                <Skeleton width={SkeletonWidthSize.Medium} />
              ) : (
                <Typography className={classes.value}>
                  {shipment?.updatedAt
                    ? dayjs(shipment?.updatedAt).format('DD/MM/YYYY HH:mm')
                    : '---'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.label} color="textPrimary">
                Quantidade
              </Typography>
              {isLoading ? (
                <Skeleton width={SkeletonWidthSize.Medium} />
              ) : (
                <Typography className={classes.value}>
                  {shipment?.volumesQuantity
                    ? `${shipment?.volumesQuantity} volume(s)`
                    : '---'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.label} color="textPrimary">
                Peso
              </Typography>
              {isLoading ? (
                <Skeleton width={SkeletonWidthSize.Medium} />
              ) : (
                <Typography className={classes.value}>
                  {shipment?.totalWeight
                    ? `${decimalNumber(shipment?.totalWeight)} Kg`
                    : '---'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.label} color="textPrimary">
                Peso cubado
              </Typography>
              {isLoading ? (
                <Skeleton width={SkeletonWidthSize.Medium} />
              ) : (
                <Typography className={classes.value}>
                  {shipment?.cubageWeight
                    ? `${decimalNumber(shipment?.cubageWeight)} Kg`
                    : '---'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.label} color="textPrimary">
                Valor declarado
              </Typography>
              {isLoading ? (
                <Skeleton width={SkeletonWidthSize.Medium} />
              ) : (
                <Typography className={classes.value}>
                  {shipment?.declaredValue
                    ? `R$ ${decimalNumber(shipment?.declaredValue)}`
                    : '---'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.label} color="textPrimary">
                Previsão de saída
              </Typography>
              {isLoading ? (
                <Skeleton width={SkeletonWidthSize.Medium} />
              ) : (
                <Typography className={classes.value}>
                  {shipment?.etd
                    ? dayjs(shipment?.etd).format('DD/MM/YYYY HH:mm')
                    : '---'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.label} color="textPrimary">
                Previsão de entrega
              </Typography>
              {isLoading ? (
                <Skeleton width={SkeletonWidthSize.Medium} />
              ) : (
                <Typography className={classes.value}>
                  {shipment?.eta
                    ? dayjs(shipment?.eta).format('DD/MM/YYYY HH:mm')
                    : '---'}
                </Typography>
              )}
            </Grid>
          </Grid>
          {(shipment?.sender || shipment?.dispatcher) && (
            <Box marginTop="30px">
              <EntityDetails
                entity={shipment?.sender || shipment?.dispatcher}
                isLoading={isLoading}
                title="Remetente"
              />
            </Box>
          )}
          {(shipment?.recipient || shipment?.receiver) && (
            <Box marginTop="30px">
              <EntityDetails
                entity={shipment?.recipient || shipment?.receiver}
                isLoading={isLoading}
                title="Destinatário"
              />
            </Box>
          )}
          {shipment?.consignee && (
            <Box marginTop="30px">
              <EntityDetails
                entity={shipment.consignee}
                isLoading={isLoading}
                title="Tomador"
              />
            </Box>
          )}
          {shipment?.authorizedReceiver && (
            <Box marginTop="30px">
              <EntityDetails
                entity={shipment?.authorizedReceiver}
                isLoading={isLoading}
                title="Responsável pelo recebimento"
              />
            </Box>
          )}
          <Box marginTop="30px">
            <EntityDetails
              entity={
                shipment?.receiptModality === ShipmentModality.Counter &&
                shipment?.routeLegs[0]?.originEntity
                  ? shipment?.routeLegs[0]?.originEntity
                  : shipment?.originShipperEntity
              }
              isLoading={isLoading}
              title="Local de Coleta"
            />
          </Box>
          <Box marginTop="30px">
            <EntityDetails
              entity={
                shipment?.receiptModality === ShipmentModality.Counter &&
                shipment?.routeLegs[0]?.destinationEntity
                  ? shipment?.routeLegs[0]?.destinationEntity
                  : shipment?.destinationShipperEntity
              }
              isLoading={isLoading}
              title="Local de Entrega"
            />
          </Box>
        </>
      )}
    </>
  );
};

export default ShipmentDetails;
