import { useEffect, useRef, useState } from 'react';

const useInterval = (
  callback: () => void,
  delay: number | null,
  executeImmediately = false
) => {
  const [immediateExecution, setImmediateExecution] = useState<boolean>(
    executeImmediately
  );
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null) {
      return undefined;
    }

    if (immediateExecution) {
      savedCallback.current();
      setImmediateExecution(false);
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay, immediateExecution]);
};

export default useInterval;
