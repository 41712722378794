import * as yup from 'yup';

import { CONTENT_STATEMENT_SCHEMA } from '../components/ContentStatementForm/ContentStatementForm';
import { ERROR_MESSAGES } from '../constants/error-messages';
import { SELECT_PLACEHOLDER_VALUE } from '../constants/select-placeholder-value';
import ShipmentModality from '../enums/shipment-modality.enum';

const DESTINATION_DOOR_MODALITY_SCHEMA = yup.lazy((_, data) => {
  if (data.parent.deliveryModality === ShipmentModality.Counter) {
    return yup.number().notRequired();
  }
  return NUMBER_SCHEMA;
});

const NUMBER_SCHEMA = yup
  .number()
  .positive(ERROR_MESSAGES.number.required)
  .required(ERROR_MESSAGES.number.required);

const ORIGIN_DOOR_MODALITY_SCHEMA = yup.lazy((_, data) => {
  if (data.parent.receiptModality === ShipmentModality.Counter) {
    return yup.number().notRequired();
  }
  return NUMBER_SCHEMA;
});

// #region VolumesForm
const VOLUME_DIMENSIONS_SCHEMA = yup
  .number()
  .integer(ERROR_MESSAGES.number.integer)
  .positive(ERROR_MESSAGES.number.positive)
  .min(1, ERROR_MESSAGES.number.positive)
  .max(99999, ERROR_MESSAGES.number.maxDigits(5)) // Máximo de 5 dígitos
  .required(ERROR_MESSAGES.string.required)
  .typeError(ERROR_MESSAGES.string.required);

export const VOLUME_FORM_SCHEMAS = {
  declaredValue: yup
    .string()
    .notOneOf(['0'], ERROR_MESSAGES.number.positive)
    .required(ERROR_MESSAGES.string.required),
  totalWeight: yup
    .string()
    .notOneOf(['0'], ERROR_MESSAGES.number.positive)
    .required(ERROR_MESSAGES.string.required),
  volumes: yup
    .array()
    .of(
      yup.object().shape({
        height: VOLUME_DIMENSIONS_SCHEMA,
        length: VOLUME_DIMENSIONS_SCHEMA,
        package: yup.string().when(['height', 'length', 'width'], {
          is: (height, length, width) => !height || !length || !width,
          then: yup
            .string()
            .notOneOf(
              [SELECT_PLACEHOLDER_VALUE, ''],
              ERROR_MESSAGES.string.required
            ),
          otherwise: yup.string(),
        }),
        quantity: VOLUME_DIMENSIONS_SCHEMA,
        width: VOLUME_DIMENSIONS_SCHEMA,
      })
    )
    .min(1),
};
// #endregion VolumesForm

const SHIPMENT_SCHEMA = yup.object().shape({
  contentStatement: yup.lazy((_, data) => {
    if (data.parent.hasContentStatement) {
      return CONTENT_STATEMENT_SCHEMA;
    }
    return yup.object().notRequired();
  }),
  deliveryModality: yup
    .string()
    .oneOf([ShipmentModality.Door, ShipmentModality.Counter])
    .notRequired(),
  destinationShipperEntityId: DESTINATION_DOOR_MODALITY_SCHEMA,
  destinationEntity: yup.lazy((_, data) => {
    if (data.parent.deliveryModality === ShipmentModality.Counter) {
      return NUMBER_SCHEMA;
    }
    return yup.number().notRequired();
  }),
  hasContentStatement: yup.boolean(),
  hasNFes: yup.boolean(),
  nfes: yup.lazy((_, data) => {
    if (data.parent.hasNFes) {
      return yup.array().min(1);
    }
    return yup.array().notRequired();
  }),
  recipientId: NUMBER_SCHEMA,
  receiptModality: yup
    .string()
    .oneOf([ShipmentModality.Door, ShipmentModality.Counter])
    .notRequired(),
  shipperCode: yup.string().notRequired(),
  originShipperEntityId: ORIGIN_DOOR_MODALITY_SCHEMA,
  originEntity: yup.lazy((_, data) => {
    if (data.parent.receiptModality === ShipmentModality.Counter) {
      return yup.number().notRequired();
    }
    return yup.number().notRequired();
  }),
  senderId: NUMBER_SCHEMA,
  ...VOLUME_FORM_SCHEMAS,
});

export default SHIPMENT_SCHEMA;
