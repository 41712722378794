import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { uniqBy } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@material-ui/core/Grid';

import { AutocompleteOption } from '../components/Autocomplete';
import { decimalNumber } from '../helpers/mask.helper';
import { formatDocumentNumber } from '../helpers/document.helper';
import { loadNfes } from '../../features/Shipments/New/shipmentNewSlice';
import { REACT_APP_EBB_ORGANIZATION_ID } from '../../.env';
import {
  selectOrganizationId,
  selectSessionIsCarryingCompany,
} from '../../features/Session/sessionSlice';
import {
  selectEntities,
  listEntitiesGroupedByOrganizationThunk,
} from '../../features/Entities/entitiesSlice';
import {
  ShipmentDestinationForm,
  ShipmentDestinationFormInputs,
  ShipmentOriginForm,
  ShipmentOriginFormInputs,
} from '../components/EntityForms';
import {
  ShipmentVolumeItemData,
  ShipmentVolumesForm,
} from '../components/VolumesForm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import CteForm, { CTeFormInputs } from '../components/CteForm/CteForm';
import EntityData from '../data-types/entity-data';
import FiscalDocuments, {
  FiscalDocumentsFieldType,
  FiscalDocumentsFormInputs,
} from '../components/FiscalDocuments/FiscalDocuments';
import SHIPMENT_SCHEMA from '../schemas/shipment-schema';
import ShipmentData from '../data-types/shipment-data';
import ShipmentShipperCodeForm from '../components/ShipmentShipperCodeForm/ShipperCodeForm';

export interface ShipmentFormInputs
  extends ShipmentDestinationFormInputs,
    ShipmentOriginFormInputs,
    FiscalDocumentsFormInputs,
    CTeFormInputs {
  declaredValue: string;
  shipperCode: string;
  totalWeight: string;
  volumes: ShipmentVolumeItemData[];
}

interface WithShipmentFormState {
  authorizedEntitiesOptions: AutocompleteOption[];
  filteredOptions: AutocompleteOption[];
  options: AutocompleteOption[];
  shippingSiteOptions: AutocompleteOption[];
}

export type WithShipmentFormProps = {
  children: React.ReactNode;
  contentStatementHasError?: boolean;
  nfesHasError?: boolean;
};

function withShipmentForm<P>(
  Component: React.ComponentType<P & WithShipmentFormProps>
) {
  return (
    props: P & { shipment?: ShipmentData; showDocumentsField?: boolean }
  ) => {
    const dispatch = useAppDispatch();

    const entitiesList = useAppSelector(selectEntities);
    const isCarryingCompany = useAppSelector(selectSessionIsCarryingCompany);
    const organizationId = useAppSelector(selectOrganizationId);

    const { shipment, showDocumentsField = true } = props;

    const [
      shipmentFormStates,
      setShipmentFormStates,
    ] = useState<WithShipmentFormState>({
      authorizedEntitiesOptions: [],
      filteredOptions: [],
      options: [],
      shippingSiteOptions: [],
    });

    const methods = useForm<ShipmentFormInputs>({
      defaultValues: {
        authorizedReceiverId: shipment?.authorizedReceiver?.id,
        contentStatement: {
          documentNumber: shipment?.contentStatement?.documentNumber || '',
          value: shipment?.contentStatement
            ? decimalNumber(shipment.contentStatement.value || 0)
            : '',
        },
        contentStatementHasError: false,
        declaredValue:
          shipment?.declaredValue != null
            ? decimalNumber(shipment?.declaredValue)
            : '',
        deliveryModality: shipment?.deliveryModality || undefined,
        destinationEntity: shipment?.routeLegs[0]?.destinationEntity?.id,
        destinationShipperEntityId: shipment?.destinationShipperEntity?.id || 0,
        hasContentStatement: !!shipment?.contentStatement,
        hasCTes: false,
        ctes: shipment?.fiscalDocuments || [],
        ctesHasError: false,
        hasNFes: false,
        nfes: shipment?.fiscalDocuments || [],
        nfesHasError: false,
        originEntity: shipment?.routeLegs[0]?.originEntity?.id,
        originShipperEntityId: shipment?.originShipperEntity?.id || 0,
        receiptModality: shipment?.receiptModality || undefined,
        recipientId: shipment?.recipient?.id || 0,
        senderId: shipment?.sender?.id || 0,
        shipperCode: shipment?.shipperCode || '',
        totalWeight:
          shipment?.totalWeight != null
            ? decimalNumber(shipment?.totalWeight)
            : '',
        volumes: shipment?.volumesInformations || [],
      },
      mode: 'onSubmit',
      resolver: yupResolver(SHIPMENT_SCHEMA),
      reValidateMode: 'onChange',
    });

    const { control, setValue, watch } = methods;

    const [
      contentStatementHasError,
      destinationEntity,
      nfesHasError,
      originEntity,
      recipientId,
      senderId,
    ] = watch([
      'contentStatementHasError',
      'destinationEntity',
      'nfesHasError',
      'originEntity',
      'recipientId',
      'senderId',
    ]);

    const reloadEntities = useCallback(() => {
      if (organizationId) {
        dispatch(listEntitiesGroupedByOrganizationThunk(organizationId));
      }
    }, [dispatch, organizationId]);

    const entityToAutocompleteOptions = useCallback((actors: EntityData[]) => {
      return actors.map(
        (entity: EntityData): AutocompleteOption => {
          return {
            label: entity?.displayName || entity?.name || '',
            subTitle:
              entity?.documentNumber &&
              `Documento: ${formatDocumentNumber(entity?.documentNumber)}`,
            value: entity?.id,
          };
        }
      );
    }, []);

    const filterEntitiesOptionsByEntityId = useCallback(
      (entityId?: number) => {
        const { authorizedEntitiesOptions } = shipmentFormStates;

        if (!entityId) {
          return authorizedEntitiesOptions;
        }

        return authorizedEntitiesOptions.filter((option) => {
          return option.value !== entityId;
        });
      },
      [shipmentFormStates]
    );

    const shippingSiteToAutocompleteOptions = useCallback(
      (actors: EntityData[]) => {
        const shippingSites = actors.filter((e: EntityData) => !e.disposable);

        return entityToAutocompleteOptions(shippingSites);
      },
      [entityToAutocompleteOptions]
    );

    const associatedEntites = useMemo<EntityData[]>(() => {
      return entitiesList.filter((entity) => {
        return (
          entity.organizationId === +REACT_APP_EBB_ORGANIZATION_ID &&
          entity.shippingSite
        );
      });
    }, [entitiesList]);

    const destinationEntitiesOptions = useMemo<AutocompleteOption[]>(() => {
      return filterEntitiesOptionsByEntityId(originEntity);
    }, [filterEntitiesOptionsByEntityId, originEntity]);

    const entities = useMemo<EntityData[]>(() => {
      return entitiesList.filter((entity) => {
        return entity.organizationId !== +REACT_APP_EBB_ORGANIZATION_ID;
      });
    }, [entitiesList]);

    const originEntitiesOptions = useMemo<AutocompleteOption[]>(() => {
      return filterEntitiesOptionsByEntityId(destinationEntity);
    }, [destinationEntity, filterEntitiesOptionsByEntityId]);

    useEffect(() => {
      reloadEntities();
    }, [dispatch, organizationId, reloadEntities]);

    useEffect(() => {
      const nonSelectedOptions = shipmentFormStates.options.filter(
        (opt: AutocompleteOption) => opt.value !== senderId
      );

      setShipmentFormStates((prevState) => {
        return {
          ...prevState,
          filteredOptions: nonSelectedOptions,
        };
      });
    }, [recipientId, senderId, shipmentFormStates.options]);

    useEffect(() => {
      const entityOptions = entityToAutocompleteOptions(entities);
      const shippingSites = shippingSiteToAutocompleteOptions(entities);
      const authorizedEntityOptions = entityToAutocompleteOptions(
        associatedEntites
      );

      setShipmentFormStates({
        authorizedEntitiesOptions: authorizedEntityOptions,
        filteredOptions: uniqBy(entityOptions, 'value'),
        options: uniqBy(entityOptions, 'value'),
        shippingSiteOptions: shippingSites,
      });
    }, [
      associatedEntites,
      entities,
      entityToAutocompleteOptions,
      shippingSiteToAutocompleteOptions,
    ]);

    useEffect(() => {
      if (shipment?.fiscalDocuments) {
        dispatch(loadNfes(shipment.fiscalDocuments));
      }
    }, [dispatch, shipment?.fiscalDocuments]);

    return (
      <FormProvider {...methods}>
        <Component
          {...props}
          contentStatementHasError={contentStatementHasError}
          nfesHasError={nfesHasError}
        >
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <ShipmentOriginForm
                control={control}
                entities={originEntitiesOptions}
                options={shipmentFormStates.options}
                shippingSites={shipmentFormStates.shippingSiteOptions}
                setFormValue={setValue}
              />
            </Grid>
            <Grid item>
              <ShipmentDestinationForm
                control={control}
                entities={destinationEntitiesOptions}
                filteredOptions={shipmentFormStates.filteredOptions}
                options={shipmentFormStates.options}
                shippingSites={shipmentFormStates.shippingSiteOptions}
                setFormValue={setValue}
              />
            </Grid>
            {isCarryingCompany && (
              <Grid item>
                <CteForm />
              </Grid>
            )}
            <Grid item>
              {showDocumentsField && (
                <FiscalDocuments
                  initialFieldState={FiscalDocumentsFieldType.Closed}
                />
              )}
            </Grid>
            <Grid item>
              <ShipmentVolumesForm />
            </Grid>
            <Grid item>
              <ShipmentShipperCodeForm />
            </Grid>
          </Grid>
        </Component>
      </FormProvider>
    );
  };
}

export default withShipmentForm;
