import React, { useCallback, useEffect, useState } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

import {
  listEntitiesThunk,
  selectEntitiesFilter,
  selectEntitiesFirst,
} from '../../features/Entities/entitiesSlice';
import { selectOrganizationId } from '../../features/Session/sessionSlice';
import { UncontrolledTextField } from './TextField';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: 20,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 10,
  },
}));

const EntityLocationFilter = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const filter = useAppSelector(selectEntitiesFilter);
  const first = useAppSelector(selectEntitiesFirst);
  const organizationId = useAppSelector(selectOrganizationId);

  const [location, setLocation] = useState<string>('');

  const handleSearch = useCallback(() => {
    dispatch(
      listEntitiesThunk({
        filter: {
          ...filter,
          location: location === '' ? undefined : location,
          organizationIds: [organizationId],
        },
        first,
        page: 1,
      })
    );
  }, [dispatch, filter, first, location, organizationId]);

  useEffect(() => {
    setLocation(filter?.location || '');
  }, [filter]);

  useEffect(() => {
    if (location === '' && filter?.location) {
      handleSearch();
    }
  }, [filter?.location, handleSearch, location]);

  return (
    <Box mb={2.5}>
      <Typography className={classes.title}>Cidade</Typography>
      <Paper className={classes.paper} square variant="outlined">
        <UncontrolledTextField
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSearch}>
                <SearchIcon color="action" />
              </IconButton>
            ),
          }}
          placeholder="Cidade"
          type="text"
          value={location}
          onChange={(event) => setLocation(event.target.value)}
        />
      </Paper>
    </Box>
  );
};

export default EntityLocationFilter;
