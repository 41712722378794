import {
  AnyAction,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { sortBy, uniqBy } from 'lodash';

import {
  createEntity,
  EntityUpdateData,
  fetchEntity,
  listAssociatedEntities,
  listEntities,
  listEntitiesGroupedByOrganization,
  removeEntity,
  searchAddressByCep,
  updateEntity,
} from './entitiesAPI';
import { EntityCreateData } from '../../common/clients/zordon.client';
import { RootState } from '../../app/store';
import AddressData from '../../common/data-types/address-data';
import EntityData from '../../common/data-types/entity-data';
import EntityFilterData from '../../common/data-types/entity-filter-data';
import ErrorData from '../../common/data-types/error-data';
import RequestData from '../../common/data-types/request-data';
import RequestStatus from '../../common/enums/request-status.enum';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  const startsWith = action.type.startsWith('entities');
  const endsWith = action.type.endsWith('/fulfilled');

  return startsWith && endsWith;
};

const isPendingAction = (action: AnyAction): action is PendingAction => {
  const startsWith = action.type.startsWith('entities');
  const endsWith = action.type.endsWith('/pending');

  return startsWith && endsWith;
};

const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  const startsWith = action.type.startsWith('entities');
  const endsWith = action.type.endsWith('/rejected');

  return startsWith && endsWith;
};

// #region Typings
interface ResetThunkStatusActionParams {
  requestId: string;
}
// #endregion Typings

// #region Thunks
const CREATE_ENTITY_THUNK = 'entities/create';
export const createEntityThunk = createAsyncThunk(
  CREATE_ENTITY_THUNK,
  async (entity: EntityCreateData) => {
    const data = await createEntity(entity);

    return data;
  }
);

export const listAssociatedEntitiesThunk = createAsyncThunk(
  'entities/listAssociated',
  async () => {
    const data = await listAssociatedEntities();

    return data;
  }
);

export const listEntitiesGroupedByOrganizationThunk = createAsyncThunk(
  'entities/listGroupedByOrganization',
  async (organizationId: number) => {
    const data = await listEntitiesGroupedByOrganization(organizationId);

    return data;
  }
);

const LIST_ENTITIES_THUNK = 'entities/list';
interface ListEntitiesThunkParams {
  filter?: EntityFilterData;
  first?: number;
  page?: number;
}
export const listEntitiesThunk = createAsyncThunk(
  LIST_ENTITIES_THUNK,
  async ({ filter, first, page = 1 }: ListEntitiesThunkParams) => {
    const offset = first ? (page - 1) * first : 0;

    return listEntities({ filter, first, offset });
  }
);

const FIND_ADDRESS_THUNK = 'entities/address';
export const searchAddressByCepThunk = createAsyncThunk(
  FIND_ADDRESS_THUNK,
  async (cep: string) => {
    const data = await searchAddressByCep(cep);

    return data;
  }
);

const REMOVE_ENTITY_THUNK = 'entities/removeEntityThunk';
interface RemoveEntityThunkParams {
  entityId: EntityData['id'];
}
export const removeEntityThunk = createAsyncThunk(
  REMOVE_ENTITY_THUNK,
  async ({ entityId }: RemoveEntityThunkParams) => {
    return removeEntity(entityId);
  }
);

const UPDATE_ENTITY_THUNK = 'entities/updateEntityThunk';
interface UpdateEntityThunkParams extends Partial<EntityData> {
  id: EntityUpdateData['id'];
  entity: EntityUpdateData['entity'];
}
export const updateEntityThunk = createAsyncThunk(
  UPDATE_ENTITY_THUNK,
  async ({ id, entity }: UpdateEntityThunkParams) => {
    return updateEntity({ id, entity });
  }
);

const FETCH_ENTITY_THUNK = 'entities/fetchEntityThunk';
interface FetchEntityThunkParams extends Partial<EntityData> {
  id: EntityUpdateData['id'];
}
export const fetchEntityThunk = createAsyncThunk(
  FETCH_ENTITY_THUNK,
  async ({ id }: FetchEntityThunkParams) => {
    return fetchEntity({ id });
  }
);
// #endregion Thunks

// #region Slice
interface EntitiesState {
  address: AddressData | null;
  addressStatus: RequestStatus;
  entities: EntityData[];
  entity: EntityData | null;
  error: Record<string, any> | null;
  filter?: EntityFilterData;
  first: number;
  page: number;
  requestIds: RequestData['id'][];
  requestsById: Record<RequestData['id'], RequestData>;
  total: number;
}

const initialState: EntitiesState = {
  address: null,
  addressStatus: RequestStatus.Idle,
  entities: [],
  entity: null,
  error: null,
  first: 20,
  page: 1,
  requestIds: [],
  requestsById: {},
  total: 0,
};

const entitiesSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    cleanRequests: (state) => {
      state.requestIds = [];
      state.requestsById = {};
    },
    resetThunkStatus: (
      state,
      action: PayloadAction<ResetThunkStatusActionParams>
    ) => {
      const request = state.requestsById[action.payload.requestId];

      if (request) {
        Object.assign(request, {
          error: undefined,
          status: RequestStatus.Idle,
          type: action.type,
        });
      }
    },
    clearAddress: (state, { payload }) => {
      state.address = null;

      if (payload.resetStatus) {
        state.addressStatus = RequestStatus.Idle;
      }
    },
    clearEntity: (state) => {
      state.entity = null;
      state.requestIds = [];
      state.requestsById = {};
    },
    clearEntities: (state) => {
      state.entities = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listEntitiesThunk.fulfilled, (state, action) => {
        const { entities, total } = action.payload;
        const { arg } = action.meta;
        const { page } = arg;

        state.entities =
          page === 1
            ? entities
            : uniqBy([...state.entities, ...entities], 'id');
        state.total = total;
      })
      .addCase(listEntitiesThunk.pending, (state, action) => {
        const { arg } = action.meta;
        const { filter, page } = arg;

        state.page = page || initialState.page;
        state.filter = filter;
      })
      .addCase(
        listEntitiesGroupedByOrganizationThunk.fulfilled,
        (state, action) => {
          const { associatedEntities, entities } = action.payload;

          state.entities = uniqBy([...associatedEntities, ...entities], 'id');
        }
      )
      .addCase(searchAddressByCepThunk.fulfilled, (state, action) => {
        state.address = action.payload;

        state.addressStatus = RequestStatus.Succeeded;
      })
      .addCase(searchAddressByCepThunk.pending, (state) => {
        state.addressStatus = RequestStatus.Loading;
      })
      .addCase(searchAddressByCepThunk.rejected, (state) => {
        state.addressStatus = RequestStatus.Failed;
      })
      .addCase(createEntityThunk.fulfilled, (state, action) => {
        state.entity = action.payload;

        state.entities = uniqBy([...state.entities, action.payload], 'id');
      })
      .addCase(removeEntityThunk.fulfilled, (state, action) => {
        state.entities = state.entities?.filter(
          (e) => e.id !== action.meta.arg.entityId
        );
      })
      .addCase(fetchEntityThunk.fulfilled, (state, action) => {
        state.entity = action.payload;
      })
      .addMatcher(isFulfilledAction, (state, action: AnyAction) => {
        const { meta } = action;
        const request = state.requestsById[meta.requestId];

        if (!request) {
          return;
        }

        Object.assign(request, {
          error: undefined,
          status: RequestStatus.Succeeded,
          type: action.type,
        });
      })
      .addMatcher(isPendingAction, (state, action: AnyAction) => {
        const { meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Loading,
          type,
        };

        state.requestsById[requestId] = request;
      })
      .addMatcher(isRejectedAction, (state, action: AnyAction) => {
        const { error, meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Failed,
          type,
          error,
        };

        state.requestsById[requestId] = request;
      });
  },
});
// #endregion Slice

// #region Selectors
// #region Private Selectors
const selectEntitiesIsFulfilledByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const fulfilledType = [type, 'fulfilled'].join('/');
  const request = selectEntitiesRequestByType(rootState, fulfilledType);

  return request ? request.status === RequestStatus.Succeeded : false;
};

const selectEntitiesIsRequestingByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const pendingType = [type, 'pending'].join('/');
  const request = selectEntitiesRequestByType(rootState, pendingType);

  return request ? request.status === RequestStatus.Loading : false;
};

const selectEntitiesRequestByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData => {
  const requests = selectEntitiesRequestsByType(rootState, type);

  return requests.reverse()[0];
};

const selectEntitiesRequestErrorByType = (
  rootState: RootState,
  type: RequestData['type']
): ErrorData | Record<string, any> | undefined => {
  const rejectedType = [type, 'rejected'].join('/');
  const request = selectEntitiesRequestByType(rootState, rejectedType);

  return request ? request.error : undefined;
};

const selectEntitiesRequests = (rootState: RootState): RequestData[] => {
  const state = selectEntitiesState(rootState);

  return state.requestIds.map((id) => state.requestsById[id]);
};

const selectEntitiesRequestsByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData[] => {
  const requests = selectEntitiesRequests(rootState);

  return requests.filter((r) => r.type === type);
};

const selectEntitiesState = (state: RootState): EntitiesState => state.entities;
// #endregion Private Selectors

// #region Public Selectors
export const selectAddress = (state: RootState): AddressData | null => {
  const entityState = selectEntitiesState(state);

  return entityState.address;
};

export const selectAddressStatus = (state: RootState): any => {
  const entityState = selectEntitiesState(state);

  return entityState.addressStatus;
};

export const selectEntities = (state: RootState): EntitiesState['entities'] => {
  const entityState = selectEntitiesState(state);

  return sortBy(entityState.entities, [
    (entity) => entity.displayName || entity.name,
  ]);
};

export const selectEntitiesCreateError = (
  rootState: RootState
): ErrorData | Record<string, any> | undefined =>
  selectEntitiesRequestErrorByType(rootState, CREATE_ENTITY_THUNK);

export const selectEntitiesUpdateError = (
  rootState: RootState
): ErrorData | Record<string, any> | undefined =>
  selectEntitiesRequestErrorByType(rootState, UPDATE_ENTITY_THUNK);

export const selectEntitiesFilter = (
  rootState: RootState
): EntitiesState['filter'] => {
  const state = selectEntitiesState(rootState);

  return state.filter;
};

export const selectEntitiesFirst = (
  rootState: RootState
): EntitiesState['first'] => {
  const state = selectEntitiesState(rootState);

  return state.first;
};

export const selectEntitiesIsFulfilledCreate = (
  rootState: RootState
): boolean => {
  return selectEntitiesIsFulfilledByType(rootState, CREATE_ENTITY_THUNK);
};

export const selectEntitiesIsFulfilledRemove = (
  rootState: RootState
): boolean => {
  return selectEntitiesIsFulfilledByType(rootState, REMOVE_ENTITY_THUNK);
};

export const selectEntitiesIsFulfilledUpdate = (
  rootState: RootState
): boolean => {
  return selectEntitiesIsFulfilledByType(rootState, UPDATE_ENTITY_THUNK);
};

export const selectEntitiesIsRequestingCreate = (
  rootState: RootState
): boolean => {
  return selectEntitiesIsRequestingByType(rootState, CREATE_ENTITY_THUNK);
};

export const selectEntitiesIsRequestingFind = (
  rootState: RootState
): boolean => {
  return selectEntitiesIsRequestingByType(rootState, FETCH_ENTITY_THUNK);
};

export const selectEntitiesIsRequestingList = (
  rootState: RootState
): boolean => {
  return selectEntitiesIsRequestingByType(rootState, LIST_ENTITIES_THUNK);
};

export const selectEntitiesIsRequestingRemove = (
  rootState: RootState
): boolean => {
  return selectEntitiesIsRequestingByType(rootState, REMOVE_ENTITY_THUNK);
};

export const selectEntitiesPage = (
  rootState: RootState
): EntitiesState['page'] => {
  const state = selectEntitiesState(rootState);

  return state.page;
};

export const selectEntitiesTotal = (
  rootState: RootState
): EntitiesState['total'] => {
  const state = selectEntitiesState(rootState);

  return state.total;
};

export const selectEntity = (state: RootState): EntityData | null => {
  const entityState = selectEntitiesState(state);

  return entityState.entity;
};

export const selectEntityError = (
  state: RootState
): Record<string, any> | null => {
  const entityState = selectEntitiesState(state);

  return entityState.error;
};
// #endregion Public Selectors
// #endregion Selectors

export const {
  cleanRequests,
  clearAddress,
  clearEntity,
  clearEntities,
  resetThunkStatus,
} = entitiesSlice.actions;

export const entitiesReducer = entitiesSlice.reducer;
