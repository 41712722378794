import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  listEntitiesThunk,
  selectEntities,
  selectEntitiesFilter,
  selectEntitiesFirst,
  selectEntitiesIsRequestingList,
  selectEntitiesTotal,
} from './entitiesSlice';
import { PAGE_SIZES } from '../../common/constants/page-size.constants';
import { selectOrganizationId } from '../Session/sessionSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useUrlParams } from '../../common/hooks/useUrlParams';
import EmptyListIndicator from '../../common/components/EmptyListIndicator';
import EntityFilterChips from '../../common/components/EntityFilterChips';
import EntityList from '../../common/components/EntityList/EntityList';
import EntityLocationFilter from '../../common/components/EntityLocationFilter';
import EntityNameFilter from '../../common/components/EntityNameFilter';

const useStyles = makeStyles((theme: Theme) => ({
  buttonLabel: {
    color: '#FFFFFF',
  },
  buttonRoot: {
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
    backgroundColor: theme.palette.success.main,
    boxShadow: 'none',
    marginBottom: 30,
  },
  icon: {
    fontSize: '6.5rem',
  },
  link: {
    color: theme.palette.info.main,
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
  },
}));

interface Props {
  title: string;
}

const EntitiesPage = ({ title }: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { params, replaceUrlParams } = useUrlParams();

  const entities = useAppSelector(selectEntities);
  const filter = useAppSelector(selectEntitiesFilter);
  const first = useAppSelector(selectEntitiesFirst);
  const isLoading = useAppSelector(selectEntitiesIsRequestingList);
  const organizationId = useAppSelector(selectOrganizationId);
  const total = useAppSelector(selectEntitiesTotal);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (filter) {
      replaceUrlParams(filter);
    }
  }, [filter, replaceUrlParams]);

  useEffect(() => {
    dispatch(
      listEntitiesThunk({
        filter: {
          ...params,
          disposable: false,
          organizationIds: [organizationId],
        },
        first,
        page: 1,
      })
    );
  }, [dispatch, first, params, organizationId]);

  return (
    <Box
      m="auto"
      maxWidth={PAGE_SIZES.maxWidth}
      minWidth={PAGE_SIZES.minWidth}
      pl={PAGE_SIZES.sidePadding}
      pr={PAGE_SIZES.sidePadding}
    >
      <Box mb="1.25rem" mt="2.6875rem">
        <Typography color="primary" component="h2" variant="h4">
          Participantes de envio
        </Typography>
        <Typography>{total} resultados</Typography>
      </Box>
      <Grid container spacing={7}>
        <Grid item xs={3}>
          <Button
            classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}
            component={Link}
            fullWidth
            size="large"
            startIcon={<AddIcon />}
            to="/entities/new"
            variant="contained"
          >
            Novo
          </Button>
          <EntityFilterChips />
          <EntityNameFilter />
          <EntityLocationFilter />
        </Grid>
        <Grid item xs={9}>
          {!isLoading && entities.length === 0 ? (
            <EmptyListIndicator
              append={
                <Link className={classes.link} to="/entities/new">
                  Clique aqui para cadastrar
                </Link>
              }
              message="Nenhum Participante de Envio encontrado"
              prepend={
                <AccountCircleIcon
                  classes={{ root: classes.icon }}
                  color="primary"
                />
              }
            />
          ) : (
            <EntityList />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default EntitiesPage;
