import React, { useCallback, useEffect, useState } from 'react';

import { IconButton, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { UncontrolledTextField } from '../../../../common/components/TextField';

export interface CityNameFilterProps {
  data?: string;
  type: 'origin' | 'destination';
  onSubmit: (data: string | undefined) => void;
}

const CityNameFilter = ({ data, type, onSubmit }: CityNameFilterProps) => {
  const [value, setValue] = useState(data || undefined);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    if (e.target.value === '') {
      onSubmit(undefined);
    }
  };

  const handleSubmit = useCallback(() => {
    onSubmit(value);
  }, [onSubmit, value]);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    setValue(data || '');
  }, [data]);

  return (
    <UncontrolledTextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleSubmit}>
              <SearchIcon color="action" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder={type === 'origin' ? 'Cidade de origem' : 'Cidade de destino'}
      type="text"
      value={value}
      onChange={handleChangeValue}
      onKeyPress={handleKeyPress}
    />
  );
};

export default CityNameFilter;
