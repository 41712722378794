/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback } from 'react';

import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { UncontrolledTextField, UncontrolledTextFieldProps } from './TextField';

export interface SearchFilterProps extends UncontrolledTextFieldProps {
  excludes?: any;
  items: Array<any>;
  onFilter: (items: Array<any>, term?: string) => void;
}

const SearchFilter = ({
  excludes,
  items,
  type,
  onFilter,
  ...rest
}: SearchFilterProps) => {
  const filter = useCallback(
    (term: string): Array<any> => {
      if (!term || !items) {
        return items;
      }

      const toCompare = term.toLowerCase();

      const isPresent = (item: any, term: string): boolean => {
        if (item === null) {
          return false;
        }

        if (typeof item === 'object') {
          return Object.keys(item).some((key) => {
            if (excludes?.includes(key)) {
              return false;
            }

            return isPresent(item[key], term);
          });
        }

        return item.toString().toLowerCase().includes(toCompare);
      };

      return items.filter((item: any) => isPresent(item, term));
    },
    [excludes, items]
  );

  const handleOnChange = useCallback(
    (event: any) => {
      const term = event.target.value;
      const filteredItems = filter(term);

      onFilter(filteredItems, term);
    },
    [filter, onFilter]
  );

  return (
    <UncontrolledTextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
      }}
      type={type}
      onChange={handleOnChange}
      {...rest}
    />
  );
};

export default SearchFilter;
