import React, { useEffect, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';

import Accordion from '../Accordion';
import emptyImage from '../../assets/images/empty-list.svg';
import EmptyListIndicator from '../EmptyListIndicator';
import PagedList from '../PagedList';
import Shipment from '../Shipment';
import ShipmentListData from '../../data-types/shipment-list-data';
import ShipmentListItem from './Item';

// #region Style
const useStyles = makeStyles({
  collapsedAccordion: {
    marginTop: 0,
    marginBottom: 0,
    transition: 'margin 300ms ease',
  },
  expandedAccordion: {
    '&:first-of-type': {
      borderTop: 'none',
    },
    '& + div': {
      borderTop: '1px solid #C2C3C9',
    },
    borderTop: '1px solid #C2C3C9',
    boxShadow: '2px 2px 3px 0 #C2C3C9',
    marginBottom: '0.625rem',
    marginTop: '0.625rem',
    transition: 'margin 300ms ease',
  },
  details: {
    backgroundColor: '#FAFAFA',
    border: '1px solid #C2C3C9',
    borderTop: 'none',
    width: '100%',
  },
  list: {
    '&>:first-child': {
      borderTop: '1px solid #C2C3C9',
    },
    '&>:last-child': {
      borderTop: 'none',
      marginBottom: '1rem',
    },
    overflowY: 'auto',
  },
  tips: {
    textAlign: 'center',
    color: indigo[800],
  },
});
// #endregion Style

export interface ShipmentListProps {
  currentPage: number;
  data: ShipmentListData[];
  isLoading: boolean;
  total: number;
  onPageChange: (page: number) => void;
}

const ShipmentList = ({
  currentPage,
  data = [],
  isLoading,
  total,
  onPageChange,
}: ShipmentListProps) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleOnChange = (panel: string) => {
    setExpanded((prevState) =>
      prevState === panel || isLoading ? false : panel
    );
  };

  useEffect(() => {
    if (isLoading) {
      setExpanded(false);
    }
  }, [isLoading]);

  return (
    <PagedList
      className={classes.list}
      count={total}
      currentPage={currentPage}
      emptyListComponent={
        <EmptyListIndicator
          message="Nenhum envio cadastrado"
          prepend={<img alt="" src={emptyImage} />}
        />
      }
      isLoading={isLoading}
      rowsPerPage={20}
      onPageChange={onPageChange}
    >
      {Array.from(isLoading && data.length <= 0 ? new Array(10) : data).map(
        (shipment, index) => {
          return (
            <Accordion
              classes={{
                root:
                  expanded === shipment?.trackingCode
                    ? classes.expandedAccordion
                    : classes.collapsedAccordion,
              }}
              details={
                <>
                  {!isLoading && (
                    <Box className={classes.details}>
                      <Shipment shipment={shipment} />
                    </Box>
                  )}
                </>
              }
              isExpanded={expanded === shipment?.trackingCode}
              key={index}
              summary={
                <ShipmentListItem
                  isLoading={isLoading && data.length <= 0}
                  shipment={shipment}
                />
              }
              unmountOnExit
              onToggle={() =>
                !isLoading &&
                shipment.syncedAt &&
                data.length > 0 &&
                handleOnChange(shipment?.trackingCode)
              }
            />
          );
        }
      )}
    </PagedList>
  );
};

export default ShipmentList;
