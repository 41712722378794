import React, { useCallback } from 'react';

import { grey } from '@material-ui/core/colors';
import { Typography, makeStyles } from '@material-ui/core';

import { UncontrolledSelect } from '../../../../common/components/Select';
import SortOption from '../../../../common/enums/sort-option-type.enum';

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    color: grey[700],
    display: 'flex',
    justifyContent: 'right',
  },
  label: {
    fontWeight: 'bold',
    marginRight: '0.625rem',
  },
  select: {
    color: grey[700],
    width: '9.3125rem',
  },
});

export interface SortShipmentsFieldProps {
  data: SortOption;
  onChange: (data: SortOption) => void;
}

const SortShipmentsField = ({ data, onChange }: SortShipmentsFieldProps) => {
  const classes = useStyles();

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const option = e.target.value;

      if (option === SortOption.Asc || option === SortOption.Desc) {
        onChange(option);
      }
    },
    [onChange]
  );

  return (
    <div className={classes.container}>
      <Typography className={classes.label}>Ordenar por</Typography>
      <UncontrolledSelect
        className={classes.select}
        value={data}
        onChange={handleOnChange}
        options={[
          { value: SortOption.Desc, label: 'Mais Recente' },
          { value: SortOption.Asc, label: 'Mais Antigo' },
        ]}
      />
    </div>
  );
};

export default SortShipmentsField;
