import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { AutocompleteOption } from './Autocomplete';
import OptionsListItem from './Item';

const useStyles = makeStyles({
  emptyOptionsIndicator: {
    color: '#9e9e9e',
    padding: '5px 7px',
    textAlign: 'center',
  },
  list: {
    overflowY: 'auto',
  },
});

export interface OptionsListProps {
  append?: React.ReactNode;
  emptyOptionsPlaceholder?: string;
  options: AutocompleteOption[];
  showList: boolean;
  onClick?: (option: AutocompleteOption) => void;
}

const OptionsList = ({
  append,
  emptyOptionsPlaceholder = 'Sem Opções',
  options = [],
  showList = false,
  onClick,
}: OptionsListProps) => {
  const classes = useStyles();

  return (
    <Box
      bgcolor="#ffffff"
      borderRadius={append ? 0 : 3}
      boxShadow={1}
      className={classes.list}
      hidden={!showList}
      maxHeight="10.625rem"
      mt={1}
      overflow="y"
    >
      {options && options.length > 0 ? (
        <OptionsListItem options={options} onClick={onClick} />
      ) : (
        <Box className={classes.emptyOptionsIndicator} width="100%">
          <Typography noWrap>{emptyOptionsPlaceholder}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default OptionsList;
