import React, { useCallback, useEffect, useState } from 'react';

import { Box, Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { AutocompleteOption } from '../Autocomplete';
import { ERROR_MESSAGES } from '../../constants/error-messages';
import Autocomplete from '../Autocomplete/Autocomplete';
import EntityData from '../../data-types/entity-data';
import ParticipantNewFormDrawer from '../Participant/ParticipantNewFormDrawer';
import ShipmentModality from '../../enums/shipment-modality.enum';

const NUMBER_SCHEMA = yup
  .number()
  .positive(ERROR_MESSAGES.number.required)
  .required(ERROR_MESSAGES.number.required);

const DOOR_MODALITY_SCHEMA = yup.lazy((_, data) => {
  if (data.parent.receiptModality === ShipmentModality.Counter) {
    return yup.number().notRequired();
  }
  return NUMBER_SCHEMA;
});

export const SENDER_ENTITY_SCHEMA = yup.object().shape({
  receiptModality: yup
    .string()
    .oneOf([ShipmentModality.Door, ShipmentModality.Counter])
    .required(),
  originShipperEntityId: DOOR_MODALITY_SCHEMA,
  originEntity: yup.lazy((_, data) => {
    if (data.parent.receiptModality === ShipmentModality.Counter) {
      return NUMBER_SCHEMA;
    }
    return yup.number().notRequired();
  }),
  senderId: NUMBER_SCHEMA,
});

const useStyle = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: '#f6f6f6',
    },
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '3px',
    cursor: 'pointer',
    display: 'flex',
  },
  form: {
    height: '100%',
    marginBottom: '1.7rem',
  },
  icon: {
    marginBottom: '-4px',
    marginRight: '5px',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  spacing: {
    marginRight: '1rem',
  },
  strong: {
    fontWeight: 'bolder',
  },
  submit: {
    backgroundColor: '#1976D2',
    '&:hover': {
      backgroundColor: '#1560AB',
    },
    marginRight: '1rem',
  },
  subTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  text: {
    display: 'flex',
  },
});

export interface ShipmentOriginFormInputs {
  originShipperEntityId: number;
  receiptModality: ShipmentModality;
  originEntity?: number;
  senderId: number;
}

export interface ShipmentOriginFormProps {
  control: Control<any>;
  entities: AutocompleteOption[];
  isFormIndependent?: boolean;
  options?: AutocompleteOption[];
  shippingSites: AutocompleteOption[];
  setFormValue: UseFormSetValue<any>;
}

export const ShipmentOriginForm = ({
  control,
  isFormIndependent = false,
  options = [],
  setFormValue,
}: ShipmentOriginFormProps) => {
  const classes = useStyle();

  const [showParticipantForm, setShowParticipantForm] = useState<boolean>(
    false
  );
  const [currentHandledField, setCurrentHandledField] = useState<string>();

  const formValues = useWatch({ control });

  const handleOpenParticipantForm = useCallback((field: string) => {
    setCurrentHandledField(field);
    setShowParticipantForm(true);
  }, []);

  const selectCreatedEntity = useCallback(
    (formField?: string, entity?: EntityData) => {
      if (formField && entity) {
        setFormValue(formField as any, entity?.id);
      }
    },
    [setFormValue]
  );

  const handleParticipantFormClose = useCallback(
    (formField?: string, entity?: EntityData) => {
      selectCreatedEntity(formField, entity);
      setCurrentHandledField('');
      setShowParticipantForm(false);
    },
    [selectCreatedEntity]
  );

  const appendComponent = useCallback(
    (fieldName: string): React.ReactNode => {
      return (
        <Box
          bgcolor="#ffffff"
          borderColor="#C2C3C9"
          borderTop={1}
          boxShadow={2}
          className={classes.button}
          color="#1976D2"
          px={2}
          py={2}
          textAlign="left"
          onClick={() => handleOpenParticipantForm(fieldName)}
        >
          <PersonAddIcon className={classes.icon} htmlColor="#1976D2" />
          <Typography className={classes.text} noWrap>
            Criar Novo
          </Typography>
        </Box>
      );
    },
    [classes.button, classes.icon, classes.text, handleOpenParticipantForm]
  );

  useEffect(() => {
    if (formValues.senderId) {
      setFormValue('originShipperEntityId', formValues.senderId, {
        shouldValidate: true,
      });
    }
  }, [formValues.senderId, setFormValue]);

  return (
    <Box height="100%">
      <ParticipantNewFormDrawer
        currentHandledField={currentHandledField}
        showForm={showParticipantForm && !!currentHandledField}
        onClose={handleParticipantFormClose}
      />
      <Box height="calc(100% - 4.3rem)">
        <Box
          bgcolor="#FFFFFF"
          border={1}
          borderColor="#C2C3C9"
          borderRadius={3}
          width="50rem"
          p="30px"
        >
          <Grid container>
            <Grid className={classes.marginBottom} item xs={12}>
              <Typography
                color="textSecondary"
                className={classes.strong}
                variant="h6"
              >
                <Icon className={classes.icon}>near_me </Icon>
                Origem do Transporte
              </Typography>
            </Grid>
            <Grid className={classes.marginBottom} item xs={7}>
              <Autocomplete
                append={appendComponent('senderId')}
                control={control}
                disabled={isFormIndependent}
                label="Remetente *"
                name="senderId"
                options={options}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ShipmentOriginForm;
