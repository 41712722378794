import React from 'react';

import { Box, darken, makeStyles } from '@material-ui/core';

import { EntityCreateData } from '../../../common/clients/zordon.client';
import { PersonType } from '../../../common/enums/person-type.enum';
import SubmitButton from '../../../common/components/SubmitButton';
import withEntityForm, {
  EntityFormInputs,
  WithEntityFormProps,
} from '../../../common/hocs/withEntityForm';

const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: darken('#1976D2', 0.2),
    },
    backgroundColor: '#1976D2',
  },
  footer: {
    borderTop: '1px solid #C2C3C9',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    width: '100vw',
  },
});

export interface EntityNewFormProps {
  isLoading: boolean;
  organizationId: number;
  onSubmit: (entity: EntityCreateData) => void;
}

const EntityNewForm = ({
  children,
  form,
  isLoading,
  organizationId,
  onSubmit,
}: EntityNewFormProps & WithEntityFormProps) => {
  const classes = useStyles();

  const {
    formState: { isSubmitted, isValid },
    handleSubmit,
  } = form;

  const toModel = (data: EntityFormInputs): EntityCreateData => {
    const {
      cep,
      clientCode,
      complement,
      document,
      email,
      location,
      name,
      neighborhood,
      nickname,
      number,
      personType,
      phone,
      state,
      stateRegistration,
      street,
      taxFree,
    } = data;

    return {
      address: {
        cep,
        complement,
        location,
        neighborhood,
        number,
        state,
        street,
      },
      code: clientCode,
      corporation: PersonType.Corporation === personType,
      displayName: nickname,
      documentNumber: document,
      email,
      name,
      organizationId,
      phone: phone.replace(/\D/g, ''),
      shippingSite: false,
      stateRegistration: taxFree ? '' : stateRegistration,
    };
  };

  const handleOnSubmit = (data: EntityFormInputs): void => {
    onSubmit(toModel(data));
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Box marginBottom={10}>{children}</Box>
      <Box bgcolor="background.default" className={classes.footer}>
        <Box display="flex" justifyContent="flex-end" py={2} width="50rem">
          <SubmitButton
            classes={{ root: classes.button }}
            color="primary"
            disabled={isLoading || (isSubmitted && !isValid)}
            isLoading={isLoading}
            type="submit"
            variant="contained"
          >
            Cadastrar
          </SubmitButton>
        </Box>
      </Box>
    </form>
  );
};

export default withEntityForm<EntityNewFormProps>(EntityNewForm);
