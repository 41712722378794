import React, { useMemo, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';

import FolderEyeIcon from './FolderEyeIcon';
import ImageGallery from './ImageGallery';
import Modal from './Modals/Modal';
import StoredFileData from '../data-types/stored-file-data';

const useStyles = makeStyles({
  closeButton: {
    zIndex: 2,
  },
  modal: {
    position: 'relative',
  },
});

export interface AttatchmentsButtonProps extends ButtonProps {
  attatchments: StoredFileData[];
}

const AttatchmentsButton = ({
  attatchments,
  ...rest
}: AttatchmentsButtonProps) => {
  const classes = useStyles();

  const [isOpen, setOpen] = useState<boolean>(false);

  const images = useMemo<Array<{ original: string }>>(() => {
    return attatchments.map((attatchment: StoredFileData) => {
      return {
        original: attatchment.url,
      };
    });
  }, [attatchments]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={isOpen}
        PaperProps={{ elevation: 0, square: true }}
        onClose={handleClose}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <ImageGallery images={images} />
        </Box>
      </Modal>
      <Button
        {...rest}
        startIcon={<FolderEyeIcon />}
        variant={rest.variant || 'outlined'}
        onClick={handleOpen}
      >
        Visualizar Anexos ({images.length})
      </Button>
    </>
  );
};

export default AttatchmentsButton;
