import React, { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Icon,
  makeStyles,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { sortBy } from 'lodash';

import { ConfirmationModal } from '.';
import { UncontrolledRadio } from '../Radio';
import OrganizationData from '../../data-types/organization-data';
import SearchFilter from '../SearchFilter';

const useStyles = makeStyles((theme) => ({
  emptyListMessage: {
    color: theme.palette.text.hint,
    fontSize: 18,
    fontStyle: 'italic',
    marginTop: '8%',
    textAlign: 'center',
  },
  radioGroupContainer: {
    height: 168,
    overflowY: 'auto',
  },
}));

export interface OrganizationChangeModalProps {
  open: boolean;
  organizationId: number;
  organizations: OrganizationData[];
  onConfirm: (organizationId: number) => void;
  onCancel: () => void;
}

const OrganizationChangeModal = ({
  open,
  organizationId,
  organizations = [],
  onConfirm,
  onCancel,
}: OrganizationChangeModalProps) => {
  const classes = useStyles();

  const orderedOrganizations = useMemo(() => sortBy(organizations, 'name'), [
    organizations,
  ]);

  const [selectedOrganization, setSelectedOrganization] = useState<number>(
    organizationId
  );

  const [filteredOrganizations, setFiltered] = useState<OrganizationData[]>(
    orderedOrganizations
  );

  const handleChangeOrganization = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOrganization(+e.target.value);
  };

  useEffect(() => {
    setFiltered(orderedOrganizations);
  }, [orderedOrganizations]);

  return (
    <ConfirmationModal
      content={
        <Box px={3}>
          <SearchFilter
            fullWidth
            items={orderedOrganizations}
            style={{ marginBottom: 40 }}
            onFilter={(items) => setFiltered(items)}
          />
          <Box className={classes.radioGroupContainer}>
            {filteredOrganizations.length > 0 ? (
              <RadioGroup
                value={selectedOrganization}
                onChange={handleChangeOrganization}
              >
                {filteredOrganizations.map((organization: OrganizationData) => (
                  <UncontrolledRadio
                    key={organization.id}
                    label={organization.name}
                    labelPlacement="end"
                    value={organization.id}
                  />
                ))}
              </RadioGroup>
            ) : (
              <Box>
                <Typography className={classes.emptyListMessage}>
                  Nenhuma organização encontrada.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      }
      fullWidth
      maxWidth="sm"
      open={open}
      title={
        <Box display="flex" alignItems="center">
          <Icon style={{ marginRight: 8 }}>corporate_fare</Icon>
          <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
            Selecione a Organização
          </Typography>
        </Box>
      }
      onCancel={onCancel}
      onClose={onCancel}
      onConfirm={() => onConfirm(selectedOrganization)}
    />
  );
};

export default OrganizationChangeModal;
