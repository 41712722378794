import React, { useCallback, useEffect } from 'react';

import { Divider, List, ListItem, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import HttpsIcon from '@material-ui/icons/Https';
import Typography from '@material-ui/core/Typography';

import {
  cleanRequests,
  selectSessionErrorUserEdit,
  selectSessionIsFulfilledUserEdit,
  selectSessionIsRequestingUserEdit,
  selectUser,
  userUpdateThunk,
} from '../sessionSlice';
import { PAGE_SIZES } from '../../../common/constants/page-size.constants';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import SideBarButton from '../../../common/components/SideBarButton';
import UserProfileForm from '../../../common/components/UserProfileForm';
import UserEditData from '../../../common/data-types/user-edit-data';
import GenericErrorDialog from '../../../common/components/GenericErrorDialog';

const useStyles = makeStyles({
  sectionTitle: {
    fontSize: '1.375rem',
    marginBottom: 30,
  },
  title: {
    margin: '30px 0',
  },
});

interface Props {
  title: string;
}

const UserProfileEditPage = ({ title }: Props) => {
  const classes = useStyles();

  const history = useHistory();

  const user = useAppSelector(selectUser);

  const error = useAppSelector(selectSessionErrorUserEdit);
  const isLoading = useAppSelector(selectSessionIsRequestingUserEdit);
  const isSucceeded = useAppSelector(selectSessionIsFulfilledUserEdit);

  const dispatch = useAppDispatch();

  const handleOnSubmit = (data: UserEditData) => {
    dispatch(userUpdateThunk({ user: data }));
  };

  const cleanStatuses = useCallback(() => {
    dispatch(cleanRequests());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      cleanStatuses();
    };
  }, [cleanStatuses]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Box
      m="auto"
      maxWidth={PAGE_SIZES.maxWidth}
      minWidth={PAGE_SIZES.minWidth}
      pl={PAGE_SIZES.sidePadding}
      pr={PAGE_SIZES.sidePadding}
    >
      <GenericErrorDialog error={error} />
      <Typography
        className={classes.title}
        color="primary"
        component="h2"
        variant="h4"
      >
        Minha Conta
      </Typography>
      <Grid container spacing={7}>
        <Grid item xs={3}>
          <List>
            <ListItem disableGutters>
              <SideBarButton
                icon={<AccountCircleIcon />}
                isSelected
                label="Perfil"
              />
            </ListItem>
            <Divider style={{ margin: '0.5rem 0' }} />
            <ListItem disableGutters>
              <SideBarButton
                icon={<HttpsIcon />}
                label="Alterar Senha"
                onSelect={() => history.push('/user/password')}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={9}>
          <Box
            bgcolor="white"
            border="1px solid #C2C3C9"
            padding="2.5rem 5.625rem"
          >
            <Typography
              className={classes.sectionTitle}
              color="primary"
              component="h3"
              variant="h4"
            >
              Perfil
            </Typography>
            <Box width="25rem">
              <UserProfileForm
                error={error}
                isLoading={isLoading}
                isSucceeded={isSucceeded}
                user={user}
                onCleanError={cleanStatuses}
                onSubmit={handleOnSubmit}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserProfileEditPage;
