import React, { useCallback, useEffect } from 'react';

import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';

import {
  cleanRequests,
  selectSessionErrorTemporaryPasswordEdit,
  selectSessionIsFulfilledTemporaryPasswordEdit,
  selectSessionIsRequestingTemporaryPasswordEdit,
  temporaryPasswordEditThunk,
} from '../sessionSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import AlertCard from '../../../common/components/AlertCard';
import GenericErrorDialog from '../../../common/components/GenericErrorDialog';
import logo from '../../../common/assets/images/ebb-logo-light.svg';
import PasswordEditData from '../../../common/data-types/password-edit-data';
import PasswordEditForm from '../../../common/components/PasswordEditForm';

const useStyles = makeStyles({
  alertTitle: {
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 4,
  },
  card: {
    '@media(min-height: 50rem)': {
      alignItems: 'flex-start',
      marginTop: 130,
    },
    margin: 'auto',
    maxWidth: 424,
  },
  cardContent: {
    padding: 64,
    paddingTop: 32,
  },
});

interface Props {
  title: string;
}

const PasswordEditPage = ({ title }: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const error = useAppSelector(selectSessionErrorTemporaryPasswordEdit);
  const isLoading = useAppSelector(
    selectSessionIsRequestingTemporaryPasswordEdit
  );
  const isSucceeded = useAppSelector(
    selectSessionIsFulfilledTemporaryPasswordEdit
  );

  const history = useHistory();

  const onSubmit = (data: PasswordEditData) => {
    dispatch(temporaryPasswordEditThunk(data));
  };

  const onCleanStatuses = useCallback(() => {
    dispatch(cleanRequests());
  }, [dispatch]);

  useEffect(() => {
    if (isSucceeded) {
      history.push('/');
    }
  }, [history, isSucceeded]);

  useEffect(() => {
    return () => {
      onCleanStatuses();
    };
  }, [onCleanStatuses]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <GenericErrorDialog error={error} />
      <Box
        bgcolor="primary.main"
        pb={24}
        position="fixed"
        pt={3}
        px={5}
        width="100%"
      >
        <img alt="Logo da Enviabybus" src={logo} title="Enviabybus" />
      </Box>
      <Box
        alignItems="center"
        display="flex"
        height="100vh"
        justifyContent="center"
        position="relative"
        width="100vw"
      >
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography
              color="textSecondary"
              style={{ fontSize: 23, marginBottom: 20 }}
            >
              Altere sua senha
            </Typography>
            <AlertCard
              classes={{ title: classes.alertTitle }}
              severity="info"
              style={{ marginBottom: 20 }}
              title="Senha temporária"
            >
              Verificamos que a senha utilizada é uma{' '}
              <strong>senha temporária</strong>.<br />
              Por favor cadastre uma nova senha.
            </AlertCard>
            <PasswordEditForm
              error={error}
              isLoading={isLoading}
              onCleanError={onCleanStatuses}
              onSubmit={onSubmit}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default PasswordEditPage;
