export const APP_NAME = process.env.APP_NAME || 'Dashboard';

export const GOOGLE_MAPS_BASE_URL =
  process.env.GOOGLE_MAPS_BASE_URL || 'https://www.google.com/maps/search/';

export const REACT_APP_EBB_CARRIER_PARENT_ENTITY_ID =
  process.env.REACT_APP_EBB_CARRIER_PARENT_ENTITY_ID || 1;

export const REACT_APP_EBB_ORGANIZATION_ID =
  process.env.REACT_APP_EBB_ORGANIZATION_ID || 1;

export const REACT_APP_GANDALF_HOST =
  process.env.REACT_APP_GANDALF_HOST || 'http://localhost:3007';

export const REACT_APP_ZORDON_HOST =
  process.env.REACT_APP_ZORDON_HOST || 'http://localhost:3010';

export const REACT_APP_BRUDAM_SYNCER_HOST =
  process.env.REACT_APP_BRUDAM_SYNCER_HOST || 'http://localhost:3011';

export const REACT_APP_REQUIRED_ORGS = (
  process.env.REACT_APP_REQUIRED_ORGS || '480,638,647,742,751'
)
  .split(',')
  .map(Number);
