import Lodash from 'lodash';
import React, { useEffect } from 'react';

import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import {
  selectShipmentOccurrences,
  selectShipmentOccurrencesIsRequesting,
} from '../../features/Shipments/shipmentsSlice';
import { Timeline, TimelineItem } from './Timeline';
import { useAppSelector } from '../../app/hooks';
import Occurrence from './Occurrence';
import ShipmentData from '../data-types/shipment-data';

const useStyles = makeStyles((theme: Theme) => ({
  emptyList: {
    color: theme.palette.text.hint,
    fontSize: '1.125rem',
    fontStyle: 'italic',
  },
}));

export interface ShipmentDetailsProps {
  shipment: ShipmentData;
  onFetchOccurrences: () => void;
}

const ShipmentOccurrences = ({
  shipment,
  onFetchOccurrences,
}: ShipmentDetailsProps) => {
  const classes = useStyles();

  const occurrences = useAppSelector(selectShipmentOccurrences);
  const isLoading = useAppSelector(selectShipmentOccurrencesIsRequesting);

  const filteredOcurrences = (ocurrenceList) =>
    Lodash(ocurrenceList)
      .uniqWith(
        (arrVal, othVal) =>
          arrVal.description === othVal.description &&
          arrVal.registeredAt === othVal.registeredAt
      )
      .value();

  useEffect(() => {
    onFetchOccurrences();
  }, [onFetchOccurrences]);

  return (
    <>
      {(isLoading || occurrences.length > 0) && (
        <Grid container>
          <Grid item xs={12}>
            <Timeline showStartDot>
              {isLoading && shipment
                ? Array.from(Array(5)).map((_, index) => (
                    <TimelineItem key={index}>
                      <Occurrence isLoading={isLoading} shipment={shipment} />
                    </TimelineItem>
                  ))
                : filteredOcurrences(occurrences).map((o) => (
                    <TimelineItem key={`occurrence-${o.id}`}>
                      <Occurrence
                        isLoading={isLoading}
                        occurrence={o}
                        shipment={shipment}
                      />
                    </TimelineItem>
                  ))}
            </Timeline>
          </Grid>
        </Grid>
      )}
      {occurrences && occurrences.length === 0 && (
        <Box
          alignItems="center"
          className={classes.emptyList}
          display="flex"
          height="21.125rem"
          justifyContent="center"
        >
          Nenhuma ocorrência adicionada no envio
        </Box>
      )}
    </>
  );
};

export default ShipmentOccurrences;
