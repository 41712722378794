import { getActiveToken } from '../../services/authentication.service';
import GraphqlHttpClient from './graphql-http.client.ts';

export interface GraphqlClientConfig {
  query: string;
  variables?: Record<string, unknown>;
  abortSignal?: AbortSignal;
}

interface GraphqlClientDependencies {
  host: string;
  httpClient: GraphqlHttpClient;
}

export class GraphqlClient {
  host: string;

  httpClient: GraphqlHttpClient;

  private controller = new AbortController();

  constructor({ host, httpClient }: GraphqlClientDependencies) {
    this.host = host;
    this.httpClient = httpClient;
  }

  abortRequest(): void {
    this.controller.abort();
  }

  executeGraphQL(
    query: string,
    variables?: Record<string, unknown>,
    abortable = false,
    extraHeaders?: Record<string, unknown>
  ): Promise<any> {
    const sessionToken = getActiveToken();

    let authorization;
    let extraArgs;

    if (abortable) {
      const { signal } = this.controller;
      extraArgs = { signal };
    }

    if (sessionToken) {
      authorization = {
        Authorization: `Bearer ${sessionToken}`,
      };
    }

    const headers = {
      ...extraHeaders,
      ...authorization,
    };

    return this.fetchRequest({ query, variables }, headers, extraArgs);
  }

  gql(stringChunks: TemplateStringsArray, ...expressions: unknown[]): string {
    return stringChunks.reduce((acc, str, index) => {
      const exp = expressions[index] || '';
      return `${acc}${str}${exp}`;
    }, '');
  }

  private buildUrl(path: string): string {
    return [this.host, path]
      .filter(Boolean)
      .map((p) => p.replace(/^\//, '').replace(/\/$/, ''))
      .join('/');
  }

  private async fetchRequest(
    { query, variables }: GraphqlClientConfig,
    headers: Record<string, unknown>,
    extraArgs?: Record<string, unknown>
  ): Promise<any> {
    const url = this.buildUrl('graphql');
    const { body } = await this.httpClient.post({
      url,
      body: { query, variables },
      headers,
      ...extraArgs,
    });

    return body.data;
  }
}

export default GraphqlClient;
