import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

import {
  AddressForm,
  AddressFormInputs,
  addressSchema,
  IdentityForm,
  IdentityFormInputs,
  identitySchemas,
} from '../components/Participant';
import { PersonType } from '../enums/person-type.enum';
import { phoneNumberFormatter } from '../helpers/phone-input.helper';
import EntityData from '../data-types/entity-data';

const formSchema = yup.object().shape({
  ...addressSchema,
  ...identitySchemas,
});

export interface EntityFormInputs
  extends AddressFormInputs,
    IdentityFormInputs {}

export type WithEntityFormProps = {
  children: React.ReactNode;
  form: UseFormReturn<EntityFormInputs>;
};

function withEntityForm<P>(
  Component: React.ComponentType<P & WithEntityFormProps>
) {
  return (props: P & { entity?: EntityData }) => {
    const { entity } = props;

    const form = useForm<EntityFormInputs>({
      defaultValues: {
        cep: entity?.address?.cep || '',
        clientCode: entity?.code || '',
        complement: entity?.address?.complement || '',
        document: entity?.documentNumber || '',
        email: entity?.email || '',
        location: entity?.address?.location || '',
        name: entity?.name || '',
        neighborhood: entity?.address?.neighborhood || '',
        nickname: entity?.displayName || '',
        number: entity?.address?.number || '',
        personType: entity?.type || PersonType.Corporation,
        phone: phoneNumberFormatter(entity?.phone) || '',
        state: entity?.address?.state || '',
        stateRegistration: entity?.stateRegistration || '',
        street: entity?.address?.street || '',
        taxFree: !entity?.stateRegistration,
      },
      mode: 'onSubmit',
      resolver: yupResolver(formSchema),
      reValidateMode: 'onChange',
    });

    const {
      formState: { isSubmitted },
      setValue,
      trigger,
    } = form;

    const handleSetValue = useCallback(
      (data: Record<string, any>) => {
        Object.entries(data).map(([key, value]) => {
          const field = key;
          setValue(field as keyof EntityFormInputs, value);

          return field;
        });

        if (isSubmitted) {
          trigger();
        }
      },
      [isSubmitted, setValue, trigger]
    );

    return (
      <Component form={form} {...props}>
        <IdentityForm control={form.control} />
        <AddressForm
          control={form.control}
          showAddressFields={!!entity?.address?.cep}
          setFormValue={handleSetValue}
        />
      </Component>
    );
  };
}

export default withEntityForm;
