import React from 'react';

import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@material-ui/core';

export interface TabsProps extends Omit<MuiTabsProps, 'onChange'> {
  value: any;
  onChange: (event: React.ChangeEvent<any>, newValue: any) => void;
}

export const Tabs = ({ children, value, onChange, ...rest }: TabsProps) => (
  <MuiTabs value={value} onChange={onChange} {...rest}>
    {children}
  </MuiTabs>
);
