import React, { useCallback } from 'react';

import { Box, Grid, Typography, Icon, makeStyles } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { phoneNumberFormatter } from '../helpers/phone-input.helper';
import {
  RadioButtonOption,
  UncontrolledRadioButtonList,
} from './RadioButtonList';
import TextField from './TextField';

const useStyles = makeStyles({
  icon: {
    marginBottom: '-4px',
    marginRight: '5px',
  },
  label: {
    fontSize: 18,
    marginBottom: 14,
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  strong: {
    fontWeight: 'bolder',
  },
});

const radioButtonOptionsValues = new Map<string, boolean>([
  ['true', true],
  ['false', false],
]);

const radioButtonOptions: RadioButtonOption[] = [
  {
    label: 'SIM',
    value: 'true',
  },
  {
    label: 'NÃO',
    value: 'false',
  },
];

interface UserFormFieldsProps {
  disabledFields?: Partial<Record<'name' | 'email' | 'phone', boolean>>;
}

const UserFormFields = ({ disabledFields }: UserFormFieldsProps) => {
  const classes = useStyles();

  const { control, watch, setValue } = useFormContext();

  const phone = watch('phone', '');
  const isAdmin = watch('isAdmin', false);

  const handleIsAdminChange = useCallback(
    (value: string) => {
      setValue('isAdmin', radioButtonOptionsValues.get(value));
    },
    [setValue]
  );

  return (
    <Box>
      <Grid container>
        <Grid className={classes.marginBottom} item xs={12}>
          <Typography
            color="textSecondary"
            className={classes.strong}
            variant="h6"
          >
            <Icon className={classes.icon}>person </Icon>
            Identificação
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <TextField
            control={control}
            disabled={disabledFields?.name || false}
            fullWidth
            inputProps={{ maxLength: 60 }}
            label="Nome *"
            margin="normal"
            name="name"
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            control={control}
            disabled={disabledFields?.email || false}
            fullWidth
            inputProps={{ maxLength: 60 }}
            label="E-mail *"
            margin="normal"
            name="email"
            type="email"
          />
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={5}>
          <TextField
            control={control}
            disabled={disabledFields?.phone || false}
            fullWidth
            label="Telefone"
            margin="normal"
            name="phone"
            value={phoneNumberFormatter(phone)}
          />
        </Grid>
        <Grid item style={{ marginTop: 42 }} xs={12}>
          <Typography className={classes.label} color="textSecondary">
            O Usuário é Administrador?
          </Typography>
          <UncontrolledRadioButtonList
            options={radioButtonOptions}
            row
            value={isAdmin.toString()}
            onChange={handleIsAdminChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserFormFields;
