import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router';

import {
  clearEntity,
  fetchEntityThunk,
  removeEntityThunk,
  resetThunkStatus,
  selectEntitiesIsFulfilledRemove,
  selectEntitiesIsFulfilledUpdate,
  selectEntitiesIsRequestingFind,
  selectEntitiesIsRequestingRemove,
  selectEntitiesUpdateError,
  selectEntity,
  updateEntityThunk,
} from './entitiesSlice';
import { EntityUpdateData } from './entitiesAPI';
import { PAGE_SIZES } from '../../common/constants/page-size.constants';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import BackButton from '../../common/components/BackButton';
import EntityEditForm from './components/EntityEditForm';
import errorImage from '../../common/assets/images/dissatisfied-icon.svg';
import ErrorModal from '../../common/components/Modals/ErrorModal';

const useStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.info.main,
  },
  container: {
    maxWidth: '50rem',
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
  },
}));

interface Props {
  title: string;
}

const EntityEditPage = ({ title }: Props) => {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useAppDispatch();
  const entity = useAppSelector(selectEntity);

  const error = useAppSelector(selectEntitiesUpdateError);
  const isLoading = useAppSelector(selectEntitiesIsRequestingFind);
  const isRemoving = useAppSelector(selectEntitiesIsRequestingRemove);
  const isRemoved = useAppSelector(selectEntitiesIsFulfilledRemove);
  const isEntityUpdated = useAppSelector(selectEntitiesIsFulfilledUpdate);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [requestIds, setRequestIds] = useState<string[]>([]);

  const { id } = useParams<{ id?: string }>();

  const handleSubmit = useCallback(
    (data: EntityUpdateData['entity']) => {
      const { requestId } = dispatch(
        updateEntityThunk({ id: Number(id), entity: data })
      );
      setRequestIds([...requestIds, requestId]);
    },
    [dispatch, id, requestIds]
  );

  const handleOnRemove = useCallback(() => {
    const { requestId } = dispatch(removeEntityThunk({ entityId: Number(id) }));
    setRequestIds([...requestIds, requestId]);
  }, [dispatch, id, requestIds]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    dispatch(fetchEntityThunk({ id: Number(id) }));

    return () => {
      dispatch(clearEntity());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (isEntityUpdated || isRemoved) {
      history.goBack();
    }

    return () => {
      requestIds.forEach((requestId) => {
        dispatch(resetThunkStatus({ requestId }));
      });
    };
  }, [dispatch, history, isEntityUpdated, isRemoved, requestIds]);

  useEffect(() => {
    if (!!error && error.code === 'entity_document_unique_error') {
      setIsErrorModalOpen(true);
    }
  }, [error]);

  return (
    <>
      <Box
        m="auto"
        maxWidth={PAGE_SIZES.maxWidth}
        minWidth={PAGE_SIZES.minWidth}
        pl={PAGE_SIZES.sidePadding}
        pr={PAGE_SIZES.sidePadding}
      >
        <Container className={classes.container} disableGutters>
          <Box mb={3} mt={4}>
            <Grid alignItems="center" container direction="row" spacing={3}>
              <Grid item>
                <BackButton />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.title}
                  color="primary"
                  component="h2"
                >
                  Editar Participante
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {entity ? (
            <EntityEditForm
              entity={entity}
              isLoading={isLoading || isRemoving}
              onRemove={handleOnRemove}
              onSubmit={handleSubmit}
            />
          ) : (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}
        </Container>
      </Box>
      <ErrorModal
        image={errorImage}
        message="Por gentileza revisar as informações do Participante do envio"
        open={isErrorModalOpen}
        title="CPF ou CNPJ já cadastrado"
        onClose={() => setIsErrorModalOpen(false)}
      />
    </>
  );
};

export default EntityEditPage;
