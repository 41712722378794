import { gql } from '../../common/helpers/gql.helper';

const packageFragment = gql`
  height
  id
  length
  name
  organizationId
  width
`;

export const PACKAGE_FIND_QUERY = gql`
  query FindPackage($id: Int!) {
    package(id: $id) {
      ${packageFragment}
    }
  }
`;

export const PACKAGE_LIST_QUERY = gql`
  query ListPackages($organizationId: Int) {
    packages(organizationId: $organizationId) {
      ${packageFragment}
    }
  }
`;
