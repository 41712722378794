import React from 'react';

import {
  Box,
  Chip as MuiChip,
  ChipClassKey,
  ChipProps as MuiChipProps,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles({
  label: {
    fontSize: '10px',
    marginBottom: '-3px',
  },
  value: {
    fontSize: '13px',
  },
});

interface ChipStyles {
  label?: string;
  root?: string;
  value?: string;
}

interface ChipLabelProps extends Pick<ChipProps, 'label' | 'value'> {
  classes?: ChipStyles;
}

const ChipLabel = ({ classes, label, value }: ChipLabelProps) => (
  <Box>
    <Typography className={classes?.label}>{label}</Typography>
    <Typography className={classes?.value}>{value}</Typography>
  </Box>
);

export interface ChipProps extends MuiChipProps {
  classes?: Partial<ClassNameMap<ChipClassKey | 'value'>>;
  label?: string;
  value: string;
  onRemove?: () => void;
}

const Chip = ({ classes, label, value, onRemove, ...rest }: ChipProps) => {
  const styles = useStyles();

  return (
    <MuiChip
      {...rest}
      label={
        <ChipLabel
          classes={{
            label: `${classes?.label} ${styles.label}`,
            value: `${classes?.value} ${styles.value}`,
          }}
          label={label}
          value={value}
        />
      }
      onDelete={onRemove}
    />
  );
};

export default Chip;
