import React from 'react';

import { Control, RegisterOptions, useController } from 'react-hook-form';
import {
  InputBaseComponentProps,
  StandardTextFieldProps,
  TextField as MuiTextField,
} from '@material-ui/core';

export interface UncontrolledTextFieldProps extends StandardTextFieldProps {
  type?: 'email' | 'password' | 'text' | 'number';
  htmlInputProps?: InputBaseComponentProps;
}

export const UncontrolledTextField = ({
  htmlInputProps,
  type = 'text',
  ...rest
}: UncontrolledTextFieldProps) => {
  return <MuiTextField inputProps={htmlInputProps} type={type} {...rest} />;
};

export interface TextFieldProps extends UncontrolledTextFieldProps {
  control?: Control<any>;
  defaultValue?: unknown;
  name: string | `${string}.${string}` | `${string}.${number}`;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  shouldUnregister?: boolean;
}

const TextField = ({
  control,
  defaultValue = '',
  htmlInputProps,
  name,
  rules,
  shouldUnregister,
  ...rest
}: TextFieldProps) => {
  const {
    field: { onBlur, onChange, value },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  });

  return (
    <UncontrolledTextField
      error={invalid}
      helperText={error?.message}
      inputProps={htmlInputProps}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value || defaultValue}
      {...rest}
    />
  );
};

export default TextField;
