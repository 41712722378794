import React from 'react';

import Item from './Item';
import UserListData from '../../../../../common/data-types/user-list-data';

interface UserListProps {
  isLoading: boolean;
  usersIds: UserListData['id'][];
  onUpdate: (userId: UserListData['id'], status: boolean) => void;
}

const UserList = ({ isLoading, usersIds, onUpdate }: UserListProps) => {
  return (
    <>
      {Array.from(usersIds.length > 0 || !isLoading ? usersIds : Array(5)).map(
        (userId, index) => (
          <Item
            isLoading={isLoading}
            key={isLoading ? index : userId}
            userId={userId}
            onUpdate={onUpdate}
          />
        )
      )}
    </>
  );
};

export default UserList;
