import React, { useEffect } from 'react';

import { Box, Card, CardContent, makeStyles } from '@material-ui/core';

import {
  cleanRequests,
  loginThunk,
  selectSessionErrorLogin,
  selectSessionIsRequestingLogin,
} from '../sessionSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import GenericErrorDialog from '../../../common/components/GenericErrorDialog';
import LoginForm from './components/LoginForm';
import logo from '../../../common/assets/images/ebb-logo-light.svg';

const useStyles = makeStyles({
  card: {
    margin: 'auto',
    marginTop: -110,
    maxWidth: 424,
  },
  cardContent: {
    padding: 64,
    paddingTop: 32,
  },
});

interface Props {
  title: string;
}

const LoginPage = ({ title }: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const error = useAppSelector(selectSessionErrorLogin);
  const isLoading = useAppSelector(selectSessionIsRequestingLogin);

  const handleOnSubmit = (data) => {
    dispatch(loginThunk(data));
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    return () => {
      dispatch(cleanRequests());
    };
  }, [dispatch]);

  return (
    <>
      <GenericErrorDialog error={error} />
      <Box bgcolor="primary.main" pb={24} pt={3} px={5}>
        <img alt="Logo da Enviabybus" src={logo} title="Enviabybus" />
      </Box>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <LoginForm
            error={error}
            isLoading={isLoading}
            onSubmit={handleOnSubmit}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default LoginPage;
