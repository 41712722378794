/* eslint-disable no-template-curly-in-string */
export const ERROR_MESSAGES = {
  number: {
    positive: 'O número deve ser positivo',
    required: 'Preencha esse campo',
    integer: 'O número deve ser inteiro',
    maxDigits: (size: number) =>
      `O campo deve possuir no máximo ${size} caracteres`,
  },
  string: {
    email: 'E-mail inválido',
    max: 'O campo deve possuir no máximo ${max} caracteres',
    min: 'O campo deve possuir no mínimo ${min} caracteres',
    required: 'Preencha esse campo',
    number: 'Deve ser um Número',
  },
  file: {
    fileSize: (size: string) =>
      `O tamanho do arquivo não deve ultrapassar ${size}`,
    fileTipe: `Tipo de arquivo não permitido. Por favor, escolha um arquivo com extensão .jpg, .jpeg, .png, .gif, .tiff, .bmp ou .pdf.`,
  },
};
