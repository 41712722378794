import React, { ElementType, Ref } from 'react';

import { MenuItem as MuiMenuItem } from '@material-ui/core';

export interface MenuItemProps {
  children: React.ReactNode;
  classes?: Record<string, any>;
  component?: ElementType;
  dense?: boolean;
  disabled?: boolean;
  disableGutters?: boolean;
  ListItemClasses?: Record<string, any>;
  selected?: boolean;
  onClick?: () => void;
}

const MenuItem = React.forwardRef(
  (
    {
      children,
      classes,
      component = 'li',
      dense,
      disabled = false,
      disableGutters = false,
      ListItemClasses,
      selected = false,
      onClick,
    }: MenuItemProps,
    ref: Ref<any>
  ) => {
    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        e.stopPropagation();
        onClick();
      }
    };

    return (
      <MuiMenuItem
        classes={classes}
        component={component}
        dense={dense}
        disabled={disabled}
        disableGutters={disableGutters}
        ListItemClasses={ListItemClasses}
        ref={ref}
        selected={selected}
        onClick={handleOnClick}
      >
        {children}
      </MuiMenuItem>
    );
  }
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
