import React from 'react';

import { Control } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import TextField from '../TextField';

export interface UserProfileFormFieldsProps {
  control: Control<any>;
  onChange: (fieldName: string, value: any) => void;
}

const UserProfileFormFields = ({
  control,
  onChange,
}: UserProfileFormFieldsProps) => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <TextField
          control={control}
          disabled
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="E-mail"
          name="email"
          required
        />
      </Grid>
      <Grid item>
        <TextField
          control={control}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Nome*"
          name="name"
        />
      </Grid>
      <Grid item>
        <TextField
          control={control}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Telefone"
          name="phone"
          onChange={(e) => onChange('phone', e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default UserProfileFormFields;
