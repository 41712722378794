import NFe from '../data-types/nfe-data';
import FiscalDocument from '../data-types/fiscal-document-data';

export const fiscalDocumentToNfe = (
  fiscalDocument: FiscalDocument,
  recipientDocument: string | undefined,
  senderDocument: string | undefined
): NFe => {
  const {
    file,
    id,
    issueDate,
    key,
    number,
    order,
    serie,
    type,
    value,
    volumesQuantity,
    weight,
  } = fiscalDocument;

  return {
    file,
    id,
    issueDate,
    key,
    number,
    order,
    recipientDocumentNumber: recipientDocument,
    senderDocumentNumber: senderDocument,
    serie,
    type,
    value,
    volumesQuantity,
    weight,
  };
};
