import React, { useEffect, useState } from 'react';

import ErrorModal from './Modals/ErrorModal';
import icon from '../assets/images/dissatisfied-icon.svg';

interface GenericErrorDialogProps {
  error: Record<string, any> | null | undefined;
}

const GenericErrorDialog = ({ error }: GenericErrorDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (error?.code === 'http_client_error') {
      setOpen(true);
    }
  }, [error]);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <ErrorModal
      image={icon}
      message="Aconteceu uma falha interna, por favor tente novamente em instantes, caso persista, nos comunique"
      open={open}
      title="Opa! Aconteceu um erro"
      onClose={handleCloseDialog}
    />
  );
};

export default GenericErrorDialog;
