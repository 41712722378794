import React, { useCallback, useEffect } from 'react';

import { Box, Card, CardContent, Icon, makeStyles } from '@material-ui/core';

import {
  cleanRequests,
  requestPasswordResetThunk,
  selectSessionErrorRequestResetPassword,
  selectSessionIsFulfilledRequestResetPassword,
  selectSessionIsRequestingRequestResetPassword,
} from '../sessionSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import GenericErrorDialog from '../../../common/components/GenericErrorDialog';
import logo from '../../../common/assets/images/ebb-logo-light.svg';

const useStyles = makeStyles({
  card: {
    margin: 'auto',
    marginTop: -110,
    maxWidth: 424,
  },
  cardContent: {
    padding: 64,
    paddingTop: 32,
  },
});

interface Props {
  title: string;
}

const ForgotPasswordPage = ({ title }: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const error = useAppSelector(selectSessionErrorRequestResetPassword);
  const isLoading = useAppSelector(
    selectSessionIsRequestingRequestResetPassword
  );
  const isSucceeded = useAppSelector(
    selectSessionIsFulfilledRequestResetPassword
  );

  const handleSubmit = useCallback(
    (data) => {
      const { email } = data;
      dispatch(requestPasswordResetThunk(email));
    },
    [dispatch]
  );

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    return () => {
      dispatch(cleanRequests());
    };
  }, [dispatch]);

  return (
    <>
      <GenericErrorDialog error={error} />
      <Box bgcolor="primary.main" pb={24} pt={3} px={5}>
        <img alt="Logo da Enviabybus" src={logo} title="Enviabybus" />
      </Box>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {!isSucceeded && (
            <ForgotPasswordForm
              error={error}
              isLoading={isLoading}
              onSubmit={(data) => handleSubmit(data)}
            />
          )}
          {isSucceeded && (
            <Box
              alignItems="center"
              color="#28A745"
              justifyContent="center"
              py={3}
            >
              <Box fontSize={105} textAlign="center">
                <Icon fontSize="inherit">check_circle</Icon>
              </Box>
              <Box fontSize={25} textAlign="center">
                Enviamos um e-mail para você trocar sua senha
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default ForgotPasswordPage;
