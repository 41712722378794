import React, { useCallback, useState } from 'react';

import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import RefreshIcon from '@material-ui/icons/Refresh';

export interface RefreshButtonProps {
  className?: string;
  onRefresh: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.info.main,
    fontWeight: 'bold',
    marginRight: '20px',
  },
  refreshDate: {
    color: theme.palette.text.primary,
  },
}));

const RefreshButton = ({ className = '', onRefresh }: RefreshButtonProps) => {
  const classes = useStyles();

  const [refreshDate, setRefreshDate] = useState(dayjs());

  const handleClick = useCallback(() => {
    const now = dayjs();

    setRefreshDate(now);
    onRefresh();
  }, [onRefresh]);

  return (
    <Box alignItems="center" className={className} display="flex">
      <Button
        className={classes.button}
        color="inherit"
        startIcon={<RefreshIcon />}
        variant="text"
        onClick={handleClick}
      >
        Atualizar
      </Button>
      <Typography
        className={classes.refreshDate}
        component="small"
        variant="caption"
      >
        atualizado às {dayjs(refreshDate).format('HH:mm')}
      </Typography>
    </Box>
  );
};

export default RefreshButton;
