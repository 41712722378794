import { isNil, omitBy } from 'lodash';
import dayjs from 'dayjs';

import {
  FIND_BILLING_BY_PERIODICITY,
  FIND_BILLING_QUERY,
  LIST_BILLING_SHIPMENTS_QUERY,
} from './billingGraphql';
import { getGraphqlClient } from '../../app/initializer';
import { ShipmentStatus } from '../../common/enums/shipment-status.enum';
import AddressData from '../../common/data-types/address-data';
import BillingPeriodicity from '../../common/enums/billing-periodicity.enum';

// #region Typings
interface BillingData {
  groupedShipments: BillingGroupedShipmentsData[];
  totals: BillingTotalsData;
}

interface BillingShipmentCostData {
  advalorem: number;
  firstMile: number;
  icms: number;
  lastMile: number;
  middleMile: number;
  totalTaxes: number;
}

interface BillingShipmentCteData {
  number: number;
}

interface BillingShipmentData {
  becameReadyAt: string;
  cte: BillingShipmentCteData;
  destinationShipperEntityId: number;
  id: number;
  originShipperEntityId: number;
  shipmentCost: BillingShipmentCostData;
  status: ShipmentStatus;
  totalWeight: number;
  volumesQuantity: number;
}

interface BillingShipperEntityData {
  address: AddressData;
  displayName?: string;
  id: number;
  name: string;
}

export interface BillingFilters {
  endDate: Date;
  organizationId?: number;
  periodicity?: BillingPeriodicity;
  startDate: Date;
}

export interface BillingGroupedShipmentsData {
  destinationShipperEntity: BillingShipperEntityData;
  id: string;
  originShipperEntity: BillingShipperEntityData;
  shipmentsQuantity: number;
  totalValue: number;
  totalWeight: number;
  volumesQuantity: number;
}

export interface BillingShipmentListData {
  shipments: BillingShipmentData[];
  total: number;
}

export interface BillingTotalsData {
  numberOfShipments: number;
  numberOfVolumes: number;
  totalValue: number;
  totalWeight: number;
}

export interface BillingTotalsByPeriodicityData {
  numberOfShipments: number;
  numberOfVolumes: number;
  periodicity: BillingPeriodicity;
  periodicityValue: string;
  totalValue: number;
  totalWeight: number;
}

export interface ListBillingShipmentsFilter {
  becameReadyAtEnd?: string;
  becameReadyAtStart?: string;
  destinationShipperEntityId?: number;
  originShipperEntityId?: number;
}

export interface ListBillingShipmentsOpt {
  filter?: ListBillingShipmentsFilter;
  first?: number;
  offset?: number;
  order?: 'ASC' | 'DESC';
}
// #endregion Typings

const graphqlClient = getGraphqlClient();

// #region Private functions
const buildBillingFilters = ({
  endDate,
  organizationId,
  periodicity,
  startDate,
}: BillingFilters): Record<string, any> => {
  const filters = omitBy(
    {
      endDate: dayjs(endDate).endOf('day').toISOString(),
      organizationId,
      periodicity,
      startDate: dayjs(startDate).startOf('day').toISOString(),
    },
    isNil
  );

  return filters;
};

const convertGramsToKg = (weight: number): number => {
  const weightInKg = weight / 1000;

  return Math.round(weightInKg * 1e2) / 1e2;
};

const parseAddress = (json: Record<string, any>): AddressData => {
  const { location, state } = json;

  return { location, state };
};

const parseBilling = (json: Record<string, any>): BillingData => {
  const { groupedShipments, total } = json;

  return {
    groupedShipments:
      groupedShipments &&
      groupedShipments.map((group: Record<string, any>) =>
        parseBillingGroupedShipments(group)
      ),
    totals: parseBillingTotals(total),
  };
};

const parseBillingGroupedShipments = (
  json: Record<string, any>
): BillingGroupedShipmentsData => {
  const {
    destinationShipperEntity,
    originShipperEntity,
    shipmentsQuantity,
    totalValue,
    totalWeight,
    volumesQuantity,
  } = json;

  const parsedDestinationShipperEntity =
    destinationShipperEntity &&
    parseBillingShipperEntity(destinationShipperEntity);

  const parsedOriginShipperEntity =
    originShipperEntity && parseBillingShipperEntity(originShipperEntity);

  const id = [
    parsedOriginShipperEntity.id,
    parsedDestinationShipperEntity.id,
  ].join('-');

  return {
    destinationShipperEntity: parsedDestinationShipperEntity,
    id,
    originShipperEntity: parsedOriginShipperEntity,
    shipmentsQuantity,
    totalValue,
    totalWeight: convertGramsToKg(totalWeight),
    volumesQuantity,
  };
};

const parseBillingPeriodicity = (periodicity: string): BillingPeriodicity => {
  switch (periodicity) {
    case 'monthly':
      return BillingPeriodicity.Monthly;
    case 'weekly':
      return BillingPeriodicity.Weekly;
    default:
      return BillingPeriodicity.Daily;
  }
};

const parseBillingPeriodicityValue = (
  periodicity: string,
  periodicityValue: string
): string => {
  switch (periodicity) {
    case 'daily':
      return dayjs(periodicityValue).format('DD/MM');
    case 'monthly':
      return dayjs(periodicityValue).format('MMM/YY');
    case 'weekly':
      return periodicityValue.slice(-2);
    default:
      return periodicityValue;
  }
};

const parseBillingShipperEntity = (
  json: Record<string, any>
): BillingShipperEntityData => {
  const { address, displayName, id, name } = json;

  return { address: address && parseAddress(address), displayName, id, name };
};

const parseBillingTotals = (json: Record<string, any>): BillingTotalsData => {
  const { numberOfShipments, numberOfVolumes, totalValue, totalWeight } = json;

  return {
    numberOfShipments,
    numberOfVolumes,
    totalValue,
    totalWeight: convertGramsToKg(totalWeight),
  };
};

const parseBillingTotalsByPeriodicity = (
  json: Record<string, any>
): BillingTotalsByPeriodicityData => {
  const {
    numberOfShipments,
    numberOfVolumes,
    periodicity,
    periodicityValue,
    totalValue,
    totalWeight,
  } = json;

  return {
    numberOfShipments,
    numberOfVolumes,
    periodicity: parseBillingPeriodicity(periodicity),
    periodicityValue: parseBillingPeriodicityValue(
      periodicity,
      periodicityValue
    ),
    totalValue,
    totalWeight: convertGramsToKg(totalWeight),
  };
};

const parseShipment = (json: Record<string, any>): BillingShipmentData => {
  const {
    becameReadyAt,
    cte,
    destinationShipperEntity,
    id,
    originShipperEntity,
    shipmentCost,
    status,
    totalWeight,
    volumesQuantity,
  } = json;

  return {
    becameReadyAt,
    cte: cte && parseShipmentCte(cte),
    destinationShipperEntityId: destinationShipperEntity.id,
    id,
    originShipperEntityId: originShipperEntity.id,
    shipmentCost: shipmentCost && parseShipmentCost(shipmentCost),
    status: status && parseShipmentStatus(status),
    totalWeight: convertGramsToKg(totalWeight),
    volumesQuantity,
  };
};

const parseShipmentCost = (
  json: Record<string, any>
): BillingShipmentCostData => {
  const { advalorem, firstMile, icms, lastMile, middleMile, totalTaxes } = json;

  return { advalorem, firstMile, icms, lastMile, middleMile, totalTaxes };
};

const parseShipmentCte = (
  json: Record<string, any>
): BillingShipmentCteData => {
  const { number } = json;

  return { number };
};

const parseShipmentStatus = (status: string): ShipmentStatus => {
  switch (status) {
    case 'cancelled':
      return ShipmentStatus.Cancelled;
    case 'draft':
      return ShipmentStatus.Draft;
    case 'finished':
      return ShipmentStatus.Finished;
    case 'inTransit':
      return ShipmentStatus.InTransit;
    case 'ready':
      return ShipmentStatus.Ready;
    default:
      return ShipmentStatus.Unknown;
  }
};
// #endregion Private functions

// #region Public functions
export const findBilling = async ({
  endDate,
  organizationId,
  startDate,
}: BillingFilters): Promise<BillingData> => {
  const filters = buildBillingFilters({ endDate, organizationId, startDate });

  const { billing } = await graphqlClient.executeGraphQL(
    FIND_BILLING_QUERY,
    filters,
    true
  );

  return parseBilling(billing);
};

export const findBillingByPeriodicity = async ({
  endDate,
  organizationId,
  periodicity = BillingPeriodicity.Daily,
  startDate,
}: BillingFilters): Promise<BillingTotalsByPeriodicityData[]> => {
  const filters = buildBillingFilters({
    endDate,
    organizationId,
    periodicity,
    startDate,
  });

  const { billingByPeriodicity } = await graphqlClient.executeGraphQL(
    FIND_BILLING_BY_PERIODICITY,
    filters
  );

  const billingTotals = billingByPeriodicity.map((json: Record<string, any>) =>
    parseBillingTotalsByPeriodicity(json)
  );

  return billingTotals;
};

export const listBillingShipments = async ({
  filter = {},
  first = 10,
  offset = 0,
  order = 'ASC',
}: ListBillingShipmentsOpt): Promise<BillingShipmentListData> => {
  const {
    shipments: { data, total },
  } = await graphqlClient.executeGraphQL(
    LIST_BILLING_SHIPMENTS_QUERY,
    { filter, first, offset, order },
    true
  );

  const shipments =
    data && data.map((json: Record<string, any>) => parseShipment(json));

  return { shipments, total };
};
// #endregion Public functions
