import React from 'react';

import {
  Button,
  ButtonProps as MuiButtonProps,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.info.main,
  },
}));

interface BackButtonProps extends MuiButtonProps {
  label?: string;
}

const BackButton = ({ label, ...rest }: BackButtonProps) => {
  const classes = useStyles();
  const history = useHistory();

  const handleOnClick = () => {
    history.goBack();
  };

  return (
    <Button {...rest} onClick={handleOnClick}>
      <ArrowBackIcon className={classes.arrow} fontSize="large" />
      {label && <Typography>{label}</Typography>}
    </Button>
  );
};

export default BackButton;
