import { JSONSchema4Object } from 'json-schema';

import { BaseErrorDetails } from '../../errors/base.error';
import HttpClientError, {
  HttpClientErrorCode,
  HttpClientUnexpectedError,
} from '../../errors/clients/http-client.error';
import { GraphQLApolloErrors } from './graphql-errors.enum';
import {
  // GraphqlHttpMethod,
  // HttpClientAdapter,
  HttpRequestWithBodyArgs,
  HttpResponse,
} from '../http-client/http.client';

export type GraphqlHttpMethod = 'POST';

interface GraphqlError {
  extensions: {
    code: GraphQLApolloErrors;
  };
  message?: string;
  locations?: Record<string, any>[];
  path?: string[];
}

interface HandleErrorParams {
  errors: GraphqlError[];
  method: GraphqlHttpMethod;
  response: Response;
}

type HandleResponseParams = Omit<HandleErrorParams, 'errors'>;

export interface GraphqlClient {
  post(args: HttpRequestWithBodyArgs): Promise<HttpResponse>;
}

class GraphqlHttpClient implements GraphqlClient {
  post(args: HttpRequestWithBodyArgs): Promise<HttpResponse> {
    return this.makeRequest('POST', args);
  }

  private async handleError({
    errors,
    method,
    response,
  }: HandleErrorParams): Promise<void> {
    const { status, url } = response;

    const details: BaseErrorDetails = {
      method,
      url,
    };

    if (errors.length > 0) {
      const { extensions, message } = errors[0];

      throw new HttpClientError({
        code: this.httpErrorCodeFromJSON(extensions.code),
        details,
        message: message?.toString(),
      });
    }

    const text = await response.text();

    throw new HttpClientUnexpectedError({
      code: HttpClientErrorCode.Unexpected,
      details: {
        status,
        body: text,
      },
      message: 'Unexpected Error',
    });
  }

  private async handleResponse({
    method,
    response,
  }: HandleResponseParams): Promise<HttpResponse> {
    const { headers, status } = response;
    let body: Record<string, any> = {};

    if (status !== 204) {
      body = await response.json();
    }

    if (body?.errors) {
      await this.handleError({ errors: body.errors, method, response });
    }

    return { body, header: headers, status };
  }

  private httpErrorCodeFromJSON(
    code?: GraphQLApolloErrors
  ): HttpClientErrorCode {
    switch (code) {
      case 'UNAUTHENTICATED':
        return HttpClientErrorCode.Unauthorized;
      case 'FORBIDDEN':
        return HttpClientErrorCode.Forbidden;
      case 'BAD_USER_INPUT':
      case 'GRAPHQL_VALIDATION_FAILED':
        return HttpClientErrorCode.InvalidParam;
      default:
        return HttpClientErrorCode.Generic;
    }
  }

  private async makeRequest(
    method: GraphqlHttpMethod,
    args: HttpRequestWithBodyArgs
  ): Promise<HttpResponse> {
    const { body, headers, signal, url } = args;

    const response = await fetch(url, {
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json', ...headers },
      method,
      mode: 'cors',
      signal,
    });

    return this.handleResponse({ method, response });
  }

  private safeJSONParse(text: string): JSONSchema4Object[] {
    try {
      return [null, JSON.parse(text)];
    } catch (err) {
      return [err as JSONSchema4Object];
    }
  }
}

export default GraphqlHttpClient;
