import React, { useCallback } from 'react';

import {
  Button,
  ButtonClassKey,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { fileHandler, FileHelperParams } from '../helpers/file-handler.helper';
import FileViewIcon from './FileViewIcon';

const useStyles = makeStyles((theme: Theme) => ({
  columnLabel: {
    display: 'block',
    textAlign: 'center',
    textTransform: 'none',
  },
  icon: {
    color: theme.palette.info.light,
    height: '1.5rem',
    marginRight: '5px',
    width: '1.5rem',
  },
  rowLabel: {
    display: 'flex',
    textAlign: 'center',
    textTransform: 'none',
  },
  text: {
    color: theme.palette.info.light,
    fontSize: '0.75rem',
  },
}));

interface DocumentViewButtonProps {
  classes?: Partial<ClassNameMap<ButtonClassKey>>;
  fileParams: FileHelperParams;
  label?: string;
  labelDirection?: 'column' | 'row';
}

const DocumentViewButton = ({
  classes = {},
  fileParams,
  label,
  labelDirection = 'column',
}: DocumentViewButtonProps) => {
  const classNames = useStyles();

  const handleOnClick = useCallback(() => {
    fileHandler(fileParams);
  }, [fileParams]);

  return (
    <Button
      classes={{
        label:
          labelDirection === 'column'
            ? classNames.columnLabel
            : classNames.rowLabel,
        ...classes,
      }}
      onClick={handleOnClick}
    >
      <FileViewIcon className={classNames.icon} />
      {label && (
        <Typography className={classNames.text} component="p">
          {label}
        </Typography>
      )}
    </Button>
  );
};

export default DocumentViewButton;
