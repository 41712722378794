import React from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import AlertCard from '../AlertCard';
import Modal from './Modal';

const useStyles = makeStyles((theme: Theme) => ({
  confirmationButton: {
    color: theme.palette.info.main,
  },
}));

interface ExcelDownloadErrorProps {
  onClose: () => void;
  open: boolean;
}

const ExcelDownloadError = ({ onClose, open }: ExcelDownloadErrorProps) => {
  const classes = useStyles();

  const handleOnClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Modal
      actions={
        <Button className={classes.confirmationButton} onClick={handleOnClose}>
          OK
        </Button>
      }
      BackdropProps={{ style: { backgroundColor: '#000' } }}
      header="Ocorreu um erro"
      onClose={onClose}
      open={open}
    >
      <AlertCard severity="warning">
        Não foi possível fazer download do <strong>Excel</strong> neste momento.
        <br />
        <br />
        Tente novamente mais tarde. Caso persista, entre em contato.
      </AlertCard>
    </Modal>
  );
};

export default ExcelDownloadError;
