import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Typography from '@material-ui/core/Typography';

import Spinner from './Spinner';

interface AsyncFeedbackProps {
  error?: Record<string, any>;
  errorMessage?: string;
  isLoading: boolean;
  loadingMessage?: string;
  successMessage?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: 120,
  },
  message: {
    fontSize: 24,
    marginTop: 10,
  },
  messageContainer: {
    textAlign: 'center',
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: 120,
  },
}));

const AsyncFeedback = ({
  error,
  errorMessage = 'Opa! Aconteceu um erro',
  isLoading = false,
  loadingMessage = 'Carregando...',
  successMessage = 'Ação realizada com sucesso',
}: AsyncFeedbackProps) => {
  const classes = useStyles();

  return (
    <Box>
      {isLoading ? (
        <Box className={classes.messageContainer}>
          <Spinner size={100} theme="primary" />
          <Typography className={classes.message}>{loadingMessage}</Typography>
        </Box>
      ) : error ? (
        <Box className={classes.messageContainer}>
          <ErrorOutlineOutlinedIcon className={classes.errorIcon} />
          <Typography className={classes.message}>{errorMessage}</Typography>
        </Box>
      ) : (
        <Box className={classes.messageContainer}>
          <CheckCircleOutlineOutlinedIcon className={classes.successIcon} />
          <Typography className={classes.message}>{successMessage}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default AsyncFeedback;
