export const decimalMask = (input: number | string): string => {
  const value = input.toString();
  let formattedValue = value.replace(/\D|,|\./g, '');

  if (input < 1) {
    formattedValue = formattedValue.replace(/^0+(\d)/, '$1');
  }

  const valueLength = formattedValue.length;
  if (valueLength === 0) {
    return formattedValue;
  }
  let intSlice = formattedValue.slice(0, valueLength - 2);
  let decimal = formattedValue.slice(valueLength - 2, valueLength);

  if (decimal.length < 2 && decimal[0] === '0') {
    decimal = `${decimal}0`;
  }

  intSlice = intSlice.replace(/^0+(\d)/, '$1'); // Remove left-side zeroes
  intSlice = new Intl.NumberFormat('pt-BR').format(+intSlice);

  return `${intSlice || '0'},${decimal || '00'}`;
};

export const decimalNumber = (value: number): string => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
    .format(value)
    .replace(/^R\$/, '')
    .trim();
};

export const parseNumberMask = (value: string): number => {
  value = value.replace(/\./, '');
  value = value.replace(/,/, '.');

  return parseFloat(value);
};

export const maskStringByPattern = (
  value: string,
  pattern: string | undefined
): string => {
  let i = 0;

  if (value === '') {
    return value;
  }

  return (
    pattern?.replace(/#/g, () => {
      const newValue = value[i];
      i += 1;
      return newValue;
    }) || ''
  );
};
