import React, { useCallback, useState } from 'react';

import { Alert, AlertTitle } from '@material-ui/lab';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

import { AutocompleteOption, UncontrolledAutocomplete } from '../Autocomplete';
import Modal from './Modal';

const useStyles = makeStyles({
  bolder: {
    fontWeight: 'bolder',
  },
  button: {
    color: '#1976D2',
    fontWeight: 'bolder',
    marginRight: '1rem',
  },
  content: {
    minHeight: '12.5rem',
    overflowY: 'hidden',
    width: '40rem',
  },
});

export interface ChangeModalityModalProps {
  open: boolean;
  options: AutocompleteOption[];
  onClose?: () => void;
  onSubmit: (originShipperEntityId: number) => void;
}

const ChangeModalityModal = ({
  open,
  options,
  onClose,
  onSubmit,
}: ChangeModalityModalProps) => {
  const classes = useStyles();

  const [originShipperEntityId, setOriginShipperEntityId] = useState<
    number | undefined
  >();

  const handleFieldChange = useCallback(
    (option: AutocompleteOption['value']) => {
      const entity = options.find(
        (opt: AutocompleteOption) => opt.value === option
      );
      setOriginShipperEntityId(entity?.value);
    },
    [options]
  );

  const handleOnClose = useCallback(() => {
    setOriginShipperEntityId(undefined);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Modal
      actions={
        <>
          <Button
            classes={{ root: classes.button }}
            variant="text"
            onClick={handleOnClose}
          >
            Cancelar
          </Button>
          <Button
            classes={{ root: classes.button }}
            disabled={!originShipperEntityId}
            variant="text"
            onClick={() =>
              originShipperEntityId && onSubmit(originShipperEntityId)
            }
          >
            Cadastrar
          </Button>
        </>
      }
      header="Definir Coleta"
      maxWidth="lg"
      open={open}
      style={{ zIndex: 10 }}
      onClose={handleOnClose}
    >
      <Box className={classes.content}>
        <Box color="#606771">
          <Typography component="p" color="inherit">
            Para definir uma coleta com esses envios será necessário informar
            qual será o <strong>Local de Coleta</strong>
          </Typography>
        </Box>
        <Box mt={2}>
          <Alert severity="warning">
            <AlertTitle className={classes.bolder}>ATENÇÃO</AlertTitle>
            Ao definir o local de coleta, <strong>todos os envios</strong> desse
            agrupamento irão ser atualizados com essa informação
          </Alert>
        </Box>
        <Box width="80%" py={2}>
          <UncontrolledAutocomplete
            label="Local de Coleta *"
            name="entites"
            options={options}
            value={originShipperEntityId}
            onChange={handleFieldChange}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangeModalityModal;
