import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';

import {
  findShipmentCostThunk,
  findShipmentDetailsThunk,
  findShipmentDocumentsThunk,
  findShipmentsShippingEventsThunk,
  findShipmentVolumeDetailsThunk,
  selectShipmentIsRequestingVolumes,
  selectShipmentsInformationsById,
} from '../../features/Shipments/shipmentsSlice';
import { TabPanel } from './Tabs';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ShipmentCost from './ShipmentCost';
import ShipmentData from '../data-types/shipment-data';
import ShipmentDetails from './ShipmentDetails';
import ShipmentDocuments from './ShipmentDocuments';
import ShipmentOccurrences from './ShipmentOccurrences';
import ShipmentTabs, { ShipmentTabsOptions } from './ShipmentTabs';
import ShipmentVolumes from './ShipmentVolumes';

export interface ShipmentProps {
  shipment: ShipmentData;
}

const Shipment = ({ shipment }: ShipmentProps) => {
  const shipmentInformations = useAppSelector((state) =>
    selectShipmentsInformationsById(state, shipment.id)
  );

  const [isdisabled, setIsdisabled] = useState(true);

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectShipmentIsRequestingVolumes);

  const [tab, setTab] = useState<ShipmentTabsOptions>(
    ShipmentTabsOptions.Details
  );

  const handleOnChangeTab = (option: ShipmentTabsOptions) => {
    setTab(option);
  };

  // const hasCTE = () => {
  //   if (shipment.cte) return true;
  //   return false;
  // };

  const handleFindCost = useCallback(() => {
    dispatch(findShipmentCostThunk({ id: shipment.id }));
  }, [dispatch, shipment.id]);

  const handleFindDetails = useCallback(() => {
    dispatch(findShipmentDetailsThunk({ id: shipment.id }));
  }, [dispatch, shipment.id]);

  const handleFindDocuments = useCallback(() => {
    dispatch(findShipmentDocumentsThunk({ id: shipment.id }));
  }, [dispatch, shipment.id]);

  const handleFindOccurrences = useCallback(() => {
    dispatch(findShipmentsShippingEventsThunk({ shipmentId: shipment.id }));
  }, [dispatch, shipment.id]);

  const handleFindVolumes = useCallback(() => {
    dispatch(findShipmentVolumeDetailsThunk({ id: shipment.id }));
  }, [dispatch, shipment.id]);

  useEffect(() => {
    if (shipmentInformations && !shipmentInformations.volumesInformations) {
      handleFindVolumes();
      setIsdisabled(false);
    }
    if (shipmentInformations && shipmentInformations.volumesInformations)
      setIsdisabled(false);
  }, [handleFindVolumes, shipmentInformations]);

  return (
    <>
      <ShipmentTabs
        value={tab}
        onChange={handleOnChangeTab}
        isVolumeLoading={isLoading}
        isDislabled={isdisabled}
      />
      <Box overflow="auto scroll" height="24.125rem">
        <TabContext value={tab}>
          <TabPanel value={ShipmentTabsOptions.Details}>
            <ShipmentDetails
              shipment={shipmentInformations}
              onFindDetails={handleFindDetails}
            />
          </TabPanel>
          <TabPanel value={ShipmentTabsOptions.Volumes}>
            <ShipmentVolumes
              shipment={shipmentInformations}
              onFindVolumes={handleFindVolumes}
              // hasCTE={hasCTE}
              isLoading={isLoading}
            />
          </TabPanel>
          <TabPanel value={ShipmentTabsOptions.Documents}>
            <ShipmentDocuments
              shipment={shipmentInformations}
              onFindDocuments={handleFindDocuments}
            />
          </TabPanel>
          <TabPanel value={ShipmentTabsOptions.Occurrences}>
            <ShipmentOccurrences
              shipment={shipmentInformations}
              onFetchOccurrences={handleFindOccurrences}
            />
          </TabPanel>
          <TabPanel value={ShipmentTabsOptions.ShipmentCost}>
            <ShipmentCost
              shipmentCost={shipmentInformations?.shipmentCost}
              onFindCost={handleFindCost}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default Shipment;
