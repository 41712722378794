import React, { useCallback, useEffect, useMemo } from 'react';

import { Box, Button, makeStyles } from '@material-ui/core';
import { useWatch } from 'react-hook-form';

import {
  clearAddress,
  clearEntity,
  createEntityThunk,
  selectEntitiesIsFulfilledCreate,
  selectEntitiesIsRequestingCreate,
  selectEntity,
  selectEntityError,
} from '../../../features/Entities/entitiesSlice';
import { EntityCreateData } from '../../clients/zordon.client';
import { PersonType } from '../../enums/person-type.enum';
import { selectOrganizationId } from '../../../features/Session/sessionSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Drawer from '../Drawer';
import EntityData from '../../data-types/entity-data';
import GenericErrorDialog from '../GenericErrorDialog';
import withEntityForm, {
  EntityFormInputs,
  WithEntityFormProps,
} from '../../hocs/withEntityForm';

const useStyle = makeStyles({
  footer: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#F2F2F4',
  },
  form: {
    height: '100%',
    marginBottom: '1.7rem',
  },
  innerForm: {
    marginBottom: '1.7rem',
  },
  spacing: {
    marginRight: '1rem',
  },
  strong: {
    fontWeight: 'bolder',
  },
  submit: {
    '&:hover': {
      backgroundColor: '#1560AB',
    },
    backgroundColor: '#1976D2',
    marginRight: '1rem',
  },
});

interface ParticipantNewFormDrawerProps extends WithEntityFormProps {
  currentHandledField?: string;
  showForm: boolean;
  onClose: (formField?: string, entity?: EntityData) => void;
}

const ParticipantNewFormDrawer = ({
  children,
  currentHandledField,
  form,
  showForm,
  onClose,
}: ParticipantNewFormDrawerProps) => {
  const {
    clearErrors,
    control,
    formState: { isSubmitted, isValid },
    handleSubmit,
    reset,
    setValue,
  } = form;

  const classes = useStyle();

  const dispatch = useAppDispatch();

  const createdEntity = useAppSelector(selectEntity);
  const error = useAppSelector(selectEntityError);
  const isEntityCreated = useAppSelector(selectEntitiesIsFulfilledCreate);
  const isEntityCreating = useAppSelector(selectEntitiesIsRequestingCreate);
  const userOrganizationId = useAppSelector(selectOrganizationId);

  const isTaxFree = useWatch({ control, name: 'taxFree' });

  const toModel = useCallback(
    (data: EntityFormInputs): EntityCreateData => {
      const {
        cep,
        clientCode,
        complement,
        document,
        email,
        location,
        neighborhood,
        name,
        nickname,
        number,
        personType,
        phone,
        state,
        stateRegistration,
        street,
        taxFree,
      } = data;

      return {
        address: {
          cep,
          complement,
          location,
          neighborhood,
          number,
          state,
          street,
        },
        code: clientCode,
        corporation: PersonType.Corporation === personType,
        displayName: nickname,
        documentNumber: document,
        email,
        name,
        organizationId: userOrganizationId,
        phone: phone.replace(/\D/g, ''),
        shippingSite: false,
        stateRegistration: taxFree ? '' : stateRegistration,
      };
    },
    [userOrganizationId]
  );

  const handleOnSubmit = useCallback(
    (data: EntityFormInputs) => {
      dispatch(createEntityThunk(toModel(data)));
      dispatch(clearAddress({ resetStatus: true }));
    },
    [dispatch, toModel]
  );

  const canSubmit = useMemo(() => {
    return isValid && !isEntityCreating;
  }, [isEntityCreating, isValid]);

  const handleOnClose = useCallback(() => {
    if (onClose) {
      reset();
      dispatch(clearAddress({ resetStatus: true }));
      onClose();
    }
  }, [dispatch, onClose, reset]);

  useEffect(() => {
    if (isTaxFree) {
      clearErrors('stateRegistration');
      setValue('stateRegistration', '');
    }
  }, [clearErrors, isTaxFree, setValue]);

  useEffect(() => {
    if (createdEntity && isEntityCreated) {
      const entity = { ...createdEntity };
      dispatch(clearEntity());
      onClose(currentHandledField, entity);
    }
  }, [createdEntity, currentHandledField, dispatch, isEntityCreated, onClose]);

  return (
    <Drawer header="Novo Participante" open={showForm}>
      <GenericErrorDialog error={error} />
      <form className={classes.form} onSubmit={handleSubmit(handleOnSubmit)}>
        <Box p={3} pb={10} minHeight="calc(100% - 4.3rem)">
          {children}
        </Box>
        <Box
          borderColor="#C2C3C9"
          borderTop={1}
          className={classes.footer}
          p={2}
          width="100%"
        >
          <Button
            classes={{ root: classes.submit }}
            color="primary"
            disabled={!canSubmit && isSubmitted}
            type="submit"
            variant="contained"
          >
            Cadastrar
          </Button>
          <Button variant="text" onClick={handleOnClose}>
            Cancelar
          </Button>
        </Box>
      </form>
    </Drawer>
  );
};

export default withEntityForm<
  Omit<ParticipantNewFormDrawerProps, keyof WithEntityFormProps>
>(ParticipantNewFormDrawer);
