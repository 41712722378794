import React, { useCallback } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import {
  createPackageThunk,
  selectPackageIsRequestingCreate,
} from '../../features/Packages/packagesSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import PackageData from '../data-types/package-data';
import SubmitButton from './SubmitButton';
import withPackageForm, {
  PackageFormInputs,
  WithPackageFormProps,
} from '../hocs/withPackageForm';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    borderTopColor: theme.palette.grey['500'],
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    marginLeft: -24,
    marginRight: -24,
    padding: '20px 24px 0 24px',
  },
  formFields: {
    marginBottom: 50,
  },
  root: {
    padding: '0 24px',
  },
  submitButton: {
    '&:hover': {
      backgroundColor: '#1560AB',
    },
    backgroundColor: theme.palette.info.light,
    color: 'white',
    marginRight: '1rem',
  },
}));

interface PackageDrawerProps {
  onClose: () => void;
  onCreate: (packageId: PackageData['id']) => void;
}

const PackageDrawer = ({
  children,
  form,
  onClose,
  onCreate,
}: PackageDrawerProps & WithPackageFormProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectPackageIsRequestingCreate);

  const {
    formState: { isSubmitted, isValid },
    handleSubmit,
  } = form;

  const handleSuccess = useCallback(
    (packageData: PackageData) => {
      onCreate(packageData.id);
    },
    [onCreate]
  );

  const handleOnSubmit = useCallback(
    (formData: PackageFormInputs) => {
      dispatch(createPackageThunk(formData))
        .unwrap()
        .then((packageData: PackageData) => handleSuccess(packageData));
    },
    [dispatch, handleSuccess]
  );

  return (
    <form className={classes.root} onSubmit={handleSubmit(handleOnSubmit)}>
      <Box className={classes.formFields}>{children}</Box>
      <Box className={classes.footer}>
        <SubmitButton
          className={classes.submitButton}
          disabled={isLoading || (isSubmitted && !isValid)}
          isLoading={isLoading}
          type="submit"
          variant="contained"
        >
          Cadastrar
        </SubmitButton>
        <Button onClick={onClose}>Cancelar</Button>
      </Box>
    </form>
  );
};

export default withPackageForm<PackageDrawerProps>(PackageDrawer);
