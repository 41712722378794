import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { authenticationErrorHandlerMiddleware } from './middlewares';

import rootReducer from './rootReducer';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'shipments/downloadMinutaById/fulfilled',
          'shipments/downloadShipmentTagByType/fulfilled',
        ],
      },
    }).concat(authenticationErrorHandlerMiddleware),
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
