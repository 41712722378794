import React from 'react';

import { Box, makeStyles } from '@material-ui/core';

import Pagination from './Pagination';

const useStyles = makeStyles({
  footer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '4.25rem',
    width: '100%',
  },
  pagination: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #C2C3C9',
  },
});

interface PagedListProps {
  children?: React.ReactNode;
  className?: string;
  count: number;
  currentPage: number;
  emptyListComponent?: React.ReactNode;
  isLoading: boolean;
  rowsPerPage?: number;
  transparentFooter?: boolean;
  onPageChange: (page: number) => void;
}

const PagedList = ({
  children,
  className,
  count = 0,
  currentPage,
  emptyListComponent,
  isLoading,
  rowsPerPage = 20,
  transparentFooter = false,
  onPageChange,
}: PagedListProps) => {
  const classes = useStyles();

  return (
    <Box className={className}>
      {!isLoading && count <= 0 ? (
        emptyListComponent
      ) : (
        <>
          {children}
          <Box
            className={`${classes.footer} ${
              transparentFooter ? '' : classes.pagination
            }`}
          >
            <Pagination
              count={count}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              onChange={(_, page: number) => onPageChange(page)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default PagedList;
