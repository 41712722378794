import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import FiscalDocument from '../../data-types/fiscal-document-data';
import ShipmentPreviousDocumentsItem from './Item';
import SkeletonField from '../SkeletonField';

export interface ShipmentPreviousDocumentsProps {
  isLoading: boolean;
  previousDocuments: FiscalDocument[];
}

const ShipmentPreviousDocuments = ({
  isLoading,
  previousDocuments,
}: ShipmentPreviousDocumentsProps) => (
  <Box mb={5}>
    <SkeletonField height="large" isLoading={isLoading} width="large">
      <Typography color="primary" style={{ fontSize: 16 }}>
        Documentos Anteriores ({previousDocuments.length})
      </Typography>
    </SkeletonField>
    {previousDocuments.map((previousDocument) => (
      <Box key={previousDocument.id} mt="20px">
        <ShipmentPreviousDocumentsItem
          previousDocument={previousDocument}
          isLoading={isLoading}
        />
      </Box>
    ))}
  </Box>
);

export default ShipmentPreviousDocuments;
