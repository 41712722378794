import React from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import AlertCard from '../AlertCard';
import Modal from './Modal';

const useStyles = makeStyles((theme: Theme) => ({
  confirmationButton: {
    color: theme.palette.info.main,
  },
}));

interface DocumentNotFoundModalProps {
  documentType: 'etiquetas' | 'minutas';
  onClose: () => void;
  open: boolean;
}

const DocumentNotFoundModal = ({
  documentType,
  onClose,
  open,
}: DocumentNotFoundModalProps) => {
  const classes = useStyles();

  const handleOnClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Modal
      actions={
        <Button className={classes.confirmationButton} onClick={handleOnClose}>
          OK
        </Button>
      }
      BackdropProps={{ style: { backgroundColor: '#000' } }}
      header={`"Não há ${documentType} para esse envio"`}
      onClose={onClose}
      open={open}
    >
      <AlertCard severity="warning">
        Não foi possível encontrar <strong>{documentType}</strong> para esse
        envio.
        <br />
        <br />
        Tente novamente mais tarde. Caso persista, entre em contato.
      </AlertCard>
    </Modal>
  );
};

export default DocumentNotFoundModal;
