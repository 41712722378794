import React, { useCallback } from 'react';

import { Control } from 'react-hook-form';
import { FormControlLabel, makeStyles, Radio } from '@material-ui/core';

import RadioGroup, { UncontrolledRadioGroup } from './RadioGroup';

const useStyle = makeStyles({
  formControlLabel: {
    marginRight: 50,
  },
});

export interface RadioButtonOption {
  label: string;
  value: string;
}

export interface UncontrolledRadioButtonListProps {
  options: RadioButtonOption[];
  row?: boolean;
  value: string;
  onChange?: (value: string) => void;
}

export const UncontrolledRadioButtonList = ({
  options,
  row,
  value,
  onChange,
}: UncontrolledRadioButtonListProps) => {
  const classes = useStyle();

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange]
  );

  return (
    <UncontrolledRadioGroup value={value} row={row} onChange={handleOnChange}>
      {options.map((option) => (
        <FormControlLabel
          className={classes.formControlLabel}
          control={<Radio />}
          key={option.value}
          label={option.label}
          value={option.value}
        />
      ))}
    </UncontrolledRadioGroup>
  );
};

export interface RadioButtonListProps
  extends Omit<UncontrolledRadioButtonListProps, 'onChange' | 'value'> {
  control: Control<any>;
  name: string;
}

export const RadioButtonList = ({
  control,
  name,
  options,
  row = false,
}: RadioButtonListProps) => {
  const classes = useStyle();

  return (
    <RadioGroup control={control} name={name} row={row}>
      {options.map((option) => (
        <FormControlLabel
          className={classes.formControlLabel}
          control={<Radio />}
          key={option.value}
          label={option.label}
          value={option.value}
        />
      ))}
    </RadioGroup>
  );
};

export default RadioButtonList;
