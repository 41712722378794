import React from 'react';

import { Box } from '@material-ui/core';

import { ShipmentStatus } from '../../enums/shipment-status.enum';
import ShipmentModality from '../../enums/shipment-modality.enum';
import Status from './Item';

export interface StatusData {
  status: ShipmentStatus;
  quantity: number;
}

export interface StatusesProps {
  isLoading: boolean;
  receiptModality?: ShipmentModality;
  status?: ShipmentStatus;
  quantity?: number;
}

const Statuses = ({
  isLoading,
  receiptModality = ShipmentModality.Undefined,
  quantity = 0,
  status = ShipmentStatus.Unknown,
}: StatusesProps) => (
  <>
    <Box paddingBottom="2px" paddingTop="2px">
      <Status
        isLoading={isLoading}
        quantity={quantity}
        receiptModality={receiptModality}
        status={status}
      />
    </Box>
  </>
);

export default Statuses;
