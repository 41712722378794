import React, { useCallback, useMemo } from 'react';

import { compact } from 'lodash';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import {
  listEntitiesThunk,
  selectEntitiesFilter,
  selectEntitiesFirst,
  selectEntitiesPage,
} from '../../features/Entities/entitiesSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Chip from './Chip';
import EntityFilterData from '../data-types/entity-filter-data';

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '62%',
  },
  clearButton: {
    color: theme.palette.text.primary,
    fontSize: 12,
    marginTop: 9,
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
  root: {
    flexDirection: 'column',
    marginBottom: 10,
  },
}));

interface EntityFilterChipData {
  label: string;
  value: string;
  onRemove: () => void;
}

const EntityFilterChips = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const filter = useAppSelector(selectEntitiesFilter);
  const first = useAppSelector(selectEntitiesFirst);
  const page = useAppSelector(selectEntitiesPage);

  const handleClear = useCallback(() => {
    dispatch(
      listEntitiesThunk({
        filter: { ...filter, location: undefined, displayName: undefined },
        first,
        page: 1,
      })
    );
  }, [dispatch, filter, first]);

  const handleRemove = useCallback(
    (data: EntityFilterData) => {
      dispatch(listEntitiesThunk({ filter: data, first, page }));
    },
    [dispatch, first, page]
  );

  const chips = useMemo<EntityFilterChipData[]>(
    () =>
      compact<EntityFilterChipData>([
        filter?.displayName && {
          label: 'Nome',
          value: filter.displayName,
          onRemove: () => handleRemove({ ...filter, displayName: undefined }),
        },
        filter?.location && {
          label: 'Cidade',
          value: filter.location,
          onRemove: () => handleRemove({ ...filter, location: undefined }),
        },
      ]),
    [filter, handleRemove]
  );

  return (
    <Grid className={classes.root} container spacing={1}>
      {chips.map(({ label, value, onRemove }, index) => (
        <Grid item key={index} xs={12}>
          <Chip
            className={classes.chip}
            color="primary"
            label={label}
            size="medium"
            value={value}
            variant="outlined"
            onRemove={onRemove}
          />
        </Grid>
      ))}
      {chips.length > 0 && (
        <Grid item xs={12}>
          <Button
            className={classes.clearButton}
            variant="text"
            onClick={handleClear}
          >
            Limpar Todos
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default EntityFilterChips;
