import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

type OpenPackageIconProps = SvgIconProps;

const OpenPackageIcon = ({ ...props }: OpenPackageIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 106 94">
      <path
        d="M3.25999 42.1467C0.75999 40.84 0.00998992 37.9933 1.40999 35.7533L8.90999 23.6667C9.45999 22.7333 10.31 22.08 11.26 21.7067L50.41 1.17334C51.21 0.613336 52.21 0.333336 53.26 0.333336C54.31 0.333336 55.31 0.613336 56.11 1.17334L95.61 21.8933C96.56 22.36 97.36 23.1067 97.81 24.04L105.06 35.8C106.46 38.04 105.61 40.8867 103.26 42.1467L98.26 44.8533V68C98.26 69.7733 97.21 71.3133 95.61 72.1067L56.11 92.8267C55.31 93.3867 54.31 93.6667 53.26 93.6667C52.21 93.6667 51.21 93.3867 50.41 92.8267L10.91 72.1067C9.30999 71.3133 8.25999 69.7733 8.25999 68V42.1467C6.75999 42.94 4.85999 42.9867 3.25999 42.1467ZM53.26 10.3667V41.6333L83.06 26L53.26 10.3667ZM18.26 65.2467L48.26 81.02V49.7067L18.26 33.98V65.2467ZM88.26 65.2467V50.22L63.26 63.7533C61.61 64.5933 59.76 64.5467 58.26 63.8V81.02L88.26 65.2467ZM62.51 53.3467L93.91 36.4067L91.01 31.6933L59.61 48.6333L62.51 53.3467Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default OpenPackageIcon;
