import React, { ElementType } from 'react';

import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
} from '@material-ui/lab';

export enum SkeletonHeightSize {
  Large = 25,
  Larger = 34,
  Medium = 18,
  Small = 15,
}

export enum SkeletonWidthSize {
  Large = 211,
  Larger = 230,
  Medium = 101,
  Small = 55,
}

export interface SkeletonProps {
  animation?: 'pulse' | 'wave' | false;
  children?: React.ReactNode;
  classes?: MuiSkeletonProps['classes'];
  component?: ElementType;
  height?: SkeletonHeightSize | number | string;
  variant?: 'text' | 'rect' | 'circle';
  width?: SkeletonWidthSize | number | string;
}

const Skeleton = ({
  animation = 'wave',
  children,
  classes,
  component = 'span',
  height,
  variant = 'text',
  width,
}: SkeletonProps) => {
  return (
    <MuiSkeleton
      animation={animation}
      classes={classes}
      component={component}
      height={height}
      variant={variant}
      width={width}
    >
      {children}
    </MuiSkeleton>
  );
};

export default Skeleton;
