import React from 'react';

import {
  Timeline as MuiTimeline,
  TimelineProps as MuiTimelineProps,
} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

import { TimelineItem } from './Item';

const useStyles = makeStyles({
  timeline: {
    width: '100%',
  },
});

interface TimelineProps extends MuiTimelineProps {
  showStartDot?: boolean;
}

export const Timeline = ({
  align = 'left',
  children,
  showStartDot = false,
  ...rest
}: TimelineProps) => {
  const classes = useStyles();

  return (
    <MuiTimeline {...rest} align={align} classes={{ root: classes.timeline }}>
      {children}
      {showStartDot && (
        <TimelineItem showConnector={false}>Início</TimelineItem>
      )}
    </MuiTimeline>
  );
};
