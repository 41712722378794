import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ErrorIcon from '@material-ui/icons/Error';
import theme from '../../app/theme';

const useStyles = makeStyles({
  message: {
    alignItems: 'center',
    display: 'flex',
  },
});

interface MessageStyleAttributes {
  color: string;
  icon: React.ReactNode;
}

export interface FeedbackMessageProps {
  message: string;
  severity: 'error' | 'success' | 'warning';
  showIcon?: boolean;
  hasMarginTop?: boolean;
}

const FeedbackMessage = ({
  message,
  severity,
  showIcon = false,
  hasMarginTop = false,
}: FeedbackMessageProps) => {
  const classes = useStyles();

  const style: Record<typeof severity, MessageStyleAttributes> = {
    error: {
      color: theme.palette.error.main,
      icon: <CancelRoundedIcon />,
    },
    success: {
      color: theme.palette.success.main,
      icon: <CheckCircleIcon />,
    },
    warning: {
      color: theme.palette.warning.main,
      icon: <ErrorIcon />, // Exclamation point icon
    },
  };

  return (
    <Box
      marginTop={hasMarginTop ? '0.75rem' : ''}
      className={classes.message}
      color={style[severity].color}
    >
      {showIcon && <Box marginRight="0.75rem">{style[severity].icon}</Box>}
      <Typography component="p">{message}</Typography>
    </Box>
  );
};

export default FeedbackMessage;
