import React from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  activatedLink: {
    color: theme.palette.primary.main,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  link: {
    color: theme.palette.primary.main,
    display: 'flex',
    padding: 26,
    textDecoration: 'none',
  },
}));

export interface ToolbarLinkProps {
  icon: React.ReactNode;
  label: string;
  to: string;
}

const ToolbarLink: React.FC<ToolbarLinkProps> = ({ icon, label, to }) => {
  const classes = useStyles();

  return (
    <NavLink
      activeClassName={classes.activatedLink}
      className={classes.link}
      exact
      to={to}
    >
      <Box marginRight="10px">{icon}</Box>
      <Typography>{label}</Typography>
    </NavLink>
  );
};

export default ToolbarLink;
