import React, { useEffect, useState } from 'react';

import {
  Box,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {
  cleanRequests,
  createEntityThunk,
  selectEntitiesCreateError,
  selectEntitiesIsFulfilledCreate,
  selectEntitiesIsRequestingCreate,
} from './entitiesSlice';
import { EntityCreateData } from '../../common/clients/zordon.client';
import { PAGE_SIZES } from '../../common/constants/page-size.constants';
import { selectOrganizationId } from '../Session/sessionSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import EntityNewForm from './components/EntityNewForm';
import errorImage from '../../common/assets/images/dissatisfied-icon.svg';
import ErrorModal from '../../common/components/Modals/ErrorModal';

const useStyles = makeStyles({
  arrow: {
    color: '#1976D2',
  },
  container: {
    maxWidth: '50rem',
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
  },
});

interface Props {
  title: string;
}

const EntityNewPage = ({ title }: Props) => {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const error = useAppSelector(selectEntitiesCreateError);
  const isEntityCreated = useAppSelector(selectEntitiesIsFulfilledCreate);
  const isLoading = useAppSelector(selectEntitiesIsRequestingCreate);
  const organizationId = useAppSelector(selectOrganizationId);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isEntityCreated) {
      history.push('/entities');
      dispatch(cleanRequests());
    }
  }, [dispatch, history, isEntityCreated]);

  useEffect(() => {
    if (!!error && error.code === 'entity_document_unique_error') {
      setIsErrorModalOpen(true);
    }
  }, [error]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleOnSubmit = (data: EntityCreateData) => {
    dispatch(createEntityThunk(data));
  };

  return (
    <>
      <Box
        m="auto"
        maxWidth={PAGE_SIZES.maxWidth}
        minWidth={PAGE_SIZES.minWidth}
        pl={PAGE_SIZES.sidePadding}
        pr={PAGE_SIZES.sidePadding}
      >
        <Container className={classes.container} disableGutters>
          <Box mb={3} mt={4}>
            <Grid alignItems="center" container direction="row" spacing={3}>
              <Grid item>
                <IconButton
                  aria-label="back"
                  onClick={() => history.replace('/entities')}
                >
                  <ArrowBackIcon className={classes.arrow} fontSize="large" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.title}
                  color="primary"
                  component="h2"
                >
                  Novo Participante
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <EntityNewForm
            isLoading={isLoading}
            organizationId={organizationId}
            onSubmit={handleOnSubmit}
          />
        </Container>
      </Box>
      <ErrorModal
        image={errorImage}
        message="Por gentileza revisar as informações do Participante do envio"
        open={isErrorModalOpen}
        title="CPF ou CNPJ já cadastrado"
        onClose={() => setIsErrorModalOpen(false)}
      />
    </>
  );
};

export default EntityNewPage;
