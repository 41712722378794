export const gql = (
  stringChunks: TemplateStringsArray,
  ...expressions: unknown[]
): string => {
  return stringChunks.reduce((acc, str, index) => {
    const exp = expressions[index] || '';

    return `${acc}${str}${exp}`;
  }, '');
};
