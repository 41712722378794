import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageString,
} from './local-storage.service';
import SessionData from '../data-types/session-data';

interface SetTokenOpt {
  maxAge?: number;
}

const USER_STORAGE_KEY = 'EBB_DASHBOARD_USER';
const TOKEN_STORAGE_KEY = 'EBB_DASHBOARD_SESSION_TOKEN';
const ACTIVE_ORGANIZATION_ID_STORAGE_KEY = 'EBB_DASHBOARD_ACTIVE_ORGANIZATION';

export const getSessionData = (): SessionData | null => {
  try {
    const tokens = getTokens();
    const user = getLocalStorageItem(USER_STORAGE_KEY);

    if (!tokens) {
      return null;
    }

    const activeOrganizationId = getActiveOrganizationId(tokens);

    if (!activeOrganizationId) {
      return null;
    }

    return {
      activeOrganizationId,
      tokens,
      user: (user && JSON.parse(user)) || undefined,
    };
  } catch {
    logout();
    return null;
  }
};

export const login = ({ tokens, user }: SessionData): void => {
  setTokens(tokens);
  setLocalStorageString(USER_STORAGE_KEY, JSON.stringify(user));
  setLocalStorageString(
    ACTIVE_ORGANIZATION_ID_STORAGE_KEY,
    getActiveOrganizationId(tokens).toString()
  );
};

export const logout = (): void => {
  removeTokens();
  removeLocalStorageItem(USER_STORAGE_KEY);
};

export const getActiveOrganizationId = (
  tokens: Record<number, string>
): number => {
  const activeOrganizationId = getLocalStorageItem(
    ACTIVE_ORGANIZATION_ID_STORAGE_KEY
  );

  if (activeOrganizationId && !tokens[activeOrganizationId]) {
    return Number(Object.keys(tokens)[0]);
  }

  return activeOrganizationId
    ? Number(activeOrganizationId)
    : Number(Object.keys(tokens)[0]);
};

export const setActiveOrganizationId = (organizationId: number): void => {
  setLocalStorageString(
    ACTIVE_ORGANIZATION_ID_STORAGE_KEY,
    organizationId.toString()
  );
};

export const getActiveToken = (): string | null => {
  try {
    const tokens = getTokens();

    if (!tokens || Object.keys(tokens).length <= 0) {
      return null;
    }

    const organizationId = getActiveOrganizationId(tokens);

    return tokens[organizationId];
  } catch {
    return null;
  }
};

const getTokens = (): Record<number, string> | null => {
  const tokens = getLocalStorageItem(TOKEN_STORAGE_KEY);

  if (!tokens) {
    return null;
  }

  return JSON.parse(tokens);
};

const removeTokens = (): void => {
  removeLocalStorageItem(TOKEN_STORAGE_KEY);
};

const setTokens = (
  tokens: Record<number, string>,
  opt: SetTokenOpt = {}
): void => {
  const parsedToken = JSON.stringify(tokens);
  setLocalStorageString(TOKEN_STORAGE_KEY, parsedToken, opt);
};
