import {
  ActionReducerMapBuilder,
  AnyAction,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { remove } from 'lodash';
import { JSONSchema4Type } from 'json-schema';

import {
  associateShipmentToPickup,
  disassociateShipmentFromPickup,
  DropOffsGroupedByOrigin,
  listShipmentsGroupedByOrigin,
  logStatusUpdateError,
  PickupsGroupedByOrigin,
  removeShipment,
  ShipmentsGroupedByOrigin,
  shipmentTagStatus,
  UndefinedGroupedByOrigin,
  updateShipment,
  updateShipmentStatus,
} from '../shipmentsAPI';
import { REACT_APP_EBB_CARRIER_PARENT_ENTITY_ID } from '../../../.env';
import { RootState } from '../../../app/store';
import { ShipmentStatus } from '../../../common/enums/shipment-status.enum';
import { ShipmentUpdateData } from '../../../common/data-types/shipment-update-data';
import EntityData from '../../../common/data-types/entity-data';
import ErrorData from '../../../common/data-types/error-data';
import PickupData from '../../../common/data-types/pickup-data';
import RequestData from '../../../common/data-types/request-data';
import RequestStatus from '../../../common/enums/request-status.enum';
import ShipmentCategory from '../../../common/enums/shipment-category.enum';
import ShipmentDraftData from '../../../common/data-types/shipment-draft-data';
import ShipmentDraftFilterData from '../../../common/data-types/shipment-draft-filter-data';
import ShipmentModality from '../../../common/enums/shipment-modality.enum';

export interface ShipmentDraftUpdateOptions {
  category: ShipmentCategory;
  id: ShipmentDraftData['id'];
  modality: ShipmentModality;
  originId: EntityData['id'];
}

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  const startsWith = action.type.startsWith('shipmentDraft');
  const endsWith = action.type.endsWith('/fulfilled');

  return startsWith && endsWith;
};

const isPendingAction = (action: AnyAction): action is PendingAction => {
  const startsWith = action.type.startsWith('shipmentDraft');
  const endsWith = action.type.endsWith('/pending');

  return startsWith && endsWith;
};

const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  const startsWith = action.type.startsWith('shipmentDraft');
  const endsWith = action.type.endsWith('/rejected');

  return startsWith && endsWith;
};

// #region Action Types
interface TagErrorData {
  hasError: boolean;
  error: Record<string, any> | null;
}
interface UpdateShipmentDraftDropOffActionParams {
  data: Partial<ShipmentUpdateData>;
  options: ShipmentDraftUpdateOptions;
}

interface UpdateShipmentDraftPickupActionParams {
  data: Partial<ShipmentUpdateData>;
  options: ShipmentDraftUpdateOptions;
}

interface UpdateShipmentDraftUndefinedActionParams {
  data: Partial<ShipmentUpdateData>;
  options: ShipmentDraftUpdateOptions;
}
// #endregion Action Types

// #region Thunks
interface ShipmentDraftAssociateToPickupThunkParams {
  id: ShipmentDraftData['id'];
  identifier: PickupData['identifier'];
  originId: EntityData['id'];
}
const ASSOCIATE_SHIPMENT_DRAFT_TO_PICKUP_THUNK =
  'shipmentDraft/associateShipmentDraftToPickup';
export const associateShipmentDraftToPickupThunk = createAsyncThunk(
  ASSOCIATE_SHIPMENT_DRAFT_TO_PICKUP_THUNK,
  async ({
    id,
    identifier,
    originId,
  }: ShipmentDraftAssociateToPickupThunkParams) => {
    await associateShipmentToPickup(id, identifier);

    return { id, identifier, originId };
  }
);

interface ShipmentDraftDisassociateFromPickupThunkParams {
  id: ShipmentDraftData['id'];
  identifier: PickupData['identifier'];
  originId: EntityData['id'];
}
const DISASSOCIATE_SHIPMENT_DRAFT_FROM_PICKUP_THUNK =
  'shipmentDraft/disassociateShipmentDraftFromPickup';
export const disassociateShipmentDraftFromPickupThunk = createAsyncThunk(
  DISASSOCIATE_SHIPMENT_DRAFT_FROM_PICKUP_THUNK,
  async ({
    id,
    identifier,
    originId,
  }: ShipmentDraftDisassociateFromPickupThunkParams) => {
    await disassociateShipmentFromPickup(id, identifier);

    return { id, identifier, originId };
  }
);

const LIST_SHIPMENTS_GROUPED_BY_ORIGIN_THUNK =
  'shipmentDraft/listShipmentsGroupedByOrigin';
export const listShipmentsGroupedByOriginThunk = createAsyncThunk(
  LIST_SHIPMENTS_GROUPED_BY_ORIGIN_THUNK,
  async () => {
    const response = await listShipmentsGroupedByOrigin({
      carrierParentEntityId: +REACT_APP_EBB_CARRIER_PARENT_ENTITY_ID,
    });

    return response;
  }
);

interface ShipmentDraftLogUpdateStatusErrorThunkParams {
  context: JSONSchema4Type;
  message: string;
}
const LOG_SHIPMENT_DRAFT_UPDATE_STATUS_ERROR_THUNK =
  'shipmentDraft/logShipmentDraftUpdateStatusError';
export const logShipmentDraftUpdateStatusErrorThunk = createAsyncThunk(
  LOG_SHIPMENT_DRAFT_UPDATE_STATUS_ERROR_THUNK,
  async ({
    context,
    message,
  }: ShipmentDraftLogUpdateStatusErrorThunkParams) => {
    await logStatusUpdateError({
      context,
      message,
    });
  }
);

interface ShipmentDraftRemoveThunkParams {
  category: ShipmentCategory;
  id: ShipmentDraftData['id'];
  modality: ShipmentModality;
  originId: EntityData['id'];
}
const REMOVE_SHIPMENT_DRAFT_THUNK = 'shipmentDraft/removeShipmentDraft';
export const removeShipmentDraftThunk = createAsyncThunk(
  REMOVE_SHIPMENT_DRAFT_THUNK,
  async ({
    category,
    id,
    modality,
    originId,
  }: ShipmentDraftRemoveThunkParams) => {
    await removeShipment(id);

    return { category, id, modality, originId };
  }
);

const UPDATE_SHIPMENT_DRAFT_THUNK = 'shipmentDraft/updateShipmentDraft';
interface ShipmentDraftUpdateThunkParams {
  id: ShipmentDraftData['id'];
  data: Partial<ShipmentUpdateData>;
}
export const updateShipmentDraftThunk = createAsyncThunk(
  UPDATE_SHIPMENT_DRAFT_THUNK,
  async ({ id, data }: ShipmentDraftUpdateThunkParams) => {
    await updateShipment({ id, data });
  }
);

interface ShipmentListTagStatusThunkParams {
  id: ShipmentDraftData['id'];
  identifier: PickupData['identifier'];
  originId: EntityData['id'];
}

const SHIPMENT_LIST_TAG_STATUS_THUNK = 'shipments/shipmentListTagStatus';

export const shipmentListTagStatusThunk = createAsyncThunk(
  SHIPMENT_LIST_TAG_STATUS_THUNK,
  async ({ id, identifier, originId }: ShipmentListTagStatusThunkParams) => {
    const shipment = await shipmentTagStatus({
      id,
    });

    return {
      id,
      identifier,
      originId,
      shipment,
    };
  }
);

const UPDATE_SHIPMENT_DRAFT_STATUS_THUNK =
  'shipmentDraft/updateShipmentDraftStatus';
interface ShipmentDraftUpdateStatusThunkParams {
  category: ShipmentCategory;
  originId: EntityData['id'];
  shipment: ShipmentDraftData;
  status: ShipmentDraftData['status'];
}
export const updateShipmentDraftStatusThunk = createAsyncThunk(
  UPDATE_SHIPMENT_DRAFT_STATUS_THUNK,
  async ({
    category,
    originId,
    shipment,
    status,
  }: ShipmentDraftUpdateStatusThunkParams) => {
    await updateShipmentStatus({ id: shipment.id, status });

    return {
      category,
      originId,
      shipment,
      status,
    };
  }
);
// #endregion Thunks

// #region Slice
interface ShipmentDraftState {
  filters: ShipmentDraftFilterData;
  groupedShipments: ShipmentsGroupedByOrigin;
  requestIds: RequestData['id'][];
  requestsById: Record<RequestData['id'], RequestData>;
  tagError: Record<ShipmentDraftData['id'], TagErrorData>;
  tagStatusById: Record<ShipmentDraftData['id'], boolean>;
}

const initialState: ShipmentDraftState = {
  filters: {},
  groupedShipments: {
    dropOffs: [],
    pickups: [],
    undefined: [],
  },
  requestIds: [],
  requestsById: {},
  tagError: {},
  tagStatusById: {},
};

const shipmentDraftSlice = createSlice({
  initialState,
  name: 'shipmentDraft',
  reducers: {
    cleanRequests: (state) => {
      state.requestIds = [];
      state.requestsById = {};
    },
    updateShipmentDraftFiltersAction: (
      state,
      action: PayloadAction<ShipmentDraftFilterData>
    ) => {
      state.filters = { ...action.payload };
    },
    updateShipmentDraftDropOffAction: (
      state,
      action: PayloadAction<UpdateShipmentDraftDropOffActionParams>
    ) => {
      const { dropOffs } = state.groupedShipments;
      const {
        data,
        options: { category, id, originId },
      } = action.payload;

      const dropOff = dropOffs.find((d) => d.origin.id === originId);

      let shipment: ShipmentDraftData | undefined;

      if (category === ShipmentCategory.DeliveryReady) {
        shipment = dropOff?.shipments.find((s) => s.id === id);
      } else {
        shipment = dropOff?.pendingShipments.find((s) => s.id === id);
      }

      Object.assign(shipment, { ...data });
    },
    updateShipmentDraftPickupAction: (
      state,
      action: PayloadAction<UpdateShipmentDraftPickupActionParams>
    ) => {
      const { pickups } = state.groupedShipments;
      const {
        data,
        options: { id, originId },
      } = action.payload;

      const pickup = pickups.find((p) => p.origin.id === originId);

      const shipment = pickup?.pendingShipments.find((s) => {
        return s.id === id;
      });

      Object.assign(shipment, { ...data });
    },
    updateShipmentDraftUndefinedAction: (
      state,
      action: PayloadAction<UpdateShipmentDraftUndefinedActionParams>
    ) => {
      const { undefined } = state.groupedShipments;
      const {
        data,
        options: { id, originId },
      } = action.payload;

      const undefinedShipments = undefined.find(
        (d) => d.origin.id === originId
      );

      const shipment = undefinedShipments?.pendingShipments.find(
        (s) => s.id === id
      );

      Object.assign(shipment, { ...data });
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ShipmentDraftState>) => {
    builder
      .addCase(
        associateShipmentDraftToPickupThunk.fulfilled,
        (state, action) => {
          const { id, identifier, originId } = action.payload;

          const pickup = state.groupedShipments.pickups.find(
            (p) => p.origin.id === originId
          );

          if (pickup) {
            const shipment = remove(
              pickup.pendingShipments,
              (s) => s.id === id
            );

            pickup.pickups
              .find((p) => p.identifier === identifier)
              ?.shipments.push(...shipment);
          }
        }
      )
      .addCase(
        disassociateShipmentDraftFromPickupThunk.fulfilled,
        (state, action) => {
          const { id, identifier, originId } = action.payload;

          const pickupGroup = state.groupedShipments?.pickups.find(
            (p) => p.origin.id === originId
          );

          if (pickupGroup) {
            const pickup = pickupGroup.pickups.find(
              (g) => g.identifier === identifier
            );

            if (!pickup) {
              return;
            }

            const shipment = remove(pickup.shipments, (s) => s.id === id);

            pickupGroup.pendingShipments.push(...shipment);
          }
        }
      )
      .addCase(listShipmentsGroupedByOriginThunk.fulfilled, (state, action) => {
        state.groupedShipments = action.payload;
      })
      .addCase(removeShipmentDraftThunk.fulfilled, (state, action) => {
        const { category, id, modality, originId } = action.payload;

        if (modality === ShipmentModality.Counter) {
          const dropoff = state.groupedShipments.dropOffs.find(
            (d) => d.origin.id === originId
          );

          if (dropoff) {
            remove(
              category === ShipmentCategory.DeliveryReady
                ? dropoff.shipments
                : dropoff.pendingShipments,
              (s) => s.id === id
            );
          }
        } else if (modality === ShipmentModality.Door) {
          const pickup = state.groupedShipments.pickups.find(
            (p) => p.origin.id === originId
          );

          if (pickup) {
            remove(pickup.pendingShipments, (s) => s.id === id);
          }
        } else {
          const undefinedGroup = state.groupedShipments.undefined.find(
            (u) => u.origin.id === originId
          );

          if (undefinedGroup) {
            remove(undefinedGroup.pendingShipments, (s) => s.id === id);
          }
        }
      })
      .addCase(updateShipmentDraftStatusThunk.rejected, (state, action) => {
        const { error, meta } = action;
        const request = state.requestsById[meta.requestId];

        if (request) {
          const { shipment, status } = meta.arg;
          const { id, status: shipmentStatus } = shipment;

          const errorData: ErrorData = {
            ...error,
            details: {
              currentStatus: shipmentStatus,
              shipmentId: id,
              updatingStatusTo: status,
            },
          };

          Object.assign(request, {
            error: errorData,
            status: RequestStatus.Failed,
            type: action.type,
          });
        }
      })
      .addCase(shipmentListTagStatusThunk.fulfilled, (state, action) => {
        const {
          shipment: { shipment },
        } = action.payload;
        const { id, identifier, originId } = action.meta.arg;
        const hasVolumes = shipment?.volumes?.length > 0;

        let isTagValid;

        if (hasVolumes) {
          shipment.volumes.forEach(({ carrierLabelFile }) => {
            if (!carrierLabelFile?.filePath) {
              isTagValid = false;
            }
          });
        } else {
          isTagValid = false;
        }

        state.tagStatusById[id] = isTagValid;

        const pickupGroup = state.groupedShipments?.pickups.find(
          (p) => p.origin.id === originId
        );

        if (pickupGroup && shipment.syncedAt) {
          const pickup = pickupGroup.pickups.find(
            (g) => g.identifier === identifier
          );

          if (!pickup) {
            return;
          }

          const shipmentData = pickup.shipments.find((s) => s.id === id);

          Object.assign(shipmentData, {
            ...shipment,
            syncedAt: shipment.syncedAt,
          });
        }
      })
      .addCase(shipmentListTagStatusThunk.rejected, (state, action) => {
        state.tagError[action.meta.arg.id] = {
          hasError: !!action.error,
          error: action.error,
        };
      })
      .addCase(updateShipmentDraftStatusThunk.fulfilled, (state, action) => {
        const { category, originId, shipment, status } = action.payload;

        const dropoff = state.groupedShipments.dropOffs.find(
          (d) => d.origin.id === originId
        );

        if (dropoff) {
          const shipments =
            category === ShipmentCategory.DeliveryReady
              ? dropoff.shipments
              : dropoff.pendingShipments;

          const shipmentData = shipments.find((s) => s.id === shipment.id);

          if (shipmentData) {
            Object.assign(shipmentData, {
              ...shipment,
              status,
            });

            remove(
              category === ShipmentCategory.DeliveryReady
                ? dropoff.shipments
                : dropoff.pendingShipments,
              (s) => s.id === shipment.id
            );

            if (status === ShipmentStatus.Ready) {
              dropoff.shipments.push({ ...shipmentData });
            } else if (status === ShipmentStatus.Draft) {
              dropoff.pendingShipments.push({ ...shipmentData });
            }
          }
        }
      })
      .addMatcher(isFulfilledAction, (state, action: AnyAction) => {
        const { meta } = action;
        const request = state.requestsById[meta.requestId];

        if (!request) {
          return;
        }

        Object.assign(request, {
          error: undefined,
          status: RequestStatus.Succeeded,
          type: action.type,
        });
      })
      .addMatcher(isPendingAction, (state, action: AnyAction) => {
        const { meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Loading,
          type,
        };

        state.requestsById[requestId] = request;
      })
      .addMatcher(isRejectedAction, (state, action: AnyAction) => {
        const { error: defaultError, meta, type } = action;
        const { requestId } = meta;
        const { error } = state.requestsById[requestId];

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Failed,
          type,
          error: error || defaultError,
        };

        state.requestsById[requestId] = request;
      });
  },
});
// #endregion Slice

// #region Selectors
// #region Private Selectors
const selectShipmentDraftDropOffs = (
  rootState: RootState
): DropOffsGroupedByOrigin[] => {
  const groupedShipments = selectShipmentDraftGroupedShipments(rootState);

  return groupedShipments.dropOffs;
};

const selectShipmentsIsFullfilledByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const fulfilledType = [type, 'fulfilled'].join('/');
  const request = selectShipmentDraftRequestByType(rootState, fulfilledType);

  return request ? request.status === RequestStatus.Succeeded : false;
};

const selectShipmentsIsRequestErrorByType = (
  rootState: RootState,
  type: RequestData['type']
): ErrorData | Record<string, any> | undefined => {
  const rejectedType = [type, 'rejected'].join('/');
  const request = selectShipmentDraftRequestByType(rootState, rejectedType);

  return request ? request.error : undefined;
};

const selectShipmentDraftIsRequestingByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const pendingType = [type, 'pending'].join('/');
  const request = selectShipmentDraftRequestByType(rootState, pendingType);

  return request ? request.status === RequestStatus.Loading : false;
};

const selectShipmentDraftPickups = (
  rootState: RootState
): PickupsGroupedByOrigin[] => {
  const groupedShipments = selectShipmentDraftGroupedShipments(rootState);

  return groupedShipments.pickups;
};

const selectShipmentDraftRequests = (rootState: RootState): RequestData[] => {
  const state = selectShipmentDraftState(rootState);

  return state.requestIds.map((id) => state.requestsById[id]);
};

const selectShipmentDraftRequestByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData => {
  const requests = selectShipmentDraftRequestsByType(rootState, type);

  return requests.reverse()[0];
};

const selectShipmentDraftRequestErrorByType = (
  rootState: RootState,
  type: RequestData['type']
): ErrorData | Record<string, any> | undefined => {
  const rejectedType = [type, 'rejected'].join('/');
  const request = selectShipmentDraftRequestByType(rootState, rejectedType);

  return request ? request.error : undefined;
};

const selectShipmentDraftRequestsByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData[] => {
  const requests = selectShipmentDraftRequests(rootState);

  return requests.filter((r) => r.type === type);
};

const selectShipmentDraftState = (state: RootState): ShipmentDraftState =>
  state.shipmentDraft;

const selectShipmentDraftUndefined = (
  rootState: RootState
): UndefinedGroupedByOrigin[] => {
  const groupedShipments = selectShipmentDraftGroupedShipments(rootState);

  return groupedShipments.undefined;
};
// #endregion Private Selectors

// #region Public Selectors
export const selectShipmentDraftFilters = (
  rootState: RootState
): ShipmentDraftFilterData => {
  const state = selectShipmentDraftState(rootState);

  return state.filters;
};

export const selectShipmentDraftUpdateStatusError = (
  rootState: RootState
): ErrorData | Record<string, any> | undefined =>
  selectShipmentDraftRequestErrorByType(
    rootState,
    UPDATE_SHIPMENT_DRAFT_STATUS_THUNK
  );

export const selectShipmentDraftFilteredDropOffs = (
  rootState: RootState
): DropOffsGroupedByOrigin[] => {
  const dropOffs = selectShipmentDraftDropOffs(rootState);
  const { location, modality, state } = selectShipmentDraftFilters(rootState);

  if (!location && !modality && !state) {
    return dropOffs;
  }

  return dropOffs.filter((dropOff) => {
    const { origin } = dropOff;
    return (
      origin.address?.location.toLowerCase() === location?.toLowerCase() &&
      origin.address?.state.toLowerCase() === state?.toLowerCase() &&
      modality === ShipmentModality.Counter
    );
  });
};

export const selectShipmentDraftFilteredPickups = (
  rootState: RootState
): PickupsGroupedByOrigin[] => {
  const pickups = selectShipmentDraftPickups(rootState);
  const { location, modality, state } = selectShipmentDraftFilters(rootState);

  if (!location && !modality && !state) {
    return pickups;
  }

  return pickups.filter((pickup) => {
    const { origin } = pickup;
    return (
      origin.address?.location.toLowerCase() === location?.toLowerCase() &&
      origin.address?.state.toLowerCase() === state?.toLowerCase() &&
      modality === ShipmentModality.Door
    );
  });
};

export const selectShipmentDraftFilteredUndefined = (
  rootState: RootState
): UndefinedGroupedByOrigin[] => {
  const undefineds = selectShipmentDraftUndefined(rootState);
  const { location, modality, state } = selectShipmentDraftFilters(rootState);

  if (!location && !modality && !state) {
    return undefineds;
  }

  return undefineds.filter((undefined) => {
    const { origin } = undefined;
    return (
      origin.address?.location.toLowerCase() === location?.toLowerCase() &&
      origin.address?.state.toLowerCase() === state?.toLowerCase() &&
      modality === ShipmentModality.Undefined
    );
  });
};

export const selectShipmentDraftGroupedShipments = (
  rootState: RootState
): ShipmentsGroupedByOrigin => {
  const state = selectShipmentDraftState(rootState);

  return state.groupedShipments;
};

export const selectShipmentDraftIsRequestingList = (
  rootState: RootState
): boolean => {
  return selectShipmentDraftIsRequestingByType(
    rootState,
    LIST_SHIPMENTS_GROUPED_BY_ORIGIN_THUNK
  );
};

export const selectShipmentDraftIsRequestingUpdate = (
  rootState: RootState
): boolean => {
  return selectShipmentDraftIsRequestingByType(
    rootState,
    UPDATE_SHIPMENT_DRAFT_THUNK
  );
};

export const selectShipmentDraftIsFulfilledUpdate = (
  rootState: RootState
): boolean => {
  return selectShipmentsIsFullfilledByType(
    rootState,
    UPDATE_SHIPMENT_DRAFT_THUNK
  );
};

export const selectShipmentDraftIsErrorUpdate = (
  rootState: RootState
): ErrorData | Record<string, any> | undefined => {
  return selectShipmentsIsRequestErrorByType(
    rootState,
    UPDATE_SHIPMENT_DRAFT_THUNK
  );
};

export const selectShipmentTagError = (
  rootState: RootState
): ShipmentDraftState['tagError'] => {
  const shipments = selectShipmentDraftState(rootState);

  return shipments.tagError;
};

export const selectTagStatus = (
  rootState: RootState
): ShipmentDraftState['tagStatusById'] => {
  const shipments = selectShipmentDraftState(rootState);

  return shipments.tagStatusById;
};
// #endregion Public Selectors
// #endregion Selectors

export const {
  cleanRequests,
  updateShipmentDraftDropOffAction,
  updateShipmentDraftFiltersAction,
  updateShipmentDraftPickupAction,
  updateShipmentDraftUndefinedAction,
} = shipmentDraftSlice.actions;

export const shipmentDraftReducer = shipmentDraftSlice.reducer;
