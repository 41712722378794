import { getActiveToken } from '../services/authentication.service';
import { HttpClient, HttpResponse } from './http-client/http.client';
import TokenType from '../enums/token-type.enum';

// #region Typings

interface BrudamSyncerClientDependencies {
  host: string;
  httpClient: HttpClient;
}

// #endregion Typings

class BrudamSyncerClient {
  private host: string;

  private httpClient: HttpClient;

  constructor({ host, httpClient }: BrudamSyncerClientDependencies) {
    this.host = host;
    this.httpClient = httpClient;
  }

  async downloadMinutaById(id: number): Promise<HttpResponse<Blob>> {
    const url = this.buildUrl(`/minutas/print/${id}`);
    const reqArgs = this.signRequest({ url });

    return this.httpClient.getBlob(reqArgs);
  }

  private buildUrl(path: string): string {
    return [this.host, path]
      .filter(Boolean)
      .map((p) => p.replace(/^\//, '').replace(/\/$/, ''))
      .join('/');
  }

  private signRequest<T>(
    reqArgs: T,
    tokenType?: TokenType
  ): T & { headers: { Authorization: string } } {
    const token = getActiveToken();
    return {
      ...(reqArgs as any),
      headers: {
        Authorization: `Bearer ${token}`,
        ...(tokenType ? { 'authorization-Token-Type': tokenType } : {}),
      },
    };
  }
}
export default BrudamSyncerClient;
