import React from 'react';

import { Button, makeStyles, Theme, Typography } from '@material-ui/core';

import { selectUserIsRequestingUpdate } from '../../features/Users/usersSlice';
import { useAppSelector } from '../../app/hooks';
import Modal from './Modals/Modal';
import UserStatus from '../enums/user-status-type.enum';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    color: theme.palette.info.main,
  },
  titleIcon: {
    fontWeight: 'bolder',
  },
}));

interface ChangeStatusConfirmationDialogProps {
  currentStatus: UserStatus;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ChangeStatusConfirmationDialog = ({
  currentStatus,
  isOpen,
  onCancel,
  onConfirm,
}: ChangeStatusConfirmationDialogProps) => {
  const classes = useStyles();

  const isLoading = useAppSelector(selectUserIsRequestingUpdate);

  return (
    <Modal
      actions={
        <>
          <Button
            className={classes.actionButton}
            disabled={isLoading}
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button
            className={classes.actionButton}
            disabled={isLoading}
            onClick={onConfirm}
          >
            Confirmar
          </Button>
        </>
      }
      header={
        <Typography className={classes.titleIcon}>
          {currentStatus === UserStatus.Active ? 'Desativar' : 'Ativar'} esse
          usuário ?
        </Typography>
      }
      open={isOpen}
    >
      {currentStatus === UserStatus.Active
        ? 'Tem certeza que deseja desativar esse usuário? Este usuário ficará inativo e não conseguirá ter acesso ao sistema'
        : 'Tem certeza que deseja ativar esse usuário? Este usuário ficara ativo e terá acesso ao sistema'}
    </Modal>
  );
};

export default ChangeStatusConfirmationDialog;
