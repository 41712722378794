import {
  AnyAction,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import {
  createPackage,
  findPackage,
  listPackages,
  PackageCreateParams,
  PackageUpdateParams,
  removePackage,
  updatePackage,
} from './packagesAPI';
import { RootState } from '../../app/store';
import PackageData from '../../common/data-types/package-data';
import RequestData from '../../common/data-types/request-data';
import RequestStatus from '../../common/enums/request-status.enum';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  const startsWith = action.type.startsWith('packages');
  const endsWith = action.type.endsWith('/fulfilled');

  return startsWith && endsWith;
};

const isPendingAction = (action: AnyAction): action is PendingAction => {
  const startsWith = action.type.startsWith('packages');
  const endsWith = action.type.endsWith('/pending');

  return startsWith && endsWith;
};

const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  const startsWith = action.type.startsWith('packages');
  const endsWith = action.type.endsWith('/rejected');

  return startsWith && endsWith;
};

// #region Thunks
const FIND_PACKAGE_THUNK = 'packages/find';
interface FindPackageThunkParams {
  id: PackageData['id'];
}
export const findPackageThunk = createAsyncThunk(
  FIND_PACKAGE_THUNK,
  async ({ id }: FindPackageThunkParams) => {
    return findPackage({ id });
  }
);

const LIST_PACKAGES_THUNK = 'packages/list';
export const listPackagesThunk = createAsyncThunk(
  LIST_PACKAGES_THUNK,
  async () => listPackages()
);

const PACKAGE_CREATE_THUNK = 'packages/create';
export const createPackageThunk = createAsyncThunk(
  PACKAGE_CREATE_THUNK,
  async ({ description, height, length, width }: PackageCreateParams) => {
    return createPackage({
      description,
      height,
      length,
      width,
    });
  }
);

const PACKAGE_REMOVED_THUNK = 'packages/remove';
interface PackageRemoveThunkParams {
  id: PackageData['id'];
}
export const packageRemoveThunk = createAsyncThunk(
  PACKAGE_REMOVED_THUNK,
  async ({ id }: PackageRemoveThunkParams) => {
    return removePackage({ id });
  }
);

const PACKAGE_UPDATE_THUNK = 'packages/update';
export const packageUpdateThunk = createAsyncThunk(
  PACKAGE_UPDATE_THUNK,
  async (params: PackageUpdateParams) => {
    return updatePackage(params);
  }
);
// #endregion Thunks

// #region Slice
interface PackagesState {
  package: PackageData | null;
  packages: PackageData[];
  requestIds: RequestData['id'][];
  requestsById: Record<RequestData['id'], RequestData>;
}

const initialState: PackagesState = {
  package: null,
  packages: [],
  requestIds: [],
  requestsById: {},
};

const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    cleanPackage: (state) => {
      state.package = null;
    },
    cleanRequests: (state) => {
      state.requestIds = [];
      state.requestsById = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPackageThunk.fulfilled, (state, action) => {
        state.packages = uniqBy([...state.packages, action.payload], 'id');
      })
      .addCase(findPackageThunk.fulfilled, (state, action) => {
        const packageData = action.payload;

        state.package = packageData;
      })
      .addCase(packageRemoveThunk.fulfilled, (state, action) => {
        state.packages = state.packages?.filter(
          (e) => e.id !== action.meta.arg.id
        );
      })
      .addCase(listPackagesThunk.fulfilled, (state, action) => {
        const { packages } = action.payload;

        state.packages = packages || [];
      })
      .addMatcher(isFulfilledAction, (state, action: AnyAction) => {
        const { meta } = action;
        const request = state.requestsById[meta.requestId];

        if (!request) {
          return;
        }

        Object.assign(request, {
          error: undefined,
          status: RequestStatus.Succeeded,
          type: action.type,
        });
      })
      .addMatcher(isPendingAction, (state, action: AnyAction) => {
        const { meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Loading,
          type,
        };

        state.requestsById[requestId] = request;
      })
      .addMatcher(isRejectedAction, (state, action: AnyAction) => {
        const { error, meta, type } = action;
        const { requestId } = meta;

        state.requestIds.push(requestId);

        const request: RequestData = {
          id: requestId,
          status: RequestStatus.Failed,
          type,
          error,
        };

        state.requestsById[requestId] = request;
      });
  },
});
// #endregion Slice

// #region Selectors
// #region Private Selectors
const selectPackagesState = (state: RootState): PackagesState => state.packages;

const selectPackagesIsFulfilledByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const fulfilledType = [type, 'fulfilled'].join('/');
  const request = selectPackagesRequestByType(rootState, fulfilledType);

  return request ? request.status === RequestStatus.Succeeded : false;
};

const selectPackagesIsRejectedByType = (
  rootState: RootState,
  type: RequestData['type']
): Record<string, any> | null => {
  const rejectedType = [type, 'rejected'].join('/');
  const request = selectPackagesRequestByType(rootState, rejectedType);

  return request && request?.status === RequestStatus.Failed && request.error
    ? request.error
    : null;
};

const selectPackagesIsRequestingByType = (
  rootState: RootState,
  type: RequestData['type']
): boolean => {
  const pendingType = [type, 'pending'].join('/');
  const request = selectPackagesRequestByType(rootState, pendingType);

  return request ? request.status === RequestStatus.Loading : false;
};

const selectPackagesRequestByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData => {
  const requests = selectPackagesRequestsByType(rootState, type);

  return requests.reverse()[0];
};

const selectPackagesRequests = (rootState: RootState): RequestData[] => {
  const state = selectPackagesState(rootState);

  return state.requestIds.map((id) => state.requestsById[id]);
};

const selectPackagesRequestsByType = (
  rootState: RootState,
  type: RequestData['type']
): RequestData[] => {
  const requests = selectPackagesRequests(rootState);

  return requests.filter((r) => r.type === type);
};
// #endregion Private Selectors

// #region Public Selectors
export const selectPackage = (state: RootState): PackageData | null => {
  const packageState = selectPackagesState(state);

  return packageState.package;
};

export const selectPackages = (state: RootState): PackageData[] => {
  const packageState = selectPackagesState(state);

  return packageState.packages;
};

export const selectPackagesIsRejectedList = (
  rootState: RootState
): Record<string, any> | null => {
  return selectPackagesIsRejectedByType(rootState, LIST_PACKAGES_THUNK);
};

export const selectPackagesIsRequestingList = (
  rootState: RootState
): boolean => {
  return selectPackagesIsRequestingByType(rootState, LIST_PACKAGES_THUNK);
};

export const selectPackageIsFulfilledCreate = (
  rootState: RootState
): boolean => {
  return selectPackagesIsFulfilledByType(rootState, PACKAGE_CREATE_THUNK);
};

export const selectPackageIsRejectedCreate = (
  rootState: RootState
): Record<string, any> | null => {
  return selectPackagesIsRejectedByType(rootState, PACKAGE_CREATE_THUNK);
};

export const selectPackageIsRequestingCreate = (
  rootState: RootState
): boolean => {
  return selectPackagesIsRequestingByType(rootState, PACKAGE_CREATE_THUNK);
};

export const selectPackagesIsFulfilledUpdate = (
  rootState: RootState
): boolean => {
  return selectPackagesIsFulfilledByType(rootState, PACKAGE_UPDATE_THUNK);
};

export const selectPackagesIsRejectedUpdate = (
  rootState: RootState
): Record<string, any> | null => {
  return selectPackagesIsRejectedByType(rootState, PACKAGE_UPDATE_THUNK);
};

export const selectPackagesIsRequestingUpdate = (
  rootState: RootState
): boolean => {
  return selectPackagesIsRequestingByType(rootState, PACKAGE_UPDATE_THUNK);
};

export const selectPackageIsFulfilledRemove = (
  rootState: RootState
): boolean => {
  return selectPackagesIsFulfilledByType(rootState, PACKAGE_REMOVED_THUNK);
};

export const selectPackageIsRequestingRemove = (
  rootState: RootState
): boolean => {
  return selectPackagesIsRequestingByType(rootState, PACKAGE_REMOVED_THUNK);
};
// #endregion Public Selectors
// #endregion Selectors

export const { cleanPackage, cleanRequests } = packagesSlice.actions;

export const packagesReducer = packagesSlice.reducer;
