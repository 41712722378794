import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/BlockRounded';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';

import {
  cleanRequests,
  selectUserIsFulfilledUpdate,
  selectUserErrorUpdate,
  selectUsersById,
} from '../../../usersSlice';
import { phoneNumberFormatter } from '../../../../../common/helpers/phone-input.helper';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import AlertCard from '../../../../../common/components/AlertCard';
import ChangeStatusConfirmationDialog from '../../../../../common/components/ChangeStatusConfirmationDialog';
import Menu from '../../../../../common/components/Menu';
import MenuItem from '../../../../../common/components/MenuItem';
import SkeletonField from '../../../../../common/components/SkeletonField';
import UserCheckIcon from '../../../../../common/components/UserCheckIcon';
import UserListData from '../../../../../common/data-types/user-list-data';
import UserStatus from '../../../../../common/enums/user-status-type.enum';

const useStyles = makeStyles((theme: Theme) => ({
  actionIcon: {
    marginRight: '10px',
  },
  cardBody: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 5,
    padding: '0 0.625rem',
  },
  cardContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  disabled: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  inactive: {
    fontSize: 14,
    fontStyle: 'italic',
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
  },
  link: {
    alignItems: 'center',
    color: 'inherit',
    display: 'flex',
    padding: '0rem',
    textDecoration: 'none',
  },
  menu: {
    padding: 0,
  },
  menuContainer: {
    display: 'flex',
    justifyContent: 'end',
    paddingLeft: '0.625rem',
  },
  menuIcon: {
    color: theme.palette.primary.main,
  },
  menuItem: {
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
  },
  name: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  root: {
    '&:first-child': {
      borderTop: '1px solid #C2C3C9',
    },
    backgroundColor: 'white',
    borderBottom: '1px solid #C2C3C9',
    borderLeft: '1px solid #C2C3C9',
    borderRight: '1px solid #C2C3C9',
    padding: '16px 0.625rem 1rem 1.25rem',
  },
  value: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface UserListItemProps {
  isLoading: boolean;
  userId: number;
  onUpdate: (userId: UserListData['id'], status: boolean) => void;
}

const UserListItem = ({ isLoading, userId, onUpdate }: UserListItemProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const usersById = useAppSelector(selectUsersById);
  const isUpdated = useAppSelector(selectUserIsFulfilledUpdate);
  const error = useAppSelector(selectUserErrorUpdate);

  const user = useMemo<UserListData>(() => usersById[userId], [
    userId,
    usersById,
  ]);

  const isActive = useMemo<boolean>(() => {
    return !!user?.active;
  }, [user?.active]);

  const isAdmin = useMemo<boolean>(() => {
    return !!user?.isAdmin;
  }, [user?.isAdmin]);

  const labelClasses = useMemo<string>(() => {
    return isActive ? classes.label : `${classes.label} ${classes.disabled}`;
  }, [classes.disabled, classes.label, isActive]);

  const nameClasses = useMemo<string>(() => {
    return isActive ? classes.name : `${classes.value} ${classes.disabled}`;
  }, [classes.disabled, classes.name, classes.value, isActive]);

  const valueClasses = useMemo<string>(() => {
    return isActive ? classes.value : `${classes.value} ${classes.disabled}`;
  }, [classes.disabled, classes.value, isActive]);

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleModalClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleChangeUserStatus = () => {
    onUpdate(userId, !isActive);
  };

  useEffect(() => {
    if (isUpdated) {
      handleModalClose();

      dispatch(cleanRequests());
    }
  }, [dispatch, handleModalClose, isUpdated]);

  useEffect(() => {
    if (error) {
      handleModalClose();
    }
  }, [dispatch, error, handleModalClose]);

  return (
    <>
      <Grid className={classes.root} container>
        <Grid item xs={3}>
          <SkeletonField isLoading={isLoading} width="small">
            <Typography className={labelClasses}>Nome</Typography>
          </SkeletonField>
          <SkeletonField isLoading={isLoading} width="large">
            <Typography className={nameClasses}>{user?.name}</Typography>
          </SkeletonField>
        </Grid>
        <Grid item xs={4}>
          <SkeletonField isLoading={isLoading} width="small">
            <Typography className={labelClasses}>E-mail</Typography>
          </SkeletonField>
          <SkeletonField isLoading={isLoading} width="large">
            <Typography className={valueClasses}>{user?.email}</Typography>
          </SkeletonField>
        </Grid>
        <Grid item xs={2}>
          <SkeletonField isLoading={isLoading} width="small">
            <Typography className={labelClasses}>Telefone</Typography>
          </SkeletonField>
          <SkeletonField isLoading={isLoading} width="large">
            <Typography className={valueClasses}>
              {user?.cellphone ? phoneNumberFormatter(user.cellphone) : '---'}
            </Typography>
          </SkeletonField>
        </Grid>
        <Grid className={classes.cardContainer} item xs={2}>
          {!isLoading && isActive && isAdmin && (
            <AlertCard
              classes={{ root: classes.cardBody }}
              icon={false}
              severity="info"
            >
              Administrador
            </AlertCard>
          )}
          {!isLoading && !isActive && (
            <Typography className={classes.inactive}>Inativo</Typography>
          )}
        </Grid>
        <Grid className={classes.menuContainer} item xs={1}>
          <Menu
            buttonChildren={
              <MoreVertIcon classes={{ root: classes.menuIcon }} />
            }
            buttonClasses={{ root: classes.menu }}
            elevation={1}
            open={openMenu}
            onButtonClick={() => setOpenMenu((prevState) => !prevState)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>
              <Link className={classes.link} to={`users/${userId}/edit`}>
                <EditIcon classes={{ root: classes.actionIcon }} />
                Alterar
              </Link>
            </MenuItem>
            {isActive ? (
              <MenuItem
                classes={{ root: classes.menuItem }}
                disableGutters
                onClick={handleModalOpen}
              >
                <BlockIcon classes={{ root: classes.actionIcon }} />
                Desativar
              </MenuItem>
            ) : (
              <MenuItem
                classes={{ root: classes.menuItem }}
                disableGutters
                onClick={handleModalOpen}
              >
                <UserCheckIcon classes={{ root: classes.actionIcon }} />
                Ativar
              </MenuItem>
            )}
          </Menu>
        </Grid>
      </Grid>
      <ChangeStatusConfirmationDialog
        currentStatus={isActive ? UserStatus.Active : UserStatus.Inactive}
        isOpen={openModal}
        onCancel={handleModalClose}
        onConfirm={handleChangeUserStatus}
      />
    </>
  );
};

export default UserListItem;
