enum UserStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export const USER_STATUS_MAP = new Map([
  [UserStatus.Active, 'Ativo'],
  [UserStatus.Inactive, 'Inativo'],
]);

export default UserStatus;
