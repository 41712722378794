import React from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';

import { Grid, makeStyles, Typography } from '@material-ui/core';

import Modal from './Modal';

const useStyles = makeStyles({
  content: {
    padding: '0 2rem 4rem 2rem',
    width: '750px',
  },
  description: {
    fontSize: 18,
    marginBottom: 10,
    wordWrap: 'break-word',
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
  },
});

interface SuccessModalProps {
  message?: string;
  open: boolean;
  title: string;
  onClose(): void;
}

const SuccessModal = ({ message, open, title, onClose }: SuccessModalProps) => {
  const classes = useStyles();

  return (
    <Modal
      maxWidth="lg"
      open={open}
      PaperProps={{ elevation: 0, square: true }}
      onClose={onClose}
    >
      <Grid
        alignItems="center"
        className={classes.content}
        container
        justifyContent="center"
      >
        <Grid item xs={2}>
          <CheckCircle color="primary" style={{ width: 80, height: 80 }} />
        </Grid>
        <Grid item xs={7}>
          <Typography className={classes.title} variant="h1" color="primary">
            {title}
          </Typography>
          <Typography className={classes.description}>{message}</Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SuccessModal;
